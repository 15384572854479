/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  SelectPicker,
  Table,
  Pagination,
  IconButton,
  Whisper,
  Popover,
  Dropdown,
} from "rsuite";
import FilterGeneralDateSelect from "../select/FilterGeneralDateSelect";
import naira from "./../../../asset/svgs/NairaBlack.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatDate, formatDateWithDay } from "../../../utils";

const { Column, HeaderCell, Cell } = Table;

export default function TransactionHistory({ transactions, user }) {
  // states
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [generalFilter, setGeneralFilter] = useState("All time");
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [transactionData, setTransactionData] = useState();

  //datas
  const categoryOptions = ["payday", "subscription", "funding"].map((item) => {
    return {
      label: item,
      value: item,
    };
  });
  const typeOptions = ["credit", "debit"].map((item) => {
    return {
      label: item,
      value: item,
    };
  });
  const statusOption = ["success", "pending", "failed"].map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const filteredData = transactions?.filter((transaction) => {
    if (generalFilter === "All time") {
      return (
        transaction?.category === (category || transaction?.category) &&
        transaction?.status === (status || transaction?.status) &&
        transaction?.type === (type || transaction?.type)
      ); // Return true to include all transactions
    }

    const filterDate = new Date(generalFilter);
    const isFilterDateValid = !isNaN(filterDate) && filterDate instanceof Date;

    if (isFilterDateValid) {
      return (
        formatDate(transaction?.date_created) === generalFilter.join("") &&
        transaction?.category === (category || transaction?.category) &&
        transaction?.status === (status || transaction?.status) &&
        transaction?.type === (type || transaction?.type)
      );
    } else {
      return (
        transaction?.category === (category || transaction?.category) &&
        transaction?.status === (status || transaction?.status) &&
        transaction?.type === (type || transaction?.type)
      );
    }
  });

  const data = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  //components
  const MoneyCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <img src={naira} alt="money" />
          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover visible>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={1}>
            <div className="flex items-center gap-2">
              <VisibilityIcon fontSize="16px" />
              <span className="text-txgray2 text-sm font-bold">
                {" "}
                View details
              </span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="autoVerticalEnd" trigger="click" speaker={speaker}>
          <IconButton
            appearance="subtle"
            style={{ padding: 0 }}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                  fill="#475569"
                />
              </svg>
            }
          />
        </Whisper>
      </Cell>
    );
  };

  const StatusCell = ({ rowData, dataKey, ...props }) => {
    if (rowData[dataKey] === "success") {
      return (
        <Cell {...props}>
          <div className="bg-[#ECFDF5] rounded py-[2px] px-2 flex gap-2 items-center justify-center">
            <span className="block w-2 h-2 bg-[#059669] rounded-full"></span>
            <span className="text-[#059669]">{rowData[dataKey]}</span>
          </div>
        </Cell>
      );
    } else if (rowData[dataKey] === "pending") {
      return (
        <Cell {...props}>
          <div className="bg-[#FFFBEB] rounded py-[2px] px-2 flex gap-2 items-center justify-center">
            <span className="block w-2 h-2 bg-[#D97706] rounded-full"></span>
            <span className="text-[#D97706]">{rowData[dataKey]}</span>
          </div>
        </Cell>
      );
    } else if (
      rowData[dataKey] === "declined" ||
      rowData[dataKey] === "failed"
    ) {
      return (
        <Cell {...props}>
          <div className="bg-[#e11d472d] rounded py-[2px] px-2 flex gap-2 items-center justify-center">
            <span className="block w-2 h-2 bg-[#E11D48] rounded-full"></span>
            <span className="text-[#E11D48]">{rowData[dataKey]}</span>
          </div>
        </Cell>
      );
    }
  };

  const DateCell = ({ rowData, dataKey, ...props }) => {
    return <Cell {...props}>{formatDateWithDay(rowData[dataKey])}</Cell>;
  };

  const CategoryCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} className="capitalize">
        {rowData[dataKey] === "pay_day" ? "Payday" : rowData[dataKey]}
      </Cell>
    );
  };

  //functions
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  return (
    <div className="mt-10 bg-[#FFFFFF] p-2 px-4 lg:px-10 py-6">
      <p className="mb-8 text-[#334155] font-bold text-lg">Transactions</p>
      <div className="flex justify-between gap-2 flex-col lg:flex-row">
        <div className="flex gap-2 w-full">
          <SelectPicker
            placeholder="Category"
            data={categoryOptions}
            value={category}
            onChange={setCategory}
            size="lg"
            className="w-full md:w-[200px]"
          />
          <SelectPicker
            placeholder="Type"
            data={typeOptions}
            value={type}
            onChange={setType}
            size="lg"
            className="w-full md:w-[200px]"
          />
        </div>
        <div className="flex gap-2">
          <FilterGeneralDateSelect
            value={generalFilter}
            setValue={setGeneralFilter}
            style={{ width: "100%" }}
            createdDate={user.date_created}
          />
          <SelectPicker
            placeholder="Status"
            data={statusOption}
            value={status}
            onChange={setStatus}
            size="lg"
            className="w-full md:w-[200px] ml-1"
          />
        </div>
      </div>
      <Table
        height={400}
        data={data}
        className="border-[1px] border-[#F1F5F9] rounded-lg mt-4"
      >
        <Column width={120} align="left">
          <HeaderCell>Category</HeaderCell>
          <CategoryCell dataKey="category" />
        </Column>
        <Column width={120} align="left">
          <HeaderCell>Date</HeaderCell>
          <DateCell dataKey="date_created" />
        </Column>
        <Column width={140}>
          <HeaderCell>Amount</HeaderCell>
          <MoneyCell dataKey="amount" />
        </Column>
        <Column width={200}>
          <HeaderCell>Transaction REF</HeaderCell>
          <Cell dataKey="reference" />
        </Column>
        <Column width={120}>
          <HeaderCell>Status</HeaderCell>
          <StatusCell dataKey="status" />
        </Column>
        <Column width={100}>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="type" />
        </Column>

        <Column width={70}>
          <HeaderCell>
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0V14H18V0H0ZM5.33 12H2V2H5.33V12ZM10.67 12H7.34V2H10.67V12ZM16 12H12.67V2H16V12Z"
                fill="#475569"
              />
            </svg>
          </HeaderCell>
          <ActionCell dataKey="id" />
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={transactions?.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  );
}
