import React from "react";
import { Modal } from "rsuite";
import close from "./../../../../asset/svgs/close-circle.svg";
import lamp from "./../../../../asset/svgs/lamp-on.svg";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordInput from "../../../dashboard-component/inputs/PasswordInput";
import BlueBotton from "../../../../component/atoms/BlueBotton";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import toast from "react-hot-toast";

export default function RevertPayrollSetup({ open, onClose, archiveData }) {
  console.log(archiveData);
  const schema = yup.object().shape({
    password: yup.string().required("please enter password"),
  });
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: schema,
      onSubmit: (values, { resetForm }) => {
        resetForm();
        mutation.mutate({
          password: values.password,
        });
      },
    });

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/auth/verify-password-for-change", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      revertMutation.mutate({
        payroll_archive_id: archiveData?.id,
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
  const revertMutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/payroll/revert-archive", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  return (
    <Modal sx={{ padding: "16px 24px" }} open={open} onClose={onClose}>
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="text-txgray2 text-[22px] font-semibold">
          Revert payroll setup
        </p>
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
      </div>

      <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded mb-4">
        <img src={lamp} alt="close" />
        <p>
          Reverting to {archiveData?.name} will permanently clear & archive the
          existing payroll setup and update this as the current payroll setup.
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <p className="mb-3">To confirm, please type in your password</p>
        <PasswordInput
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          values={values.password}
        />
        <div className="flex gap-6 justify-end mt-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
          <BlueBotton label="Revert" loading={mutation?.isPending} />
        </div>
      </form>
    </Modal>
  );
}
