import React from "react";
import SignUpLayout from "../layout/SignUpLayout";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../utils/https";
import { NameToInitials, setToken, setUser } from "../../utils";
import Loader from "../../component/atoms/Loader";
import { useDispatch } from "react-redux";
import { getTenantDetails } from "../../store/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import LineButton from "../../component/atoms/LineButton";

const SelectWorkSpace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "";

  //query
  const { data: workspaces, isLoading } = useQuery({
    queryKey: ["workspaces"],
    queryFn: async () => {
      const response = await axiosInstance().get("/auth/workspaces");
      return response?.data?.data?.results;
    },
  });

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/auth/workspace/singin", payload),
    onSuccess: (data) => {
      const { access_token, expires_in, refresh_token, scope } =
        data?.data?.data;
      setToken(access_token);
      setUser({ refresh_token, scope, expires_in });
      GetTenantDetails();
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  //functions
  const GetTenantDetails = async () => {
    const res = await dispatch(getTenantDetails());
    const tenant_detail = res?.payload?.data?.data;
    if (res.payload.status >= 400) {
      toast.error(res.payload.message);
    } else {
      toast.success("SignIn successfull");
      const onboarding_settings =
        tenant_detail?.onboarding_levels_completed?.map(
          (item) => item.completed
        );
      if (onboarding_settings.every((item) => item === true)) {
        setTimeout(() => {
          toast.success("Redirecting to dashboard, please wait...");
          navigate("/dashboard");
        }, 1100);
      } else {
        setTimeout(() => {
          if (from === "") {
            navigate("/auth/onboarding");
          } else {
            navigate(from, { replace: true });
          }
        }, 2200);
      }
    }
  };

  return (
    <SignUpLayout>
      <div className="flex flex-col items-center justify-center">
        <div className="max-w-[523px] flex flex-col items-center text-center">
          <h2 className="text-lg font-[700] w-full mb-8">Select a workspace</h2>
          <p>
            Tap the sign in button to the organization you’d like to have access
            to.
          </p>
          <div className="flex flex-col mt-9 w-full border-t-[1px] border-[#E2E8F0]">
            {isLoading ? (
              <div className="flex justify-center items-center mt-[10rem]">
                <Loader />
              </div>
            ) : (
              workspaces?.map((_, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between px-4 py-3 border-b-[1px]  border-[#E2E8F0]"
                >
                  <div className="flex items-center gap-3">
                    <div className="items-center justify-center flex bg-slate-300 w-8 h-8 rounded-full text-txgray font-semibold ">
                      {NameToInitials(_.tenant_name)}
                    </div>
                    <p className="text-txgray2 text-sm font-bold">
                      {_.tenant_name}
                    </p>
                  </div>
                  <LineButton
                    label="Sign in"
                    onClick={() =>
                      mutation.mutate({
                        team_workspace_id: _.team_id,
                      })
                    }
                    loading={mutation.isPending}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default SelectWorkSpace;
