/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateRangeSinceCreated, getTimeOfDay } from "../../utils";
import morning from "./../../asset/svgs/morning.svg";
import afternoon from "./../../asset/svgs/afternoon.svg";
import evening from "./../../asset/svgs/evening.svg";
import WalletBalance from "../dashboard-component/cards/WalletBalance";
import AmountFunded from "../dashboard-component/cards/AmountFunded";
import AmountSpent from "../dashboard-component/cards/AmountSpent";
import PaydayStatistics from "../dashboard-component/cards/PaydayStatistics";
import EmployeeNumber from "../dashboard-component/cards/EmployeeNumber";
import BranchNumber from "../dashboard-component/cards/BranchNumber";
import { GetPayrollSetup } from "../../store/slices/PayrollSlice";
import { GetBranches } from "../../store/slices/userSlice";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/https";
import { GetAllEmployees } from "../../store/slices/PayrollSlice";
import TransactionHistory from "../dashboard-component/cards/TransactionHistory";
import { PayrollRequestAlert } from "../dashboard-component/alerts/PayrollRequestAlert";
import { toast } from "react-toastify";

import Loader from "../../component/atoms/Loader";

const DashboardIndex = () => {
  const [setTitle] = useOutletContext();
  const { user } = useSelector((state) => state?.users);
  const dispatch = useDispatch();
  const { allBranches } = useSelector((state) => state.users);
  const { allEmployees } = useSelector((state) => state?.payroll);
  const [showRequest, setShowRequest] = useState(false);
  const [value, setValue] = useState(
    DateRangeSinceCreated(user.date_created)[
      DateRangeSinceCreated(user.date_created).length - 1
    ]
  );

  // Async functions
  const getBranches = async () => {
    await dispatch(GetBranches());
  };
  const getEmployees = async () => {
    await dispatch(GetAllEmployees());
  };
  const getPayrollSetup = async () => {
    const res = await dispatch(GetPayrollSetup());
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  //queries
  const { data: dashboardData, isPending } = useQuery({
    queryKey: ["dashboardData", value],
    queryFn: async () => {
      const response = await axiosInstance().get(
        `/tenants/dashboard?year=${value}`
      );
      return response?.data?.data;
    },
  });
  const { data: userDetails, isLoadeing: detailsIsLoading } = useQuery({
    queryKey: ["userDetails"],
    queryFn: async () => {
      const response = await axiosInstance().get(
        `users/team-workspaces/details`
      );
      return response?.data?.data;
    },
  });

  const { data: branchPayroll } = useQuery({
    queryKey: ["branchesPayrollRecord"],
    queryFn: async () => {
      const response = await axiosInstance().get(`branches/payroll-records`);
      return response?.data?.data?.results;
    },
  });

  //checks

  const payrollRequest = branchPayroll?.some(
    (item) => item.current_month_payroll_record_status === "pending"
  );

  useEffect(() => {
    setTitle("Dashboard");
    getBranches();
    if (allBranches?.length <= 0) {
      getBranches();
    } else if (!allBranches) {
      getBranches();
    }

    if (allEmployees?.length <= 0) {
      getEmployees();
    } else if (!allEmployees) {
      getEmployees();
    }
    if (
      payrollRequest &&
      !userDetails?.is_tenant_admin &&
      userDetails?.user_permissions[1]?.payroll[1]?.access === true
    ) {
      setShowRequest(true);
    }
    getPayrollSetup();
  }, [branchPayroll, userDetails]);
  return (
    <>
      {detailsIsLoading ? (
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="px-4 lg:px-9 py-6">
          {showRequest ? <PayrollRequestAlert /> : null}

          <div className="flex flex-col md:flex-row items-start justify-between gap-6 md:gap-0">
            <div>
              <p className="text-txgray2 font-bold text-[16px] flex gap-2 items-center">
                Good {getTimeOfDay()},{" "}
                {userDetails && userDetails.last_name
                  ? [userDetails.last_name, " ", userDetails.first_name]
                  : ""}{" "}
                <img
                  src={
                    getTimeOfDay() === "morning"
                      ? morning
                      : getTimeOfDay() === "afternoon"
                      ? afternoon
                      : getTimeOfDay() === "evening"
                      ? evening
                      : null
                  }
                  alt="time"
                />
              </p>
              <p className="text-txgray font-medium md:text-sm">
                It’s another day to run payroll and be a super HR.
              </p>
            </div>
            {/* <FilterGeneralDateSelect
          value={generalFilter}
          setValue={setGeneralFilter}
          createdDate={user.date_created}
        /> */}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8 gap-5">
            <WalletBalance
              walletDetails={dashboardData?.account_details}
              isPending={isPending}
            />
            <AmountFunded
              details={dashboardData?.total_amount_credited}
              isPending={isPending}
            />
            <AmountSpent
              details={dashboardData?.total_amount_spent}
              isPending={isPending}
            />
          </div>
          <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-5">
            <PaydayStatistics
              componentData={dashboardData?.payday_statistics}
              createdDate={user.date_created}
              value={value}
              setValue={setValue}
            />
            <div className="flex col-span-2 md:col-span-1 md:flex-col gap-2 lg:gap-6">
              <EmployeeNumber
                allEmployees={dashboardData?.total_number_of_employees}
              />
              <BranchNumber
                allBranches={dashboardData?.total_number_of_branches}
              />
            </div>
          </div>
          <TransactionHistory
            transactions={dashboardData?.transactions}
            user={user}
          />
        </div>
      )}
    </>
  );
};

export default DashboardIndex;
