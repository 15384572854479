import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import add from "./../../../asset/svgs/add-circle.svg";
import Naira from "./../../../asset/svgs/Naira.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TopUpWallet from "../../dashboard-modals/TopUpWallet";
import toast from "react-hot-toast";
import GenerateWallet from "../../dashboard-modals/GenerateWallet";
import WalletCreated from "../../dashboard-modals/WalletCreated";
import { useQueryClient } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { isEmpty } from "../../../utils";
import { useRecoilState } from "recoil";
import { viewBalanceState } from "../../dashboard-pages/Human Resources/utils/states";
import Tour from "reactour";
import tourImage from "./../../../asset/svgs/tourImage.svg";
import styled from "styled-components";
import CreateWallet from "../../dashboard-modals/CreateWallet";
import { useSelector } from "react-redux";

const StyledTour = styled(Tour)`
  padding: 0px;
  border-radius: 8px;
`;
export default function WalletBalance({ walletDetails, isPending }) {
  //states
  const [viewBalance, setViewBalance] = useRecoilState(viewBalanceState);
  const [topUpWallet, setTopUpWallet] = useState(false);
  const [generateWallet, setGenerateWallet] = useState(false);
  const [walletCreated, setWalletCreated] = useState(false);
  const [walletData, setWalletData] = useState();
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [openCreateWallet, setCreateWallet] = useState(false);
  const { payrollSetup } = useSelector((state) => state?.payroll);

  const steps = [
    {
      selector: ".step-1",
      content: () => (
        <div>
          <img src={tourImage} alt="tier" />
          <div className="mt-3 px-4">
            <p className="font-bold text-[#334155] text-[16px]">
              Welcome. Let’s create your wallet
            </p>
            <p className="font-normal text-[13px] text-txgray">
              Wallet helps your organization run payroll by funding the wallet
              and using it to disburse pay.
            </p>
          </div>
          <div className="px-4 flex justify-end">
            <button
              className="text-sm bg-[#2563EB] font-bold text-[#FFFFFF] h-9 px-4 rounded-md mt-8 mb-6"
              onClick={() => {
                if (!payrollSetup) {
                  toast.error(
                    "Please setup payroll first, Human reasource > payroll"
                  );
                } else {
                  setIsTourOpen(false);
                  setCreateWallet(true);
                }
              }}
            >
              Create wallet
            </button>
          </div>
        </div>
      ),
      position: "right",
    },
  ];

  useEffect(() => {
    // add ! in front of isEmpty when testing
    if (isEmpty(walletDetails) && !isPending) {
      setIsTourOpen(true);
    } else {
      setIsTourOpen(false);
    }
  }, [walletDetails, isPending]);

  useEffect(() => console.log(walletData), [walletData]);

  return (
    <>
      <div className="relative">
        <div
          style={{
            background:
              " linear-gradient(131.8deg, #3B82F6 15.37%, #1E40AF 100%)",
          }}
          className="p-6 rounded flex flex-col "
        >
          <div className="flex items-center justify-between">
            <p className="text-white font-semibold text-sm  flex items-center">
              Wallet Balance
              <IconButton onClick={() => setViewBalance(!viewBalance)}>
                {viewBalance ? (
                  <FaEyeSlash className="text-white text-[16px]" />
                ) : (
                  <FaEye className="text-white text-[16px]" />
                )}
              </IconButton>
            </p>
            <span
              className="font-bold text-sm text-white rounded p-2 border-[1px] border-[#FFFFFF] flex gap-1 items-center cursor-pointer "
              onClick={() => setTopUpWallet(true)}
            >
              <img src={add} alt="add" />
              Top up
            </span>
          </div>
          <div className="flex items-center mt-2">
            <img src={Naira} alt="naira" />
            {!viewBalance ? (
              <p className="text-white text-[22px] font-semibold">
                {walletDetails?.balance?.toLocaleString()}
              </p>
            ) : (
              <p className="text-white text-[22px] font-semibold">****</p>
            )}
          </div>
          <span className="bg-[#1E40AF] text-white font-bold text-[13px] px-[6px]  flex justify-between items-center rounded mt-3">
            {walletDetails?.bank_name}: {walletDetails?.account_number}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(walletDetails?.account_number);
                toast.success("Copied!");
              }}
            >
              <ContentCopyIcon
                sx={{ fontSize: "14px", color: "#ffffff" }}
                color="action"
              />
            </IconButton>
          </span>
        </div>
        {/* add ! in front of isEmpty when testing */}
        {isEmpty(walletDetails) && !isPending ? (
          <div
            className="flex flex-col items-center gap-6 absolute w-full top-0 h-full justify-center rounded"
            style={{
              background:
                "linear-gradient(131.56deg, rgba(255, 255, 255, 0.5) 3.42%, rgba(255, 255, 255, 0.2) 97.06%)",
              backdropFilter: "blur(5px)",
            }}
          >
            <p className="font-bold text-white text-[16px]">
              You have no wallet created yet
            </p>
            <button
              className="text-sm bg-[#2563EB] font-bold text-[#FFFFFF] h-9 px-4 rounded-md step-1"
              onClick={() => {
                if (!payrollSetup) {
                  toast.error(
                    "Please setup payroll first, Human reasource > payroll"
                  );
                } else {
                  setIsTourOpen(false);
                  setCreateWallet(true);
                }
              }}
            >
              Create wallet
            </button>
          </div>
        ) : null}
        {isPending ? (
          <div
            className="flex flex-col items-center gap-6 absolute w-full top-0 h-full justify-center rounded"
            style={{
              background:
                "linear-gradient(131.56deg, rgba(255, 255, 255, 0.5) 3.42%, rgba(255, 255, 255, 0.2) 97.06%)",
              backdropFilter: "blur(5px)",
            }}
          >
            <CircularProgress
              sx={{
                color: "#ffffffd1",
                width: "30px !important",
                height: "30px !important",
              }}
            />
          </div>
        ) : null}
      </div>

      <TopUpWallet
        open={topUpWallet}
        onClose={() => setTopUpWallet(false)}
        wallet={walletDetails}
      />
      <GenerateWallet
        open={generateWallet}
        onClose={() => setGenerateWallet(false)}
      />
      <WalletCreated
        onClose={() => setWalletCreated(false)}
        open={walletCreated}
        walletData={walletData}
      />
      <StyledTour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        showButtons={false}
        showCloseButton={false}
        showNavigationNumber={false}
        showNumber={false}
        showNavigation={false}
        disableDotsNavigation={true}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onBeforeClose={() => (document.body.style.overflow = "scroll")}
      />
      <CreateWallet
        open={openCreateWallet}
        onClose={() => setCreateWallet(false)}
        setWalletData={setWalletData}
        setGenerateWallet={setGenerateWallet}
        setWalletCreated={setWalletCreated}
      />
    </>
  );
}
