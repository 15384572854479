import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkEmailVerification } from "../../../store/slices/userSlice";
import LoginLayout from "../../layout/LoginLayout";
import Loader from "../../../component/atoms/Loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const EmailConfirmed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  var urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get("uid");
  const token = urlParams.get("token");

  // Async Functions
  const CheckEmailVerification = async () => {
    setLoading(true);
    const res = await dispatch(checkEmailVerification({ uid, token }));
    setLoading(false);
    if (res.payload.status < 400) {
      setStatus(true);
      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    } else { 
      toast.update({
      render: res.payload.message,
      type: "error",
      type: "error",
      isLoading: false,
      autoClose: 1000,
    });
      setStatus(false);
    }
  };

  useEffect(() => {
    CheckEmailVerification();
  }, []);

  return (
    <LoginLayout>
      <div className=" h-[100%] flex justify-center items-center">
        {loading ? (
          <div className="min-h-[200px] flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            {status ? (
              <div className=" rounded-[4px] relative">
                <div className="">
                  <div className="relative w-max mx-auto mt-[56px]">
                    <img
                      src="/signup-icons/check_circle.svg                             "
                      alt="confirmed"
                      className="w-[80px] h-[80px]"
                    />
                    <img
                      src="/signup-icons/star.svg"
                      alt="star"
                      className="w-[16px] h-[16px] absolute top-0 right-0"
                    />
                    <img
                      src="/signup-icons/star.svg"
                      alt="star"
                      className="w-[16px] h-[16px] absolute top-[15px] right-[-20px]"
                    />
                    <img
                      src="/signup-icons/star.svg"
                      alt="star"
                      className="w-[16px] h-[16px] absolute top-[35px] right-[-20px]"
                    />
                  </div>
                </div>
                <div className="text-center mt-[45px] text-[#64748B] font-semibold">
                  <h2 className="text-[23px]">Email Confirmed</h2>
                  <p className="text-[15px] mt-[35px] tracking-[0.15px] text-[#64748B]">
                    We are redirecting you to your sign in page
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <p>User not Verified</p>
              </div>
            )}
          </>
        )}
      </div>
    </LoginLayout>
  );
};

export default EmailConfirmed;
