/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SelectBranch from "../../../../dashboard-component/select/SelectBranch";
import { Checkbox, Input, InputGroup, Pagination, Table } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useSelector } from "react-redux";
import SelectDropDown from "../../../../dashboard-component/select/SelectDropDown";
import BlueBotton from "../../../../../component/atoms/BlueBotton";
import { SelectPicker } from "rsuite";
import AddDeductionModal from "../modals/AddDeductionModal";
import LineButton from "../../../../../component/atoms/LineButton";

export default function AddDeduction({ setMainPage }) {
  const [branch, setBranch] = useState();
  const { Column, HeaderCell, Cell } = Table;
  const [search, setSearch] = useState("");
  const [limit, setLimit] = React.useState(10);
  const [page, setTablePage] = React.useState(1);
  const [department, setDepartment] = useState("");
  const [open, setOpen] = useState(false);
  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState();
  let checked = false;
  let indeterminate = false;
  const { allEmployees } = useSelector((state) => state?.payroll);
  const { allDept } = useSelector((state) => state?.users);

  //datas
  const branchEmployees = allEmployees?.filter(
    (employee) => employee.branch === branch?.name
  );

  const filteredData = branchEmployees?.filter(
    (employee) =>
      (employee?.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        employee?.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        employee?.email?.toLowerCase()?.includes(search?.toLowerCase())) &&
      employee?.department === (department || employee?.department)
  );
  const mainData = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const deptOptions = allDept?.map((dept) => {
    return {
      label: dept?.name,
      value: dept?.name,
    };
  });

  useEffect(() => {
    setSelectedEmployeeData(() => {
      return branchEmployees?.filter((employee) =>
        checkedKeys.includes(employee.user_id)
      );
    });
  }, [checkedKeys]);

  //functions
  if (checkedKeys.length === branchEmployees?.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (
    checkedKeys.length > 0 &&
    checkedKeys.length < branchEmployees?.length
  ) {
    indeterminate = true;
  }
  const handleChangeLimit = (dataKey) => {
    setTablePage(1);
    setLimit(dataKey);
  };

  const handleCheckAll = (value, checked) => {
    const keys = checked ? branchEmployees?.map((item) => item.user_id) : [];
    setCheckedKeys(keys);
  };

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };

  const ToggleOpenAddDed = () => {
    setOpen(!open);
  };

  //components
  const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "46px" }}>
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={onChange}
          checked={checkedKeys.some((item) => item === rowData[dataKey])}
        />
      </div>
    </Cell>
  );
  //
  const NairaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.333333 4.94444H1.81481V0.5H3.2963L5.82963 4.94444H9.22222V0.5H10.7037V4.94444H12.1852V6.42593H10.7037V7.90741H12.1852V9.38889H10.7037V13.8333H9.22222L6.68148 9.38889H3.2963V13.8333H1.81481V9.38889H0.333333V7.90741H1.81481V6.42593H0.333333V4.94444ZM3.2963 4.94444H4.13333L3.2963 3.48519V4.94444ZM3.2963 6.42593V7.90741H5.82963L4.98518 6.42593H3.2963ZM9.22222 10.8704V9.38889H8.37037L9.22222 10.8704ZM6.67407 6.42593L7.52593 7.90741H9.22222V6.42593H6.67407Z"
              fill="#475569"
            />
          </svg>

          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };

  const NameCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} className="text-txblue">
        {rowData[dataKey]} {rowData["first_name"]}
      </Cell>
    );
  };

  return (
    <div className="mt-8">
      <div>
        <p className="text-txgray3 font-semibold text-[22px] leading-8 mb-5">
          Add a deduction for an employee
        </p>
        <SelectBranch branch={branch} setBranch={setBranch} />
      </div>

      {branchEmployees?.length > 0 && (
        <>
          <div className="mt-5">
            <p className="text-txgray3 font-semibold text-[22px]  mb-2">
              Select an employee
            </p>
            <p className="text-txgray2 font-semibold text-[16px]  mb-5">
              You currently have {branchEmployees?.length} employees in your
              organisation’s {branch?.name}
            </p>
          </div>
          <div className="mt-10 bg-[#FFFFFF] px-10 py-6">
            <div className="flex justify-between items-center mb-5">
              <div className="flex">
                <InputGroup className="!w-full md:!w-[330px] h-[42px]">
                  <InputGroup.Addon>
                    {" "}
                    <SearchIcon />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Search for employee name or job title"
                    value={search}
                    onChange={setSearch}
                  />
                </InputGroup>
                <SelectPicker
                  placeholder="Department"
                  data={deptOptions}
                  style={{ width: "200px", marginLeft: 5 }}
                  value={department}
                  onChange={setDepartment}
                  size="lg"
                />
              </div>
              {/* <div className="flex items-center">
                <span className="flex items-center">
                  <p className="px-2">Sort</p>
                  <SelectDropDown value="A - Z" options={["A - Z", "Z - A"]} />
                </span>
              </div> */}
            </div>
            <div>
              <Table
                height={400}
                data={mainData}
                className="border-[1px] border-[#F1F5F9] rounded-lg"
              >
                <Column width={50} align="center" fixed>
                  <HeaderCell style={{ padding: 0 }}>
                    <div style={{ lineHeight: "40px" }}>
                      <Checkbox
                        inline
                        checked={checked}
                        indeterminate={indeterminate}
                        onChange={handleCheckAll}
                      />
                    </div>
                  </HeaderCell>
                  <CheckCell
                    dataKey="user_id"
                    checkedKeys={checkedKeys}
                    onChange={handleCheck}
                  />
                </Column>
                <Column width={200} align="left">
                  <HeaderCell>Employee Name</HeaderCell>
                  <NameCell dataKey="last_name" />
                </Column>
                <Column width={200} align="left">
                  <HeaderCell>Job Title</HeaderCell>
                  <Cell dataKey="designation" />
                </Column>
                <Column width={200} align="left">
                  <HeaderCell>Department</HeaderCell>
                  <Cell dataKey="department" />
                </Column>
                <Column width={200} align="left">
                  <HeaderCell>Gross Salary</HeaderCell>
                  <NairaCell dataKey="monthly_gross_salary" />
                </Column>

                <Column width={200} align="left">
                  <HeaderCell>Net Salary</HeaderCell>
                  <NairaCell dataKey="net_pay" />
                </Column>
              </Table>
              <div style={{ padding: 20 }}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="xs"
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  total={branchEmployees.length}
                  limitOptions={[10, 30, 50]}
                  limit={limit}
                  activePage={page}
                  onChangePage={setTablePage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </div>
            <div className="flex gap-6">
              <LineButton
                label={"Done"}
                onClick={() => setMainPage("Deduction")}
              />
              <BlueBotton
                disabled={checkedKeys.length <= 0}
                label="Add Deductions"
                onClick={ToggleOpenAddDed}
              />
            </div>
          </div>
        </>
      )}
      <AddDeductionModal
        selectedEmployeeData={selectedEmployeeData}
        ToggleOpenAddDed={ToggleOpenAddDed}
        open={open}
        branch={branch}
        type="add"
        ToggleLoadModal={ToggleOpenAddDed}
        setMainPage={setMainPage}
      />
    </div>
  );
}
