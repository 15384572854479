import React from "react";
import { Checkbox } from "rsuite";

export default function RoleCheckBox({
  data,
  title,
  dataKey,
  permissions,
  setPermissions,
}) {
  //states
  let checked = false;
  let indeterminate = false;
  const permissionIndex = permissions?.findIndex((group) =>
    group.hasOwnProperty(dataKey)
  );

  //functions
  const handleCheck = (index, value, checked) => {
    const updatedPermissions = [...permissions];
    const permissionIndex = updatedPermissions?.findIndex((group) =>
      group.hasOwnProperty(dataKey)
    );
    if (permissionIndex !== -1) {
      updatedPermissions[permissionIndex][dataKey][index].access =
        !updatedPermissions[permissionIndex][dataKey][index].access;
      setPermissions(updatedPermissions);
      // console.log(updatedPermissions);
    }
  };
  //

  const handleCheckAll = (value, checked) => {
    const updatedPermissions = [...permissions];
    const permissionIndex = updatedPermissions?.findIndex((group) =>
      group.hasOwnProperty(dataKey)
    );

    if (permissionIndex !== -1) {
      if (
        updatedPermissions[permissionIndex][dataKey]?.some(
          (item) => item.access === false
        )
      ) {
        updatedPermissions[permissionIndex][dataKey]?.forEach((item) => {
          item.access = true;
        });
        setPermissions(updatedPermissions);
        return;
      } else {
        updatedPermissions[permissionIndex][dataKey]?.forEach((item) => {
          item.access = false;
        });
      }
      setPermissions(updatedPermissions);
    }
  };
  //
  if (
    permissions[permissionIndex][dataKey]?.every((item) => item.access === true)
  ) {
    checked = true;
  } else if (
    permissions[permissionIndex][dataKey]?.every(
      (item) => item.access === false
    )
  ) {
    checked = false;
  } else if (
    permissions[permissionIndex][dataKey]?.some((item) => item.access === false)
  ) {
    indeterminate = true;
  }

  return (
    <div>
      <div className="flex items-center">
        <Checkbox
          inline
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleCheckAll}
        />
        <p className="text-txgray2 text-sm font-medium">{title}</p>
      </div>
      <div className="ml-6">
        {data?.map((_, index) => (
          <div className="flex items-center" key={index}>
            <Checkbox
              value={_.value}
              inline
              onChange={(value, checked) => handleCheck(index, value, checked)}
              checked={permissions[permissionIndex][dataKey][index]?.access}
            />
            <p className="text-txgray2 text-[12px] font-medium"> {_.name} </p>
          </div>
        ))}
      </div>
    </div>
  );
}
