import * as yup from "yup";

export const signUpFormSchema = yup.object().shape({
  first_name: yup.string().required("Enter your first name"),
  last_name: yup.string().required("Enter your last name"),
  company_name: yup.string().required("Enter your company name"),
  company_size: yup.string().required("Select your company size"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Enter your business email"),
  phone: yup
    .string()
    .max(11, "Invalid phone number")
    .required("Enter your phone number"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      "Password must have at least one number, both lower and uppercase letters and special characters"
    )
    .required("Password is required"),
  terms: yup
    .boolean()
    .required("You must accept the Terms of Service to proceed")
    .oneOf([true], "You must accept the Terms of Service to proceed"),
});

export const LoginFormSchema = yup.object().shape({
  username: yup.string().required("Enter your username"),
  password: yup.string().required("Enter a password"),
});

export const resetPasswordFormSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Enter your email"),
});

export const newPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      "Password must have at least one number, both lower and uppercase letters and special characters"
    )
    .required("Password is required"),
});

export const organizationURLFormSchema = yup.object().shape({
  website: yup.string().required("Enter your website URL"),
});

export const newUsersFormSchema = yup.object().shape({
  userData: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email("Invalid email")
        .required("Enter user business email"),
      group_id: yup.object().shape({
        label: yup.string().required("Role is a required field"),
      }),
    })
  ),
});

export const newTenantBranchesFormSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Enter branch name"),
      address: yup.string().required("Please add an address"),
    })
  ),
});

export const CreateContactSchema = yup.object().shape({
  first_name: yup.string().required("First Name"),
  last_name: yup.string().required("Last Name"),
  phone_number: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Must be a valid phone number"
    )
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits"),
  email: yup.string().email("Invalid email").required("Email"),
});

export const CreateDepartmentSchema = yup.object().shape({
  name: yup.string().required("Department Name"),
});

export const CreateBusinessProileSchema = yup.object().shape({
  business_category: yup.string().required("Business Category is required"),
  business_description: yup
    .string()
    .required("Business Description is required"),
  country: yup.string().required("Business Description is required"),
  alternate_email: yup
    .string()
    .required("Alternate Business Email is required"),
});

export const RoleformSchema = yup.object().shape({
  name: yup.string().required("Enter desired role name"),
});

export const newLevelFormSchema = yup.object().shape({
  level_name_id: yup.object().shape({
    label: yup.string().required("Level field"),
  }),
  notice_period: yup.object().shape({
    label: yup.string().required("Notice Period"),
  }),
  leaves: yup.array().of(
    yup.object().shape({
      leave_id: yup.object().shape({
        label: yup.string().required("Leave is a required field"),
      }),
      number_of_days: yup.number().required("Number of days is required"),
    })
  ),
});

export const positionsFormSchema = yup.object().shape({
  positions: yup.array().of(
    yup.object().shape({
      position_id: yup.object().shape({
        label: yup.string().required("Position is a required field"),
      }),
      position_level_name_id: yup.object().shape({
        label: yup.string().required("Level is a required field"),
      }),
    })
  ),
});

export const PoliciesFormSchema = yup.object().shape({
  leave_policy: yup.object().shape({
    label: yup.string().required("Leave policy is a required field"),
  }),
  pension_computation: yup.object().shape({
    label: yup.string().required("Pension computation is a required field"),
  }),
});

export const GrossComponentSchema = yup.object().shape({
  name: yup.string().required("Component Name is required"),
  percentage: yup.string().required("Percentage is required"),
});

export const ReliefComponentSchema = yup.object().shape({
  percentage: yup.string().required("Percentage is required"),
});
