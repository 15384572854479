/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Checkbox, Input, InputGroup, Pagination, Table } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import LineButton from "../../../../../component/atoms/LineButton";
import BlueBotton from "../../../../../component/atoms/BlueBotton";
import ConfirmRunPayroll from "../modals/ConfirmRunPayroll";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../../utils/https";
import Loader from "../../../../../component/atoms/Loader";

export default function RunEmployeesPayroll({
  setSelect,
  setConfirm,
  setRunPayrollPage,
  setCheckout,
  setSelectedEmployees,
}) {
  const [search, setSearch] = useState("");
  const [setTitle] = useOutletContext();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [openConfirmPayroll, setOpenConfirmPayroll] = useState(false);
  const { Column, HeaderCell, Cell } = Table;
  const [checkedKeys, setCheckedKeys] = React.useState([]);
  let checked = false;
  let indeterminate = false;
  const { branchName } = useParams();

  useEffect(() => setTitle("Human Resources"), []);
  useEffect(() => setSelectedEmployees(selectedEmployeeData), [checkedKeys]);
  useEffect(() => {
    if (checkedKeys?.length >= 1) {
      setSelect("processed");
    } else {
      setSelect("processing");
    }
  }, [checkedKeys]);

  const { data: employeesData, isLoading } = useQuery({
    queryKey: ["getAllEmployees"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/users/employees/`);
      return response?.data?.data?.results;
    },
  });

  const branchEmployees = employeesData?.filter(
    (employee) => employee.branch === branchName
  );

  const filteredData = branchEmployees?.filter(
    (employee) =>
      employee?.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      employee?.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      employee?.email?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const mainData = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const selectedEmployeeData = branchEmployees?.filter((employee) =>
    checkedKeys.includes(employee.user_id)
  );

  //functions
  if (checkedKeys?.length === branchEmployees?.length) {
    checked = true;
  } else if (checkedKeys?.length === 0) {
    checked = false;
  } else if (
    checkedKeys?.length > 0 &&
    checkedKeys?.length < branchEmployees?.length
  ) {
    indeterminate = true;
  }

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleCheckAll = (value, checked) => {
    const keys = checked ? branchEmployees?.map((item) => item.user_id) : [];
    setCheckedKeys(keys);
  };

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };

  const ToggleOpenConfirm = () => {
    setOpenConfirmPayroll(!openConfirmPayroll);
  };

  //components
  const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "46px" }}>
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={onChange}
          checked={checkedKeys.some((item) => item === rowData[dataKey])}
        />
      </div>
    </Cell>
  );

  const NairaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.333333 4.94444H1.81481V0.5H3.2963L5.82963 4.94444H9.22222V0.5H10.7037V4.94444H12.1852V6.42593H10.7037V7.90741H12.1852V9.38889H10.7037V13.8333H9.22222L6.68148 9.38889H3.2963V13.8333H1.81481V9.38889H0.333333V7.90741H1.81481V6.42593H0.333333V4.94444ZM3.2963 4.94444H4.13333L3.2963 3.48519V4.94444ZM3.2963 6.42593V7.90741H5.82963L4.98518 6.42593H3.2963ZM9.22222 10.8704V9.38889H8.37037L9.22222 10.8704ZM6.67407 6.42593L7.52593 7.90741H9.22222V6.42593H6.67407Z"
              fill="#475569"
            />
          </svg>

          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };
  const NameCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} className="text-txblue">
        {rowData[dataKey]} {rowData["first_name"]}
      </Cell>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div>
          <div>
            <div className="mt-8 pl-4 lg:pl-10">
              <p className="text-txgray3 font-semibold text-[24px] leading-8">
                Run Payroll
              </p>
              <p className="text-txgray2 text-sm font-normal">
                Select all employees to run payroll for
              </p>
              <p className="text-txgray2 text-sm font-normal mt-6">
                You currently have{" "}
                <span className="text-[22px] font-semibold">
                  {branchEmployees?.length}
                </span>{" "}
                employees in your organisation’s {branchName}
              </p>
            </div>

            <div className="mt-10 bg-[#FFFFFF] px-4 lg:px-10 py-6">
              {checkedKeys?.length > 0 && (
                <div className="flex gap-3 mb-3">
                  <LineButton label="Cancel" />
                  <BlueBotton
                    disabled={checkedKeys?.length <= 0}
                    label="Save & Continue"
                    onClick={() => {
                      ToggleOpenConfirm();
                      setConfirm("processing");
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between items-center mb-5">
                <div className="flex flex-col lg:flex-row gap-2 w-full">
                  <InputGroup className="!w-full md:!w-[330px] h-[42px]">
                    <InputGroup.Addon>
                      {" "}
                      <SearchIcon />
                    </InputGroup.Addon>
                    <Input
                      placeholder="Search for employee name or job title"
                      value={search}
                      onChange={setSearch}
                    />
                  </InputGroup>
                </div>
                <div className="flex items-center justify-between lg:justify-normal w-full">
                  <span className="text-txblue bg-[#EFF6FF] py-[6px] px-[12px] rounded">
                    {checkedKeys?.length} selected
                  </span>
                  {/* <span className="flex items-center">
              <p className="px-2">Sort</p>
              <SelectDropDown value="A - Z" options={["A - Z", "Z - A"]} />
            </span> */}
                </div>
              </div>

              <div>
                <Table
                  height={400}
                  data={mainData}
                  className="border-[1px] border-[#F1F5F9] rounded-lg"
                >
                  <Column width={50} align="center" fixed>
                    <HeaderCell style={{ padding: 0 }}>
                      <div style={{ lineHeight: "40px" }}>
                        <Checkbox
                          inline
                          checked={checked}
                          indeterminate={indeterminate}
                          onChange={handleCheckAll}
                        />
                      </div>
                    </HeaderCell>
                    <CheckCell
                      dataKey="user_id"
                      checkedKeys={checkedKeys}
                      onChange={handleCheck}
                    />
                  </Column>
                  <Column align="left" width={150}>
                    <HeaderCell>Employee Name</HeaderCell>
                    <NameCell dataKey="last_name" className="text-txblue" />
                  </Column>

                  <Column width={120} align="left">
                    <HeaderCell>Gross Salary</HeaderCell>
                    <NairaCell dataKey="monthly_gross_salary" />
                  </Column>
                  <Column align="left" width={120}>
                    <HeaderCell>Deductions</HeaderCell>
                    <NairaCell dataKey="deduction_amount" />
                  </Column>
                  <Column align="left" width={120}>
                    <HeaderCell>Taxes</HeaderCell>
                    <NairaCell dataKey="tax_paid" />
                  </Column>
                  <Column align="left" width={120}>
                    <HeaderCell>Benefits</HeaderCell>
                    <NairaCell dataKey="benefit_amount" />
                  </Column>
                  <Column width={120} align="left">
                    <HeaderCell>Net Salary</HeaderCell>
                    <NairaCell dataKey="net_pay" />
                  </Column>
                </Table>
                <div style={{ padding: 20 }}>
                  <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={["total", "-", "limit", "|", "pager", "skip"]}
                    total={branchEmployees?.length}
                    limitOptions={[10, 30, 50]}
                    limit={limit}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={handleChangeLimit}
                  />
                </div>
              </div>
            </div>
          </div>
          <ConfirmRunPayroll
            ToggleOpenConfirm={ToggleOpenConfirm}
            open={openConfirmPayroll}
            setRunPayrollPage={setRunPayrollPage}
            setConfirm={setConfirm}
            setCheckout={setCheckout}
          />
        </div>
      )}
    </>
  );
}
