import React from "react";
import MainModal from "../../../../../component/MainModal.";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { DeleteEmployee } from "../../../../../store/slices/PayrollSlice";
import LineButton from "../../../../../component/atoms/LineButton";

const NewBranchModal = ({ modalOpen, onClose, data }) => {
  const dispatch = useDispatch();

  const onSubmit = async (event) => {
    event.preventDefault();

    const toastId = toast.loading("Deleting Employee, please wait...");
    const res = await dispatch(DeleteEmployee(data?.user_id));
    if (res?.payload?.message === "Internal Server Error") {
      toast.update(toastId, {
        render: res?.payload?.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render: res?.payload.data?.message || "Employee deleted successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      onClose();
    } else {
      toast.update(toastId, {
        render:
          res?.payload?.data?.message || res?.message || "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <MainModal
        size={"sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Delete Employee"
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          <p className="mb-3">Are you sure you want to delete this employee?</p>

          <div className="flex gap-6 mt-6">
            <LineButton label={"No, Cancel"} onClick={onClose} />
            <button
              onClick={onSubmit}
              className="text-sm bg-[#E11D48] font-bold text-[#FFFFFF] h-12 px-4 rounded-md"
            >
              Delete Employee
            </button>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default NewBranchModal;
