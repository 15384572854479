import React from "react";
import { Modal } from "rsuite";
import close from "./../../../../asset/svgs/close-circle.svg";
import lamp from "./../../../../asset/svgs/lamp-on.svg";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordInput from "../../../dashboard-component/inputs/PasswordInput";
import BlueBotton from "../../../../component/atoms/BlueBotton";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import toast from "react-hot-toast";

export default function ChangePassword({ open, onClose }) {
  const schema = yup.object().shape({
    password: yup.string().required("Please enter old password"),
    newPassword: yup.string().required("Please enter new password"),
    confirmNewPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        "New password and confirm password must match"
      )
      .required("Please confirm your new password"),
  });
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        password: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      validationSchema: schema,
      onSubmit: (values, { resetForm }) => {
        resetForm();
      },
    });

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/auth/password-change", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  return (
    <Modal sx={{ padding: "16px 24px" }} open={open} onClose={onClose}>
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="text-txgray2 text-[22px] font-semibold">
          Change Password
        </p>
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
      </div>

      <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded mb-4">
        <img src={lamp} alt="close" />
        <p>Kindly choose a more secured password to change to</p>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <PasswordInput
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          values={values.password}
          id="password"
        />
        <PasswordInput
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          values={values.newPassword}
          id="newPassword"
        />
        <PasswordInput
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          values={values.confirmNewPassword}
          id="newPassword"
        />
        <div className="flex gap-6 justify-end mt-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
          <BlueBotton
            label="Change"
            type="submit"
            loading={mutation.isPending}
            onClick={() =>
              mutation.mutate({
                current_password: values.password,
                new_password: values.newPassword,
              })
            }
          />
        </div>
      </form>
    </Modal>
  );
}
