import React from "react";
import { Modal } from "rsuite";
import styled from "styled-components";
import Button from "../../../../component/atoms/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import toast from "react-hot-toast";
import ButtonFilled from "../../../../component/atoms/ButtonFilled";

const CustomModal = styled(Modal)`
  .rs-modal-content {
    min-width: 440px;
    padding: 0px;
    border-radius: 8px;
  }
`;
export default function DeleteAction({ open, onClose, roleData, type }) {
  const queryClient = useQueryClient();
  console.log(roleData);

  //mutations
  const mutation = useMutation({
    mutationFn: () => axiosInstance().delete(`groups/${roleData?.id}/delete`),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      queryClient.invalidateQueries({
        queryKey: ["getAllRoles"],
        refetchType: "active",
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const deleteMember = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post(
        "/users/team-members/remove-users-from-team",
        payload
      ),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      queryClient.invalidateQueries({
        queryKey: ["getAllMembers"],
        refetchType: "active",
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
  return (
    <CustomModal open={open} onClose={onClose}>
      <div className="p-6 flex flex-col items-center">
        <p className="text-txgray2 text-[16px] font-bold">
          Delete {type === "role" ? "role?" : "member?"}{" "}
        </p>
        <p className="text-txgray text-sm font-normal">
          {type === "role"
            ? "Users under this role won’t be able to login and this cannot be undone."
            : "This user record will be completely wiped off and cannot be undone."}
        </p>
      </div>
      <div className="bg-[#F8FAFC] p-6 flex justify-center gap-2 rounded-b-[6px]">
        <Button onClick={onClose}>cancel </Button>

        <ButtonFilled
          onClick={() => {
            type === "role"
              ? mutation.mutate()
              : deleteMember.mutate({
                  user_ids: [roleData.user_id],
                });
          }}
          style={{ background: "#F43F5E" }}
          loading={
            type === "role" ? mutation.isPending : deleteMember.isPending
          }
        >
          Yes, Delete
        </ButtonFilled>
      </div>
    </CustomModal>
  );
}
