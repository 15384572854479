import { format } from "fecha";

export const keyEncoding = "x".repeat(15);
export const currencySymbol = "₦";
export const accept = {
  pdf: "application/pdf, .pdf",
  docx: "application/msword, .doc, .docx",
  excel: "application/vnd.ms-excel, .xls, .xlsx",
  csv: ".csv",
  ppt: "application/vnd.ms-powerpoint, .ppt, .pptx",
  txt: "text/plain",
  images: "image/*",
  video: "video/mp4,video/x-m4v,video/*",
  audio: "audio/*",
};
export function setToken(userToken) {
  localStorage.setItem("token", userToken);
}
export function setUser(user) {
  localStorage.setItem("user_detail", JSON.stringify(user));
}

export function setType(key, userRole) {
  localStorage.setItem(key, JSON.stringify(userRole));
}

export function getUserToken() {
  let tokenString = localStorage.getItem("token");

  return tokenString;
}

export function getUserDetails() {
  let userDetails = localStorage.getItem("user_detail");

  return JSON.parse(userDetails);
}

export const LOGGER = (
  key,
  value,
  isProd = process.env.NODE_ENV === "production"
) => {
  if (isProd) return;

  // eslint-disable-next-line no-console
  console.log(key, value);
};

export function getType(key) {
  let tokenString = JSON?.parse(localStorage.getItem(key));

  return tokenString;
}

export function endSession() {
  localStorage.clear();
}
export const formatAmount = (num) => {
  if (num) {
    const initial = parseFloat(num).toFixed(2);
    return initial.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};
export const formatDateObjectHandler = (dateObj, dateFormat = "YYYY-MM-DD") => {
  if (!dateObj) {
    return undefined;
  }
  // format(new Date(parse(dateObj)), dateFormat);
  const formattedDate = format(new Date(dateObj), dateFormat);
  return formattedDate;
};

export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
export const parseQuery = (queryString) => {
  const query = new URLSearchParams(queryString).entries();
  const queryObj = {};
  for (const [key, value] of query) {
    queryObj[key] = value;
  }
  return queryObj;
};
export const objToFormData = (obj, allowNull) => {
  const formdata = new FormData();
  for (let key in obj) {
    if (obj[key] || allowNull) {
      formdata.append(key, obj[key]);
    }
  }
  return formdata;
};
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
export const requestsHasError = (responses) => {
  return responses?.some((response) => !response.success);
};
export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
};

//  this function returns the initials of the name parsed as parameters, it is used in creating initials Avater for users with no picturs
// e.g stringAvater(Bhadmus Damilola) would return BD
export function NameToInitials(name) {
  return `${name.split(" ")[0][0]}`;
}

export function generateRandomColor() {
  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min) + min);

  const red = getRandomNumber(0, 256);
  const green = getRandomNumber(0, 256);
  const blue = getRandomNumber(0, 256);

  const color = `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;

  return color;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const formatDateWithDay = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
export function getTimeOfDay() {
  const currentTime = new Date().getHours();

  if (currentTime >= 5 && currentTime < 12) {
    return "morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
}

export function convertDateToMonth(dateString) {
  const date = new Date(dateString);
  const options = { month: "short" };
  const monthAbbr = date.toLocaleString("en-US", options);
  return monthAbbr;
}

export function isEmpty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export const DateRangeSinceCreated = (dateString) => {
  const inputDate = new Date(dateString);
  const startYear = inputDate.getFullYear();
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year.toString());
  }

  return years;
};
