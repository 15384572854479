import React, { useState } from "react";
import { Checkbox, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useSelector } from "react-redux";
import {
  Input,
  Pagination,
  IconButton,
  Table,
  Popover,
  Dropdown,
  Whisper,
} from "rsuite";
import { formatDate } from "../../../../../utils";
import edit from "./../../../../../asset/svgs/edit.svg";
import deleteIcon from "./../../../../../asset/svgs/delete.svg";
import DeleteAction from "../modals/DeleteAction";
import Loader from "../../../../../component/atoms/Loader";
import AddBonusModal from "../modals/AddBonusModal";

export default function EmployeesWithBonus() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { Column, HeaderCell, Cell } = Table;
  const [employeeBonus, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [openDeleteAction, setOpenDeleteAction] = useState(false);
  const { bonuses } = useSelector((state) => state?.payroll);
  const [loading, setLoading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [deleteType, setDeleteType] = useState("");
  let checked = false;
  let indeterminate = false;

  //datas
  const filteredData = bonuses?.filter((employee) =>
    employee?.user_fullname?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const mainData = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  //functions
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const ToggleOpenAddBon = () => {
    setOpen(!open);
  };
  const ToggleLoadModal = () => {
    setLoadModal(false);
  };
  const ToggleDeleteAction = () => {
    setOpenDeleteAction(!openDeleteAction);
  };
  const handleCheckAll = (value, checked) => {
    const keys = checked ? bonuses?.map((item) => item.id) : [];
    setCheckedKeys(keys);
  };

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };
  if (checkedKeys.length === bonuses?.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < bonuses?.length) {
    indeterminate = true;
  }

  //components
  const NairaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.333333 4.94444H1.81481V0.5H3.2963L5.82963 4.94444H9.22222V0.5H10.7037V4.94444H12.1852V6.42593H10.7037V7.90741H12.1852V9.38889H10.7037V13.8333H9.22222L6.68148 9.38889H3.2963V13.8333H1.81481V9.38889H0.333333V7.90741H1.81481V6.42593H0.333333V4.94444ZM3.2963 4.94444H4.13333L3.2963 3.48519V4.94444ZM3.2963 6.42593V7.90741H5.82963L4.98518 6.42593H3.2963ZM9.22222 10.8704V9.38889H8.37037L9.22222 10.8704ZM6.67407 6.42593L7.52593 7.90741H9.22222V6.42593H6.67407Z"
              fill="#475569"
            />
          </svg>

          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };
  const StartPeriodCell = ({ rowData, dataKey, ...props }) => {
    return <Cell {...props}>{formatDate(rowData[dataKey])}</Cell>;
  };
  const CycleCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        {rowData[dataKey] === "mid_year"
          ? "Mid Year"
          : rowData[dataKey] === "weekly"
          ? "Weekly"
          : rowData[dataKey] === "monthly"
          ? "Monthly"
          : rowData[dataKey] === "quarterly"
          ? "Quarterly"
          : rowData[dataKey] === "yearly"
          ? "Yearly"
          : null}
      </Cell>
    );
  };

  const StatusCell = ({ rowData, dataKey, ...props }) => {
    if (rowData[dataKey] === "active") {
      return (
        <Cell {...props}>
          <div className="bg-[#ECFDF5] rounded py-[2px] px-2 flex gap-2 items-center">
            <span className="block w-2 h-2 bg-[#059669] rounded-full"></span>
            <span className="text-[#059669]">{rowData[dataKey]}</span>
          </div>
        </Cell>
      );
    } else if (rowData[dataKey] === "draft") {
      return (
        <Cell {...props}>
          <div className="bg-[#FFFBEB] rounded py-[2px] px-2 flex gap-2 items-center">
            <span className="block w-2 h-2 bg-[#D97706] rounded-full"></span>
            <span className="text-[#D97706]">{rowData[dataKey]}</span>
          </div>
        </Cell>
      );
    } else if (rowData[dataKey] === "deleted") {
      return (
        <Cell {...props}>
          <div className="bg-[#e11d472d] rounded py-[2px] px-2 flex gap-2 items-center">
            <span className="block w-2 h-2 bg-[#E11D48] rounded-full"></span>
            <span className="text-[#E11D48]">{rowData[dataKey]}</span>
          </div>
        </Cell>
      );
    }
  };
  const BenefitTypeCell = ({ rowData, dataKey, ...props }) => {
    if (rowData[dataKey] === "fixed_amount") {
      return <Cell {...props}>Fixed</Cell>;
    }
    return (
      <Cell {...props} className="capitalize">
        {rowData[dataKey]}
      </Cell>
    );
  };
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover visible>
        <Dropdown.Menu>
          <Dropdown.Item
            eventKey={1}
            onClick={() => {
              ToggleOpenAddBon();
              setRowData(rowData);
              setLoadModal(true);
            }}
          >
            <div className="flex items-center gap-2">
              <img src={edit} alt="edit" />
              <span className="text-txgray2 text-sm font-bold">
                {" "}
                Edit Bonus
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            eventKey={2}
            onClick={() => {
              ToggleDeleteAction();
              setRowData(rowData);
              setDeleteType("single");
            }}
          >
            <div className="flex items-center gap-2">
              <img src={deleteIcon} alt="edit" />
              <span className="text-[#E11D48] text-sm font-bold">
                {" "}
                Delete Bonus
              </span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
    return (
      <Cell {...props}>
        {" "}
        <Whisper placement="autoVerticalEnd" trigger="click" speaker={speaker}>
          <IconButton
            appearance="subtle"
            style={{ padding: 0 }}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                  fill="#475569"
                />
              </svg>
            }
          />
        </Whisper>
      </Cell>
    );
  };
  const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "46px" }}>
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={onChange}
          checked={checkedKeys.some((item) => item === rowData[dataKey])}
        />
      </div>
    </Cell>
  );

  return loading ? (
    <div className="flex justify-center items-center mt-[10rem]">
      <Loader />
    </div>
  ) : (
    <div className="mt-10 bg-[#FFFFFF]  px-[2px] lg:px-10 py-6">
      <div className="flex justify-between items-center mb-5">
        <div className="flex">
          <InputGroup className="!w-full md:!w-[330px] h-[42px]">
            <InputGroup.Addon>
              {" "}
              <SearchIcon />
            </InputGroup.Addon>
            <Input
              placeholder="Search for employee name or job title"
              value={search}
              onChange={setSearch}
            />
          </InputGroup>
        </div>
        <div className="flex items-center gap-2">
          {checkedKeys?.length > 0 ? (
            <span
              className="text-[#E11D48] bg-[#e11d472d] py-[6px] px-[12px] rounded flex items-center gap-1 cursor-pointer"
              onClick={() => {
                setDeleteType("multiple");
                ToggleDeleteAction();
              }}
            >
              <img src={deleteIcon} alt="edit" />
              delete
            </span>
          ) : null}
          <span className="text-txblue bg-[#EFF6FF] py-[6px] px-[12px] rounded">
            {checkedKeys.length} selected
          </span>
          {/* <span className="flex items-center">
            <p className="px-2">Sort</p>
            <SelectDropDown value="A - Z" options={["A - Z", "Z - A"]} />
          </span> */}
        </div>
      </div>
      <div>
        <Table
          height={400}
          data={mainData}
          className="border-[1px] border-[#F1F5F9] rounded-lg"
        >
          <Column width={50} align="center" fixed>
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: "40px" }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={handleCheck}
            />
          </Column>
          <Column width={200} align="left">
            <HeaderCell>Employee Name</HeaderCell>
            <Cell dataKey="user_fullname" />
          </Column>
          <Column width={230} align="left">
            <HeaderCell>Bonus Name</HeaderCell>
            <Cell dataKey="bonus_name" />
          </Column>
          <Column width={100} align="left">
            <HeaderCell>Bonus Type</HeaderCell>
            <BenefitTypeCell dataKey="type" />
          </Column>
          <Column width={100} align="left">
            <HeaderCell>Amount</HeaderCell>
            <NairaCell dataKey="amount" />
          </Column>
          <Column width={100} align="left">
            <HeaderCell>Frequency</HeaderCell>
            <CycleCell dataKey="cycle" />
          </Column>

          <Column width={100} align="left">
            <HeaderCell>Start period</HeaderCell>
            <StartPeriodCell dataKey="start_date" />
          </Column>

          <Column width={100} align="left">
            <HeaderCell>Status</HeaderCell>
            <StatusCell dataKey="status" />
          </Column>
          <Column width={70} align="left">
            <HeaderCell>
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0V14H18V0H0ZM5.33 12H2V2H5.33V12ZM10.67 12H7.34V2H10.67V12ZM16 12H12.67V2H16V12Z"
                  fill="#475569"
                />
              </svg>
            </HeaderCell>
            <ActionCell dataKey="net_pay" />
          </Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={bonuses?.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      {loadModal ? (
        <AddBonusModal
          ToggleOpenAddBon={ToggleOpenAddBon}
          open={open}
          employeeBonus={employeeBonus}
          actionType="edit"
          ToggleLoadModal={ToggleLoadModal}
        />
      ) : null}
      <DeleteAction
        type="bonus"
        deleteType={deleteType}
        openDeleteAction={openDeleteAction}
        ToggleDeleteAction={ToggleDeleteAction}
        employeeBonus={employeeBonus}
        setLoading={setLoading}
        deleteMultiple={checkedKeys}
      />
    </div>
  );
}
