import { IconButton } from "@mui/material";
import React from "react";
import { Modal } from "rsuite";
import cancel from "./../../../../../asset/CloseBtn.svg";
import styled from "styled-components";
import LineButton from "../../../../../component/atoms/LineButton";
import { useDispatch } from "react-redux";
import {
  DeleteBenefit,
  DeleteBonus,
  DeleteDeduction,
  GetBenefits,
  GetBonuses,
  GetDeductions,
} from "../../../../../store/slices/PayrollSlice";
import { toast } from "react-toastify";

const CustomModal = styled(Modal)`
  .rs-modal-content {
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 584px;
  }
`;
export default function DeleteAction({
  type,
  openDeleteAction,
  ToggleDeleteAction,
  employeeDeduction,
  employeeBenefit,
  employeeBonus,
  setLoading,
  deleteType,
  deleteMultiple,
}) {
  const dispatch = useDispatch();

  async function handleDelete() {
    const toastId = toast.loading(
      type === "deduction"
        ? "Deleting deduction, please wait..."
        : type === "benefit"
        ? "Deleting benefit, please wait..."
        : type === "bonus"
        ? "Deleting bonus, please wait..."
        : null
    );

    const data =
      type === "deduction"
        ? {
            deduction_ids:
              deleteType === "multiple"
                ? deleteMultiple
                : [employeeDeduction?.id],
          }
        : type === "benefit"
        ? {
            benefit_ids:
              deleteType === "multiple"
                ? deleteMultiple
                : [employeeBenefit?.id],
          }
        : type === "bonus"
        ? {
            bonus_ids:
              deleteType === "multiple" ? deleteMultiple : [employeeBonus?.id],
          }
        : null;

    setLoading(true);
    //
    const res = await dispatch(
      type === "deduction"
        ? DeleteDeduction({ data })
        : type === "benefit"
        ? DeleteBenefit({ data })
        : type === "bonus"
        ? DeleteBonus({ data })
        : null
    );
    //
    if (type === "deduction") {
      dispatch(GetDeductions());
    } else if (type === "benefit") {
      dispatch(GetBenefits());
    } else if (type === "bonus") {
      dispatch(GetBonuses());
    }
    setLoading(false);
    //
    if (res.payload.status < 400) {
      toast.update(toastId, {
        render: res?.payload?.message || res?.payload?.data?.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      ToggleDeleteAction();
    } else {
      toast.update(toastId, {
        render:
          res?.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res?.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  }
  return (
    <CustomModal open={openDeleteAction} onClose={ToggleDeleteAction}>
      <div className="flex items-center justify-between gap-5 pb-4 ">
        <p className="text-txgray3 text-[22px] font-semibold">
          {type === "deduction"
            ? "Delete Deduction?"
            : type === "benefit"
            ? "Delete Benefit"
            : type === "bonus"
            ? "Delete Bonus"
            : ""}
        </p>
        <IconButton
          onClick={() => {
            ToggleDeleteAction();
          }}
        >
          <img src={cancel} alt="close" />
        </IconButton>
      </div>
      {deleteType === "multiple" ? (
        <p>
          Are you sure you want to delete these{" "}
          {type === "deduction"
            ? "deduction(s)"
            : type === "benefit"
            ? "benefit(s)"
            : type === "bonus"
            ? "bonuse(s)"
            : null}{" "}
          for {deleteMultiple.length} employee(s)? This action is irreversible
        </p>
      ) : type === "deduction" ? (
        <p>
          Are you sure you want to delete {employeeDeduction?.deduction_name}{" "}
          for {employeeDeduction?.user_fullname}? This action is irreversible
        </p>
      ) : type === "benefit" ? (
        <p>
          Are you sure you want to delete {employeeBenefit?.benefit_name} for{" "}
          {employeeBenefit?.user_fullname}? This action is irreversible
        </p>
      ) : type === "bonus" ? (
        <p>
          Are you sure you want to delete {employeeBonus?.bonus_name} for{" "}
          {employeeBonus?.user_fullname}? This action is irreversible
        </p>
      ) : null}
      <div className="flex gap-6 mt-6">
        <LineButton label={"No, Cancel"} onClick={ToggleDeleteAction} />
        <button
          onClick={handleDelete}
          className="text-sm bg-[#E11D48] font-bold text-[#FFFFFF] h-12 px-4 rounded-md"
        >
          {type === "deduction"
            ? "Yes, Delete Deduction"
            : type === "benefit"
            ? "Yes, Delete Benefit"
            : type === "bonus"
            ? "Yes, Delete Bonus"
            : null}
        </button>
      </div>
    </CustomModal>
  );
}
