import React from "react";
import MainModal from "../../../../../component/MainModal.";
import Naira from "../../../../../asset/NairaIcon.svg";
import { useSelector } from "react-redux";
import Loader from "../../../../../component/atoms/Loader";

const PayrollBreakDownModal = ({ modalOpen, onClose, loading }) => {
  const { payrollBreakdown } = useSelector((state) => state?.payroll);
  return (
    <>
      <MainModal
        size={"lg"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Payroll breakdown"
      >
        <div className="mt-10 xl:w-[100%] mx-auto border-2 rounded p-4">
          {loading ? (
            <div className="min-h-[200px] flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <>
              <div className="mb-3">
                <h6 className="text-[#64748B] text=[14px] font-[700]">
                  Gross before remittance
                </h6>
                <div className="mt-2">
                  {payrollBreakdown &&
                    Object?.entries(
                      payrollBreakdown?.["Gross before remittance"]
                    )?.map(([key, value], index) => (
                      <div
                        className="flex justify-between items-center border-2 rounded mb-3"
                        key={index}
                      >
                        <div className="border-r-2 bg-[#E2E8F0] h-[40px] w-[50%] flex items-center">
                          <p className="pl-1 text-[#64748B] text=[14px] font-[500]">
                            {key}
                          </p>
                        </div>
                        <div className="h-[40px] flex w-[50%] items-center">
                          <img src={Naira} alt="Naira" />
                          <h6 className="pl-1">
                            {Number(value).toLocaleString()}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="mb-3">
                <h6 className="text-[#64748B] text=[14px] font-[700]">
                  Gross components
                </h6>
                <div className="mt-2">
                  {payrollBreakdown &&
                    Object?.entries(
                      payrollBreakdown?.["Gross components"]
                    )?.map(([key, value], index) => (
                      <div
                        className="flex justify-between items-center border-2 rounded mb-3"
                        key={index}
                      >
                        <div className="border-r-2 bg-[#E2E8F0] h-[40px] w-[50%] flex items-center">
                          <p className="pl-1 text-[#64748B] text=[14px] font-[500] capitalize">
                            {key} Salary
                          </p>
                        </div>
                        <div className="h-[40px] flex w-[50%] items-center">
                          <img src={Naira} alt="Naira" />
                          <h6 className="pl-1">
                            {" "}
                            {Number(value).toLocaleString()}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="mb-3">
                <h6 className="text-[#F43F5E] text=[14px] font-[700]">
                  Remittances
                </h6>
                <div className="mt-2">
                  {payrollBreakdown &&
                    Object?.entries(payrollBreakdown?.["Remittances"])?.map(
                      ([key, value], index) => (
                        <div
                          className="flex justify-between items-center border-2 rounded mb-3"
                          key={index}
                        >
                          <div className="border-r-2 bg-[#E2E8F0] h-[40px] w-[50%] flex items-center">
                            <p className="pl-1 text-[#64748B] text=[14px] font-[500] capitalize">
                              {key}
                            </p>
                          </div>
                          <div className="h-[40px] flex w-[50%] items-center">
                            <img src={Naira} alt="Naira" />
                            <h6 className="pl-1">
                              {" "}
                              {Number(value).toLocaleString()}
                            </h6>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="mb-3">
                <h6 className="text-[#64748B] text=[14px] font-[700]">
                  Net after remittance
                </h6>
                <div className="mt-2">
                  {payrollBreakdown &&
                    Object?.entries(
                      payrollBreakdown?.["Net after remittance"]
                    )?.map(([key, value], index) => (
                      <div
                        className="flex justify-between items-center border-2 rounded mb-3"
                        key={index}
                      >
                        <div className="border-r-2 bg-[#E2E8F0] h-[40px] w-[50%] flex items-center">
                          <p className="pl-1 text-[#64748B] text=[14px] font-[500]">
                            {key}
                          </p>
                        </div>
                        <div className="h-[40px] flex w-[50%] items-center">
                          <img src={Naira} alt="Naira" />
                          <h6 className="pl-1">
                            {" "}
                            {Number(value).toLocaleString()}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </MainModal>
    </>
  );
};

export default PayrollBreakDownModal;
