import React, { useEffect } from "react";
import MainModal from "../../../../../component/MainModal.";
import { FaPercent } from "react-icons/fa";
import { useFormik } from "formik";
import { ReliefComponentSchema } from "../../../../../authPages/schema";
import { toast } from "react-toastify";

const EditReliefModal = ({
  modalOpen,
  onClose,
  size,
  setReliefComponents,
  reliefComponents,
  data,
}) => {
  const onSubmit = (values, actions) => {
    const TOAST_ID = toast.loading("Updating component.., please wait...");
    const newReliefComponents = reliefComponents?.map((item) => {
      if (item?.id === data?.id) {
        return { ...item, percentage: values.percentage };
      } else {
        return item;
      }
    });
    setReliefComponents(newReliefComponents);
    toast.update(TOAST_ID, {
      render: 'Component updated successfully!!',
      type: "sucecess",
      isLoading: false,
      autoClose: 1000,
    });
    actions.resetForm();
    onClose();
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      percentage: "",
    },
    validationSchema: ReliefComponentSchema,
    onSubmit,
  });

  useEffect(() => {
    if (data) {
      setFieldValue("percentage", data.percentage);
    }
  }, [data]);
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle={"Edit Relief Component"}
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          <form onSubmit={handleSubmit}>
            <div>
              <div className="input-password-box mt-5">
                <input
                  type="text"
                  name="percentage"
                  placeholder="Percentage"
                  autoComplete="off"
                  id="percentage"
                  value={values.percentage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span className="view" style={{ cursor: "pointer" }}>
                  <FaPercent />
                </span>
              </div>

              <label htmlFor="email" className="label-name">
                <span className="label-text">
                  Pension fund employee percentage
                </span>
              </label>
            </div>
            <div className="upload-from-csv-box-three mt-8">
              <button type="submit" disabled={isSubmitting}>
                Save
              </button>
            </div>
          </form>
        </div>
      </MainModal>
    </>
  );
};

export default EditReliefModal;
