import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BlueArrow from "../../asset/auth/blue-back-arrow.svg";
import Logo from "../../asset/auth/second-logo.svg";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import LoginLayout from "../layout/LoginLayout";
import { resetPasswordFormSchema } from "../schema";
import { ForgotPassword_ } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const RESET_PSW_TOAST_ID = toast.loading(
      "Reseting password, please wait..."
    );

    const { email } = values;

    const data = {
      email,
    };

    const res = await dispatch(ForgotPassword_(data));
    if (res.payload.status < 400) {
      toast.update(RESET_PSW_TOAST_ID, {
        render: res.payload?.data.message
          ? res.payload?.data.message
          : "succesful!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      setTimeout(() => {
        actions.resetForm();
      }, 2300);
    } else {
      toast.update(RESET_PSW_TOAST_ID, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPasswordFormSchema,
    onSubmit,
  });

  return (
    <LoginLayout>
      <div className="row">
        <div className="2xl:w-9/12 lg:w-10/12 mx-auto">
          <div className="second-auth-container-box-header mb-5">
            <div className="logo-box hidden sm:block">
              <img src={Logo} alt="" />
            </div>
            <div className="logo-box sm:hidden">
              <img src={Logo2} alt="" />
            </div>
            <h5 className="mt-8 sm:mt-0">Reset Password</h5>
            <p>We’d like to verify you first</p>
          </div>

          <div className="second-auth-container-box-body mt-14">
            <form className="row" onSubmit={handleSubmit}>
              <div className=" mb-6">
                <div
                  className={`input-box ${
                    !errors.email && touched.email && values.email
                      ? "filled"
                      : errors.email && touched.email
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    name="email"
                    autoComplete="off"
                    placeholder="Enter an email address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="email" className="label-name">
                    <span className="label-text">Email </span>
                  </label>
                </div>
                <div>
                  {errors.email && touched.email && (
                    <p className="error">{errors.email}</p>
                  )}
                </div>
              </div>

              <div className="submit-btn ">
                {/* <Link to="/auth/new-password"> */}
                <button disabled={isSubmitting}>Reset Password</button>
                {/* </Link> */}
              </div>
            </form>

            <div className="second-auth-container-box-body-footer mt-4">
              <Link to="/auth/login">
                <p className="flex gap-1 items-center">
                  <span className="icon">
                    <img src={BlueArrow} alt="" />
                  </span>

                  <span className="text">Take me back to sign in page</span>
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ResetPassword;
