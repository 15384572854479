import React from "react";
import { useSelector } from "react-redux";
import payrollIcon from "./../../../../../asset/svgs/payroll.svg";
import peopleIcon from "./../../../../../asset/svgs/people.svg";
import calendarIcon from "./../../../../../asset/svgs/calendar.svg";
import { Pagination, Table } from "rsuite";
import LineButton from "../../../../../component/atoms/LineButton";
import BlueBotton from "../../../../../component/atoms/BlueBotton";
import { useNavigate } from "react-router-dom";
import naira from "./../../../../../asset/svgs/NairaBlack.svg";

import { formatDateWithDay } from "../../../../../utils";

export default function PayrollSummary({ selectedEmployees, payrollSummary }) {
  const { user } = useSelector((state) => state.users);
  const { Column, HeaderCell, Cell } = Table;
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const totalPayroll = selectedEmployees
    .reduce((acc, curr) => {
      return parseFloat(acc) + parseFloat(curr.net_pay);
    }, 0)
    .toLocaleString();

  const NairaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <img src={naira} alt="naira" />
          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };
  const NameCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} className="text-txblue">
        {rowData[dataKey]} {rowData["first_name"]}
      </Cell>
    );
  };

  return (
    <div>
      <div className="py-6">
        <div className="px-4 lg:px-9">
          <p className="text-txgray2 text-lg lg:text-[24px] font-semibold">
            Well done {user.name},
          </p>
          <p className="text-[#475569] text-sm">
            You can relax now, Fuse ERP will debit you NGN {totalPayroll} and
            your employees would get paid instantly or on your default payment
            date so far you APPROVE the payroll.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-3 mt-6 gap-2 lg:gap-6">
            <div
              className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
              style={{
                boxShadow: "2px 2px 8px 0px #9494940D",
                border: "1px solid #F1F5F9",
              }}
            >
              <img src={payrollIcon} alt="icon" />
              <div>
                <p className="text-sm font-normal">Total Payroll</p>
                <p className="text-[22px] font-semibold rounded-[4px] mt-1 flex items-center">
                  <sup className="">
                    <img src={naira} alt="naira" className="w-3 h-3" />
                  </sup>
                  {totalPayroll}
                </p>
              </div>
            </div>
            <div
              className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
              style={{
                boxShadow: "2px 2px 8px 0px #9494940D",
                border: "1px solid #F1F5F9",
              }}
            >
              <img src={peopleIcon} alt="icon" />
              <div>
                <p className="text-sm font-normal">Employee count</p>
                <p className="text-[22px] font-semibold mt-1">
                  {selectedEmployees.length}{" "}
                </p>
              </div>
            </div>
            <div
              className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
              style={{
                boxShadow: "2px 2px 8px 0px #9494940D",
                border: "1px solid #F1F5F9",
              }}
            >
              <img src={calendarIcon} alt="icon" />
              <div>
                <p className="text-sm font-normal">Payroll payment Date</p>
                <p className="text-[22px] font-semibold mt-1">
                  {formatDateWithDay(payrollSummary?.payment_date)}
                </p>
              </div>
            </div>
          </div>
          <p className="text-[16px] font-semibold mt-8 mb-6">Payroll Summary</p>
        </div>
        <div className="mt-10 bg-[#FFFFFF] px-4 lg:px-10 py-6">
          <Table
            height={400}
            data={selectedEmployees}
            onRowClick={(rowData) => {}}
            className="border-[1px] border-[#F1F5F9] rounded-lg"
          >
            <Column align="left" width={150}>
              <HeaderCell>Employee Name</HeaderCell>
              <NameCell dataKey="last_name" />
            </Column>
            <Column width={150} align="left">
              <HeaderCell>Gross Salary</HeaderCell>
              <NairaCell dataKey="monthly_gross_salary" />
            </Column>
            <Column align="left" width={130}>
              <HeaderCell>Deductions</HeaderCell>
              <NairaCell dataKey="deduction_amount" />
            </Column>
            <Column align="left" width={130}>
              <HeaderCell>Taxes</HeaderCell>
              <NairaCell dataKey="tax_paid" />
            </Column>
            <Column align="left" width={130}>
              <HeaderCell>Benefits</HeaderCell>
              <NairaCell dataKey="benefit_amount" />
            </Column>
            <Column width={150} align="left">
              <HeaderCell>Net Salary</HeaderCell>
              <NairaCell dataKey="net_pay" />
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={selectedEmployees?.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
        <div className="flex gap-4 px-4 lg:px-9 py-6">
          <LineButton label="Done" />
          <BlueBotton
            label="Submit for Approval"
            onClick={() => {
              navigate("/dashboard/payroll");
            }}
          />
        </div>
      </div>
    </div>
  );
}
