import { useFormik, FormikProvider, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { newTenantBranchesFormSchema } from "../../authPages/schema";
import {
  AddTenantBranches,
  DeleteTenantBranch,
  GetBranches,
} from "../../store/slices/userSlice";

const AddBranch = ({ setStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allBranches } = useSelector((state) => state?.users);

  // Async Functions
  const deleteBranch = async (id) => {
    const toastId = toast.loading("Deleting department, please wait...");
    const body = {
      branch_ids: [id],
    };
    const res = await dispatch(DeleteTenantBranch(body));
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render: res?.payload.data?.message || "Branch deleted successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render:
          res?.payload?.data?.message ||
          res?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const onSubmit = async (values, actions) => {
    const validation = values.data.every((item) => item.name && item.address);
    if (allBranches?.length > 0 && !validation) {
      setStep(5);
    } else {
      const toastId = toast.loading("Creating Branches, please wait...");
      const res = await dispatch(AddTenantBranches(Object.values(values)[0]));
      if (res?.payload?.status < 400) {
        toast.update(toastId, {
          render: res?.payload.data?.message || "Branches added successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        setTimeout(() => {
          setStep(5);
          actions.resetForm();
        }, 1200);
      } else {
        toast.update(toastId, {
          render:
            res?.response?.data?.message ||
            res?.message ||
            "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  const getBranches = async () => {
    const res = await dispatch(GetBranches());
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  // UseEffect
  useEffect(() => {
    getBranches();
  }, []);

  const formik = useFormik({
    initialValues: {
      data: [
        {
          name: "",
          address: "",
        },
      ],
    },
    onSubmit,
    validationSchema:
      allBranches?.length > 0 ? null : newTenantBranchesFormSchema,
  });

  return (
    <div className="mt-12 xl:w-[90%] 2xl:w-[90%] mx-auto">
      <div className="mb-1">
        <button
          className="flex gap-1 items-center ml-auto"
          onClick={() => navigate("/")}
        >
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <path
                d="M0 6L4.25 3L0 0V6ZM1 1.93L2.515 3L1 4.07V1.93ZM5 0H6V6H5V0Z"
                fill="#2563EB"
              />
            </svg>
          </span>
          <span
            className="text-[#2563EB] text-sm tracking-[0.1px] font-medium"
            onClick={() => setStep(5)}
          >
            Skip this step
          </span>
        </button>
      </div>
      <div className="onboarding-container-box-two-header mb-8">
        <h6 className="text-[#475569] text-base tracking-[0.15px] font-semibold">
          Add Branch
        </h6>
        <p className="font-medium text-xs tracking-[0.5px] text-[#475569]">
          Add branches for your organization
        </p>
      </div>

      <div className="onboarding-container-box-two-body pb-14">
        <FormikProvider value={formik}>
          <form className="row" onSubmit={formik.handleSubmit}>
            <FieldArray
              name="data"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {formik.values.data &&
                      formik.values.data?.length !== 0 &&
                      formik.values.data?.map((branch, i) => (
                        <div className="flex items-center gap-x-3 mb-3" key={i}>
                          <div className="grid xl:grid-cols-2 gap-x-2 sm:gap-y-2 flex-grow">
                            <div className=" mb-2">
                              <div
                                className={`input-box
                                ${
                                  Object.keys(formik.errors).length > 0 &&
                                  Object.keys(formik.touched).length > 0 &&
                                  !formik.errors.data[i]?.name &&
                                  formik?.touched?.data[i]?.name &&
                                  formik?.values?.data[i]?.name
                                    ? "filled"
                                    : Object.keys(formik.errors).length > 0 &&
                                      Object.keys(formik.touched).length > 0 &&
                                      formik?.errors?.data[i]?.name &&
                                      formik?.touched?.data[i]?.name
                                    ? "error"
                                    : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  name={`data[${i}].name`}
                                  autoComplete="off"
                                  id={`data[${i}].name`}
                                  value={formik.values.data[i].name}
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor="name" className="label-name">
                                  <span className="label-text">
                                    Branch Name
                                  </span>
                                </label>
                              </div>
                              <div>
                                {Object.keys(formik.errors).length > 0 &&
                                  Object.keys(formik.touched).length > 0 &&
                                  formik?.errors.data[i]?.name &&
                                  formik?.touched.data[i]?.name && (
                                    <p className="error">
                                      {formik.errors.data[i].name}
                                    </p>
                                  )}
                              </div>
                            </div>

                            <div className=" mb-2">
                              <div
                                className={`input-box
                                ${
                                  Object.keys(formik.errors).length > 0 &&
                                  Object.keys(formik.touched).length > 0 &&
                                  !formik.errors.data[i]?.address &&
                                  formik?.touched?.data[i]?.address &&
                                  formik?.values?.data[i]?.address
                                    ? "filled"
                                    : Object.keys(formik.errors).length > 0 &&
                                      Object.keys(formik.touched).length > 0 &&
                                      formik?.errors?.data[i]?.address &&
                                      formik?.touched?.data[i]?.address
                                    ? "error"
                                    : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  name={`data[${i}].address`}
                                  autoComplete="off"
                                  id={`data[${i}].address`}
                                  value={formik.values.data[i].address}
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor="address" className="label-name">
                                  <span className="label-text">
                                    Branch Address
                                  </span>
                                </label>
                              </div>
                              <div>
                                {Object.keys(formik.errors).length > 0 &&
                                  Object.keys(formik.touched).length > 0 &&
                                  formik?.errors.data[i]?.address &&
                                  formik?.touched.data[i]?.address && (
                                    <p className="error">
                                      {formik.errors.data[i]?.address}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          {i > 0 && (
                            <button
                              onClick={() => arrayHelpers.remove(i)}
                              type="button"
                            >
                              <FaTrash
                                style={{ fill: "red", cursor: "pointer" }}
                              />
                            </button>
                          )}
                        </div>
                      ))}
                    <div>
                      <button
                        onClick={() =>
                          arrayHelpers.push({ name: "", address: "" })
                        }
                        className="bg-[#fff] text-[#2563EB] text-sm tracking-[0.1px] py-[10px] min-w-[150px] font-bold flex gap-1 items-center"
                        type="button"
                        style={{
                          border: "none",
                          borderRadius: "4px",
                          outline: "none",
                        }}
                      >
                        <span className="text-[20px]">+</span>
                        Add another branch
                      </button>
                      <p className="mt-6 text-[#64748B] text-[11px] leading-[145%] tracking-[0.5px] font-medium">
                        You can add, remove & edit branches in your organization
                        setting
                      </p>
                    </div>
                    <div className="mt-9">
                      <h6>Branches</h6>
                      <div className="mt-4 flex justify-between h-[200px] overflow-auto">
                        {allBranches?.length ? (
                          <div className="w-[100%]">
                            {allBranches?.map((branch, i) => (
                              <div
                                key={i}
                                className="mr-2 bg-[#F8FAFC] p-2 flex justify-between mb-3"
                              >
                                <div>
                                  <span>Branch Name: {branch.name}</span>
                                  <br />
                                  <span>Branch location: {branch.address}</span>
                                </div>
                                <button
                                  className="text-[12px] ml-2"
                                  type='button'
                                  onClick={() => deleteBranch(branch.id)}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-[#94A3B8] text-xs font-medium tracking-[0.5px]">
                            NO BRANCH ADDED YET
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }}
            />{" "}
            <div className="onboarding-container-main-box-body-footer min-h-[50px] pl-8 xl:pl-14 lg:pr-[105px] py-[18px]">
              <div className="flex justify-between">
                <button
                  type="button"
                  className="next-btn bg-[#F1F5F9] text-[#334155] p-[11px] min-w-[150px]"
                  onClick={() => setStep(3)}
                >
                  Previous
                </button>
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="next-btn bg-[#2563EB] text-white p-[11px] min-w-[250px]"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default AddBranch;
