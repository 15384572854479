import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import * as yup from "yup";
import { StyledInputWrapper } from "../../component/atoms/StyledComponents";
import BlueBotton from "../../component/atoms/BlueBotton";
import OtpModal from "./OtpModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../utils/https";
import toast from "react-hot-toast";
import close from "./../../asset/svgs/Icon.svg";
import { IconButton } from "@mui/material";

const CreateWallet = ({
  open,
  onClose,
  setWalletData,
  setGenerateWallet,
  setWalletCreated,
}) => {
  const [bvnVData, setBvnVData] = useState(null);
  const [openConfirmOtp, setOpenConfirmOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const queryClient = useQueryClient();

  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        bvn: "",
      },
      onSubmit: (values) => {
        bvnMutation.mutate({
          bvn_number: values?.bvn,
          retry: false,
        });
      },
      validationSchema: yup.object().shape({
        bvn: yup
          .string()
          .required("Please enter BVN")
          .max(11, "BVN must not be more than 11-digits.")
          .min(11, "BVN must not be less than 11-digits."),
      }),
    });
  const bvnMutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post(
        "/tenants/safe-haven/initiate-bvn-verification",
        payload
      ),
    onSuccess: (data) => {
      // onClose();
      toast.success("OTP sent");
      setOpenConfirmOtp(true);
      setBvnVData(data?.data?.data);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("virtual-accounts/safe-haven/create", payload),
    onSuccess: (data) => {
      setWalletData(data?.data?.data);
      setGenerateWallet(false);
      setWalletCreated(true);
      setOpenConfirmOtp(false);
      onClose();
      queryClient.invalidateQueries({
        queryKey: ["dashboardData"],
        refetchType: "active",
      });
    },
    onError: (err) => {
      toast.error(err.message);
      setGenerateWallet(false);
      setOpenConfirmOtp(false);
      onClose();
    },
  });
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex items-center gap-2 border-b-[1px] border-[hsl(210,40%,96%)] mb-6 pb-2">
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
        <p className="tetx-txgray2 text-[22px] font-semibold">Create wallet</p>
      </div>
      <form className="flex flex-col gap-4">
        <p className="text-txgray text-[12px] ">
          We will be needing some information to verify you
        </p>
        <StyledInputWrapper>
          <input
            id="bvn"
            name="bvn"
            type="text"
            placeholder="Enter BVN"
            value={values?.bvn}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.bvn && touched.bvn && "error"}
          />
          {errors.bvn && touched.bvn && (
            <p className="field_error">{errors.bvn}</p>
          )}
        </StyledInputWrapper>
      </form>

      <div className="flex gap-[10px] justify-end mt-6">
        <button
          className="bg-[#F1F5F9] px-4 py-2 rounded"
          onClick={onClose}
          type="button"
        >
          Cancel
        </button>
        <BlueBotton
          label="Create"
          onClick={() => handleSubmit()}
          type="submit"
          loading={bvnMutation.isPending}
        />
      </div>
      <OtpModal
        btnTitle={"Create"}
        onClose={() => setOpenConfirmOtp(false)}
        openConfirmOtp={openConfirmOtp}
        sendOtpMutation={() =>
          bvnMutation.mutate({
            bvn_number: values?.bvn,
            retry: false,
          })
        }
        createAccount={() =>
          mutation.mutate({
            bvn_number: bvnVData?.bvn_number,
            identity_verification_request_id:
              bvnVData?.verification_response?.identity_verification_request_id,
            otp: otp,
          })
        }
        createAccountFn={mutation}
        otp={otp}
        setOtp={setOtp}
      />
    </Modal>
  );
};

export default CreateWallet;
