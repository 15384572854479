import { atom } from "recoil";

export const dashboardTab = atom({
  key: "dashboardTab",
  default: "Dashboard",
});

export const payrollTab = atom({
  key: "payrollTab",
  default: 0,
});
export const settingsTab = atom({
  key: "settingsTab",
  default: "info",
});

export const selectedBranchesPersist = atom({
  key: "selectedBranchesPersist",
  default: [],
});
export const viewBalanceState = atom({
  key: "viewBalanceState",
  default: false,
});
