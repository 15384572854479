import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../../dashboard/dashboard-styles/dashboard.css";
import Header from "./Header";
import SideBar from "./SideBar";
import { Box } from "@mui/material";
import Loader from "../atoms/Loader";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/https";

const DashboardLayout = () => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const { error, isLoading, isError } = useQuery({
    queryKey: ["getTenantDetails"],
    queryFn: async () => {
      const response = await axiosInstance().get("/tenants/profile");
      return response?.data?.data;
    },
  });

  useEffect(() => {
    if (isError) {
      navigate("/auth/login");
    }
  }, [error, isError]);

  return (
    <>
      {isLoading ? (
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="container-fluid dashboard flex">
            <SideBar />
            <Box
              className="dashboard-main-content"
              sx={{ width: { lg: "calc(100vw - 240px)", xs: "100%" } }}
            >
              <Header title={title} />
              <div className="dashboard-main-content-body min-h-screen">
                <Outlet context={[setTitle]} />
              </div>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardLayout;
