import React from "react";
import CompanyProfile from "./CompanyProfile";
import UserProfile from "./UserProfile";
import { useSelector } from "react-redux";

const CompanyInfo = () => {
  const { userDetails } = useSelector((state) => state?.users);

  return (
    <div className="w-full flex  items-center flex-col gap-6">
      {userDetails?.user_permissions === null && <CompanyProfile />}
      <UserProfile />
    </div>
  );
};

export default CompanyInfo;
