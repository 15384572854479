import React, { useState } from "react";
import addcircle from "./../../../../asset/svgs/add-circle.svg";
import Roles from "./Roles";
import Teams from "./Teams";
import CreateRole from "../settings-modal/CreateRole";
import AddMember from "../settings-modal/AddMember";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import Tabs from "../../../dashboard-component/controls/Tabs";
import { useSelector } from "react-redux";
import useUserPermissions from "./../../../../utils/permissions";

export default function TeamAndRoles() {
  const [activeTab, setActiveTab] = useState("roles");
  const [openCreateRole, setOpenCreateRole] = useState(false);
  const [openMember, setOpenMember] = useState(false);
  const [openAddMember, setAddTeamMember] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const { userDetails } = useSelector((state) => state?.users);
  const {
    viewRolePermission,
    addRolePermission,
    viewTeamPermission,
    addTeamPermission,
  } = useUserPermissions();

  const ToggleLoadModal = () => {
    setLoadModal(!loadModal);
  };
  //queries
  useQuery({
    queryKey: ["getAllRoles"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/groups/`);
      return response?.data?.data?.results;
    },
  });
  useQuery({
    queryKey: ["getAllEmployees"],
    queryFn: async () => {
      const response = await axiosInstance().get(`users/employees/`);
      return response?.data?.data?.results;
    },
  });
  return (
    <div className="bg-[#FFFFFF] px-4 lg:px-6 py-4 w-full">
      <div className="flex flex-col gap-4 lg:flex-row justify-between items-start lg:items-center">
        <Tabs
          activeTab={activeTab}
          data={[
            ...(viewRolePermission
              ? [
                  {
                    name: "Roles & permissions",
                    id: "roles",
                    action: () => setActiveTab("roles"),
                  },
                ]
              : []),
            ...(viewTeamPermission
              ? [
                  {
                    name: "Team members",
                    id: "team",
                    action: () => setActiveTab("team"),
                  },
                ]
              : []),
          ]}
        />

        {activeTab === "team" && addTeamPermission ? (
          <button
            className={`text-sm bg-[#2563EB] font-bold text-[#FFFFFF] py-[10px] px-4 rounded-md flex gap-2 items-center`}
            onClick={() => {
              setAddTeamMember(true);
              setLoadModal(true);
            }}
          >
            <img src={addcircle} alt="add" />
            Add member
          </button>
        ) : activeTab === "roles" && addRolePermission ? (
          <button
            className={`text-sm bg-[#2563EB] font-bold text-[#FFFFFF] py-[10px] px-4 rounded-md flex gap-2 items-center`}
            onClick={() => {
              setOpenCreateRole(true);
              setLoadModal(true);
            }}
          >
            <img src={addcircle} alt="add" />
            Create role
          </button>
        ) : null}
      </div>
      <div className="mt-8">
        {activeTab === "team" &&
        (userDetails?.user_permissions?.[3]?.team_member?.[0]?.access ||
          userDetails?.user_permissions === null) ? (
          <Teams setOpenMember={setOpenMember} openMember={openMember} />
        ) : activeTab === "roles" &&
          (userDetails?.user_permissions?.[0]?.group?.[0]?.access ||
            userDetails?.user_permissions === null) ? (
          <Roles
            setOpenCreateRole={setOpenCreateRole}
            openCreateRole={openCreateRole}
          />
        ) : null}
      </div>

      {loadModal ? (
        <>
          <CreateRole
            actionType={"add"}
            onClose={() => {
              setOpenCreateRole(false);
              ToggleLoadModal();
            }}
            open={openCreateRole}
          />
          <AddMember
            actionType={"add"}
            onClose={() => {
              setAddTeamMember(false);
              ToggleLoadModal();
            }}
            open={openAddMember}
            setActiveTab={setActiveTab}
          />
        </>
      ) : null}
    </div>
  );
}
