import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { Modal } from "rsuite";
import close from "./../../../../asset/svgs/close-circle.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import Select, { components } from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import addIcon from "./../../../../asset/svgs/add.svg";
import lamp from "./../../../../asset/svgs/lamp-on.svg";
import ButtonFilled from "../../../../component/atoms/ButtonFilled";
import { StyledInputWrapper } from "../../../../component/atoms/StyledComponents";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

export default function AddMember({
  open,
  onClose,
  actionType,
  setActiveTab,
  memberData,
}) {
  const queryClient = useQueryClient();
  console.log(memberData);
  //queries
  const { data: roleData } = useQuery({
    queryKey: ["getAllRoles"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/groups/`);
      return response?.data?.data?.results;
    },
  });

  const { data: employeesData } = useQuery({
    queryKey: ["getAllEmployees"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/users/employees/`);
      return response?.data?.data?.results;
    },
  });

  //muations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/users/team-members/add-users-to-team", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      queryClient.invalidateQueries({
        queryKey: ["getAllMembers"],
        refetchType: "active",
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  //datas
  const roles = roleData?.map((item) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });
  const employees = employeesData?.map((item) => {
    return {
      label: [item?.first_name, " ", item?.last_name],
      value: item?.user_id,
    };
  });

  //functions
  useEffect(() => {
    const findEmployee = employeesData?.find(
      (item) =>
        item?.first_name === memberData?.first_name &&
        item?.last_name === memberData?.last_name
    );
    const findRole = roleData?.find((item) => item.name === memberData?.group);
    if (actionType === "edit") {
      setFieldValue("employee", {
        label: [findEmployee?.first_name, " ", findEmployee?.last_name],
        value: findEmployee?.user_id,
      });
      setFieldValue("role", {
        label: findRole?.name,
        value: findRole?.id,
      });
    }
  }, []);

  //schema
  const schema = yup.object().shape({
    employee: yup.object().shape({
      label: yup.array().required("This field is required"),
    }),
    role: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
  });

  //formik
  const { values, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      employee: "",
      role: "",
    },
    onSubmit: (values, { resetForm }) => {
      resetForm();
      const data = {
        group_id: values.role.value,
        user_ids: [values.employee.value],
      };
      mutation.mutate(data);
    },
    validationSchema: schema,
  });

  //components
  const NameMenu = (props) => {
    return (
      <>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              type="button"
              className="text-[#2563EB] my-1 flex justify-start items-center p-2"
              onClick={() => {
                onClose();
                // setActiveTab("roles");
              }}
            >
              <img src={addIcon} alt="addIcon" />
              <span className="font-bold text-sm">Add role</span>
            </button>
          </div>
        </components.Menu>
      </>
    );
  };
  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>{props.children}</components.Option>
      </>
    );
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="text-txgray2 text-[22px] font-semibold">
          {actionType === "edit" ? "Edit member" : "Add member"}
        </p>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="close" />
        </IconButton>
      </div>
      <form className="flex flex-col gap-3">
        <StyledInputWrapper>
          <Select
            value={values?.employee}
            onChange={(employee) => {
              setFieldValue("employee", employee);
            }}
            options={employees}
            placeholder="Select Employee"
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "48px",
                fontSize: "14px",
              }),
            }}
            isDisabled={actionType === "edit"}
          />
          {errors.employee && touched.employee && (
            <p className="field_error">{errors.employee.label}</p>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Select
            value={values?.role}
            onChange={(role) => {
              setFieldValue("role", role);
            }}
            options={roles}
            placeholder="Select Role"
            components={{ Menu: NameMenu, Option }}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "48px",
                fontSize: "14px",
              }),
            }}
          />
          {errors.role && touched.role && (
            <p className="field_error">{errors.role.label}</p>
          )}
        </StyledInputWrapper>
        <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded my-4">
          <img src={lamp} alt="close" />
          <p>
            Please make sure roles have been created on the{" "}
            <button
              className="text-[#2563EB]"
              onClick={() => {
                onClose();
                // setActiveTab("roles");
              }}
            >
              roles & permission tab
            </button>{" "}
            before you add a user tab in your settings
          </p>
        </div>
        <div className="flex gap-2 justify-end mt-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
          <ButtonFilled onClick={handleSubmit} loading={mutation.isPending}>
            {actionType === "edit" ? "Edit" : "Add"}
          </ButtonFilled>
        </div>
      </form>
    </Modal>
  );
}
