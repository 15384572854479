// AuthContext.js
import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => {
    // Retrieve value from local storage on page reload
    return JSON.parse(localStorage.getItem("fuse_auth")) || {};
  });

  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("access_token")
  );
  const [tenantsDetails, setTenantDetails] = useState(
    JSON.parse(localStorage.getItem("tenant-details"))
  );

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    setIsAuthenticated(token);
    const ID = JSON.parse(localStorage.getItem("tenant-details"));
    setTenantDetails(ID);
  }, []);

  useEffect(() => {
    // Save value to local storage when it updates
    localStorage.setItem("fuse_auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        tenantsDetails,
        auth,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
