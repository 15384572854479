import React, { useState } from "react";
import hrIcon from "./../../../../../asset/svgs/hrIcon.svg";
import breadcrumbarrow from "./../../../../../asset/svgs/breadcrumbarrow.svg";
import RunEmployeesPayroll from "./RunEmployeesPayroll";
import CheckIcon from "../../../../../asset/IconJs/checkIcon";
import CheckOutPayroll from "./CheckOutPayroll";
import PayrollSummary from "./PayrollSummary";

export default function RunEmployeePayrollIndex() {
  const [select, setSelect] = useState("processing");
  const [confirm, setConfirm] = useState("unprocessed");
  const [checkout, setCheckout] = useState("unprocessed");
  const [success, setSuccess] = useState("unprocessed");
  const [page, setRunPayrollPage] = useState("selectEmployees");

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [payrollSummary, setPayrollSummary] = useState();

  const textColor = (state) => {
    switch (state) {
      case "unprocessed":
        return "text-[#475569]";
      case "processing":
        return "text-[#2563EB]";
      case "processed":
        return "text-[#059669]";
      default:
        return "text-[#475569]";
    }
  };

  return (
    <div className="flex w-full">
      <div className="w-full">
        <div className="flex items-center pt-6 pl-4 lg:pl-10">
          <span className="flex items-center gap-1">
            <img src={hrIcon} alt="icon" />
            <p className="text-txblue text-[12px] lg:text-sm">Human Resource</p>
          </span>
          <img src={breadcrumbarrow} alt="arrow" className="px-2" />
          <p className="text-txblue text-[12px] lg:text-sm">Payroll</p>
          <img src={breadcrumbarrow} alt="arrow" className="px-2" />
          <p className="text-txgray text-[12px] lg:text-sm">
            Run Employees Payroll
          </p>
        </div>
        {page === "selectEmployees" ? (
          <RunEmployeesPayroll
            setSelect={setSelect}
            setConfirm={setConfirm}
            setRunPayrollPage={setRunPayrollPage}
            setCheckout={setCheckout}
            setSelectedEmployees={setSelectedEmployees}
          />
        ) : page === "checkout" ? (
          <CheckOutPayroll
            selectedEmployees={selectedEmployees}
            setCheckout={setCheckout}
            setSelect={setSelect}
            setConfirm={setConfirm}
            setSuccess={setSuccess}
            setRunPayrollPage={setRunPayrollPage}
            setPayrollSummary={setPayrollSummary}
          />
        ) : page === "success" ? (
          <PayrollSummary
            selectedEmployees={selectedEmployees}
            payrollSummary={payrollSummary}
          />
        ) : null}
      </div>
      <div className="bg-[#FFFFFF] min-w-[280px] py-10 px-6 h-screen hidden  lg:block">
        <div className="sticky top-[115px]">
          <p className="text-[#0F172A] text-[16px] font-semibold">
            Run Employees Payroll
          </p>

          <div className="mt-4 flex flex-col">
            <div className="flex gap-2 py-2 items-center">
              <CheckIcon state={select} />
              <p className={`${textColor(select)} font-bold`}>
                Select Employees
              </p>
            </div>
            <div className="flex gap-2 py-2 items-center">
              <CheckIcon state={confirm} />
              <p className={`${textColor(confirm)} font-bold`}>Confirmation</p>
            </div>
            <div className="flex gap-2 py-2 items-center">
              <CheckIcon state={checkout} />
              <p className={`${textColor(checkout)} font-bold`}>
                Payment action/summary
              </p>
            </div>
            <div className="flex gap-2 py-2 items-center">
              <CheckIcon state={success} />
              <p className={`${textColor(success)} font-bold`}>Success</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
