/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NilImg from "../../../../asset/dashboard/nil-hand.svg";
import {
  GetAllEmployees,
  GetBreakdown,
} from "../../../../store/slices/PayrollSlice";
import AddemployeeModal from "./modals/AddEmployeeModal";
import {
  Table,
  Pagination,
  IconButton,
  Whisper,
  Popover,
  Dropdown,
  InputGroup,
  Input,
} from "rsuite";
import { SelectPicker } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import Loader from "../../../../component/atoms/Loader";
import EmployeeDetailsModal from "./modals/EmployeeDetails";
import PayrollBreakdown from "./modals/PayrollBreakDown";
import DeleteEmployee from "./modals/DeleteEmployee";
import edit from "./../../../../asset/svgs/edit.svg";
import deleteIcon from "./../../../../asset/svgs/delete.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useUserPermissions from "../../../../utils/permissions";

const { Column, HeaderCell, Cell } = Table;

const Employees = ({ fetch }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [breakdownModal, setBreakdownModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payrollLoading, setPayrollLoading] = useState(false);
  const { allEmployees, payrollSetup } = useSelector((state) => state?.payroll);
  const { allBranches, allDept } = useSelector((state) => state?.users);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [department, setDepartment] = useState("");
  const [branch, setBranch] = useState("");
  const [type, setType] = useState("");
  const [data_, setData] = useState(null);
  const [view, setView] = useState(false);
  const {
    editEmployeePermission,
    deleteEmployeePermission,
    viewEmployeePermission,
  } = useUserPermissions();

  // console.log(allEmployees);
  // Async functions
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  // Async functions
  const getEmployees = async () => {
    setLoading(true);
    const res = await dispatch(GetAllEmployees());
    setLoading(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const getEmployeesB = async () => {
    const res = await dispatch(GetAllEmployees());
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const getBreakdown = async () => {
    setPayrollLoading(true);
    const body = {
      amount: data_?.monthly_gross_salary,
      payroll_id: payrollSetup?.id,
      nhf: data_?.has_nhf,
    };
    const res = await dispatch(GetBreakdown(body));
    setPayrollLoading(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    getBreakdown();
  }, [data_]);

  // UseEffect
  useEffect(() => {
    if (payrollSetup?.setup_complete && allEmployees?.length <= 0) {
      getEmployees();
    } else if (!allEmployees) {
      getEmployees();
    }
    getEmployeesB();
  }, [payrollSetup]);

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover>
        <Dropdown.Menu>
          {deleteEmployeePermission && (
            <Dropdown.Item
              eventKey={1}
              onClick={() => {
                setDeleteModal(true);
                setData(rowData);
              }}
            >
              <div className="flex items-center gap-2">
                <img src={deleteIcon} alt="edit" />
                <span className="text-[#E11D48] text-sm font-bold">
                  {" "}
                  Delete Employee
                </span>
              </div>
            </Dropdown.Item>
          )}

          {editEmployeePermission && (
            <Dropdown.Item
              eventKey={2}
              onClick={() => {
                setType("edit");
                setOpen(true);
                setData(rowData);
              }}
            >
              <div className="flex items-center gap-2">
                <img src={edit} alt="edit" />
                <span className="text-txgray2 text-sm font-bold">
                  {" "}
                  Edit Employee
                </span>
              </div>
            </Dropdown.Item>
          )}

          {viewEmployeePermission && (
            <Dropdown.Item
              eventKey={3}
              onClick={() => {
                setView(true);
                setData(rowData);
              }}
            >
              <div className="flex items-center gap-2">
                <VisibilityIcon fontSize="16px" />
                <span className="text-txgray2 text-sm font-bold">
                  {" "}
                  View Employee
                </span>
              </div>
            </Dropdown.Item>
          )}
          {viewEmployeePermission && (
            <Dropdown.Item
              eventKey={4}
              onClick={async () => {
                setData(rowData);
                setBreakdownModal(true);
              }}
            >
              <div className="flex items-center gap-2">
                <VisibilityIcon fontSize="16px" />
                <span className="text-txgray2 text-sm font-bold">
                  {" "}
                  View Payroll breakdown
                </span>
              </div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Popover>
    );
    return (
      <Cell {...props}>
        <Whisper placement="autoVerticalEnd" trigger="click" speaker={speaker}>
          <IconButton
            appearance="subtle"
            style={{ padding: 0 }}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                  fill="#475569"
                />
              </svg>
            }
          />
        </Whisper>
      </Cell>
    );
  };

  const MoneyCell = ({ rowData, dataKey, ...props }) => {
    return <Cell {...props}>{Number(rowData[dataKey]).toLocaleString()}</Cell>;
  };

  const filteredData = allEmployees?.filter(
    (employee) =>
      (employee?.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        employee?.last_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        employee?.email?.toLowerCase()?.includes(search?.toLowerCase())) &&
      employee?.department === (department || employee?.department) &&
      employee?.branch === (branch || employee?.branch)
  );

  const data = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const branchOptions = allBranches?.map((branch) => {
    return {
      label: branch?.name,
      value: branch?.name,
    };
  });

  const deptOptions = allDept?.map((dept) => {
    return {
      label: dept?.name,
      value: dept?.name,
    };
  });
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center mt-[10rem]">
          <Loader />
        </div>
      ) : (
        <>
          {allEmployees?.length > 0 ? (
            <div className="mt-10 bg-[#FFFFFF] p-2 px-4 lg:px-10 py-6">
              <div className="flex justify-between items-center my-5">
                <div className="flex flex-col lg:flex-row gap-2 w-full">
                  <InputGroup className="!w-full md:!w-[330px] h-[42px]">
                    <InputGroup.Addon>
                      {" "}
                      <SearchIcon />
                    </InputGroup.Addon>
                    <Input
                      placeholder="Search for employee name or job title"
                      value={search}
                      onChange={setSearch}
                    />
                  </InputGroup>
                  <div className="flex gap-2">
                    <SelectPicker
                      placeholder="Branch"
                      data={branchOptions}
                      value={branch}
                      onChange={setBranch}
                      size="lg"
                      className="w-full md:w-[200px]"
                    />
                    <SelectPicker
                      placeholder="Department"
                      data={deptOptions}
                      value={department}
                      onChange={setDepartment}
                      size="lg"
                      className="w-full md:w-[200px]"
                    />
                  </div>
                </div>
              </div>
              <Table
                height={400}
                data={data}
                className="border-[1px] border-[#F1F5F9] rounded-lg"
              >
                <Column width={100} align="left">
                  <HeaderCell>First Name</HeaderCell>
                  <Cell dataKey="first_name" />
                </Column>
                <Column width={100} align="left">
                  <HeaderCell>Last Name</HeaderCell>
                  <Cell dataKey="last_name" />
                </Column>
                <Column width={200}>
                  <HeaderCell>Email</HeaderCell>
                  <Cell dataKey="email" />
                </Column>
                <Column width={200}>
                  <HeaderCell>Designation</HeaderCell>
                  <Cell dataKey="designation" />
                </Column>
                <Column width={120}>
                  <HeaderCell>Monthly Salary</HeaderCell>
                  <MoneyCell dataKey="monthly_gross_salary" />
                </Column>
                <Column width={100}>
                  <HeaderCell>Department</HeaderCell>
                  <Cell dataKey="department" />
                </Column>
                <Column width={120}>
                  <HeaderCell>Branch</HeaderCell>
                  <Cell dataKey="branch" />
                </Column>
                {/* <Column width={120}>
                  <HeaderCell>Status</HeaderCell>
                  <StatusCell dataKey="status" />
                </Column> */}
                <Column width={70}>
                  <HeaderCell>
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 0V14H18V0H0ZM5.33 12H2V2H5.33V12ZM10.67 12H7.34V2H10.67V12ZM16 12H12.67V2H16V12Z"
                        fill="#475569"
                      />
                    </svg>
                  </HeaderCell>
                  <ActionCell dataKey="id" />
                </Column>
              </Table>
              <div style={{ padding: 20 }}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="xs"
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  total={allEmployees?.length}
                  limitOptions={[10, 30, 50]}
                  limit={limit}
                  activePage={page}
                  onChangePage={setPage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </div>
          ) : (
            <div className="payroll-content">
              <div className="payroll-content-section min-h-[600px] flex items-center justify-center">
                <div className="xl:w-[70%] 2xl:w-[45%] mx-auto text-center">
                  <div className="payroll-content-section-img-box mb-6">
                    <img src={NilImg} className="w-auto mx-auto" alt="" />
                  </div>

                  {payrollSetup?.setup_complete ? (
                    <div className="payroll-content-section-text-section">
                      <h5 className="text-[#1e293b] text-[22px] font-semibold leading-[127.273%] mb-4">
                        We advice you set up your add your employees before any
                        other action
                      </h5>
                      <p className="text-[#475569] text-sm font-normal tracking-[0.5px] w-[80%] mx-auto">
                        Click the "Add Employee" button below to get started.
                      </p>
                    </div>
                  ) : (
                    <div className="payroll-content-section-text-section">
                      <h5 className="text-[#1e293b] text-[22px] font-semibold leading-[127.273%] mb-4">
                        We advice you set up your payroll before any other
                        action
                      </h5>
                      <p className="text-[#475569] text-sm font-normal tracking-[0.5px] w-[80%] mx-auto">
                        You have not setup payroll for your organization yet.
                        When you do setup one, the details will appear here.
                      </p>
                    </div>
                  )}

                  {payrollSetup?.setup_complete && (
                    <button
                      type="submit"
                      className=" bg-[#2563EB] px-5 text-[#FFFFFF] mt-7 py-2 rounded font-[700]"
                      onClick={() => {
                        setType("add");
                        setOpen(true);
                      }}
                    >
                      Add Employee
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <AddemployeeModal
        modalOpen={open}
        onClose={() => {
          setData(null);
          setOpen(false);
        }}
        type={type}
        data={data_}
        fetch={fetch}
      />
      <PayrollBreakdown
        modalOpen={breakdownModal}
        onClose={() => setBreakdownModal(false)}
        size="sm"
        loading={payrollLoading}
      />
      <EmployeeDetailsModal
        open={view}
        onClose={() => setView(false)}
        data={data_}
      />

      <DeleteEmployee
        modalOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        data={data_}
      />
    </div>
  );
};

export default Employees;
