import React, { useEffect, useState } from "react";
import { IconButton, Modal } from "rsuite";
import cancel from "./../../../../../asset/CloseBtn.svg";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  CreateBenefitName,
  CreateBonusName,
  CreateDeductionName,
  GetBenefitNames,
  GetBonusNames,
  GetDeductionNames,
} from "../../../../../store/slices/PayrollSlice";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../../utils/https";

const StyledInput = styled.input`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  padding: 12px 16px;
  outline: none;
  color: #1e293b;
  background-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  z-index: 0;
`;
export default function AddNameValue({
  openaddNameValue,
  ToggleAddNameValue,
  type,
  branch,
  addType,
  nameData,
}) {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (addType === "edit") {
      setValue(nameData?.label);
    }
  }, [nameData]);

  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().put(
        `/${
          type === "Deduction"
            ? "deductions"
            : type === "Benefit"
            ? "benefits"
            : type === "Bonus"
            ? "bonuses"
            : null
        }/names/${payload?.id}/edit`,
        {
          name: payload?.label,
        }
      ),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      // if (type === "deduction") {
      dispatch(GetDeductionNames());
      // } else if (type === "benefit") {
      dispatch(GetBenefitNames());
      // } else if (type === "bonus") {
      dispatch(GetBonusNames());
      // }
      ToggleAddNameValue();
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    if (addType === "edit") {
      mutation.mutate({
        id: nameData?.value,
        label: value,
      });
    } else {
      const toastId = toast.loading(
        type === "Deduction"
          ? "Creating deduction name, please wait..."
          : type === "Benefit"
          ? "Creating benefit name, please wait..."
          : type === "Bonus"
          ? "Creating bonus name, please wait..."
          : null
      );

      const deductionData = {
        name: value,
        branch: branch?.id,
      };
      const benefitData = {
        name: value,
      };
      const bonusData = {
        name: value,
      };
      //
      const response = await dispatch(
        type === "Deduction"
          ? CreateDeductionName(deductionData)
          : type === "Benefit"
          ? CreateBenefitName(benefitData)
          : type === "Bonus"
          ? CreateBonusName(bonusData)
          : null
      );
      //
      if (type === "deduction") {
        dispatch(GetDeductionNames());
      } else if (type === "benefit") {
        dispatch(GetBenefitNames());
      } else if (type === "bonus") {
        dispatch(GetBonusNames());
      }
      if (response.payload.status < 400) {
        toast.update(toastId, {
          render:
            response?.payload?.message || response?.payload?.data?.message,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        setValue("");
        ToggleAddNameValue(!openaddNameValue);
      } else {
        toast.update(toastId, {
          render:
            response?.payload.message === "Request failed with status code 500"
              ? "Something Went Wrong"
              : response?.payload.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  }

  return (
    <Modal open={openaddNameValue} onClose={ToggleAddNameValue}>
      <div className="flex items-center justify-between gap-5 border-b-[1px] border-[#F1F5F9] pb-4 mb-6">
        <p className="text-txgray3 text-[22px] font-semibold">
          {type === "Deduction"
            ? addType === "edit"
              ? "Edit Deduction Name"
              : "New Deduction Name"
            : type === "Benefit"
            ? addType === "edit"
              ? "Edit Benefit Name"
              : "New Benefit Name"
            : type === "Bonus"
            ? addType === "edit"
              ? "Edit Bonus Name"
              : "New Bonus Name"
            : ""}
        </p>
        <IconButton
          onClick={() => {
            ToggleAddNameValue();
          }}
        >
          <img src={cancel} alt="close" />
        </IconButton>
      </div>
      <form action="" onSubmit={handleSubmit} className="flex flex-col gap-11">
        <StyledInput
          type="text"
          value={value}
          onChange={(_) => setValue(_.target.value)}
          placeholder={`Name of ${
            type === "Deduction"
              ? "Deduction"
              : type === "Benefit"
              ? "Benefit"
              : type === "Bonus"
              ? "Bonus"
              : null
          } `}
        />
        <button
          type="submit"
          className="text-sm w-full bg-[#2563EB] font-bold text-[#FFFFFF] h-12 px-4 rounded-md"
        >
          {addType === "edit"
            ? "Save changes"
            : `Add ${
                type === "Deduction"
                  ? "Deduction"
                  : type === "Benefit"
                  ? "Benefit"
                  : type === "Bonus"
                  ? "Bonus"
                  : null
              } Name`}
        </button>
      </form>
    </Modal>
  );
}
