import React from "react";

export default function HRIcon({ active = false }) {
  return active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2915 5.62484C4.2915 3.85572 5.73072 2.4165 7.49984 2.4165C9.26847 2.4165 10.7074 3.85493 10.7082 5.62339C10.7007 7.35917 9.35032 8.75729 7.62856 8.8248C7.5418 8.81631 7.45622 8.81721 7.37841 8.82334C5.61719 8.7391 4.2915 7.34175 4.2915 5.62484Z"
        fill="#2563EB"
        stroke="#2563EB"
        stroke-width="1.5"
      />
      <path
        d="M3.68471 16.5359L3.68266 16.5345C2.78423 15.9356 2.3833 15.1962 2.3833 14.4836C2.3833 13.7703 2.78483 13.023 3.69054 12.4162C4.71373 11.7395 6.09437 11.3794 7.51038 11.3794C8.92736 11.3794 10.3034 11.74 11.3173 12.4159C12.2102 13.0112 12.6097 13.7501 12.6166 14.4699C12.6155 15.1906 12.2137 15.9295 11.3147 16.5363C10.2967 17.2195 8.91746 17.5836 7.49997 17.5836C6.08222 17.5836 4.70271 17.2194 3.68471 16.5359Z"
        fill="#2563EB"
        stroke="#2563EB"
        stroke-width="1.5"
      />
      <path
        d="M13.7621 8.61179C13.4626 8.61948 13.1803 8.57364 12.9234 8.48355C13.5309 7.63291 13.8461 6.56771 13.7559 5.44011L13.7559 5.44011L13.7555 5.43535C13.7161 4.97893 13.6098 4.54557 13.4501 4.14267C13.4636 4.14124 13.4771 4.13992 13.4906 4.13871C14.7111 4.03329 15.8042 4.94417 15.9109 6.17952C16.0096 7.38433 15.1666 8.42894 14.0027 8.59146C13.9778 8.59084 13.8807 8.58905 13.7621 8.61179Z"
        fill="#2563EB"
        stroke="#2563EB"
        stroke-width="1.5"
      />
      <path
        d="M17.5781 13.7552L17.5781 13.7552L17.5774 13.7635C17.5365 14.2601 17.2195 14.7644 16.5144 15.1508L16.5134 15.1513C16.2078 15.3194 15.8602 15.4517 15.4871 15.5453C15.606 15.2418 15.6827 14.9233 15.7133 14.5946L15.7134 14.5946L15.7141 14.5855C15.8187 13.288 15.2111 12.1052 14.1453 11.2068C15.1391 11.3201 16.0895 11.6675 16.7705 12.2171L16.7715 12.2179C17.3993 12.7229 17.6235 13.2714 17.5781 13.7552Z"
        fill="#2563EB"
        stroke="#2563EB"
        stroke-width="1.5"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63314 9.05817C7.5498 9.04984 7.4498 9.04984 7.35814 9.05817C5.3748 8.9915 3.7998 7.3665 3.7998 5.3665C3.7998 3.32484 5.4498 1.6665 7.4998 1.6665C9.54147 1.6665 11.1998 3.32484 11.1998 5.3665C11.1915 7.3665 9.61647 8.9915 7.63314 9.05817Z"
        stroke="#64748B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6752 3.3335C15.2919 3.3335 16.5919 4.64183 16.5919 6.25016C16.5919 7.82516 15.3419 9.1085 13.7836 9.16683C13.7169 9.1585 13.6419 9.1585 13.5669 9.16683"
        stroke="#64748B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.4666 12.1335C1.44993 13.4835 1.44993 15.6835 3.4666 17.0252C5.75827 18.5585 9.5166 18.5585 11.8083 17.0252C13.8249 15.6752 13.8249 13.4752 11.8083 12.1335C9.52494 10.6085 5.7666 10.6085 3.4666 12.1335Z"
        stroke="#64748B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2832 16.6665C15.8832 16.5415 16.4499 16.2998 16.9165 15.9415C18.2165 14.9665 18.2165 13.3582 16.9165 12.3832C16.4582 12.0332 15.8999 11.7998 15.3082 11.6665"
        stroke="#64748B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
