import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledInputWrapper } from "../../../../component/atoms/StyledComponents";
import ButtonFilled from "../../../../component/atoms/ButtonFilled";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import Loader from "../../../../component/atoms/Loader";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../../store/slices/userSlice";

const UserProfile = () => {
  const dispatch = useDispatch();

  //query

  const { data: userProfile, isPending } = useQuery({
    queryKey: ["detail"],
    queryFn: async () => {
      const response = await axiosInstance().get(
        "/users/team-workspaces/details"
      );
      return response?.data?.data;
    },
  });

  //mutation
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().patch("/users/team-workspaces/edit", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      logo: "",
    },
    onSubmit: (values) => {
      mutation.mutate({
        first_name: values.firstName,
        last_name: values.lastName,
        logo: values.logo,
      });
    },
    validationSchema: yup.object({
      firstName: yup.string().required("This field is required"),
      lastName: yup.string().required("This field is required"),
      email: yup.string().required("This field is required"),
    }),
  });

  useEffect(() => {
    if (userProfile) {
      setValues({
        firstName: userProfile?.first_name || "",
        lastName: userProfile?.last_name || "",
        email: userProfile?.email || "",
        logo: userProfile?.profile_picture || "",
      });
    }
  }, [userProfile]);
  //  Async functions
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file.size > 2097152) {
      toast.error("File size should not exceed 2MB");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadFile(formData));
      if (response?.payload?.status < 400) {
        const responseData = response?.payload.data?.data;
        setFieldValue("logo", responseData?.url);
        console.log(values);
      } else {
        toast.error("An error occured, please try again");
      }
    }
  };
  return (
    <>
      {isPending ? (
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="bg-white px-4 py-6 max-w-[733px] w-full">
          <div>
            <p className="font-bold text-[18px] leading-[27px] text-[#2563EB] ">
              User Profile
            </p>
            <p className="text-[12px] text-txgray3 leading-[18px]">
              Manage details of your user profile
            </p>
          </div>
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="flex gap-0 lg:gap-6">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex gap-0 lg:gap-6 ">
                  <div className="flex flex-col gap-4 w-full">
                    <StyledInputWrapper>
                      <label
                        htmlFor="firstName"
                        className={
                          errors.firstName && touched.firstName && "label_error"
                        }
                      >
                        First name
                      </label>
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.firstName && touched.firstName && "error"
                        }
                      />
                      {errors.firstName && touched.firstName && (
                        <p className="field_error">{errors.firstName}</p>
                      )}
                    </StyledInputWrapper>

                    <StyledInputWrapper>
                      <label
                        htmlFor="lastName"
                        className={
                          errors.lastName && touched.lastName && "label_error"
                        }
                      >
                        Last name
                      </label>
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.lastName && touched.lastName && "error"
                        }
                      />
                      {errors.lastName && touched.lastName && (
                        <p className="field_error">{errors.lastName}</p>
                      )}
                    </StyledInputWrapper>
                  </div>
                  <div className="flex flex-col items-center px-[28px] max-w-[105px] w-full box-content gap-2">
                    <p>Profile picture</p>
                    {values?.logo ? (
                      <div
                        style={{
                          backgroundImage: `url(${values?.logo})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          borderRadius: "50%",
                        }}
                        className="w-[72px] h-[72px]"
                        alt=""
                      />
                    ) : (
                      <div className="items-center justify-center flex bg-gray-500-100 w-8 h-8 rounded-full text-[#FFFFFF] font-semibold "></div>
                    )}
                    <label
                      className={`bg-[#F1F5F9] font-bold text-[#475569] py-1 px-2 rounded-md flex gap-2 items-center text-[13px] cursor-pointer`}
                      htmlFor="logo"
                    >
                      Upload Photo
                    </label>
                    <input
                      type="file"
                      name="logo"
                      id="logo"
                      className="file-upload-input"
                      accept=".jpeg,.png"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <p
                      className="font-medium text-[#3B82F6] text-[13px] cursor-pointer"
                      onClick={() => setFieldValue("logo", "")}
                    >
                      Remove Photo
                    </p>
                  </div>
                </div>
                <StyledInputWrapper>
                  <label
                    htmlFor="address"
                    className={
                      errors.address && touched.address && "label_error"
                    }
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.email && touched.email && "error"}
                    disabled
                    style={{ color: "#64748B" }}
                  />
                  {errors.email && touched.email && (
                    <p className="field_error">{errors.email}</p>
                  )}
                </StyledInputWrapper>
              </div>
            </div>

            <div className="mt-6 flex justify-between items-center">
              <ButtonFilled type="submit" loading={mutation.isPending}>
                Save
              </ButtonFilled>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default UserProfile;
