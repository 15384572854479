import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function ButtonFilled({ children, onClick, style, loading }) {
  return (
    <button
      className={`text-sm bg-[#2563EB] font-bold text-[#FFFFFF] py-[10px] px-4 rounded-md flex gap-2 items-center`}
      onClick={onClick}
      style={style}
    >
      {loading ? (
        <CircularProgress
          sx={{
            color: "#ffffffd1",
            width: "20px !important",
            height: "20px !important",
          }}
        />
      ) : (
        children
      )}
    </button>
  );
}
