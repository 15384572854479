/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../store/slices/userSlice";
import HRIcon from "../../asset/IconJs/HRIcon";
import ArrowIcon from "../../asset/IconJs/arrowIcon";
import close from "../../asset/svgs/close-circle.svg";
import settings from "./../../asset/svgs/setting.svg";
import settingsActive from "./../../asset/svgs/settingActive.svg";
import { Collapse } from "@mui/material";
import logoutIcon from "./../../asset/svgs/logout.svg";
import logo from "../../asset/svgs/fuselogo.svg";
import DashboardIcon from "../../asset/IconJs/DashboardIcon";

export default function MobileSideBar({
  openSideNav,
  handleSideNavToggle,
  ToggleConfirmLogout,
}) {
  const [open] = useState(true);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const { activeDashboardTab } = useSelector((state) => state?.users);

  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      dispatch(setActiveTab("Dashboard"));
    }
  }, []);

  const navItems = [
    {
      name: "Dashboard",
      icon: DashboardIcon,
      haSubMenu: false,
      path: "/dashboard",
    },

    {
      name: "Human Resource",
      icon: HRIcon,
      haSubMenu: true,
      subMenu: [
        {
          title: "Payroll",
          path: "/dashboard/payroll",
        },
      ],
    },
  ];

  const NavItem = ({
    icon: Icon,
    title,
    subMenu,
    haSubMenu,
    handleActiveClick,
    path,
  }) =>
    haSubMenu ? (
      <div className="nav_list_item">
        <div
          className="flex justify-between items-center px-2 py-[10px] cursor-pointer duration-300"
          onClick={() => {
            handleActiveClick();
          }}
        >
          <div className="gap-3 flex">
            <Icon active={activeDashboardTab === title} />
            <span
              className={`${
                activeDashboardTab === title ? "text-[#2563EB] " : ""
              } text-sm font-medium`}
            >
              {title}
            </span>
          </div>
          <ArrowIcon active={activeDashboardTab === title} />
        </div>
        <Collapse
          in={open && activeDashboardTab === title}
          timeout="auto"
          unmountOnExit
        >
          {subMenu?.map((item, index) => (
            <div
              key={index}
              className={`${
                currentPath === item.path ? "bg-[#EFF6FF] text-[#475569]" : null
              }  ps-6 p-3 flex items-center cursor-pointer gap-2 rounded duration-300`}
              onClick={() => {
                navigateTo(item.path);
                handleSideNavToggle();
              }}
            >
              <span
                className={`${
                  currentPath === item.path ? "bg-[#2563EB]" : "bg-[#CBD5E1]"
                } w-[10px] h-[10px] rounded-full  block`}
              ></span>
              <p> {item.title} </p>
            </div>
          ))}
        </Collapse>
      </div>
    ) : (
      <div
        className={`${
          activeDashboardTab === title ? "text-[#2563EB]" : ""
        } p-2 gap-3 flex cursor-pointer rounded nav_list_item no-underline duration-300`}
        onClick={() => {
          handleActiveClick();
          navigateTo(path);
          handleSideNavToggle();
        }}
      >
        <Icon active={activeDashboardTab === title} />
        <span className="text-sm font-medium">{title}</span>
      </div>
    );

  return (
    <Drawer
      anchor="right"
      open={openSideNav}
      onClose={handleSideNavToggle}
      sx={{
        "& .MuiDrawer-paper": {
          width: { sm: "50%", xs: "70%" },
          padding: "0px 20px",
        },
        background: "rgba(229,229,229,0.4)",
      }}
      className="flex lg:hidden"
    >
      <div className="flex-1">
        <div className="p-2 mb-6 flex justify-between">
          <img src={logo} alt="logo" />
          <IconButton onClick={handleSideNavToggle}>
            <img src={close} alt="close" />
          </IconButton>
        </div>
        <div className="flex flex-col gap-2 nav_list_item_wrapper">
          {navItems.map((item, index) => {
            return (
              <NavItem
                icon={item.icon}
                title={item.name}
                subMenu={item.subMenu}
                haSubMenu={item.haSubMenu}
                handleActiveClick={() => dispatch(setActiveTab(item.name))}
                path={item.path}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="font-medium">
          {/* <div className="flex gap-4 items-center p-2 cursor-pointer">
            <img src={settings} alt="settings" />
            <span className="text-[#475569]">Settings</span>
          </div>{" "} */}
          <div
            className="flex gap-4 items-center p-2 cursor-pointer"
            onClick={() => {
              dispatch(setActiveTab("settings"));
              navigateTo("/dashboard/settings");
              handleSideNavToggle();
            }}
          >
            <img
              src={
                activeDashboardTab === "settings" ? settingsActive : settings
              }
              alt="settings"
            />
            <span
              className={`${
                activeDashboardTab === "settings"
                  ? "text-[#2563EB]"
                  : "text-[#475569] "
              }`}
            >
              Settings
            </span>
          </div>{" "}
          <div
            className="flex gap-4 items-center p-2 cursor-pointer"
            onClick={() => {
              ToggleConfirmLogout();
              handleSideNavToggle();
            }}
          >
            <img src={logoutIcon} alt="logout" />
            <span className="text-[#F43F5E]">Logout</span>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
