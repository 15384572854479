import React from "react";
import { Modal } from "rsuite";
import close from "./../../../../../asset/svgs/close-circle.svg";
import lamp from "./../../../../../asset/svgs/lamp-on.svg";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordInput from "../../../../dashboard-component/inputs/PasswordInput";
import BlueBotton from "../../../../../component/atoms/BlueBotton";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axiosInstance from "./../../../../../utils/https";
import { useSelector } from "react-redux";

export default function ConfirmEditPayroll({
  openEditPayrollSetupModal,
  onClose,
  setopenPayrollSetupModal,
}) {
  const { payrollSetup } = useSelector((state) => state?.payroll);

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/auth/verify-password-for-change", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      setopenPayrollSetupModal(true);
      deactivatePayroll.mutate({ id: payrollSetup?.id });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
  const deactivatePayroll = useMutation({
    mutationFn: (payload) =>
      axiosInstance().patch(`/payroll/${payload?.id}/deactivate`),
    onSuccess: (data) => {
      toast.success("Payroll setup deactivated, continue editing");
      onClose();
      setopenPayrollSetupModal(true);
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const schema = yup.object().shape({
    password: yup.string().required("please enter password"),
  });
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: schema,
      onSubmit: (values, { resetForm }) => {
        mutation.mutate({
          password: values.password,
        });
        resetForm();
      },
    });

  return (
    <Modal
      sx={{ padding: "16px 24px" }}
      open={openEditPayrollSetupModal}
      onClose={onClose}
    >
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="text-txgray2 text-[22px] font-semibold">
          Edit payroll setup
        </p>
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
      </div>

      <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded mb-4">
        <img src={lamp} alt="close" />
        <p>
          Editing payroll setup will permanently clear & archive the existing
          payroll setup.
          <br /> You can view all archived payroll setup and revert to any one
          of your choice in the{" "}
          <a href="/dashboard/settings" className="text-[#2563EB]">
            payroll archive
          </a>{" "}
          tab in your settings
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <p className="mb-3">To confirm, please type in your password</p>
        <PasswordInput
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          values={values.password}
        />
        <div className="flex gap-3 justifAy-end mt-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
          <BlueBotton label="Continue edit" loading={mutation?.isPending} />
        </div>
      </form>
    </Modal>
  );
}
