import React from "react";
import AuthLayout from "./AuthLayout";

const LoginLayout = ({ children }) => {
  return (
    <AuthLayout>
      <div className="">
        <div className="h-screen second-auth-container sm:py-[4rem] px-0 flex sm:justify-center sm:items-center">
          {/* <div className=" p-[31px] w-1/3 min-h-[400px] flex flex-col items-center bg-white pt-[18px] relative rounded-[4px]">
            <div className="w-[334px]">
              <img src="/signup-icons/logo.svg" alt="logo" className="" />
              {children}
            </div>
          </div> */}
          <div className="section-container w-full">
            <div className="">
              <div className="md:w-9/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 mx-auto ">
                <div className="second-auth-container-box md:bg-[#fff] sm:px-4 py-[50px] md:p-[30px]">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginLayout;
