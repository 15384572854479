import React from "react";
import StepActive from "../../asset/onboarding/step-active.svg";
import StepInactive from "../../asset/onboarding/step-inactive.svg";

const OnboardingStepper = ({ step, titles }) => {
  return (
    <>
      {titles.map((_, i) => (
        <div
          key={i}
          className={`onboarding-step-box ${
            step === i + 1  ? "active" : step > i  ? "completed" : ""
          }`}
        >
          <div className=" flex gap-3 items-center ">
            <div className="onboarding-step-box-number">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 0.500137C2.9625 0.500137 0.5 2.96264 0.5 6.00014C0.5 9.03764 2.9625 11.5001 6 11.5001C9.0375 11.5001 11.5 9.03764 11.5 6.00014C11.5 2.96264 9.0375 0.500137 6 0.500137ZM8.384 5.07014C8.4279 5.01996 8.46132 4.96151 8.48229 4.89822C8.50327 4.83494 8.51138 4.7681 8.50614 4.70163C8.5009 4.63517 8.48242 4.57042 8.45179 4.5112C8.42116 4.45198 8.37899 4.39949 8.32778 4.35681C8.27656 4.31413 8.21732 4.28212 8.15355 4.26267C8.08978 4.24322 8.02276 4.23672 7.95644 4.24355C7.89012 4.25038 7.82584 4.27041 7.76737 4.30245C7.70891 4.3345 7.65744 4.37791 7.616 4.43014L5.466 7.00964L4.3535 5.89664C4.2592 5.80556 4.1329 5.75516 4.0018 5.7563C3.8707 5.75744 3.7453 5.81002 3.65259 5.90273C3.55989 5.99543 3.5073 6.12084 3.50616 6.25194C3.50502 6.38304 3.55542 6.50934 3.6465 6.60364L5.1465 8.10364C5.19563 8.15274 5.25445 8.19106 5.31921 8.21617C5.38397 8.24128 5.45325 8.25261 5.52263 8.24946C5.59202 8.24631 5.65999 8.22874 5.72221 8.19786C5.78442 8.16699 5.83953 8.12349 5.884 8.07014L8.384 5.07014Z"
                    fill="#3B82F6"
                  />
                </svg>
              </span>
              <span className="number">{i + 1}</span>
            </div>
            <div className="onboarding-step-box-title ">
              <h6 className="text-base font-semibold">{_}</h6>
            </div>
          </div>
          <div>
            <div className="step-line my-2 w-[30px] flex items-center justify-center">
              {step === i + 1  || step > i  ? (
                <img src={StepActive} alt="" />
              ) : (
                <img src={StepInactive} alt="" />
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OnboardingStepper;
