import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledInputWrapper } from "../../../../component/atoms/StyledComponents";
import ButtonFilled from "../../../../component/atoms/ButtonFilled";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import Loader from "../../../../component/atoms/Loader";
import { NameToInitials } from "../../../../utils";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../../store/slices/userSlice";
import ChangePassword from "../settings-modal/ChangePassword";

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const [openChangePassword, setChangePassword] = useState(false);

  //query
  const { data: tenantProfile, isPending } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/tenants/profile`);
      return response?.data?.data;
    },
  });

  //mutation
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().patch("/tenants/profile/edit", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      businessName: "",
      address: "",
      phone: "",
      email: "",
      description: "",
      logo: "",
    },
    onSubmit: (values) => {
      mutation.mutate({
        name: values.businessName,
        logo: values.logo,
        address_line_1: values.address,
        business_description: values.description,
      });
    },
    validationSchema: yup.object({
      businessName: yup.string().required("This field is required"),
      address: yup.string().required("This field is required"),
      phone: yup.string().required("This field is required"),
      email: yup.string().required("This field is required"),
      description: yup.string().required("This field is required"),
    }),
  });

  useEffect(() => {
    if (tenantProfile) {
      setValues({
        businessName: tenantProfile?.name || "",
        address: tenantProfile?.address_line_1 || "",
        phone: tenantProfile?.phone_number || "",
        email: tenantProfile?.email || "",
        description: tenantProfile?.business_description || "",
        logo: tenantProfile?.logo || "",
      });
    }
  }, [tenantProfile]);
  //  Async functions
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file.size > 2097152) {
      toast.error("File size should not exceed 2MB");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadFile(formData));
      if (response?.payload?.status < 400) {
        const responseData = response?.payload.data?.data;
        setFieldValue("logo", responseData?.url);
        console.log(values);
      } else {
        toast.error("An error occured, please try again");
      }
    }
  };
  return (
    <>
      {isPending ? (
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="bg-white px-4 py-6 max-w-[733px] w-full">
          <div>
            <p className="font-bold text-[18px] leading-[27px] text-[#2563EB] ">
              Business Profile
            </p>
            <p className="text-[12px] text-txgray3 leading-[18px]">
              Manage details of your business profile
            </p>
          </div>
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="flex gap-0 lg:gap-6">
              <div className="flex flex-col gap-4 w-full">
                <StyledInputWrapper>
                  <label
                    htmlFor="businessName"
                    className={
                      errors.businessName &&
                      touched.businessName &&
                      "label_error"
                    }
                  >
                    Business name
                  </label>
                  <input
                    id="businessName"
                    name="businessName"
                    type="text"
                    value={values?.businessName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.businessName && touched.businessName && "error"
                    }
                  />
                  {errors.businessName && touched.businessName && (
                    <p className="field_error">{errors.businessName}</p>
                  )}
                </StyledInputWrapper>

                <StyledInputWrapper>
                  <label
                    htmlFor="address"
                    className={
                      errors.address && touched.address && "label_error"
                    }
                  >
                    Address
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    value={values?.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.address && touched.address && "error"}
                  />
                  {errors.address && touched.address && (
                    <p className="field_error">{errors.address}</p>
                  )}
                </StyledInputWrapper>
              </div>
              <div className="flex flex-col items-center px-[28px] max-w-[105px] w-full box-content gap-2">
                <p>Company logo</p>
                {values?.logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${values?.logo})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "50%",
                    }}
                    className="w-[72px] h-[72px]"
                    alt=""
                  />
                ) : (
                  <div className="items-center justify-center flex bg-gray-500-100 w-8 h-8 rounded-full text-[#FFFFFF] font-semibold ">
                    {NameToInitials(tenantProfile?.name)}
                  </div>
                )}
                <label
                  className={`bg-[#F1F5F9] font-bold text-[#475569] py-1 px-2 rounded-md flex gap-2 items-center text-[13px] cursor-pointer`}
                  htmlFor="logo"
                >
                  Upload Photo
                </label>
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  className="file-upload-input"
                  accept=".jpeg,.png"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <p
                  className="font-medium text-[#3B82F6] text-[13px] cursor-pointer"
                  onClick={() => setFieldValue("logo", "")}
                >
                  Remove Photo
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 my-4">
              <StyledInputWrapper>
                <label
                  htmlFor="phone"
                  className={errors.phone && touched.phone && "label_error"}
                >
                  Phone number
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={values?.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.phone && touched.phone && "error"}
                  disabled
                  style={{ color: "#64748B" }}
                />
                {errors.phone && touched.phone && (
                  <p className="field_error">{errors.phone}</p>
                )}
              </StyledInputWrapper>
              <StyledInputWrapper>
                <label
                  htmlFor="email"
                  className={errors.email && touched.email && "label_error"}
                >
                  Mailing address
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && "error"}
                  disabled
                  style={{ color: "#64748B" }}
                />
                {errors.email && touched.email && (
                  <p className="field_error">{errors.email}</p>
                )}
              </StyledInputWrapper>
            </div>
            <StyledInputWrapper>
              <label
                htmlFor="description"
                className={
                  errors.description && touched.description && "label_error"
                }
              >
                Company Description
              </label>
              <textarea
                id="description"
                name="description"
                type="text"
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.description && touched.description && "error"}
              />
              {errors.description && touched.description && (
                <p className="field_error">{errors.description}</p>
              )}
            </StyledInputWrapper>
            <div className="mt-6 flex justify-between items-center">
              <ButtonFilled type="submit" loading={mutation.isPending}>
                Save
              </ButtonFilled>
              <p
                className="font-semibold text-[#3B82F6] text-[13px] underline cursor-pointer"
                onClick={() => setChangePassword(true)}
              >
                Change password
              </p>
            </div>
          </form>
        </div>
      )}
      <ChangePassword
        open={openChangePassword}
        onClose={() => setChangePassword(false)}
      />
    </>
  );
};

export default CompanyProfile;
