import React, { useState } from "react";
import { Pagination } from "rsuite";
import RevertPayrollSetup from "../settings-modal/RevertPayrollSetup";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import Loader from "../../../../component/atoms/Loader";
import { formatDateWithDay } from "../../../../utils";

export default function PayrollArchive() {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [openRevertPayroll, setOpenRevertPayroll] = useState(false);
  const [archiveData, setArchiveData] = useState();

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["getPayrollArchive"],
    queryFn: async () => {
      const response = await axiosInstance().get("/payroll/archives");
      return response?.data?.data?.results;
    },
  });
  return (
    <>
      {isLoading ? (
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="w-[936px]">
          <div className="bg-[#FFFFFF] py-6 px-4">
            {data?.map((item, index) => {
              return (
                <div className="py-3 px-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
                  <div>
                    <p className="font-bold text-[16px] text-[#1E40AF]">
                      {item.name}
                    </p>
                    <p className="font-medium text-[#94A3B8] text-sm m-0">
                      Archived on {formatDateWithDay(item.date_created)}
                    </p>
                  </div>
                  <button
                    className="border-[#E2E8F0] border-[1px] bg-[#F8FAFC] py-1 px-2 rounded"
                    onClick={() => {
                      setOpenRevertPayroll(true);
                      setArchiveData(item);
                    }}
                  >
                    Revert
                  </button>
                </div>
              );
            })}
          </div>

          <div className="mt-6 px-5">
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              // total={deductions?.length}
              total={1}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
          <RevertPayrollSetup
            onClose={() => setOpenRevertPayroll(false)}
            open={openRevertPayroll}
            archiveData={archiveData}
          />
        </div>
      )}
    </>
  );
}
