import React from "react";
import { Modal } from "rsuite";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  width: 152px !important;
`;
export default function GenerateWallet({ open, onClose }) {
  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <div className="flex flex-col gap-4 items-center">
          <CircularProgress
            sx={{
              color: "#2563EB",
              width: "30px !important",
              height: "30px !important",
            }}
          />
          <p className="text-[#2563EB] font-bold text-sm">Creating wallet</p>
          <p className="text-sm font-medium m-0">Please wait..</p>
        </div>
      </CustomModal>
    </>
  );
}
