import React, { useEffect, useState } from "react";
import NilBenefit from "../../../../asset/svgs/NilBenefit.svg";
import BlueBotton from "../../../../component/atoms/BlueBotton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetBenefitNames,
  GetBenefits,
} from "../../../../store/slices/PayrollSlice";
import Loader from "../../../../component/atoms/Loader";
import AddBenefit from "./benefitspages/AddBenefit";
import EmployeesWithBenefit from "./benefitspages/EmployeesWithBenefit";

export default function Benefits() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("noBenefit");
  const [mainPage, setMainPage] = useState("");
  const { benefits } = useSelector((state) => state?.payroll);
  const dispatch = useDispatch();

  //functions
  const getBenefits = async () => {
    setLoading(true);
    const res = await dispatch(GetBenefits());
    setLoading(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    if (benefits?.length <= 0) {
      getBenefits();
    }
    else if (!benefits) {
      getBenefits();
    }
    dispatch(GetBenefitNames());
  }, []);

  return loading ? (
    <div className="flex justify-center items-center mt-[10rem]">
      <Loader />
    </div>
  ) : benefits?.length <= 0 || mainPage === "addBenefit" ? (
    page === "noBenefit" ? (
      <div className="payroll-content">
        <div className="payroll-content-section min-h-[600px] flex items-center justify-center">
          <div className="xl:w-[70%] 2xl:w-[45%] mx-auto text-center">
            <div className="payroll-content-section-img-box mb-6">
              <img src={NilBenefit} className="w-auto mx-auto" alt="" />
            </div>

            <div className="payroll-content-section-text-section flex flex-col gap-4 items-center">
              <h5 className="text-[#1e293b] text-[22px] font-semibold leading-[127.273%] mb-4">
                No Company Benefits found
              </h5>
              <p className="text-[#475569] text-[16px] mb-6 max-w-[506px]">
                You have not added any Benefits for any employee yet. When
                you add any Benefits for anyone, the details will appear here.
              </p>
              <BlueBotton
                label={"Add New Benefits"}
                onClick={() => setPage("addBenefit")}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <AddBenefit setMainPage={setMainPage} />
    )
  ) : (
    <>
      <div className="flex justify-end">
        <BlueBotton
          label="Add New Benefit"
          onClick={() => {
            setMainPage("addBenefit");
            setPage("addBenefit");
          }}
        />
      </div>
      <EmployeesWithBenefit />
    </>
  );
}
