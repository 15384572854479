import React from "react";
import { Loader } from "rsuite";
// import { Loader } from "rsuite";

const UploadingLoader = ({ uploadStatus }) => {
  return (
    <div className="main-loader flex items-center justify-center">
      <div>
        {uploadStatus === "pending" ? (
          <Loader
            size="lg"
            content="Uploading logo..."
            inverse
            center
            vertical
          />
        ) : uploadStatus === "success" ? (
          <h5 className="text-white">Logo uploaded successfully...</h5>
        ) : (
          <h5 className="text-white">Error uploading logo...</h5>
        )}
      </div>
    </div>
  );
};

export default UploadingLoader;
