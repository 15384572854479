/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetUserDetails, getAllUtils } from "../../store/slices/userSlice";
import {
  NameToInitials,
  getUserDetails,
  getUserToken,
  setToken,
  setUser,
} from "../../utils/index";
import { useIdleTimer } from "react-idle-timer";
import dropdown from "./../../asset/svgs/dropdown.svg";
import logoutIcon from "./../../asset/svgs/logout.svg";
import { Menu } from "@mui/material";
import ConfirmLogoutModal from "../ConfirmLogoutModal";
import settings from "./../../asset/svgs/setting.svg";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MobileSideBar from "./MobileSideBar";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/https";

export default function Header({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const { user } = useSelector((state) => state?.users);
  const accessToken = getUserToken();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openSideNav, setSideNav] = useState(false);
  const [openConfirmLogout, setOpenConfirmLogout] = useState(false);
  const { REACT_APP_ADMIN_CLIENT_ID: client_id, REACT_APP_SCOPE: scopes } =
    process.env;

  const getUserDetail = async () => {
    const res = await dispatch(GetUserDetails(user?.id));
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload?.data?.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const handleSideNavToggle = () => {
    setSideNav(!openSideNav);
  };

  async function fetchAllUtils() {
    await dispatch(getAllUtils());
  }

  const refreshTokenMutation = useMutation({
    mutationFn: () =>
      axiosInstance().post(
        "/o/oauth/refresh",
        {
          grant_type: "refresh_token",
          client_id: client_id,
          client_secret: "c9g+@vk$5bb3ab804f49f44b289be70ae51f11659a",
          code: getUserDetails().refresh_token,
          scope: scopes,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ),
    onSuccess: (data) => {
      const { access_token, expires_in, refresh_token, scope } =
        data?.data?.data;
      setToken(access_token);
      setUser({ refresh_token, scope, expires_in });
    },
  });

  // UseEffect
  useEffect(() => {
    getUserDetail();
    fetchAllUtils();
    setTimeout(() => {
      setInterval(() => {
        refreshTokenMutation.mutate();
      }, getUserDetails().expires_in * 1000 - 10000);
    }, getUserDetails().expires_in * 1000 - 60000);
  }, []);

  const ToggleConfirmLogout = () => {
    setOpenConfirmLogout(!openConfirmLogout);
  };

  useEffect(() => {
    if (!accessToken) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_detail");
      navigate("/auth/login");
    }
  }, [accessToken]);

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 100_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (state === "Idle" && remaining === 0) {
      // handleLogout();
    }
  }, [state, remaining]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="dashboard-main-content-top-bar flex gap-3 items-center h-[52px] border-b-[1px] border-[#E2E8F0] px-4 lg:px-9"
      style={{ borderBottom: "1px solid #E2E8F0" }}
    >
      <div className="flex gap-2 justify-between items-center flex-grow">
        <div className="dashboard-main-content-top-bar-title-box ">
          <h2 className="text-xl lg:text-[22px]">{title || "Dashboard"}</h2>
        </div>

        <div className="flex items-center gap-3">
          <div
            className="hidden lg:flex items-center cursor-pointer gap-1"
            onClick={handleClick}
          >
            {user?.logo ? (
              <div
                style={{
                  backgroundImage: `url(${user?.logo})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "50%",
                }}
                className="w-[32px] h-[32px]"
                alt=""
              />
            ) : (
              <div className="items-center justify-center flex bg-gray-500-100 w-8 h-8 rounded-full text-[#FFFFFF] font-semibold ">
                {NameToInitials(user.name)}
              </div>
            )}
            <p className="text-[#64748B] font-bold text-sm">
              {" "}
              {user.name ? user.name : ""}{" "}
            </p>
            <img src={dropdown} alt="dropdown" />
          </div>
          <IconButton
            onClick={handleSideNavToggle}
            className="!flex lg:!hidden"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div className="font-medium">
              <div
                className="flex gap-1 items-center px-6 py-2 cursor-pointer"
                onClick={() => navigate("/dashboard/settings")}
              >
                <img src={settings} alt="settings" />
                <span className="text-[#475569]">Settings</span>
              </div>{" "}
              <div
                className="flex gap-1 items-center px-6 py-2 cursor-pointer"
                onClick={ToggleConfirmLogout}
              >
                <img src={logoutIcon} alt="logout" />
                <span className="text-[#F43F5E]">Logout</span>
              </div>
            </div>
          </Menu>
        </div>
      </div>
      <ConfirmLogoutModal
        ToggleConfirmLogout={ToggleConfirmLogout}
        openConfirmLogout={openConfirmLogout}
      />
      <MobileSideBar
        openSideNav={openSideNav}
        handleSideNavToggle={handleSideNavToggle}
        ToggleConfirmLogout={ToggleConfirmLogout}
      />
    </div>
  );
}
