import React from "react";
import { useSelector } from "react-redux";
import MainModal from "../auth-component/MainModal";

const RoleDetailsModal = ({ modalOpen, onClose, size, role_id }) => {
  const { permissions, roles } = useSelector((state) => state?.users);
  const one_role = roles?.find((role) => role.id === role_id);
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle={`paystack.fuseerp.com Role Details`}
      >
        <div className="mt-3 xl:w-[90%] mx-auto">
          <div className="upload-from-csv">
            <div className="mt-5">
              <div className={`input-box`}>
                <input
                  value={one_role?.name}
                  disabled
                />
              </div>
            </div>

            <div>
              <div className="flex justify-space-between items-center mt-5">
                {permissions?.map((item, index) => {
                  let arrayIds = [];
                  item?.actions?.forEach((type) => {
                    arrayIds.push(type?.value);
                  });
                  const is_checked = arrayIds.every((v) =>
                    one_role?.permissions?.includes(v)
                  );
                  return (
                    <div key={index}>
                      <div className="flex gap-2 items-start sm:px-4 mb-3">
                        <input
                          checked={is_checked || false}
                          className="mt-1"
                          disabled
                          type="checkbox"
                        />
                        <p className="">{item?.name}</p>
                      </div>
                      {item?.actions?.map((field, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`flex gap-2 items-start sm:px-4 ml-5`}
                          >
                            <input
                              checked={
                                one_role?.permissions?.includes(field?.value) ||
                                false
                              }
                              disabled
                              className="mt-1"
                              type="checkbox"
                            />
                            <p className="">{field?.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-between items-center w-[25%] mt-5">
              <button className="create_role_cancel_button" onClick={onClose}>
                Close
              </button>
              <button className="create_role_save_button">Save</button>
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default RoleDetailsModal;
