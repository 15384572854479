import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CreateDepartmentSchema } from "../../authPages/schema";
import { useDispatch, useSelector } from "react-redux";
import {
  AddTenantDepartment,
  DeleteTenantDepartment,
  GetDepartments,
} from "../../store/slices/userSlice";
import cancel from "./../../asset/svgs/clear.svg";

const CreateDepartment = ({ setStep }) => {
  const dispatch = useDispatch();
  const { allDept } = useSelector((state) => state?.users);
  const [allTenantDept, setAllTenantDept] = useState([]);

  // Async functions
  const deleteDepartment = async (id) => {
    const toastId = toast.loading("Deleting department, please wait...");
    const body = {
      department_ids: [id],
    };
    const res = await dispatch(DeleteTenantDepartment(body));
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render: res?.payload.data?.message || "Department deleted successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    } else {
      toast.update(toastId, {
        render:
          res?.response?.data?.message ||
          res?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const onSubmit = async (values, actions) => {
    const toastId = toast.loading("Creating department, please wait...");
    const data = values.name?.split(",")?.map((val) => {
      return {
        name: val,
      };
    });
    const res = await dispatch(AddTenantDepartment(data));
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render: res?.payload.data?.message || "Department added successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm();
    } else {
      toast.update(toastId, {
        render:
          res?.response?.data?.message ||
          res?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const getDepartments = async () => {
    const res = await dispatch(GetDepartments());
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: CreateDepartmentSchema,
    onSubmit,
  });

  const handleNext = () => {
    setTimeout(() => {
      setStep(4);
    }, 1200);
  };

  // UseEffect
  useEffect(() => {
    getDepartments();
  });

  useEffect(() => {
    if (allDept?.length > 0) {
      setAllTenantDept(allDept);
    }
  }, [allDept]);

  return (
    <div className="sm:mt-12 xl:w-[90%] 2xl:w-[80%] mx-auto">
      <div className="mb-1">
        <button className="flex gap-1 items-center ml-auto">
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <path
                d="M0 6L4.25 3L0 0V6ZM1 1.93L2.515 3L1 4.07V1.93ZM5 0H6V6H5V0Z"
                fill="#2563EB"
              />
            </svg>
          </span>
          <span
            className="text-[#2563EB] text-sm tracking-[0.1px] font-medium"
            onClick={() => setStep(3)}
          >
            Skip this step
          </span>
        </button>
      </div>
      <div className="hidden sm:block onboarding-container-box-two-header mb-8">
        <h6 className="text-[#475569] text-base tracking-[0.15px] font-semibold">
          Create department
        </h6>
        <p className="font-medium text-xs tracking-[0.5px] text-[#475569]">
          Add departments for your organization
        </p>
      </div>

      <div className="onboarding-container-box-two-body pb-14">
        <form className="row" onSubmit={handleSubmit}>
          <div>
            <div>
              <div className=" mb-5 mt-6">
                <div
                  className={`input-box ${
                    !errors.name && touched.name && values.name
                      ? "filled"
                      : errors.name && touched.name
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    id="name"
                    placeholder="Department"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="name" className="label-name">
                    <span className="label-text">Department Name</span>
                  </label>
                </div>

                <div>
                  {errors.name && touched.name && (
                    <p className="error">{errors.name}</p>
                  )}
                </div>
              </div>
            </div>

            <div>
              <button
                className="bg-[#EFF6FF] text-[#2563EB] text-sm tracking-[0.1px] p-[10px] min-w-[150px] font-bold"
                type="submit"
                disabled={isSubmitting}
                style={{
                  border: "2px solid #2563eb",
                  borderRadius: "4px",
                  outline: "none",
                }}
              >
                Add Department
              </button>
            </div>
            <div className="mt-9">
              <h6>Departments</h6>
              <div className="mt-4">
                {allTenantDept?.length ? (
                  <>
                    {allTenantDept?.map((dept, i) => (
                      <span
                        key={i}
                        className="mr-2 py-[2px] px-1 bg-[#F8FAFC] min-w-[30px] rounded inline-flex items-center gap-2 "
                      >
                        <span className="text-sm font-bold text-txgray2">
                          {dept.name}
                        </span>
                        <img
                          src={cancel}
                          alt="cancel"
                          onClick={() => deleteDepartment(dept.id)}
                        />
                      </span>
                    ))}
                  </>
                ) : (
                  <p className="text-[#94A3B8] text-xs font-medium tracking-[0.5px]">
                    NO DEPARTMENT ADDED YET
                  </p>
                )}
              </div>

              <div className="mt-5">
                <p className="text-[#64748B] text-xs tracking-[0.5px]">
                  You can still add department in your organization setting
                  module
                </p>
              </div>
            </div>
            <div className="onboarding-container-main-box-body-footer min-h-[50px] w-full px-2 sm:pl-8 xl:pl-14 lg:pr-[105px] py-2 sm:py-[18px]">
              <div className="flex justify-between">
                <button
                  type="button"
                  className="next-btn bg-[#F1F5F9] text-[#334155] p-[11px] min-w-[150px]"
                  onClick={() => setStep(2)}
                >
                  Previous
                </button>
                <button
                  disabled={allTenantDept?.length === 0}
                  className="next-btn bg-[#2563EB] text-white p-[11px] min-w-[130px] lg:min-w-[250px]"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDepartment;
