/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { LOGGER, getUserToken, endSession, getUserDetails } from "./index";
import { logout1 } from "../store/slices/PayrollSlice";
import { logout } from "../store/slices/userSlice";
import { useMutation } from "@tanstack/react-query";

const baseURL = process.env.REACT_APP_BASE_API;

export default () => {
  // const { access_token: token } = useSelector(state => state.authenticationReducers);
  const accessToken = getUserToken();
  const headers = {};
 
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    headers.Authorization = `Basic RnVzZUVSUFBhcnRuZXJzUmVhY3RDbGllbnQ6YzlnK0B2ayQ1YmIzYWI4MDRmNDlmNDRiMjg5YmU3MGFlNTFmMTE2NTlh`;
  }

  const axiosInstance = axios.create({
    baseURL,
    headers,
    timeout: 60000,
  });

  const requestHandler = (request) => {
    // Token will be dynamic so we can use any app-specific way to always
    // fetch the new token before making the call
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      request.headers.Authorization = `Basic RnVzZUVSUFBhcnRuZXJzUmVhY3RDbGllbnQ6YzlnK0B2ayQ1YmIzYWI4MDRmNDlmNDRiMjg5YmU3MGFlNTFmMTE2NTlh`;
    }
    return request;
  };

  const errorHandler = (error) => {
    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(
    (request) => {
      return requestHandler(request);
    },
    (error) => {
      errorHandler(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status === 204) {
        return { status: "success" };
      }
      return {
        status: response.status,
        ...response,
      };
    },
    async (err) => {
      const statusCode = err.response.status;
      if (statusCode === 400) {
        // credentials error
        return Promise.reject({
          data: "",
          status: 400,
          message: err?.response?.data?.message,
        });
      } else if (statusCode === 401) {
        logout();
        logout1();
        window.location = "/auth/login";
        // end session
        endSession();
        // credentials error
        return Promise.reject({
          data: "",
          status: 401,
          message: err?.response?.data?.message,
        });
      } else if (statusCode === 404) {
        // eNdpoint not found
        LOGGER(`Endpoint not existent: `, err.response.config.baseURL);
        return Promise.reject({
          data: "",
          status: 404,
          message: "Resource not found",
        });
      } else if (statusCode === 403) {
        setTimeout(() => {
          // push the url to the login page
          // window.location = "/auth/login";
          // // end session
          // endSession();
        }, 1000);
        return Promise.reject({ status: statusCode });
      } else {
        // Something went wrong with the server
        LOGGER(err.response.statusText);
        // return promise
        return Promise.reject({
          status: err.response.statusCode,
          message: err.response.statusText,
        });
      }
    }
  );

  return axiosInstance;
};
