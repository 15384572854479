import { useFormik, FormikProvider, FieldArray } from "formik";
import React, { useEffect, useState, Fragment } from "react";
import Naira from "../../asset/NairaIcon.svg";
import MainModal from "../auth-component/MainModal";
import { toast } from "react-toastify";
import {
  allNoticePeriods,
  createLevel,
  getLeaveNames,
  getLevelNames,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateLevelName from "./CreateLevelName";
import CreateLeaveName from "./CreateLeaveCaption";
import { newLevelFormSchema } from "../schema";
import Select, { components } from "react-select";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import dot from "../../asset/svgs/dot-vertical.svg";

const CreateCustomLevel = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();
  const [captionmodal, setCaptionModal] = useState();
  const [fetch, setFetch] = useState(false);
  const [nameModal, setNameModal] = useState(false);
  const { allLevelNames, allLeaves, noticePeriods } = useSelector(
    (state) => state?.users
  );
  const [addNameType, setAddNameType] = useState("");
  const [nameData, setNameData] = useState("");
  const [select, setSelect] = useState("");

  // Functions
  const onSubmit = async (values) => {
    const toastId = toast.loading("Creating Level, please wait...");
    const body = {
      level_name_id: values?.level_name_id?.value,
      notice_period: values?.notice_period?.value,
      leaves: values?.leaves?.map((leave) => {
        return {
          leave_id: leave?.leave_id?.value,
          number_of_days: leave?.number_of_days,
        };
      }),
      nhis_hmo_amount: values?.nhis_hmo_amount || 0,
      life_assurance_amount: values?.life_assurance_amount || 0,
    };
    const res = await dispatch(createLevel(body));
    if (res.payload.status < 400) {
      toast.update(toastId, {
        render: res?.payload?.data?.message || "Level created successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      onClose();
    } else {
      toast.update(toastId, {
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      level_name_id: "",
      notice_period: "",
      leaves: [
        {
          leave_id: "",
          number_of_days: "",
        },
      ],
      nhis_hmo_amount: "",
      life_assurance_amount: "",
    },
    onSubmit,
    validationSchema: newLevelFormSchema,
  });
  const levelNameOptions = allLevelNames?.map((level) => {
    return {
      label: level.name,
      value: level.id,
    };
  });

  const GetLevelNames = async () => {
    setFetch(true);
    const res = await dispatch(getLevelNames());
    setFetch(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const GetLeaves = async () => {
    setFetch(true);
    const res = await dispatch(getLeaveNames());
    setFetch(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const GetNoticePeriods = async () => {
    setFetch(true);
    const res = await dispatch(allNoticePeriods());
    setFetch(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const leaveOptions = allLeaves?.map((leave) => {
    return {
      label: leave?.name,
      value: leave.id,
    };
  });

  // UseEffect
  useEffect(() => {
    GetLevelNames();
    GetLeaves();
    GetNoticePeriods();
  }, []);

  const LevelMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => {
                setNameModal(true);
                setAddNameType("add");
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add Level</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const LeaveMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => {
                setCaptionModal(true);
                setAddNameType("add");
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add Leave</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const Option = (props) => {
    const speaker = (
      <Popover visible>
        <Dropdown.Menu>
          <Dropdown.Item
            eventKey={1}
            onClick={() => {
              if (select === "level") {
                setNameModal(true);
              } else {
                setCaptionModal(true);
              }
              setAddNameType("edit");
              setNameData(props?.data);
            }}
          >
            <div className="flex items-center gap-2">
              <span className="text-txgray2 text-sm font-bold">
                {" "}
                Edit option
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey={2}>
            <div className="flex items-center gap-2">
              <span className="text-[#E11D48] text-sm font-bold">
                {" "}
                Delete option
              </span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
    return (
      <>
        <components.Option {...props}>
          <div className="flex justify-between px-4 items-center">
            <span className="text-[#475569] font-bold text-sm">
              {" "}
              {props.children}{" "}
            </span>
            <Whisper
              placement="autoVerticalEnd"
              trigger="click"
              speaker={speaker}
            >
              <IconButton
                onClick={(e) => e.stopPropagation()}
                className="cursor-pointer px-5"
              >
                <img src={dot} alt="dot" className="cursor-pointer" />
              </IconButton>
            </Whisper>
          </div>
        </components.Option>
      </>
    );
  };

  const modifiedOption = noticePeriods?.map((period) => {
    return {
      label: period.name,
      value: period.value,
    };
  });
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Add Level"
      >
        <div className="xl:w-[90%] mx-auto">
          <div className="upload-from-csv">
            <FormikProvider value={formik}>
              <form className="mt-10" onSubmit={formik.handleSubmit}>
                <div className="mb-5">
                  <div className={`input-box`}>
                    <Select
                      value={formik.values.level_name_id}
                      onChange={(level_name_id) =>
                        formik.setFieldValue("level_name_id", level_name_id)
                      }
                      fetchingData={fetch}
                      options={levelNameOptions}
                      placeholder="Select Level"
                      components={{ Menu: LevelMenu, Option }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "48px",
                          fontSize: "16px",
                        }),
                      }}
                      onClick={() => setSelect("level")}
                    />
                  </div>
                  <div>
                    {formik.errors.level_name_id &&
                      formik.touched.level_name_id && (
                        <p className="error">
                          {formik.errors.level_name_id?.label}
                        </p>
                      )}
                  </div>
                </div>
                <div className="mb-5">
                  <div className={`input-box`}>
                    <Select
                      value={formik.values.notice_period}
                      onChange={(notice_period) =>
                        formik.setFieldValue("notice_period", notice_period)
                      }
                      fetchingData={fetch}
                      options={modifiedOption}
                      placeholder="Select Notice Period"
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "48px",
                          fontSize: "16px",
                        }),
                      }}
                    />
                  </div>
                  <div>
                    {formik.errors.notice_period &&
                      formik.touched.notice_period && (
                        <p className="error">
                          {formik.errors.notice_period?.label}
                        </p>
                      )}
                  </div>
                </div>
                <div className="border-2 rounded px-4 py-7 mb-5">
                  <div className="mb-2">
                    <h6>Leave</h6>
                    <p>Leave days for people under this level</p>
                  </div>
                  <div className="mt-8">
                    <FieldArray
                      name="leaves"
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            {formik.values.leaves &&
                              formik.values.leaves?.length !== 0 &&
                              formik.values.leaves?.map((branch, i) => (
                                <div className="" key={i}>
                                  <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3 mb-10">
                                    <div className={`md:w-6/12 w-full`}>
                                      <div className={`input-box`}>
                                        <Select
                                          value={
                                            formik.values.leaves[i].leave_id
                                          }
                                          onChange={(notice_period) =>
                                            formik.setFieldValue(
                                              `leaves[${i}].leave_id`,
                                              notice_period
                                            )
                                          }
                                          fetchingData={fetch}
                                          options={leaveOptions}
                                          components={{
                                            Menu: LeaveMenu,
                                            Option,
                                          }}
                                          placeholder="Select Leave"
                                          styles={{
                                            control: (base) => ({
                                              ...base,
                                              minHeight: "48px",
                                              fontSize: "16px",
                                            }),
                                          }}
                                          onClick={() => setSelect("leave")}
                                        />
                                      </div>
                                      {/* <div>
                                        {formik?.errors &&
                                          formik?.touched &&
                                          formik?.errors.leaves[i]?.leave_id &&
                                          formik?.touched?.leaves[i]
                                            ?.leave_id && (
                                            <p className="error">
                                              {
                                                formik.errors.leaves[i].leave_id
                                                  ?.label
                                              }
                                            </p>
                                          )}
                                      </div> */}
                                    </div>
                                    <div className="md:w-6/12 w-full">
                                      <div className={`input-box`}>
                                        <input
                                          type="text"
                                          name={`leaves[${i}].number_of_days`}
                                          autoComplete="off"
                                          id={`leaves[${i}].number_of_days`}
                                          value={
                                            formik.values.leaves[i]
                                              .number_of_days
                                          }
                                          onChange={formik.handleChange}
                                        />
                                        <label
                                          htmlFor="number_of_days"
                                          className="label-name"
                                        >
                                          <span className="label-text">
                                            Number of days
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex justify-end items-center">
                                    {i > 0 && (
                                      <button
                                        onClick={() => arrayHelpers.remove(i)}
                                        className="ml-2 mt-[-3rem]"
                                      >
                                        <span className="text-[#F43F5E] text-[14px] font-[700]">
                                          - Remove leave type
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                            <button
                              onClick={() =>
                                arrayHelpers.push({
                                  leave_id: "",
                                  number_of_days: "",
                                })
                              }
                              type="button"
                              className="add-user-btn flex items-center gap-2 mb-11"
                            >
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                                    fill="#2563EB"
                                  />
                                </svg>
                              </span>

                              <span className="text">
                                Add another leave type
                              </span>
                            </button>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="border-2 rounded px-4 py-7">
                  <div className="mb-2">
                    <h6>Health insurance</h6>
                    <p>Add annual amount for the reliefs below</p>
                  </div>
                  <div className="mt-10">
                    <div className={`input-password-box`}>
                      <input
                        type="number"
                        name="nhis_hmo_amount"
                        autoComplete="off"
                        id="nhis_hmo_amount"
                        min="1"
                        value={formik.values.nhis_hmo_amount}
                        onChange={formik.handleChange}
                      />
                      <span className="view" style={{ cursor: "pointer" }}>
                        <img src={Naira} alt="Naira" />
                      </span>
                      <label htmlFor="nhis_hmo_amount" className="label-name">
                        <span className="label-text">
                          Annual NHIS/HMO Amount
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className={`input-password-box`}>
                      <input
                        type="number"
                        name="life_assurance_amount"
                        autoComplete="off"
                        id="life_assurance_amount"
                        min="1"
                        value={formik.values.life_assurance_amount}
                        onChange={formik.handleChange}
                      />
                      <span className="view" style={{ cursor: "pointer" }}>
                        <img src={Naira} alt="Naira" />
                      </span>
                      <label
                        htmlFor="life_assurance_amount"
                        className="label-name"
                      >
                        <span className="label-text">Life Assurance</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center w-[30%] mt-10">
                  <button
                    className="create_role_cancel_button"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="create_role_save_button"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </form>
            </FormikProvider>
          </div>
          <CreateLevelName
            modalOpen={nameModal}
            onClose={() => setNameModal(false)}
            size="sm"
            addType={addNameType}
            nameData={nameData}
          />
          <CreateLeaveName
            modalOpen={captionmodal}
            onClose={() => setCaptionModal(false)}
            size="sm"
            addType={addNameType}
            nameData={nameData}
          />
        </div>
      </MainModal>
    </>
  );
};

export default CreateCustomLevel;
