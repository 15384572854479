import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { viewBalanceState } from "../../Human Resources/utils/states";
import { CircularProgress, IconButton } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";
import Naira from "./../../../../asset/svgs/Naira.svg";
import arrow from "./../../../../asset/svgs/double_arrow_right.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import Withdraw from "../settings-modal/Withdraw";
import UpgradeWallet from "../settings-modal/upgradeWallet";
import ConfirmWithdrawOtp from "../settings-modal/ConfirmWithdrawOtp";

const Wallet = () => {
  const [viewBalance, setViewBalance] = useRecoilState(viewBalanceState);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [otp, setOtp] = useState("");
  //queries
  const { data: dashboardData, isPending } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/tenants/dashboard`);
      return response?.data?.data;
    },
  });

  const initiateWithdraw = useMutation({
    mutationFn: () =>
      axiosInstance().post("/virtual-accounts/initiate-withdrawal"),
    onSuccess: (data) => {
      toast.success("OTP sent");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const resendOtp = useMutation({
    mutationFn: () =>
      axiosInstance().post("/virtual-accounts/resend-withdrawal-otp"),
    onSuccess: (data) => {
      toast.success("OTP sent");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const verifyOtp = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post(
        "/virtual-accounts/validate-withdrawal-otp",
        payload
      ),
    onSuccess: (data) => {
      toast.success("OTP verified");
      setOpenConfirm(false);
      setOpenWithdraw(true);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return (
    <>
      <div className="max-w-[770px] flex flex-col gap-3 items-start p-6 bg-white border-[1px] border-[#F1F5F9]">
        <p className="text-[#1E40AF] font-bold text-[15px] leading-5 mb-4">
          Wallet info
        </p>
        <div className="flex gap-6 flex-col lg:flex-row">
          <div className="relative">
            <div>
              <div
                style={{
                  background:
                    " linear-gradient(131.8deg, #3B82F6 15.37%, #1E40AF 100%)",
                }}
                className="p-6 rounded flex flex-col "
              >
                <div className="flex items-center justify-between">
                  <p className="text-white font-semibold text-sm  flex items-center">
                    Wallet Balance
                    <IconButton onClick={() => setViewBalance(!viewBalance)}>
                      {viewBalance ? (
                        <FaEyeSlash className="text-white text-[16px]" />
                      ) : (
                        <FaEye className="text-white text-[16px]" />
                      )}
                    </IconButton>
                  </p>
                  {/* <span className="bg-[#1E3A8A] px-[6px] py-1 rounded text-[#FFFFFF]">
                  Tier [0]
                </span> */}
                </div>
                <div className="flex items-center mt-2">
                  <img src={Naira} alt="naira" />
                  {!viewBalance ? (
                    <p className="text-white text-[22px] font-semibold">
                      {dashboardData?.account_details?.balance?.toLocaleString()}
                    </p>
                  ) : (
                    <p className="text-white text-[22px] font-semibold">****</p>
                  )}
                </div>
                <span className="bg-[#1E40AF] text-white font-bold text-[13px] px-[6px]  flex justify-between items-center rounded mt-3">
                  {dashboardData?.account_details?.provider}:{" "}
                  {dashboardData?.account_details?.account_number}
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        dashboardData?.account_details?.account_number
                      );
                      toast.success("Copied!");
                    }}
                  >
                    <ContentCopyIcon
                      sx={{ fontSize: "14px", color: "#ffffff" }}
                      color="action"
                    />
                  </IconButton>
                </span>
              </div>
            </div>
            {isPending ? (
              <div
                className="flex flex-col items-center gap-6 absolute w-full top-0 h-full justify-center rounded"
                style={{
                  background:
                    "linear-gradient(131.56deg, rgba(255, 255, 255, 0.5) 3.42%, rgba(255, 255, 255, 0.2) 97.06%)",
                  backdropFilter: "blur(5px)",
                }}
              >
                <CircularProgress
                  sx={{
                    color: "#ffffffd1",
                    width: "30px !important",
                    height: "30px !important",
                  }}
                />
              </div>
            ) : null}
          </div>

          <div>
            {/* <div
            className="border-[1px] border-[#E2E8F0] px-4 py-3 rounded flex gap-6 items-center cursor-pointer justify-between"
            onClick={() => setOpenUpgrade(true)}
          >
            <div>
              <p className="font-semibold text-[16px] text-[#2563EB]">
                Upgrade account
              </p>
              <p className="font-medium m-0 text-[11px]">
                Upgrade account to increase your transaction limit
              </p>
            </div>
            <img src={arrow} alt="arrow" />
          </div> */}
            <div
              className="border-[1px] border-[#E2E8F0] px-4 py-3 rounded flex gap-6 items-center cursor-pointer justify-between"
              onClick={() => {
                setOpenConfirm(true);
                initiateWithdraw.mutate();
              }}
            >
              <div>
                <p className="font-semibold text-[16px] text-[#2563EB]">
                  Withdraw
                </p>
                <p className="font-medium m-0 text-[11px]">
                  To your company account
                </p>
              </div>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
      <ConfirmWithdrawOtp
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        sendOtpMutation={() => initiateWithdraw.mutate()}
        otp={otp}
        setOtp={setOtp}
        verifyOtp={verifyOtp}
      />
      <Withdraw open={openWithdraw} onClose={() => setOpenWithdraw(false)} />
      <UpgradeWallet open={openUpgrade} onClose={() => setOpenUpgrade(false)} />
    </>
  );
};

export default Wallet;
