import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import close from "./../../../../asset/svgs/close-circle.svg";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledInputWrapper } from "../../../../component/atoms/StyledComponents";
import lamp from "./../../../../asset/svgs/lamp-on.svg";
import BlueBotton from "../../../../component/atoms/BlueBotton";
import ImageUploader from "../../../dashboard-component/inputs/ImageUploader";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../utils/https";
import toast from "react-hot-toast";
import OtpModal from "../../../dashboard-modals/OtpModal";

const UpgradeWallet = ({ open, onClose }) => {
  // states
  const [activeTab, setActiveTab] = useState("tier2");
  const [openConfirmOtp, setOpenConfirmOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [bvnVData, setBvnVData] = useState(null);
  const [images, setImages] = useState({
    image1: { url: "", file: null },
    image2: { url: "", file: null },
    image3: { url: "", file: null },
    image4: { url: "", file: null },
  });

  const handleSetImage = (key, url) => {
    setImages((prev) => ({
      ...prev,
      [key]: { ...prev[key], url },
    }));
  };

  const handleSetFile = (key, file) => {
    setImages((prev) => ({
      ...prev,
      [key]: { ...prev[key], file },
    }));
  };

  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        bvn: "",
        nin: "",
      },
      validationSchema: yup.object().shape({
        bvn: yup
          .string()
          .required("Please enter BVN")
          .max(11, "BVN must not be more than 11-digits.")
          .min(11, "BVN must not be less than 11-digits."),
        nin: yup
          .string()
          .required("Please enter NIN")
          .max(11, "NIN must not be more than 11-digits.")
          .min(11, "BVN must not be less than 10-digits."),
      }),
      onSubmit: (values) => {
        console.log(images);
      },
    });

  const bvnMutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post(
        "/tenants/safe-haven/initiate-bvn-verification",
        payload
      ),
    onSuccess: (data) => {
      toast.success("OTP sent");
      setOpenConfirmOtp(true);
      setBvnVData(data);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const confirmBvnOtpMutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/tenants/safe-haven/verify-bvn", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setOpenConfirmOtp(false);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  useEffect(() => {
    if (values?.bvn?.length === 11) {
      bvnMutation.mutate({
        bvn_number: values?.bvn,
        retry: true,
      });
    }
  }, [values?.bvn]);
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="flex items-center justify-between  gap-2 border-b-[1px] border-[hsl(210,40%,96%)] mb-6 pb-2">
          <p className="tetx-txgray2 text-[22px] font-semibold">
            Create wallet
          </p>
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <img src={close} alt="close" />
          </IconButton>
        </div>
        <div className="bg-[#F8FAFC] rounded-3xl border-[#F1F5F9] border-[1px] p-1 w-full flex relative">
          <div
            className={` font-bold text-[16px] py-2 px-9 rounded-[20px] w-full justify-center flex cursor-pointer duration-200 ${
              activeTab === "tier2"
                ? "text-[#2563EB] bg-[#FFFFFF] "
                : "text-txgray2"
            } `}
            onClick={() => setActiveTab("tier2")}
            style={{
              boxShadow:
                activeTab === "tier2" ? " 0px 8px 20px 0px #2563EB1A" : "",
            }}
          >
            Tier 2 wallet
          </div>
          <div
            className={` font-bold text-[16px] py-2 px-9 rounded-[20px] w-full justify-center flex cursor-pointer duration-200 ${
              activeTab === "tier3"
                ? "text-[#2563EB] bg-[#FFFFFF] "
                : "text-txgray2"
            } `}
            onClick={() => setActiveTab("tier3")}
            style={{
              boxShadow:
                activeTab === "tier3" ? " 0px 8px 20px 0px #2563EB1A" : "",
            }}
          >
            Tier 3 wallet
          </div>
          <div className="absolute top-[-10px] right-[15%] bg-[#EFF6FF] border-[1px] border-[#3B82F6] px-1 py-[2px] rounded-[2px] text-[#2563EB] text-[12px] font-medium">
            Recommended
          </div>
        </div>
        <div className="mt-4 flex justify-between border-b-[1px] border-[hsl(210,40%,96%)] pb-1">
          <div>
            <p className="text-txgray2 font-medium text-sm">
              Single transaction limit:
            </p>
            <p className="text-[#1E40AF] font-medium text-sm m-0">
              {activeTab === "tier2" ? " N100,000" : "N2,500,000"}
            </p>
          </div>
          <div className="text-right">
            <p className="text-txgray2 font-medium text-sm">
              Daily transaction limit:
            </p>
            <p className="text-[#1E40AF] font-medium text-sm m-0">
              {activeTab === "tier2" ? "N2,500,000" : " N10,000,000"}
            </p>
          </div>
        </div>
        <form className="flex flex-col gap-4">
          <p className="text-txgray text-[12px] mt-4">
            We will be needing some information to verify you
          </p>
          <StyledInputWrapper>
            <input
              id="bvn"
              name="bvn"
              type="text"
              placeholder="Enter BVN"
              value={values?.bvn}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.bvn && touched.bvn && "error"}
            />
            {errors.bvn && touched.bvn && (
              <p className="field_error">{errors.bvn}</p>
            )}
          </StyledInputWrapper>
          <StyledInputWrapper>
            <input
              id="nin"
              name="nin"
              type="number"
              placeholder="Enter NIN"
              value={values?.nin}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.nin && touched.nin && "error"}
            />
            {errors.nin && touched.nin && (
              <p className="field_error">{errors.nin}</p>
            )}
          </StyledInputWrapper>
        </form>
        {activeTab === "tier2" ? null : (
          <>
            {" "}
            <div className="grid grid-cols-2 gap-4 mt-4">
              <ImageUploader
                id="1"
                label="Upload Valid ID Card (Front)"
                selectedFile={images.image1.file}
                setSelectedFile={(file) => handleSetFile("image1", file)}
                setImageUrl={(url) => handleSetImage("image1", url)}
              />
              <ImageUploader
                id="2"
                label="Passport photograph"
                selectedFile={images.image2.file}
                setSelectedFile={(file) => handleSetFile("image2", file)}
                setImageUrl={(url) => handleSetImage("image2", url)}
              />
              <ImageUploader
                id="3"
                label="Utility bill/statement of account"
                selectedFile={images.image3.file}
                setSelectedFile={(file) => handleSetFile("image3", file)}
                setImageUrl={(url) => handleSetImage("image3", url)}
              />
              <ImageUploader
                id="4"
                label="Signature"
                selectedFile={images.image4.file}
                setSelectedFile={(file) => handleSetFile("image4", file)}
                setImageUrl={(url) => handleSetImage("image4", url)}
              />
            </div>
            <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded my-4">
              <img src={lamp} alt="close" />
              <p>
                You will be temporarily assigned a
                <button className="text-[#2563EB]" href="#">
                  Tier 2
                </button>{" "}
                account while your Tier 3 account is being verified.
              </p>
            </div>
          </>
        )}

        <div className="flex gap-[10px] justify-end mt-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
          <BlueBotton
            label="Create"
            onClick={handleSubmit}
            type="submit"
            loading={bvnMutation.isPending}
          />
        </div>
      </Modal>
      {/* <OtpModal
        btnTitle={"Verify"}
        onClose={() => setOpenConfirmOtp(false)}
        openConfirmOtp={openConfirmOtp}
        sendOtpMutation={() =>
          bvnMutation.mutate({
            bvn_number: values?.bvn,
            retry: true,
          })
        }
        verifyOtpMutation={() =>
          confirmBvnOtpMutation.mutate({
            identity_verification_request_id: "KudaVester",
            otp: otp,
          })
        }
        verifyOtpMutationFn={confirmBvnOtpMutation}
        otp={otp}
        setOtp={setOtp}
      /> */}
    </>
  );
};

export default UpgradeWallet;
