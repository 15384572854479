import React from "react";
import trendUp from "./../../../asset/svgs/trend-up.svg";

export default function BranchNumber({ allBranches }) {
  return (
    <div
      className="bg-[#FFFFFF] w-full border-[#F1F5F9] border-[1px] py-6 px-5 flex flex-col items-center gap-6"
      style={{ boxShadow: "2px 2px 8px 0px #9494940D" }}
    >
      <p className="text-[#334155] font-bold text-[18px]">No. of branches</p>
      <p className="text-[#1E40AF] font-semibold text-[36px]">{allBranches}</p>
    </div>
  );
}
