import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { Modal } from "rsuite";
import close from "./../../../asset/svgs/close-circle.svg";
import styled from "styled-components";
import { months } from "../../dashboard-pages/Human Resources/utils/constants";
import BlueBotton from "../../../component/atoms/BlueBotton";
import { DateRangeSinceCreated } from "../../../utils";
import { Menu, MenuItem } from "@mui/material";
import dropdown from "./../../../asset/svgs/dropdown.svg";

const CustomModal = styled(Modal)`
  width: 388px !important;
  margin: 120px 30px 0px auto;
`;

export default function SelectMonth({
  open,
  onClose,
  selectedMonth,
  setSelectedMonth,
  setValue,
  createdDate,
}) {
  const [value, setYearValue] = useState(
    DateRangeSinceCreated(createdDate)[
      DateRangeSinceCreated(createdDate).length - 1
    ]
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <CustomModal id="basic-Modal" open={open} onClose={onClose}>
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="tetx-txgray2 text-[22px] font-semibold">Month filter</p>
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
      </div>
      <div className="px-4">
        <div className="flex justify-between items-center px-1">
          <div
            onClick={handleClick}
            className="flex gap-1 items-center border-[#E2E8F0] border-[1px] rounded px-2 py-1 cursor-pointer"
          >
            <p>{value}</p>
            <img src={dropdown} alt="calender" />
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {DateRangeSinceCreated(createdDate)?.map((item, index) => (
              <MenuItem
                onClick={(event) => {
                  handleClose();
                  setYearValue(item);
                }}
                key={index}
              >
                <p className="text-semibold text-sm text-txgray2">{item}</p>
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className="flex flex-wrap gap-1 mt-3 justify-center">
          {months?.map((item, index) => (
            <div
              key={index}
              className={`${
                selectedMonth === item.value ? "bg-[#3B82F6] text-white" : ""
              } rounded py-2 px-5 font-bold text-gray duration-300 cursor-pointer w-[74px]`}
              onClick={() => setSelectedMonth(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-6 justify-end mt-6">
        <button
          className="bg-[#F1F5F9] px-4 py-2 rounded"
          onClick={onClose}
          type="button"
        >
          Cancel
        </button>
        <BlueBotton
          label="Save"
          onClick={() => {
            onClose();
            setValue([selectedMonth, " ", value]);
          }}
        />
      </div>
    </CustomModal>
  );
}
