import { TagPicker } from "rsuite";
import styled from "styled-components";

//styles
export const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  label {
    color: #64748b;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    // font-family: Poppins;
    margin-bottom: 8px;
  }
  .label_error {
    color: #f43f5e;
  }
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #1e293b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    z-index: 0;
  }
  textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #1e293b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    z-index: 0;
    resize: none;
  }
  .error {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  .field_error {
    color: #f43f5e;
    font-size: 11px;
    margin-top: 4px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
`;

export const StyledPicker = styled(TagPicker)`
  height: 48px;
  background: rgba(0, 0, 0, 0);
  .rs-picker-toggle {
    background: rgba(0, 0, 0, 0) !important;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .rs-picker-tag-wrapper {
    height: 48px;
    align-items: center;
    display: flex;
  }
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 13px !important;
  }
`;
