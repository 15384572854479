/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import cancel from "./../../../../../asset/CloseBtn.svg";
import dot from "./../../../../../asset/svgs/dot-vertical.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { FaPercent } from "react-icons/fa";
import {
  CreateDeduction,
  UpdateDeduction,
  GetDeductions,
} from "../../../../../store/slices/PayrollSlice";
import { toast } from "react-toastify";
import { format } from "date-fns";
import addIcon from "./../../../../../asset/svgs/add.svg";
import AddNameValue from "./AddNameValue";
import { Dropdown, Modal, Popover, Whisper } from "rsuite";

//styles
const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: transparent;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #1e293b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    z-index: 0;
  }
  .error {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  .field_error {
    color: #f43f5e;
    font-size: 11px;
    margin-top: 4px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
`;

const CustomModal = styled(Modal)`
  .rs-modal-content {
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 592px;
  }
`;
export default function AddDeductionModal({
  selectedEmployeeData,
  ToggleOpenAddDed,
  ToggleLoadModal,
  open,
  branch,
  employeeDeduction,
  actionType,
  setMainPage,
}) {
  //states and hooks
  const [deductionName_Id, setDeductionName_Id] = useState();
  const [openaddNameValue, setOpenAddNameValue] = useState(false);
  const [endingType, setEndingType] = useState(
    actionType === "edit" ? employeeDeduction?.ending_type : ""
  );
  const [addNameType, setAddNameType] = useState("");
  const [nameData, setNameData] = useState("");
  const dispatch = useDispatch();
  const [deductionType, setDeductionType] = useState(
    actionType === "edit" ? employeeDeduction?.type : "fixed_amount"
  );
  const {
    deduction_types,
    ending_type,
    percentage_of: percentDef,
  } = useSelector((state) => state.users.allUtilities);
  const { deductionNames } = useSelector((state) => state.payroll);

  //functions
  useEffect(() => {
    if (actionType === "edit") {
      const findDeductionName = deductionNames?.find(
        (item) => item.name === employeeDeduction?.deduction_name
      );
      const findPercentageOf = percentDef?.find(
        (ending) => ending.value === employeeDeduction?.percentage_of
      );
      const findEndingType = ending_type?.find(
        (percentageOf) => percentageOf.value === employeeDeduction?.ending_type
      );
      setFieldValue("percentage_of", {
        label: findPercentageOf?.name,
        value: findPercentageOf?.value,
      });
      setFieldValue("ending_type", {
        label: findEndingType?.name,
        value: findEndingType?.value,
      });
      setFieldValue("name", {
        label: findDeductionName?.name,
        value: findDeductionName?.id,
      });
    }
    // console.log(payroll);
  }, [actionType, deductionNames, percentDef, ending_type, employeeDeduction]);

  function ToggleAddNameValue() {
    setOpenAddNameValue(!openaddNameValue);
  }

  // datas
  const endingTypes =
    deductionType === "fixed_amount"
      ? ending_type?.map((type) => ({
          label: type?.name,
          value: type?.value,
        }))
      : ending_type
          ?.map((type) => ({
            label: type?.name,
            value: type?.value,
          }))
          .filter((type) => type.value !== "end_of_recovering_total_amount");

  const percentageOf = percentDef?.map((type) => {
    return {
      label: type?.name,
      value: type?.value,
    };
  });
  const deductionNamesList = deductionNames?.map((type) => {
    return {
      label: type?.name,
      value: type?.id,
    };
  });
  const selectedEmployeesId = selectedEmployeeData?.map((item) => item.user_id);

  //schemas
  const fixedAmountYup = yup.object().shape({
    name: yup.object().shape({
      label: yup.string().required("Deduction name is required"),
    }),
    type: yup.string().required("This field is required"),
    amount: yup.string().required("Amount is required"),
    ending_type: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
    start_date: yup
      .date()
      .typeError("Start date is required")
      .required("This field is required"),
  });
  //
  const percentageYup = yup.object().shape({
    name: yup.object().shape({
      label: yup.string().required("Deduction name is required"),
    }),
    type: yup.string().required("This field is required"),
    ending_type: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
    percentage_of: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
    percentage_value: yup.string().required("This field is required"),
    start_date: yup
      .date()
      .typeError("Start date is required")
      .required("This field is required"),
  });

  //onsubmit function
  const onSubmit = async (values, actions) => {
    const toastId = toast.loading(
      actionType === "edit"
        ? "Updating deduction, please wait"
        : "Creating deduction, please wait..."
    );
    const {
      name,
      type,
      amount,
      total_amount,
      ending_type,
      percentage_of,
      percentage_value,
      start_date,
      end_date,
    } = values;

    const fixedAmountWithEndDate = {
      name: name?.label,
      type,
      amount,
      ending_type: ending_type?.value ? ending_type?.value : "",
      start_date,
      end_date: end_date !== null ? end_date : "",
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      deduction_name_id: actionType === "edit" ? "" : deductionName_Id.value,
    };
    const fixedAmountEndsOnRecovering = {
      name: name?.label,
      type,
      amount,
      total_amount,
      ending_type: ending_type?.value ? ending_type?.value : "",
      start_date,
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      deduction_name_id: actionType === "edit" ? "" : deductionName_Id.value,
    };
    const fixedAmountNeverEnds = {
      name: name?.label,
      type,
      amount,
      ending_type: ending_type?.value ? ending_type?.value : "",
      start_date,
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      deduction_name_id: actionType === "edit" ? "" : deductionName_Id.value,
    };

    const percentageWithEndDate = {
      name: name?.label,
      type,
      ending_type: ending_type?.value ? ending_type?.value : "",
      percentage_of: percentage_of?.value ? percentage_of?.value : "",
      percentage_value,
      start_date,
      end_date: end_date !== null ? end_date : "",
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      deduction_name_id: actionType === "edit" ? "" : deductionName_Id.value,
    };
    const percentageNeverEnds = {
      name: name?.label,
      type,
      ending_type: ending_type?.value ? ending_type?.value : "",
      percentage_of: percentage_of?.value ? percentage_of?.value : "",
      percentage_value,
      start_date,
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      deduction_name_id: actionType === "edit" ? "" : deductionName_Id.value,
    };

    const data = () => {
      if (
        deductionType === "fixed_amount" &&
        endingType === "end_of_selected_month"
      ) {
        return fixedAmountWithEndDate;
      } else if (
        deductionType === "fixed_amount" &&
        endingType === "end_of_recovering_total_amount"
      ) {
        return fixedAmountEndsOnRecovering;
      } else if (
        deductionType === "fixed_amount" &&
        endingType === "never_ends"
      ) {
        return fixedAmountNeverEnds;
      } else if (
        deductionType === "percentage" &&
        endingType === "end_of_selected_month"
      ) {
        return percentageWithEndDate;
      } else if (
        deductionType === "percentage" &&
        endingType === "never_ends"
      ) {
        return percentageNeverEnds;
      } else {
        return null;
      }
    };

    const result = await dispatch(
      actionType === "edit"
        ? UpdateDeduction({
            payload: {
              ...data(),
              beneficiary_ids: [employeeDeduction.user_id],
              branch_id: employeeDeduction.branch_id,
              deduction_name_id: employeeDeduction.deduction_name_id,
            },
            deduction_id: employeeDeduction?.id,
          })
        : CreateDeduction(data())
    );
    await dispatch(GetDeductions());

    if (result.payload.status < 400) {
      toast.update(toastId, {
        render: result?.payload?.message || result?.payload?.data?.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm();
      ToggleOpenAddDed(!open);
    } else {
      toast.update(toastId, {
        render:
          result?.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : result?.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  //formik
  const formik = useFormik({
    initialValues: {
      name: "",
      type: actionType === "edit" ? employeeDeduction?.type : "",
      amount: actionType === "edit" ? employeeDeduction?.amount : "",
      total_amount:
        actionType === "edit" ? employeeDeduction?.total_amount : "",
      ending_type: "",
      percentage_of: "",
      percentage_value:
        actionType === "edit" ? employeeDeduction?.percentage_value : "",
      start_date:
        actionType === "edit"
          ? format(new Date(employeeDeduction?.start_date), "yyyy-MM-dd")
          : new Date("YYYY-MM-DD"),
      end_date:
        actionType === "edit"
          ? format(new Date(employeeDeduction?.end_date), "yyyy-MM-dd")
          : new Date("YYYY-MM-DD"),
    },
    validationSchema:
      deductionType === "fixed_amount"
        ? fixedAmountYup
        : deductionType === "percentage"
        ? percentageYup
        : null,
    onSubmit,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    // isSubmitting,
    setFieldValue,
  } = formik;

  //components
  const NameMenu = ({ childre, ...props }) => {
    return (
      <>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>
                <span>{props.children}</span>
              </div>
            )}
            <button
              type="button"
              className="text-[#2563EB] my-1 flex justify-start items-center p-2"
              onClick={() => {
                ToggleAddNameValue();
                setAddNameType("add");
              }}
            >
              <img src={addIcon} alt="addIcon" />
              <span className="font-bold text-sm">New Deduction</span>
            </button>
          </div>
        </components.Menu>
      </>
    );
  };
  const Option = (props) => {
    const speaker = (
      <Popover visible>
        <Dropdown.Menu>
          <Dropdown.Item
            eventKey={1}
            onClick={() => {
              ToggleAddNameValue();
              setAddNameType("edit");
              setNameData(props?.data);
            }}
          >
            <div className="flex items-center gap-2">
              <span className="text-txgray2 text-sm font-bold">
                {" "}
                Edit option
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey={2}>
            <div className="flex items-center gap-2">
              <span className="text-[#E11D48] text-sm font-bold">
                {" "}
                Delete option
              </span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
    return (
      <>
        <components.Option {...props}>
          <div className="flex justify-between px-4 items-center">
            <span className="text-[#475569] font-bold text-sm">
              {" "}
              {props.children}{" "}
            </span>
            <Whisper
              placement="autoVerticalEnd"
              trigger="click"
              speaker={speaker}
            >
              <IconButton
                onClick={(e) => e.stopPropagation()}
                className="cursor-pointer px-5"
              >
                <img src={dot} alt="dot" className="cursor-pointer" />
              </IconButton>
            </Whisper>
          </div>
        </components.Option>
      </>
    );
  };

  return (
    <>
      <CustomModal
        onClose={() => {
          ToggleOpenAddDed();
          ToggleLoadModal();
        }}
        open={open}
        style={{
          content: {
            padding: "24px 48px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            minWidth: "592px",
          },
        }}
      >
        <div className="flex items-center justify-between gap-5 border-b-[1px] border-[#F1F5F9] pb-4">
          <p className="text-txgray3 text-[22px] font-semibold">
            {actionType === "edit" ? "Edit Deduction" : "Add New Deduction"}
          </p>
          <IconButton
            onClick={() => {
              ToggleOpenAddDed();
              ToggleLoadModal();
            }}
          >
            <img src={cancel} alt="close" />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit} className="mt-6 flex flex-col gap-6">
          <StyledInputWrapper>
            <Select
              value={values?.name}
              onChange={(name) => {
                setFieldValue("name", name);
                setDeductionName_Id(name);
              }}
              options={deductionNamesList}
              placeholder="Deduction Name"
              components={{ Menu: NameMenu, Option }}
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: "48px",
                  fontSize: "14px",
                }),
              }}
              isDisabled={actionType === "edit"}
            />
            {errors.name && touched.name && (
              <p className="field_error">{errors.name.label}</p>
            )}
          </StyledInputWrapper>
          <StyledInputWrapper>
            <FormControl>
              <p className="text-txgray2 text-sm">
                Type of Deduction per pay period
              </p>
              <RadioGroup
                value={values.type}
                onChange={(type) => {
                  setFieldValue("type", type.target.value);
                  setDeductionType(type.target.value);
                }}
                row
              >
                {deduction_types?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<Radio />}
                    label={item.name}
                    className="text-txgray2 !text-sm"
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "14px !important",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {errors.type && touched.type && (
              <p className="field_error">{errors.type}</p>
            )}
          </StyledInputWrapper>

          {deductionType === "fixed_amount" ? (
            <StyledInputWrapper>
              <input
                id="amount"
                name="amount"
                type="number"
                placeholder="Amount"
                value={values?.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.amount && touched.amount && "error"}
              />
              {errors.amount && touched.amount && (
                <p className="field_error">{errors.amount}</p>
              )}
            </StyledInputWrapper>
          ) : (
            <>
              <StyledInputWrapper>
                <Select
                  value={values?.percentage_of}
                  onChange={(percentage_of) => {
                    setFieldValue("percentage_of", percentage_of);
                  }}
                  options={percentageOf}
                  placeholder="Percentage of"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "48px",
                      fontSize: "14px",
                    }),
                  }}
                />
                {errors.percentage_of && touched.percentage_of && (
                  <p className="field_error">{errors.percentage_of.label}</p>
                )}
              </StyledInputWrapper>
              <StyledInputWrapper>
                <div>
                  <input
                    id="percentage_value"
                    name="percentage_value"
                    type="number"
                    placeholder="Percentage"
                    value={values.percentage_value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.percentage_value &&
                      touched.percentage_value &&
                      "error"
                    }
                    style={{
                      paddingRight: "34px",
                    }}
                  />
                  <span className="ml-2 absolute top-0 right-0 p-[18px] ">
                    <FaPercent />
                  </span>
                </div>
                {errors.percentage_value && touched.percentage_value && (
                  <p className="field_error">{errors.percentage_value}</p>
                )}
              </StyledInputWrapper>
            </>
          )}

          <StyledInputWrapper>
            <div>
              <input
                id="start_date"
                name="start_date"
                type="date"
                placeholder="Start Date"
                value={values.start_date}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.start_date && touched.start_date && "error"}
              />
              <span className="absolute top-[-10px] left-[14px] bg-white px-1 text-sm text-[#475569]">
                Start Date
              </span>
            </div>
            {errors.start_date && touched.start_date && (
              <p className="field_error">{errors.start_date}</p>
            )}
          </StyledInputWrapper>
          <StyledInputWrapper>
            <Select
              value={values?.ending_type}
              onChange={(ending_type) => {
                setFieldValue("ending_type", ending_type);
                setEndingType(ending_type.value);
              }}
              options={endingTypes}
              placeholder="Select deduction ending type"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: "48px",
                  fontSize: "14px",
                }),
              }}
            />
            {errors.ending_type && touched.ending_type && (
              <p className="field_error">{errors.ending_type.label}</p>
            )}
          </StyledInputWrapper>
          {endingType === "end_of_selected_month" ? (
            <StyledInputWrapper className="input-box">
              <div>
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  placeholder="End Date"
                  value={values?.end_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.end_date && touched.end_date && "error"}
                />
                <span className="absolute top-[-10px] left-[14px] bg-white px-1 text-sm text-[#475569]">
                  End Date
                </span>
              </div>
              {errors.end_date && touched.end_date && (
                <p className="field_error">{errors.end_date}</p>
              )}
            </StyledInputWrapper>
          ) : endingType === "end_of_recovering_total_amount" ? (
            <StyledInputWrapper>
              <input
                id="total_amount"
                name="total_amount"
                type="number"
                placeholder="Total amount to deduct"
                value={values?.total_amount}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.total_amount && touched.total_amount && "error"
                }
              />
              {errors.total_amount && touched.total_amount && (
                <p className="field_error">{errors.total_amount}</p>
              )}
            </StyledInputWrapper>
          ) : null}

          <button
            type="submit"
            className="w-full text-sm bg-[#2563EB] font-bold text-[#FFFFFF] h-12 px-4 rounded-md"
          >
            {actionType === "edit" ? "Save Changes" : "Add New Deduction"}
          </button>
        </form>
      </CustomModal>
      <AddNameValue
        openaddNameValue={openaddNameValue}
        ToggleAddNameValue={ToggleAddNameValue}
        type="Deduction"
        branch={branch}
        addType={addNameType}
        nameData={nameData}
      />
    </>
  );
}
