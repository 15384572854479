import React from "react";

export default function ArrowIcon({ active = false }) {
  return active ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.71979 5.96658L7.06645 10.3132C7.57979 10.8266 8.41979 10.8266 8.93312 10.3132L13.2798 5.96658"
        stroke="#2563EB"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96658 13.2802L10.3132 8.93355C10.8266 8.42021 10.8266 7.58021 10.3132 7.06688L5.96658 2.72021"
        stroke="#64748B"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
