import React from "react";
import AuthLayout from "./AuthLayout";
import Logo from "../../asset/auth/fuse-logo.svg";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import Hand from "../../asset/auth/auth-hand.svg";
import "../styles/main.css";

const SignUpLayout = ({ children }) => {
  return (
    <AuthLayout>
      <div className="auth-container lg:py-[5rem] px-0">
        <div className=" 2xl:max-w-[1520px] xl:px-[3rem] py-0 mx-auto md:px-[3rem] sm:px-[2rem] px-0 bg-white lg:bg-transparent">
          <div className="flex flex-col lg:flex-row w-full">
            <div className="auth-container-box-one hidden lg:flex lg:w-5/12 2xl:w-4/12 bg-[#334155] px-[37px] py-[33px] text-white">
              <div className="h-full flex flex-col gap-4">
                <div className="auth-logo-box">
                  <img src={Logo} alt="" />
                </div>

                <div className="auth-content-box flex-grow flex justify-center flex-col">
                  <div className="">
                    <div className="auth-content-box-header">
                      <h3>Fuse your business with just 2 clicks</h3>
                      <h6>Resource planning for all</h6>
                    </div>
                    <div className="auth-content-box-list">
                      <p>On the bright side</p>
                      <div className="auth-content-box-list-item">
                        {[
                          "Super fast customizable invoices and voucher",
                          "Internationally recognize accounting formula and company ledger",
                          "Super fast customizable invoices and voucher",
                          "No, there’s no dark side",
                        ].map((_, i) => (
                          <div className="flex gap-2 items-start mb-3" key={i}>
                            <img src={Hand} alt="" />
                            <p>{_}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white flex-grow-1 lg:w-7/12 2xl:w-8/12 auth-container-box-two">
              {/* <div className="auth-logo-box lg:hidden mb-6">
                <img src={Logo2} alt="" />
              </div> */}
              {children}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUpLayout;
