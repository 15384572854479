import { IconButton } from "@mui/material";
import React from "react";
import { Modal } from "rsuite";
import close from "./../../asset/svgs/close-circle.svg";
import styled from "styled-components";
import toast from "react-hot-toast";

const CustomModal = styled(Modal)`
  width: 450px !important;
`;

export default function TopUpWallet({ open, onClose, wallet }) {
  return (
    <CustomModal open={open} onClose={onClose}>
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="tetx-txgray2 text-[22px] font-semibold">Top up wallet</p>
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
      </div>
      <div className="px-6">
        <p className="text-sm text-txgray2">
          Fund your wallet by sending money to account number.
        </p>

        <div className="mt-4 py-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
          <span className="flex flex-col">
            <span className="font-bold text-txgray2 text-[16px]">
              Account Name
            </span>
            <span className="font-normal text-txgray2 text-sm">
              {wallet?.account_name}
            </span>
          </span>
          <span
            className="font-bold text-txgray2 text-sm border-[#E2E8F0] border-[1px] rounded p-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(wallet?.account_name);
              toast.success("Copied!");
            }}
          >
            copy
          </span>
        </div>
        <div className="py-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
          <span className="flex flex-col">
            <span className="font-bold text-txgray2 text-[16px]">
              Account Number
            </span>
            <span className="font-normal text-txgray2 text-sm">
              {wallet?.account_number}
            </span>
          </span>
          <span
            className="font-bold text-txgray2 text-sm border-[#E2E8F0] border-[1px] rounded p-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(wallet?.account_number);
              toast.success("Copied!");
            }}
          >
            copy
          </span>
        </div>
        <div className="py-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
          <span className="flex flex-col">
            <span className="font-bold text-txgray2 text-[16px]">
              Bank Name
            </span>
            <span className="font-normal text-txgray2 text-sm">
              {wallet?.provider}
            </span>
          </span>
          <span
            className="font-bold text-txgray2 text-sm border-[#E2E8F0] border-[1px] rounded p-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(wallet?.provider);
              toast.success("Copied!");
            }}
          >
            copy
          </span>
        </div>
      </div>
      <div className="flex gap-6 justify-end mt-6">
        <button
          className="bg-[#F1F5F9] px-4 py-2 rounded"
          onClick={onClose}
          type="button"
        >
          Cancel
        </button>
      </div>
    </CustomModal>
  );
}
