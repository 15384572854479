import React from "react";
import naira from "./../../../asset/svgs/nairaGreen.svg";
import { useRecoilState } from "recoil";
import { viewBalanceState } from "../../dashboard-pages/Human Resources/utils/states";

export default function AmountFunded({ details, isPending }) {
  const [viewBalance, setViewBalance] = useRecoilState(viewBalanceState);

  return (
    <div
      className="p-6 rounded flex flex-col gap-4 bg-white"
      style={{
        boxShadow: "2px 2px 8px 0px #9494940D",
        border: "1px solid #F1F5F9",
      }}
    >
      <p className="text-[#334155] font-bold text-[18px] flex items-center">
        Total amount funded
      </p>
      <div className="flex items-center">
        <img src={naira} alt="naira" />
        {!viewBalance ? (
          <p className="text-[#1E40AF] text-[22px] font-semibold">
            {isPending ? <span>****</span> : Number(details).toLocaleString()}
          </p>
        ) : (
          <p className="text-[#1E40AF] text-[22px] font-semibold">****</p>
        )}
      </div>
     
    </div>
  );
}
