import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { settingsTab } from "../Human Resources/utils/states";
import PayrollArchive from "./settings-page/PayrollArchive";
import TeamAndRoles from "./settings-page/TeamAndRoles";
import Wallet from "./settings-page/Wallet";
import CompanyInfo from "./settings-page/CompanyInfo";
import useUserPermissions from "../../../utils/permissions";
import LineTab from "../../dashboard-component/controls/LineTab";

const StyledTab = styled(Tabs)`
  margin-bottom: 4px;
  .MuiButtonBase-root {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #475569 !important;
    text-transform: capitalize;
    &:first-child {
      padding-left: 0px !important;
    }
  }
  .Mui-selected {
    color: rgb(62, 84, 211) !important;
  }
  .MuiTabs-indicator {
    background-color: rgb(62, 84, 211) !important;
  }
`;
const Settings = () => {
  const [setTitle] = useOutletContext();
  const [activeTab, setActiveTab] = useRecoilState(settingsTab);
  const { viewRolePermission, viewTeamPermission } = useUserPermissions();

  useEffect(() => setTitle("Settings"));
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div className="px-4 lg:px-9 py-6">
      {/* <StyledTab
        className="tab"
        value={activeTab}
        variant="scrollable"
        aria-label="basic tabs example"
        allowScrollButtonsMobile
      >
        {[
          "Company Info",
          "Payroll archive",
          ...(viewRolePermission && viewTeamPermission ? ["Team & Roles"] : []),
          "Wallet",
        ]?.map((item, index) => {
          return (
            <Tab
              key={index}
              label={item}
              className="text-sm capitalize"
              onClick={() => setActiveTab(item)}
            />
          );
        })}
      </StyledTab> */}
      <LineTab
        activeTab={activeTab}
        data={[
          {
            name: "Company Info",
            id: "info",
            action: () => setActiveTab("info"),
          },
          {
            name: "Payroll archive",
            id: "archive",
            action: () => setActiveTab("archive"),
          },
          ...(viewRolePermission && viewTeamPermission
            ? [
                {
                  name: "Team members",
                  id: "team",
                  action: () => setActiveTab("team"),
                },
              ]
            : []),
          {
            name: "Wallet",
            id: "wallet",
            action: () => setActiveTab("wallet"),
          },
        ]}
      />

      <div className="mt-8 flex justify-center">
        {activeTab === "info" ? (
          <CompanyInfo />
        ) : activeTab === "archive" ? (
          <PayrollArchive />
        ) : activeTab === "team" ? (
          <TeamAndRoles />
        ) : activeTab === "wallet" ? (
          <Wallet />
        ) : null}
      </div>
    </div>
  );
};

export default Settings;
