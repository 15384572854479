import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { sendVerficationLink } from "../../../store/slices/userSlice";
import SignUpLayout from "../../layout/SignUpLayout";

const AccountCreateProcess = () => {
  const dispatch = useDispatch();
  const tenantData = JSON.parse(localStorage.getItem("tenant_data")) ?? {};
  const [emailChange, setEmailChange] = useState("");
  const [time, setTime] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [isChangeEmailModal, setIsChangeEmailModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const handleChangeEmailModal = () => {
    setIsChangeEmailModal(!isChangeEmailModal);
  };
  const handleEmailChange = (e) => {
    setEmailChange(e.target.value);
  };
  const submitEmailChange = (e) => {
    e.preventDefault();
    setIsChangeEmailModal(!isChangeEmailModal);
  };
  useEffect(() => {
    let interval;

    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [time, isActive]);

  const toggleTimer = () => {
    if (time === 0) {
      // Reset the timer when it reaches zero
      setTime(5);
      setIsActive(false);
    } else {
      setIsActive(!isActive);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };


  const SendVerificationLink = async () => {
    const TOAST_ID = toast.loading("Resend Link, please wait...");
    setLoading(true)
    const body = {
      email: tenantData?.email,
    };
    const res = await dispatch(sendVerficationLink(body));
    setLoading(false)
    if (res.payload.status < 400) {
      toast.update(TOAST_ID, {
        render: "Please check your email",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });


    } else {
      toast.update(TOAST_ID, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <SignUpLayout>
        <div className="flx hidden w-full h-full justify-center items-center">
          <img src="/signup-icons/loading.svg" alt="processing" />
        </div>
        <div className="relative only:w-full h-full flex flex-col justify-center items-center">
          <img
            src="/signup-icons/mark_email_unread.svg"
            alt="unread email"
            className="w-[64px] h-[64px]"
          />
          <div className="text-[#64748B] font-[600] text-center mt-[12px] mb-[32px]">
            <p className="text-[22px]">
              We have sent a confirmation link to your email
            </p>
            <p className="text-[14px] text-gray-400">{tenantData?.email}</p>
            <p>Please go to your email to click on the link</p>
          </div>
          <div className="flex justify-center items-center gap-x-[6px] font-[500]">
            <p className="text-[14px] text-[#2563EB]">Didn’t get the link?</p>
            <button
              onClick={SendVerificationLink}
              disabled={loading}
              className="text-[12px] text-[#2563EB] p-[8px] border-[2px] border-[#2563EB] rounded-[4px] btn-disabled"
            >
              Resend
            </button>
            {/* <Link to="/auth/email-confirmed">
              <button className="text-[12px] text-[#2563EB] p-[8px] border-[2px] border-[#2563EB] rounded-[4px] btn-disabled">
                Proceed
              </button>
            </Link> */}
            {isActive && time > 0 && (
              <p className="text-[12px]">{formatTime(time)}</p>
            )}
          </div>
          <div className="absolute bottom-[54px] right-[18px]">
            <div className="text-[12px] flex gap-x-[4px] font-[500]">
              <p>Not your email? </p>
              <button
                onClick={handleChangeEmailModal}
                className="text-[#2563EB]"
              >
                Change my email
              </button>
            </div>
          </div>
        </div>
      </SignUpLayout>

      {isChangeEmailModal && (
        <div className="bg-gray-500 bg-opacity-10 fixed top-0 right-0 left-0 bottom-0 z-[1000000000000]">
          <div className="flex justify-center items-center h-screen">
            <div className=" w-[537px] h-[330px] bg-white rounded-[4px] modalShadow px-[50px] pt-[35px] pb-[80px  ]">
              <div className=" mb-[68px] flex justify-between items-center">
                <h2 className="text-[18px] font-[700]">
                  Change my sign up email
                </h2>
                <button>
                  <img
                    src="/signup-icons/clear.svg"
                    alt="clear"
                    className="w-[32px] h-[32px]"
                    onClick={handleChangeEmailModal}
                  />
                </button>
              </div>
              <form onSubmit={submitEmailChange} method="POST">
                <input
                  type="email"
                  value={emailChange}
                  placeholder="Enter new business email"
                  onChange={handleEmailChange}
                  name="email"
                  className="w-[432px] h-[48px] px-[16px] py-[8px] border-[2px] rounded-[4px] focus:outline-none emailChangeModal"
                  required={true}
                />
                <button
                  type="submit"
                  className="bg-[#2563EB] mt-[24px] rounded-[4px] text-white w-[432px] h-[48px] flex justify-center items-center"
                >
                  Change
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountCreateProcess;
