import { useFormik, FormikProvider, FieldArray } from "formik";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateCustomPosition from "../../authPages/modals/CreateCustomPosition";
import {
  CreateOrganizationalStructure,
  getLevelNames,
  getPositions,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateCustomLevel from "../../authPages/modals/CreateCustomLevel";
import { positionsFormSchema } from "../../authPages/schema";
import Select, { components } from "react-select";

const OrganizationalStructure = ({ setStep }) => {
  const navigate = useNavigate();
  const [jobPositionModal, setJobPositionModal] = useState(false);
  const [jobLevelModal, setJobLevelModal] = useState(false);
  const [fetch, setFetch] = useState(false);

  // redux
  const dispatch = useDispatch();
  const { allPositions, allLevelNames } = useSelector((state) => state?.users);

  const onSubmit = async (values, actions) => {
    const toastId = toast.loading("Creating Structure, please wait...");

    const data = values?.positions?.map((value) => {
      return {
        position_id: value.position_id?.value,
        position_level_name_id: value.position_level_name_id?.value,
        reporters:
          value.reporters > 0
            ? value.reporters?.map((report) => report.value)
            : [],
      };
    });
    const body = {
      positions: data,
    };
    const res = await dispatch(CreateOrganizationalStructure(body));
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render:
          res?.payload.data?.message ||
          "Structure Created Sucessfully added successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm();
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } else {
      toast.update(toastId, {
        render:
          res?.response?.data?.message ||
          res?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const GetPositions = async () => {
    setFetch(true);
    const res = await dispatch(getPositions());
    setFetch(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const GetLevelNames = async () => {
    setFetch(true);
    const res = await dispatch(getLevelNames());
    setFetch(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  // UseEffect
  useEffect(() => {
    GetPositions();
    GetLevelNames();
  }, []);

  const formik = useFormik({
    initialValues: {
      positions: [
        {
          position_id: "",
          reporters: "",
          position_level_name_id: "",
        },
      ],
    },
    onSubmit,
    validationSchema: positionsFormSchema,
  });

  const jobPositionOptions = allPositions?.map((position) => {
    return {
      label: position.name,
      value: position.id,
    };
  });

  const jobLevelOptions = allLevelNames?.map((level) => {
    return {
      label: level?.name,
      value: level?.id,
    };
  });
  const RoleMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => setJobPositionModal(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add custom job position</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const LevelMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => setJobLevelModal(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add custom level</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  return (
    <div className="mt-12 xl:w-[90%] 2xl:w-[70%] mx-auto">
      <div className="mb-1">
        <button
          className="flex gap-1 items-center ml-auto"
          onClick={() => navigate("/dashboard")}
        >
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <path
                d="M0 6L4.25 3L0 0V6ZM1 1.93L2.515 3L1 4.07V1.93ZM5 0H6V6H5V0Z"
                fill="#2563EB"
              />
            </svg>
          </span>
          <span
            className="text-[#2563EB] text-sm tracking-[0.1px] font-medium"
            onClick={() => navigate("/dashboard")}
          >
            Skip this step
          </span>
        </button>
      </div>
      <div className="onboarding-container-box-two-header mb-8">
        <h6 className="text-[#475569] text-base tracking-[0.15px] font-semibold">
          Organizational Structure
        </h6>
        <p className="font-medium text-xs tracking-[0.5px] text-[#475569]">
          Add positions and levels
        </p>
      </div>

      <div className="onboarding-container-box-two-body pb-14">
        <FormikProvider value={formik}>
          <form className="row" onSubmit={formik.handleSubmit}>
            <FieldArray
              name="positions"
              render={(arrayHelpers) => {
                return (
                  <div>
                    <div className="gap-x-3 h-[400px] overflow-x-hidden overflow-y-scroll">
                      {formik.values.positions &&
                        formik.values.positions?.length !== 0 &&
                        formik.values.positions?.map((branch, i) => (
                          <div className=" flex-grow border-b-2">
                            <div className="mb-5 mt-6">
                              <div className={`input-select-box`}>
                                <Select
                                  value={formik.values.positions[i].position_id}
                                  onChange={(notice_period) =>
                                    formik.setFieldValue(
                                      `positions[${i}].position_id`,
                                      notice_period
                                    )
                                  }
                                  fetchingData={fetch}
                                  options={jobPositionOptions}
                                  components={{
                                    Menu: RoleMenu,
                                    Option,
                                  }}
                                  placeholder="Select Position"
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      minHeight: "48px",
                                      fontSize: "16px",
                                    }),
                                  }}
                                />
                              </div>
                              <div>
                                {Object?.keys(formik.errors)?.length > 0 &&
                                  Object?.keys(formik.touched)?.length > 0 &&
                                  formik.errors.positions[i].position_id &&
                                  formik.touched.positions[i].position_id && (
                                    <p className="error">
                                      {
                                        formik.errors.positions[i].position_id
                                          .label
                                      }
                                    </p>
                                  )}
                              </div>
                            </div>

                            {jobPositionOptions?.length > 1 && (
                              <div className=" mb-5 mt-6">
                                <div className={`input-box`}>
                                  <Select
                                    value={formik.values.positions[i].reporters}
                                    onChange={(notice_period) =>
                                      formik.setFieldValue(
                                        `positions[${i}].reporters`,
                                        notice_period
                                      )
                                    }
                                    fetchingData={fetch}
                                    options={jobPositionOptions?.filter(
                                      (option) =>
                                        option.value !==
                                        formik.values.positions[i].position_id
                                          ?.value
                                    )}
                                    isMulti
                                    placeholder="Select direct reports"
                                    styles={{
                                      control: (base) => ({
                                        ...base,
                                        minHeight: "48px",
                                        fontSize: "16px",
                                      }),
                                    }}
                                  />
                                </div>

                                <div>
                                  {Object?.keys(formik.errors)?.length > 0 &&
                                    Object?.keys(formik.touched)?.length > 0 &&
                                    formik?.errors?.positions[i]?.reporters &&
                                    formik?.touched?.positions[i]
                                      ?.reporters && (
                                      <p className="error">
                                        {
                                          formik.errors.positions[i].reporters
                                            ?.label
                                        }
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}

                            <div className="mb-5 mt-6">
                              <div className={`input-select-box`}>
                                <Select
                                  value={
                                    formik.values.positions[i]
                                      .position_level_name_id
                                  }
                                  onChange={(notice_period) => {
                                    formik.setFieldValue(
                                      `positions[${i}].position_level_name_id`,
                                      notice_period
                                    );
                                  }}
                                  fetchingData={fetch}
                                  options={jobLevelOptions}
                                  components={{
                                    Menu: LevelMenu,
                                    Option,
                                  }}
                                  placeholder="Select Level"
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      minHeight: "48px",
                                      fontSize: "16px",
                                    }),
                                  }}
                                />
                              </div>
                              <div>
                                {Object?.keys(formik.errors)?.length > 0 &&
                                  Object?.keys(formik.touched)?.length > 0 &&
                                  formik.errors.positions[i]
                                    .position_level_name_id &&
                                  formik.touched.positions[i]
                                    .position_level_name_id && (
                                    <p className="error">
                                      {
                                        formik.errors.positions[i]
                                          .position_level_name_id.label
                                      }
                                    </p>
                                  )}
                              </div>
                              <div className="flex justify-end mt-2">
                                {i > 0 && (
                                  <button
                                    onClick={() => arrayHelpers.remove(i)}
                                    type="button"
                                    className="text-[red]"
                                  >
                                    Remove Job Position
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div>
                      <button
                        className="bg-[#fff] text-[#2563EB] text-sm tracking-[0.1px] py-[10px] min-w-[150px] font-bold flex gap-1 items-center"
                        type="button"
                        style={{
                          border: "none",
                          borderRadius: "4px",
                          outline: "none",
                        }}
                        onClick={() =>
                          arrayHelpers.push({
                            position_id: "",
                            reporters: "",
                            position_level_name_id: "",
                          })
                        }
                      >
                        <span className="text-[20px]">+</span>
                        Add another job position
                      </button>

                      <p className="mt-6 text-[#64748B] text-[11px] leading-[145%] tracking-[0.5px] font-medium">
                        You can add, remove & edit organizational structure in
                        your organization setting
                      </p>
                    </div>

                    <div className="onboarding-container-main-box-body-footer min-h-[50px] pl-8 xl:pl-14 lg:pr-[105px] py-[18px]">
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="next-btn bg-[#F1F5F9] text-[#334155] p-[11px] min-w-[150px]"
                          onClick={() => setStep(4)}
                        >
                          Previous
                        </button>
                        <button
                          disabled={formik.isSubmitting}
                          type="submit"
                          className="next-btn bg-[#2563EB] text-white p-[11px] min-w-[250px]"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </form>
        </FormikProvider>
      </div>
      <CreateCustomPosition
        modalOpen={jobPositionModal}
        onClose={() => setJobPositionModal(false)}
        size="sm"
      />
      <CreateCustomLevel
        modalOpen={jobLevelModal}
        onClose={() => setJobLevelModal(false)}
        size="sm"
      />
    </div>
  );
};

export default OrganizationalStructure;
