import React from "react";
import { useSelector } from "react-redux";
import payrollIcon from "./../../../../asset/svgs/payroll.svg";
import peopleIcon from "./../../../../asset/svgs/people.svg";
import calendarIcon from "./../../../../asset/svgs/calendar.svg";
import InfoAlert from "../../../dashboard-component/alerts/infoAlert";
import Loader from "../../../../component/atoms/Loader";
import { TagPicker } from "rsuite";
import styled from "styled-components";
import { colors } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../utils/https";
import { useNavigate } from "react-router-dom";
import { formatDateWithDay } from "../../../../utils";
import { useRecoilState } from "recoil";
import { selectedBranchesPersist } from "../utils/states";
import useUserPermissions from "../../../../utils/permissions";

const StyledPicker = styled(TagPicker)`
  height: 48px;
  background: rgba(0, 0, 0, 0);
  .rs-picker-toggle {
    background: rgba(0, 0, 0, 0) !important;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .rs-picker-tag-wrapper {
    height: 48px;
    align-items: center;
    display: flex;
  }
  .rs-picker-toggle-caret,
  .rs-picker-toggle-clean {
    top: 13px !important;
  }
`;
export default function RunPayroll() {
  //states
  const [selectedBranches, setSelectedBranches] = useRecoilState(
    selectedBranchesPersist
  );
  const navigate = useNavigate();
  const { allBranches } = useSelector((state) => state.users);
  const { processPayrollPermission } = useUserPermissions();

  //queries
  const { data: branchPayroll, isPending } = useQuery({
    queryKey: ["branchesPayrollRecord"],
    queryFn: async () => {
      const response = await axiosInstance().get(`branches/payroll-records`);
      return response?.data?.data?.results;
    },
  });

  //datas
  const filteredBranches = branchPayroll?.filter((branch) =>
    selectedBranches?.includes(branch.name)
  );

  // functions

  return (
    <>
      {isPending ? (
        <div className="flex justify-center items-center mt-[10rem]">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="mt-11">
            <p className="text-[#1E293B] font-semibold text-[22px] mb-6">
              Process pay run.
            </p>

            <StyledPicker
              data={allBranches?.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              onChange={(value) => {
                setSelectedBranches(value);
              }}
              defaultValue={selectedBranches}
              style={{ width: "100%", maxWidth: "688px" }}
              placeholder="Select one or more branch to run payroll for"
            />
          </div>
          <div className="mt-6 gap-6 flex flex-col">
            {filteredBranches?.map((item, index) => (
              <div
                key={index}
                className="p-6 pr-2 lg:pr-[52px] bg-[#FFFFFF] rounded-lg rounded-tr-none rounded-br-none border-r-4"
                style={{ borderRightColor: colors[index] }}
              >
                <div className="flex items-center gap-2 mb-2">
                  <p
                    className="uppercase font-semibold"
                    style={{ color: colors[index] }}
                  >
                    {item.name}
                  </p>
                  {item?.current_month_payroll_record_status === "pending" ? (
                    <div className="bg-[#FFFBEB] rounded py-[2px] px-2 flex gap-2 items-center">
                      <span className="text-[#D97706] capitalize font-bold">
                        {item?.current_month_payroll_record_status}
                      </span>
                    </div>
                  ) : item?.current_month_payroll_record_status ===
                    "approved" ? (
                    <div className="bg-[#EFF6FF] rounded py-[2px] px-2 flex gap-2 items-center">
                      <span className="text-[#2563EB] capitalize font-bold">
                        {item?.current_month_payroll_record_status}
                      </span>
                    </div>
                  ) : item?.current_month_payroll_record_status ===
                    "declined" ? (
                    <div className="bg-[#e11d472d] rounded py-[2px] px-2 flex gap-2 items-center">
                      <span className="text-[#E11D48] capitalize font-bold">
                        {item?.current_month_payroll_record_status}
                      </span>
                    </div>
                  ) : item?.current_month_payroll_record_status ===
                    "partially_paid" ? (
                    <div className="bg-[#FFFBEB] rounded py-[2px] px-2 flex gap-2 items-center">
                      <span className="text-[#D97706] capitalize font-bold">
                        {item?.current_month_payroll_record_status}
                      </span>
                    </div>
                  ) : item?.current_month_payroll_record_status === "paid" ? (
                    <div className="bg-[#ECFDF5] rounded py-[2px] px-2 flex gap-2 items-center">
                      <span className="text-[#059669] capitalize font-bold">
                        {item?.current_month_payroll_record_status}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="flex items-start justify-between gap-4">
                  <div className="flex flex-col lg:flex-row">
                    <div className="border-[1px] border-[#E2E8F0] rounded-sm p-4 flex gap-3 items-start min-w-[224px]">
                      <img src={payrollIcon} alt="icon" />
                      <div>
                        <p className="text-sm font-normal">Total Payroll</p>
                        {item.is_payroll_processed_for_current_month ? (
                          <p className="flex items-center gap-1">
                            <svg
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.333333 4.94444H1.81481V0.5H3.2963L5.82963 4.94444H9.22222V0.5H10.7037V4.94444H12.1852V6.42593H10.7037V7.90741H12.1852V9.38889H10.7037V13.8333H9.22222L6.68148 9.38889H3.2963V13.8333H1.81481V9.38889H0.333333V7.90741H1.81481V6.42593H0.333333V4.94444ZM3.2963 4.94444H4.13333L3.2963 3.48519V4.94444ZM3.2963 6.42593V7.90741H5.82963L4.98518 6.42593H3.2963ZM9.22222 10.8704V9.38889H8.37037L9.22222 10.8704ZM6.67407 6.42593L7.52593 7.90741H9.22222V6.42593H6.67407Z"
                                fill="#475569"
                              />
                            </svg>
                            <span className="text-[#475569] text-[16px] font-semibold ">
                              {Number(
                                item.total_amount_processed
                              ).toLocaleString()}
                            </span>
                          </p>
                        ) : (
                          <p className="text-sm font-bold bg-[#F8FAFC] rounded-[4px] mt-1 flex items-center">
                            <span className="block bg-[#475569] w-2 h-2 rounded-full mr-2"></span>
                            Not yet processed
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="border-[1px] border-[#E2E8F0] rounded-sm p-4 flex gap-3 items-start min-w-[224px]">
                      <img src={peopleIcon} alt="icon" />
                      <div>
                        <p className="text-sm font-normal">Total Branch Employee</p>
                        <p className="text-[16px] font-semibold mt-1">
                          {item.total_number_of_employees}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="border-[1px] border-[#E2E8F0] rounded-sm p-4 flex gap-3 items-start min-w-[224px]">
                      <img src={calendarIcon} alt="icon" />
                      <div>
                        <p className="text-sm font-normal">
                          Payroll payment Date
                        </p>
                        <p className="text-[16px] font-semibold mt-1">
                          {item.payroll_payment_date ? (
                            <p className="flex items-center gap-1">
                              <span className="text-[#475569] text-[16px] font-semibold ">
                                {formatDateWithDay(item.payroll_payment_date)}
                              </span>
                            </p>
                          ) : (
                            <p className="text-sm font-bold bg-[#F8FAFC] rounded-[4px] mt-1 flex items-center">
                              <span className="block bg-[#475569] w-2 h-2 rounded-full mr-2"></span>
                              Not yet processed
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  {item.is_payroll_processed_for_current_month ? (
                    <button
                      className="bg-[#2563EB] rounded p-[10px] h-[48px] flex items-center"
                      onClick={() =>
                        navigate(
                          `/dashboard/payroll/view-payroll/${item.current_month_payroll_record_id}`
                        )
                      }
                    >
                      <p className="text-[#FFFFFF] text-sm font-bold">
                        View Details
                      </p>
                    </button>
                  ) : (
                    processPayrollPermission && (
                      <button
                        className="bg-[#2563EB] rounded p-[10px] h-[48px] flex items-center"
                        onClick={() =>
                          navigate(
                            `/dashboard/payroll/runpayroll/${item.name}/${item.id}`
                          )
                        }
                      >
                        <p className="text-[#FFFFFF] text-sm font-bold">
                          Run Payroll
                        </p>
                      </button>
                    )
                  )}
                </div>
                {/* <InfoAlert text="Please ensure your run the payroll for this month before [Month, Year] " /> */}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
