import React from "react";
import MainModal from "../../../../../component/MainModal.";
import { useFormik } from "formik";
import * as yup from "yup";
import { AddTenantBranches } from "../../../../../store/slices/userSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const NewBranchModal = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const toastId = toast.loading("Creating branch, please wait...");
    const res = await dispatch(AddTenantBranches([values]));
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render: res?.payload.data?.message || "Branch added successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      onClose();
    } else {
      toast.update(toastId, {
        render:
          res?.payload?.data?.message || res?.message || "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      address: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Branch Name is required"),
      address: yup.string().required("Branch Address is Required"),
    }),
    onSubmit,
  });
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Add Branch"
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          <p className="mb-3">Branch Info</p>
          <form onSubmit={handleSubmit}>
            <div
              className={`input-box ${
                !errors.username && touched.name && values.name
                  ? "filled"
                  : errors.name && touched.name
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="name"
                placeholder="Branch Name"
                autoComplete="off"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div>
                {errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}
              </div>
            </div>
            <div
              className={`mt-5 input-box ${
                !errors.address && touched.address && values.address
                  ? "filled"
                  : errors.address && touched.address
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="address"
                placeholder="Branch Address"
                autoComplete="off"
                id="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div>
                {errors.address && touched.address && (
                  <p className="error">{errors.address}</p>
                )}
              </div>
            </div>
            <div className="upload-from-csv-box-three mt-8">
              <button type="submit" disabled={isSubmitting}>
                Add Branch
              </button>
            </div>
          </form>
        </div>
      </MainModal>
    </>
  );
};

export default NewBranchModal;
