import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/https";

const initialState = {
  payrollSetup: null,
  payrollBreakdown: null,
  allEmployees: [],
  allPayslips: [],
  salaryComponent: [],
  deductions: [],
  deductionNames: [],
  benefits: [],
  benefitNames: [],
  bonuses: [],
  bonusesNames: [],
  status: {
    fetching: "",
    deleting: "",
    creating: "",
    deactivating: "",
    message: "",
  },
  error: { fetching: "", deleting: "" },
};

export const GetPayrollSetup = createAsyncThunk(
  "payrollSetup/GetPayrollSetup",
  async () => {
    try {
      const res = await axiosInstance().get(`/payroll/view-setup`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const PayrollSetup = createAsyncThunk(
  "payrollSetup/PayrollSetup",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`/payroll/setup`, payload);
      dispatch(GetPayrollSetup());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const UpdatePayrollSetup = createAsyncThunk(
  "payrollSetup/UpdatePayrollSetup",
  async (payload, { dispatch }) => {
    const payroll_id = payload.id;
    const body = {
      payday: payload?.payday,
      custom_payday_day: payload?.custom_payday_day,
      work_days_for_payment: payload?.activeDay,
      total_number_of_work_days: payload?.total_number_of_work_days,
      overtime_policy: payload?.overtime_policy,
      leave_policy: payload?.leave_policy,
      pension_computation: payload?.pension_computation,
      work_days_for_payment: payload?.work_days_for_payment,
    };
    try {
      const res = await axiosInstance().put(
        `/payroll/${payroll_id}/edit-setup`,
        body
      );
      dispatch(GetPayrollSetup());
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const ActivatePayrollSetup = createAsyncThunk(
  "payrollSetup/ActivatePayrollSetup",
  async (pyrl_id) => {
    try {
      const res = await axiosInstance().patch(`/payroll/${pyrl_id}/activate`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateComponent = createAsyncThunk(
  "payrollSetup/CreateComponent",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(
        `/payroll/add-custom-salary-component`,
        payload
      );
      dispatch(GetPayrollSetup());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const UpdateComponent = createAsyncThunk(
  "payrollSetup/UpdateComponent",
  async (payload, { dispatch }) => {
    const component_id = payload?.component_id;
    const body = {
      name: payload?.name,
      percentage: payload?.percentage,
      payroll_id: payload?.id,
      is_active: true,
    };
    try {
      const res = await axiosInstance().put(
        `/payroll/salary-component/${component_id}/edit`,
        body
      );
      dispatch(GetPayrollSetup());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateReliefComponent = createAsyncThunk(
  "payrollSetup/CreateReliefComponent",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(
        `/payroll/update-relief-components`,
        payload
      );
      dispatch(GetPayrollSetup());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateMandatoryRemittanceComponent = createAsyncThunk(
  "payrollSetup/CreateMandatoryRemittanceComponent",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(
        `/payroll/update-mandatory-remittance-components`,
        payload
      );
      dispatch(GetPayrollSetup());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetAllEmployees = createAsyncThunk(
  "allEmployees/GetAllEmployees",
  async () => {
    try {
      const res = await axiosInstance().get(`/users/employees/`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateEmployees = createAsyncThunk(
  "allEmployees/CreateEmployees",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(
        `/users/employees/add-payroll-employee`,
        payload
      );
      dispatch(GetAllEmployees());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const VerifyAcountNumber = createAsyncThunk(
  "payrollSetup/VerifyAcountNumber",
  async (payload) => {
    try {
      const res = await axiosInstance().post(
        `/users/employees/confirm-account-detail`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetBreakdown = createAsyncThunk(
  "payrollBreakdown/GetBreakdown",
  async (payload) => {
    try {
      const res = await axiosInstance().get(
        `/payroll/get-breakdown?amount=${payload.amount}&payroll_id=${payload?.payroll_id}&calculate_nhf=${payload?.nhf}`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetAllPayslip = createAsyncThunk(
  "allPayslips/GetAllPayslip",
  async () => {
    try {
      const res = await axiosInstance().get(`/payslips/history`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const EditEmployee = createAsyncThunk(
  "allEmployees/EditEmployee",
  async (payload, { dispatch }) => {
    const body = {
      first_name: payload?.first_name,
      last_name: payload?.last_name,
      middle_name: payload?.middle_name,
      date_of_birth: payload?.date_of_birth,
      designation: payload?.designation,
      bank_code: payload?.bank_code,
      resumption: payload?.resumption,
      branch: payload?.branch,
      monthly_gross_salary: payload?.monthly_gross_salary,
      has_nhf: payload?.has_nhf,
      account_number: payload?.account_number,
      level: payload?.level,
      department: payload?.department,
      email: payload?.email,
      user_id: payload?.user_id,
    };
    try {
      const res = await axiosInstance().put(
        `/users/employees/${payload?.user_id}/edit-payroll-employee`,
        body
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const DeleteEmployee = createAsyncThunk(
  "allEmployees/DeleteEmployee",
  async (profile_id, { dispatch }) => {
    try {
      const res = await axiosInstance().delete(
        `/users/employees/${profile_id}/delete-employee`
      );
      dispatch(GetAllEmployees());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetSalaryComponent = createAsyncThunk(
  "salaryComponent/GetSalaryComponent",
  async (payroll_setup_id) => {
    try {
      const res = await axiosInstance().get(
        `/payroll/${payroll_setup_id}/salary-components`
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const GetDeductions = createAsyncThunk(
  "deductions/GetDeductions",
  async () => {
    try {
      const res = await axiosInstance().get("/deductions/");
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const CreateDeductionName = createAsyncThunk(
  "deductionNames/CreateDeductionName",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post("/deductions/names/add", payload);
      dispatch(GetDeductionNames());
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const GetDeductionNames = createAsyncThunk(
  "deductionNames/GetDeductionNames",
  async () => {
    try {
      const res = await axiosInstance().get("/deductions/names?paginate=false");
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const CreateDeduction = createAsyncThunk(
  "deductions/CreateDeduction",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post("/deductions/add", payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const UpdateDeduction = createAsyncThunk(
  "deductions/UpdateDeduction",
  async ({ payload, deduction_id }, { dispatch }) => {
    try {
      const res = await axiosInstance().put(
        `/deductions/${deduction_id}/edit`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const DeleteDeduction = createAsyncThunk(
  "deductions/DeleteDeduction",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().delete(`/deductions/delete`, payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetBenefits = createAsyncThunk(
  "benefits/GetBenefits",
  async () => {
    try {
      const res = await axiosInstance().get("/benefits/");
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateBenefitName = createAsyncThunk(
  "benefitNames/CreateBenefitName",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post("/benefits/names/add", payload);
      dispatch(GetBenefitNames());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetBenefitNames = createAsyncThunk(
  "benefitNames/GetBenefitNames",
  async () => {
    try {
      const res = await axiosInstance().get("/benefits/names?paginate=false");
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateBenefit = createAsyncThunk(
  "benefits/CreateBenefit",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post("/benefits/add", payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const DeleteBenefit = createAsyncThunk(
  "benefits/DeleteBenefit",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().delete(`/benefits/delete`, payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const UpdateBenefit = createAsyncThunk(
  "benefits/UpdateBenefit",
  async ({ payload, benefit_id }, { dispatch }) => {
    try {
      const res = await axiosInstance().put(
        `/benefits/${benefit_id}/edit`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const GetBonuses = createAsyncThunk("bonuses/GetBonuses", async () => {
  try {
    const res = await axiosInstance().get("/bonuses/");
    return res;
  } catch (err) {
    return err;
  }
});
export const CreateBonusName = createAsyncThunk(
  "bonusNames/CreateBonusName",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post("/bonuses/names/add", payload);
      dispatch(GetBonusNames());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetBonusNames = createAsyncThunk(
  "bonusNames/GetBonusNames",
  async () => {
    try {
      const res = await axiosInstance().get("/bonuses/names?paginate=false");
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const CreateBonus = createAsyncThunk(
  "bonuses/CreateBonus",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post("/bonuses/add", payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const UpdateBonus = createAsyncThunk(
  "bonuses/UpdateBonus",
  async ({ payload, bonus_id }, { dispatch }) => {
    try {
      const res = await axiosInstance().put(
        `/bonuses/${bonus_id}/edit`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const DeleteBonus = createAsyncThunk(
  "bonuses/DeleteBonus",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().delete(`/bonuses/delete`, payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);
export const payrollSlice = createSlice({
  name: "payrollSlice",
  initialState,
  reducers: {
    logout1: (state) => {
      state.payrollSetup = null;
      state.payrollBreakdown = null;
      state.allEmployees = [];
      state.allPayslips = [];
      state.salaryComponent = [];
      state.deductions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PayrollSetup.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(PayrollSetup.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(PayrollSetup.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetPayrollSetup.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetPayrollSetup.fulfilled, (state, action) => {
        state.payrollSetup = action?.payload?.data?.data;
        state.status.fetching = "succeeded";
      })
      .addCase(GetPayrollSetup.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(UpdatePayrollSetup.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(UpdatePayrollSetup.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(UpdatePayrollSetup.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateComponent.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateComponent.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateComponent.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(UpdateComponent.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(UpdateComponent.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(UpdateComponent.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateReliefComponent.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateReliefComponent.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateReliefComponent.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateMandatoryRemittanceComponent.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateMandatoryRemittanceComponent.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateMandatoryRemittanceComponent.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateEmployees.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateEmployees.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateEmployees.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetAllEmployees.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetAllEmployees.fulfilled, (state, action) => {
        state.allEmployees = action?.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(GetAllEmployees.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(VerifyAcountNumber.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(VerifyAcountNumber.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(VerifyAcountNumber.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetBreakdown.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetBreakdown.fulfilled, (state, action) => {
        state.payrollBreakdown = action?.payload?.data?.data;
        state.status.fetching = "succeeded";
      })
      .addCase(GetBreakdown.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetAllPayslip.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetAllPayslip.fulfilled, (state, action) => {
        state.allPayslips = action?.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(GetAllPayslip.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(EditEmployee.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(EditEmployee.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(EditEmployee.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(DeleteEmployee.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(DeleteEmployee.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(DeleteEmployee.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetSalaryComponent.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetSalaryComponent.fulfilled, (state, action) => {
        state.salaryComponent = action?.payload?.data?.data;
        state.status.creating = "succeeded";
      })
      .addCase(GetSalaryComponent.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetDeductions.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetDeductions.fulfilled, (state, action) => {
        state.deductions = action?.payload?.data?.data?.results;
        state.status.creating = "succeeded";
      })
      .addCase(GetDeductions.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetDeductionNames.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetDeductionNames.fulfilled, (state, action) => {
        state.deductionNames = action?.payload?.data?.data;
        state.status.creating = "succeeded";
      })
      .addCase(GetDeductionNames.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateDeductionName.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateDeductionName.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateDeductionName.rejected, (state, action) => {
        state.status.fetching = "failed";
      })
      .addCase(CreateDeduction.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateDeduction.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateDeduction.rejected, (state, action) => {
        state.status.fetching = "failed";
      })
      .addCase(UpdateDeduction.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(UpdateDeduction.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
      })
      .addCase(UpdateDeduction.rejected, (state, action) => {
        state.status.fetching = "failed";
      })
      .addCase(GetBenefits.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetBenefits.fulfilled, (state, action) => {
        state.benefits = action?.payload?.data?.data?.results;
        state.status.creating = "succeeded";
      })
      .addCase(GetBenefits.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetBenefitNames.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetBenefitNames.fulfilled, (state, action) => {
        state.benefitNames = action?.payload?.data?.data;
        state.status.creating = "succeeded";
      })
      .addCase(GetBenefitNames.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateBenefitName.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateBenefitName.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateBenefitName.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetBonuses.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetBonuses.fulfilled, (state, action) => {
        state.bonuses = action?.payload?.data?.data?.results;
        state.status.creating = "succeeded";
      })
      .addCase(GetBonuses.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(GetBonusNames.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetBonusNames.fulfilled, (state, action) => {
        state.bonusesNames = action?.payload?.data?.data;
        state.status.creating = "succeeded";
      })
      .addCase(GetBonusNames.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(CreateBonusName.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateBonusName.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateBonusName.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      });
  },
});

export const { logout1 } = payrollSlice.actions;

export default payrollSlice.reducer;
