import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import MainModal from "../auth-component/MainModal";
import { toast } from "react-toastify";
import { createLevelName, getLevelNames } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../utils/https";

const CreateLevelName = ({ modalOpen, onClose, size, addType, nameData }) => {
  const dispatch = useDispatch();

  // Functions
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().put(`/levels/names/${payload?.id}/edit`, {
        name: payload?.label,
      }),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      dispatch(getLevelNames());
      onClose();
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const onSubmit = async (values) => {
    if (addType === "edit") {
      mutation.mutate({
        id: nameData?.value,
        label: values?.name,
      });
    } else {
      const toastId = toast.loading("Creating Level Name, please wait...");
      const res = await dispatch(createLevelName(values));
      if (res.payload.status < 400) {
        toast.update(toastId, {
          render: res?.payload?.data?.message || "Name created successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        onClose();
      } else {
        toast.update(toastId, {
          render: res.payload.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  // fORMIK
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Enter desired level name"),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (addType === "edit") {
      setFieldValue("name", nameData?.label);
    }
  }, [nameData]);
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle={addType === "edit" ? "Edit Level Name" : "Add Level Name"}
      >
        <div className="xl:w-[90%] mx-auto">
          <div className="upload-from-csv">
            <form className="row" onSubmit={handleSubmit}>
              <div className="mt-10">
                <div
                  className={`input-box ${
                    !errors.name && touched.name && values.name
                      ? "filled"
                      : errors.name && touched.name
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder="Level name."
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  {errors.name && touched.name && (
                    <p className="error">{errors.name}</p>
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center w-[30%] mt-10">
                <button
                  className="create_role_cancel_button"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="create_role_save_button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default CreateLevelName;
