/** @format */

// import { useState } from "react";

import "./App.css";
import setBodyColor from "./setBodyColor";

import { Provider } from "react-redux";

import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/AuthContext";
import "rsuite/dist/rsuite.min.css";
import { RouterProvider } from "react-router-dom";

import { store } from "./store/store";

import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import RootRouter from "./routers/RootRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { RecoilRoot } from "recoil";

let persistor = persistStore(store);
const queryClient = new QueryClient();

function App() {
  setBodyColor({ color: "#F8FAFC" });

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RecoilRoot>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              {/* <Routing /> */}
              <RouterProvider router={RootRouter} />
              <ToastContainer />
            </AuthProvider>
          </PersistGate>
        </RecoilRoot>
      </Provider>
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
