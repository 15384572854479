import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styled from "styled-components";

//styles
const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: transparent;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #1e293b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    z-index: 0;
  }
  .error {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  .field_error {
    color: #f43f5e;
    font-size: 11px;
    margin-top: 4px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
`;
export default function PasswordInput({
  errors,
  value,
  touched,
  handleChange,
  handleBlur,
  placeholder,
  id,
}) {
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <StyledInputWrapper>
      <div className="input-password-box">
        <input
          type={viewPassword ? "text" : "password"}
          name={id ? id : "password"}
          autoComplete="off"
          id={id ? id : "password"}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder ? placeholder : "Enter password"}
          className={errors.password && touched.password && "error"}
        />
        <span
          className="view"
          style={{ cursor: "pointer" }}
          onClick={() => setViewPassword(!viewPassword)}
        >
          {viewPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
      {errors.password && touched.password && (
        <p className="field_error">{errors.password}</p>
      )}
    </StyledInputWrapper>
  );
}
