import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreateContactSchema } from "../../authPages/schema";
import { CreateTenantContact } from "../../store/slices/userSlice";

const ContactPersonInformation = ({ setStep }) => {
  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.users);

  // Async function
  const onSubmit = async (values, actions) => {
    if (user?.contact?.first_name) {
      setTimeout(() => {
        setStep(3);
        actions.resetForm();
      }, 1000);
    } else {
      const toastId = toast.loading(
        "Adding contact person information, please wait..."
      );
      const { first_name, last_name, email, phone_number } = values;

      const data = {
        first_name,
        last_name,
        official_email: email,
        phone_number,
        country_code: "+234",
      };

      const res = await dispatch(CreateTenantContact(data));
      if (res?.payload?.status < 400) {
        toast.update(toastId, {
          render:
            res?.payload?.data?.message ||
            "Contact person information added successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });

        setTimeout(() => {
          setStep(3);
          actions.resetForm();
        }, 1200);
      } else {
        toast.update(toastId, {
          render:
            res?.payload?.data?.message ||
            res?.message ||
            "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: user?.contact?.first_name || "",
      last_name: user?.contact?.last_name || "",
      email: user?.contact?.email || "",
      phone_number: user?.contact?.phone_number || "",
    },
    validationSchema: CreateContactSchema,
    onSubmit,
  });
  return (
    <div className="mt-12 xl:w-[90%] 2xl:w-[80%] mx-auto">
      <div className="onboarding-container-box-two-header">
        <h6 className="text-[#475569] text-base tracking-[0.15px] font-semibold">
          Contact person information
        </h6>
        <p className="font-medium text-xs tracking-[0.5px] text-[#475569]">
          Enter information relating to a contact person of your business
        </p>
      </div>

      <div className="onboarding-container-box-two-body pb-14">
        <form className="row" onSubmit={handleSubmit}>
          <div className=" mb-5 mt-6">
            <div
              className={`input-box ${
                !errors.first_name && touched.first_name && values.first_name
                  ? "filled"
                  : errors.first_name && touched.first_name
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="first_name"
                autoComplete="off"
                id="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user?.contact?.first_name}
              />
              {!user?.contact?.first_name && (
                <label htmlFor="first_name" className="label-name">
                  <span className="label-text">First Name </span>
                </label>
              )}
            </div>

            <div>
              {errors.first_name && touched.first_name && (
                <p className="error">{errors.first_name}</p>
              )}
            </div>
          </div>

          <div className=" mb-5 mt-6">
            <div
              className={`input-box ${
                !errors.last_name && touched.last_name && values.last_name
                  ? "filled"
                  : errors.last_name && touched.last_name
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="last_name"
                autoComplete="off"
                id="last_name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user?.contact?.last_name}
              />
              {!user?.contact?.last_name && (
                <label htmlFor="last_name" className="label-name">
                  <span className="label-text">Last Name </span>
                </label>
              )}
            </div>

            <div>
              {errors.last_name && touched.last_name && (
                <p className="error">{errors.last_name}</p>
              )}
            </div>
          </div>

          <div className=" mb-5 mt-6">
            <div
              className={`input-box ${
                !errors.email && touched.email && values.email
                  ? "filled"
                  : errors.email && touched.email
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user?.contact?.email}
              />
              {!user?.contact?.email && (
                <label htmlFor="email" className="label-name">
                  <span className="label-text">Official email address</span>
                </label>
              )}
            </div>
            <div>
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>
          </div>

          <div className=" mb-5 mt-6">
            <div
              className={`input-box ${
                !errors.phone_number &&
                touched.phone_number &&
                values.phone_number
                  ? "filled"
                  : errors.phone_number && touched.phone_number
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="phone_number"
                autoComplete="off"
                id="phone_number"
                value={values.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user?.contact?.phone_number}
              />
              {!user?.contact?.phone_number && (
                <label htmlFor="number" className="label-name">
                  <span className="label-text">Phone number</span>
                </label>
              )}
            </div>

            <div>
              {errors.phone_number && touched.phone_number && (
                <p className="error">{errors.phone_number}</p>
              )}
            </div>
          </div>

          {/* <div className=" mb-5 mt-6">
            <div
              className={`input-box ${
                !errors.role && touched.role && values.role
                  ? "filled"
                  : errors.role && touched.role
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="role"
                autoComplete="off"
                id="role"
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="role" className="label-name">
                <span className="label-text">Role</span>
              </label>
            </div>

            <div>
              {errors.role && touched.role && (
                <p className="error">{errors.role}</p>
              )}
            </div>
          </div> */}

          <div className="onboarding-container-main-box-body-footer min-h-[50px] pl-8 xl:pl-14 lg:pr-[105px] py-[18px]">
            <div className="flex justify-between">
              <button
                type="button"
                className="next-btn bg-[#F1F5F9] text-[#334155] p-[11px] min-w-[150px]"
                onClick={() => setStep(1)}
              >
                Previous
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="next-btn bg-[#2563EB] text-white p-[11px] min-w-[250px]"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPersonInformation;
