import { combineReducers } from "redux";
import userSlice from "./slices/userSlice";
import payrollSlice from "./slices/PayrollSlice";

const rootReducer = combineReducers({
  users: userSlice,
  payroll: payrollSlice,
});

export default rootReducer;
