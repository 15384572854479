import React from "react";

// Basic Loader Component
const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-spinner"></div>
    </div>
  );
};

export default Loader;
