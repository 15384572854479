import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../authPages/layout/AuthLayout";
import { useAuth } from "../context/AuthContext";
import OnboardingStepper from "./onboarding-components/OnboardingStepper";
import AddBranch from "./onboarding-pages/AddBranch";
import BusinessProfileInformation from "./onboarding-pages/BusinessProfileInformation";
import ContactPersonInformation from "./onboarding-pages/ContactPersonInformation";
import CreateDepartment from "./onboarding-pages/CreateDepartment";
import OrganizationalStructure from "./onboarding-pages/OrganizationalStructure";
import "./styles/onboarding-main.css";

const OnboardingLayout = ({ children }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const { user } = useSelector((state) => state?.users);

  useEffect(() => {
    if (user) {
      const find_pending_level = user?.onboarding_levels_completed?.find(
        (level) => level?.completed === false
      );
      setStep(find_pending_level.level);
    }
  }, [user]);

  return (
    <AuthLayout>
      <div className="onboarding-container overflow-x-hidden py-[4rem]">
        <div className="2xl:max-w-[1520px] xl:px-[3rem] py-0 mx-auto md:px-[3rem] sm:px-[2rem] px-0 bg-white lg:bg-[#F1F5F9]">
          <div className="sm:w-[95%] mx-auto onboarding-container-main-box flex flex-col">
            <div className="onboarding-container-main-box-header mb-6 hidden lg:block">
              <h4 className="text-[#475569] text-[22px] leading-[127%] font-semibold">
                Welcome back {user?.name}
              </h4>
              <p className="text-[#475569] text-sm font-normal tracking-[0.25px]">
                While you’re still here, we’d like you to set up your business
                profile
              </p>
            </div>

            <div className="flex flex-col lg:flex-row onboarding-container-main-box-body sm:bg-[#fff] bg-[#F8FAFC] lg:bg-[#fff] flex-grow">
              {/* {isFetching ? (
                <div className="onboarding-container-box-one h-full py-11 px-8 xl:px-14 w-full flex justify-center items-center min-h-[80vh] bg-[#fff]">
                  <Loader />
                </div>
              ) : (
                <> */}
              <div className="lg:w-[45%]  onboarding-container-box-one bg-[#fff] sm:h-full py-3 lg:py-11 px-5 sm:px-8 xl:px-14 flex flex-row lg:flex-col justify-between lg:justify-start">
                <div className="onboarding-container-box-one-header lg:mb-11">
                  <h4 className="text-[#475569] text-[16px] leading-[24px] sm:text-2xl font-bold sm:font-semibold">
                    Setup your business profile
                  </h4>
                  <div className="sm:hidden">
                    <p className="text-[#64748B] text-[12px] font-bold tracking-[0.5px] leading-[16px]">
                      {step === 1
                        ? "Business profile information"
                        : step === 2
                        ? "Contact person information"
                        : step === 3
                        ? "Create Department"
                        : step === 4
                        ? "Add Branch"
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="onboarding-container-box-one-body">
                  <div className="onboarding-step hidden lg:flex-col lg:flex">
                    <OnboardingStepper
                      step={step}
                      titles={[
                        "Business profile information",
                        "Contact person information",
                        "Create department",
                        "Add branch",
                        "Organizational structure ",
                      ]}
                    />
                  </div>

                  <div className="onboarding-step lg:hidden">
                    <div
                      class="progress-bar"
                      style={{
                        background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),
                          conic-gradient(#2563eb ${step * 25}%, #ddd 0)`,
                      }}
                    >
                      <progress
                        value={step * 25}
                        min="0"
                        max="100"
                        style={{
                          visibility: "hidden",
                          height: "0",
                          width: "0",
                        }}
                      >
                        {step} / 5
                      </progress>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-[55%] onboarding-container-box-two bg-[#F8FAFC] sm:bg-[#fff] h-full lg:max-h-full max-w-[100vw] w-[100vw] sm:w-auto">
                {step === 1 ? (
                  <BusinessProfileInformation setStep={setStep} />
                ) : step === 2 ? (
                  <ContactPersonInformation setStep={setStep} />
                ) : step === 3 ? (
                  <CreateDepartment setStep={setStep} />
                ) : step === 4 ? (
                  <AddBranch setStep={setStep} />
                ) : step === 5 ? (
                  <OrganizationalStructure setStep={setStep} />
                ) : (
                  navigate("/dashboard")
                )}
              </div>
              {/* </>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default OnboardingLayout;
