import { useFormik, FormikProvider, FieldArray } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import BlueBack from "../../asset/auth/blue-back-arrow.svg";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import SmallCSV from "../../asset/auth/small-csv.svg";
import SignUpLayout from "../layout/SignUpLayout";
import UploadFromCsv from "../modals/UploadFromCsv";
import CreateRole from "../modals/CreateRoleModal";
import ViewRoleDetails from "../modals/RoleDetailsModal";
import { newUsersFormSchema } from "../schema";
import {
  AddUsers,
  getMerchantRoles,
  getTenantPermissions,
  sendVerficationLink,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { getUserToken } from "../../utils";

const AddNewUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenantData = JSON.parse(localStorage.getItem("tenant_data")) ?? {};
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [createRoleModal, setCreateRoleModal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [role_id] = useState("");
  const [fetch, setFetch] = useState(false);
  const { roles } = useSelector((state) => state?.users);
  const accessToken = getUserToken();

  useEffect(() => {
    if (accessToken) {
      localStorage.clear();
    }
  }, [accessToken]);

  const SendVerificationLink = async (TOAST_ID) => {
    const body = {
      email: tenantData?.email,
    };
    const res = await dispatch(sendVerficationLink(body));
    if (res.payload.status < 400) {
      toast.update(TOAST_ID, {
        render:
          res?.payload?.message ||
          res?.payload?.data?.message ||
          "Login successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      setTimeout(() => {
        toast.update(TOAST_ID, {
          render: "Redirecting to account verification, please wait...",
          type: "info",
          isLoading: false,
          autoClose: 1000,
        });
      }, 1100);

      setTimeout(() => {
        navigate("/auth/account-verification");
      }, 2200);
    } else {
      toast.update(TOAST_ID, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const onSubmit = async (values) => {
    const TOAST_ID = toast.loading("Adding Users, please wait...");
    const filtered_values = Object.values(values)[0]?.map((val) => {
      return {
        email: val.email,
        group_id: val?.group_id.value,
      };
    });
    const body = {
      userData: filtered_values,
      tenant_id: tenantData?.tenant_id,
    };
    const res = await dispatch(AddUsers(body));
    if (res.payload.status < 400) {
      SendVerificationLink(TOAST_ID);
    } else {
      toast.update(TOAST_ID, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      userData: [
        {
          email: "",
          group_id: "",
        },
      ],
    },
    onSubmit,
    validationSchema: newUsersFormSchema,
  });

  const getAllMerchantRoles = async () => {
    setFetch(true);
    const res = await dispatch(getMerchantRoles(tenantData?.tenant_id));
    setFetch(false);
    if (res.payload.message !== "Groups retrieved successfully!") {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  // Async Functions
  const getAllMerchantPermissions = async () => {
    const res = await dispatch(getTenantPermissions(tenantData?.tenant_id));
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  // useeffect
  useEffect(() => {
    getAllMerchantRoles();
    getAllMerchantPermissions();
  });

  const merchantRoles = roles?.map((role) => {
    return {
      label: role?.name,
      value: role.id,
    };
  });

  const RoleMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => setCreateRoleModal(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add Role</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };
  return (
    <>
      <SignUpLayout>
        <div className="2xl:w-11/12 mx-auto">
          <div className="flex gap-2 justify-between items-center mb-6">
            <div className="auth-logo-box lg:hidden">
              <img src={Logo2} alt="" />
            </div>
            <div className="auth-container-box-two-step-box">
              <h6>Step 3/3</h6>
              <div className="flex items-center gap-1 mt-1">
                <span></span>
                <span></span>
                <span className="active"></span>
              </div>
            </div>

            <div className="flex">
              <button
                className="flex items-center small-add-csv"
                onClick={() => setOpenCsvModal(true)}
              >
                <span className="icon">
                  <img src={SmallCSV} alt="" />
                </span>
                <span className="text">Add users from csv</span>
              </button>
            </div>
          </div>

          <div className="xl:w-8/12 mx-auto auth-container-box-two-content-header text-center">
            <h4>Who will be joining you on FUSE ERP?</h4>
            <p>Create users</p>
          </div>

          <div className="row auth-container-box-two-content-body">
            <div className="xl:w-11/12 ">
              <div>
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <FieldArray
                      name="userData"
                      render={(arrayHelpers) => {
                        return (
                          <div style={{ minHeight: "400px" }}>
                            {formik.values.userData &&
                              formik.values.userData?.length !== 0 &&
                              formik.values.userData?.map((branch, i) => (
                                <div
                                  className="flex gap-3 items-center mb-4"
                                  key={i}
                                >
                                  <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3">
                                    <div className="md:w-5/12 w-full">
                                      <div
                                        className={`input-box
                                        ${
                                          Object.keys(formik.errors).length >
                                            0 &&
                                          Object.keys(formik.touched).length >
                                            0 &&
                                          !formik.errors.userData[i]?.email &&
                                          formik?.touched?.userData[i]?.email &&
                                          formik?.values?.userData[i]?.email
                                            ? "filled"
                                            : Object.keys(formik.errors)
                                                .length > 0 &&
                                              Object.keys(formik.touched)
                                                .length > 0 &&
                                              formik?.errors?.userData[i]
                                                ?.email &&
                                              formik?.touched?.userData[i]
                                                ?.email
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          type="text"
                                          name={`userData[${i}].email`}
                                          autoComplete="off"
                                          id={`userData[${i}].email`}
                                          value={
                                            formik.values.userData[i].email
                                          }
                                          onChange={formik.handleChange}
                                        />
                                        <label
                                          htmlFor="email"
                                          className="label-name"
                                        >
                                          <span className="label-text">
                                            Email
                                          </span>
                                        </label>
                                      </div>

                                      <div>
                                        {Object.keys(formik.errors).length >
                                          0 &&
                                          Object.keys(formik.touched).length >
                                            0 &&
                                          formik?.errors.userData[i]?.email &&
                                          formik?.touched.userData[i]
                                            ?.email && (
                                            <p className="error">
                                              {formik.errors.userData[i].email}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className={`md:w-5/12 w-full`}>
                                      <Select
                                        value={
                                          formik.values.userData[i].group_id
                                        }
                                        onChange={(notice_period) =>
                                          formik.setFieldValue(
                                            `userData[${i}].group_id`,
                                            notice_period
                                          )
                                        }
                                        fetchingData={fetch}
                                        options={merchantRoles}
                                        components={{
                                          Menu: RoleMenu,
                                          Option,
                                        }}
                                        placeholder="Select Role"
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            minHeight: "48px",
                                            fontSize: "16px",
                                          }),
                                        }}
                                      />
                                      <div>
                                        {Object.keys(formik.errors).length >
                                          0 &&
                                          Object.keys(formik.touched).length >
                                            0 &&
                                          formik?.errors.userData[i]
                                            ?.group_id &&
                                          formik?.touched.userData[i]
                                            ?.group_id && (
                                            <p className="error">
                                              {
                                                formik.errors.userData[i]
                                                  .group_id?.label
                                              }
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="flex lg:justify-center justify-end items-center md:w-1/12 w-full">
                                      {i > 0 && (
                                        <button
                                          onClick={() => arrayHelpers.remove(i)}
                                          className="ml-2"
                                        >
                                          <FaTrash
                                            style={{
                                              fill: "red",
                                              marginTop: "-0.5rem",
                                            }}
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}

                            <div>
                              <button
                                onClick={() =>
                                  arrayHelpers.push({ email: "", group_id: "" })
                                }
                                type="button"
                                className="add-user-btn flex items-center gap-2 mb-11"
                              >
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                                      fill="#2563EB"
                                    />
                                  </svg>
                                </span>

                                <span className="text">Add another user</span>
                              </button>
                              <p className="text-[#64748B] text-[11px] leading-[16px] font-medium tracking-[0.5px]">
                                You can add, remove & edit users role in your
                                organization setting
                              </p>
                            </div>
                          </div>
                        );
                      }}
                    />

                    <div className="submit-btn mb-5">
                      <div className="lg:w-6/12 mx-auto">
                        <button type="submit" disabled={formik.isSubmitting}>
                          Start free trial
                        </button>
                        <Link
                          to="/auth/choose-features"
                          className="text-decoration-none"
                        >
                          <p className="text-center mt-3 flex items-center justify-center">
                            <span className="icon me-1">
                              <img src={BlueBack} alt="" />
                            </span>
                            <span className="text">Go to previous</span>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </SignUpLayout>

      <UploadFromCsv
        modalOpen={openCsvModal}
        onClose={() => setOpenCsvModal(false)}
        size="sm"
      />
      <CreateRole
        modalOpen={createRoleModal}
        onClose={() => setCreateRoleModal(false)}
        size="sm"
      />
      <ViewRoleDetails
        modalOpen={viewDetailsModal}
        onClose={() => setViewDetailsModal(false)}
        size="sm"
        role_id={role_id}
      />
    </>
  );
};

export default AddNewUsers;
