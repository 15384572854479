import React, { useState } from "react";
import { Input } from "rsuite";
import dropdown from "./../../../asset/svgs/dropdown.svg";
import { Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";

export default function SelectBranch({ branch, setBranch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { allBranches } = useSelector((state) => state.users);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="relative max-w-[688px]" onClick={handleClick}>
        <Input
          className="border-[1px] border-[#E2E8F0] rounded px-4 py-2 text-sm text-medium text-txgray2 w-full h-[48px] !bg-[rgba(0,0,0,0)]"
          value={branch?.name}
          placeholder="Choose branch"
        />
        <img
          src={dropdown}
          alt="dropdown"
          className="ml-2 absolute top-0 right-0 p-2 "
        />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          width: "100%",
          ".MuiPaper-root": {
            minWidth: "688px",
            marginTop: "4px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          },
        }}
      >
        {allBranches?.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                setBranch(item);
              }}
            >
              <div className="gap-2 py-2">
                <p className="text-txgray3 text-sm font-semibold">
                  {item.name}
                </p>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
