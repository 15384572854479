import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import calender from "./../../../asset/svgs/calendar2.svg";
import dropdown from "./../../../asset/svgs/dropdown.svg";
import SelectMonth from "./SelectMonth";

export default function FilterGeneralDateSelect({
  value,
  setValue,
  style,
  createdDate,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDate, setOpenDate] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("January");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        onClick={handleClick}
        className="flex gap-1 items-center justify-between border-[#E2E8F0] border-[1px] rounded px-2 py-1 cursor-pointer "
        style={style}
      >
        <img src={calender} alt="calender" className="w-4 md:w-6" />
        <p className="text-[12px] md:text-sm">{value}</p>
        <img src={dropdown} alt="calender" />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(event) => {
            handleClose();
            setOpenDate(true);
          }}
        >
          <p className="text-semibold text-sm text-txgray2">By month</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setValue("All time");
          }}
        >
          <p className="text-semibold text-sm text-txgray2">All time</p>
        </MenuItem>
      </Menu>
      <SelectMonth
        open={openDate}
        onClose={() => setOpenDate(false)}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        setValue={setValue}
        createdDate={createdDate}
      />
    </>
  );
}
