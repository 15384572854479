import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Upload from "../../asset/auth/upload.svg";
import UploadingLoader from "../onboarding-components/UploadingLoader";
import { CreateBusinessProileSchema } from "../../authPages/schema";
import { useDispatch, useSelector } from "react-redux";
import { CreateTenantProfile, uploadFile } from "../../store/slices/userSlice";

const BusinessProfileInformation = ({ setStep }) => {
  // redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.users);

  // states
  const [logoUrl, setLogoUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [actionStatus, setActionStatus] = useState({
    isGettingUtils: true,
    isUploadingLogo: false,
    uploadStatus: "pending",
  });
  const { isUploadingLogo, uploadStatus } = actionStatus;
  const businessCategories = [
    { label: "Agriculture", value: "agriculture" },
    { label: "Automotive", value: "automotive" },
    { label: "Banking and Finance", value: "banking_finance" },
    { label: "Construction", value: "construction" },
    { label: "Consulting", value: "consulting" },
    { label: "Education", value: "education" },
    { label: "Entertainment", value: "entertainment" },
    { label: "Healthcare", value: "healthcare" },
    { label: "Hospitality", value: "hospitality" },
    { label: "Information Technology", value: "information_technology" },
    { label: "Legal Services", value: "legal_services" },
    { label: "Manufacturing", value: "manufacturing" },
    { label: "Marketing and Advertising", value: "marketing_advertising" },
    { label: "Media and Publishing", value: "media_publishing" },
    { label: "Non-profit", value: "non_profit" },
    { label: "Real Estate", value: "real_estate" },
    { label: "Retail", value: "retail" },
    { label: "Telecommunications", value: "telecommunications" },
    {
      label: "Transportation and Logistics",
      value: "transportation_logistics",
    },
    { label: "Others", value: "others" },
  ];

  // / Async functions
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const path = URL.createObjectURL(file);
    setSelectedFile(path);

    setActionStatus({
      ...actionStatus,
      isUploadingLogo: true,
      uploadStatus: "pending",
    });

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const response = await dispatch(uploadFile(formData));
    if (response?.payload?.status < 400) {
      const responseData = response?.payload.data?.data;
      setLogoUrl(responseData?.url);
      setTimeout(() => {
        setActionStatus({
          ...actionStatus,
          isUploadingLogo: true,
          uploadStatus: "success",
        });
      }, 1000);

      setTimeout(() => {
        setActionStatus({
          ...actionStatus,
          isUploadingLogo: false,
          uploadStatus: "success",
        });
      }, 3000);
    } else {
      setTimeout(() => {
        setActionStatus({
          ...actionStatus,
          isUploadingLogo: true,
          uploadStatus: "failed",
        });
      }, 1000);

      setTimeout(() => {
        setActionStatus({
          ...actionStatus,
          isUploadingLogo: false,
          uploadStatus: "failed",
        });
      }, 3000);
    }
  };

  const onSubmit = async (values, actions) => {
    if (user?.business_category) {
      setTimeout(() => {
        setStep(2);
        actions.resetForm();
      }, 1000);
    } else {
      const toastId = toast.loading("Setting business profile, please wait...");
      const {
        business_category,
        business_description,
        alternate_email,
        country,
      } = values;

      const data = {
        logo: logoUrl,
        business_category,
        business_description,
        alternate_email,
        country,
      };

      const res = await dispatch(CreateTenantProfile(data));
      if (res.payload.status < 400) {
        toast.update(toastId, {
          render:
            res?.payload?.data?.message || "Business profile set successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });

        setTimeout(() => {
          setStep(2);
          actions.resetForm();
        }, 1200);
      } else {
        toast.update(toastId, {
          render:
            res?.response?.data?.message ||
            res?.message ||
            "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      business_category: user?.business_category || "",
      business_description: user?.business_description || "",
      country: user?.country || "",
      alternate_email: user?.alternate_email || "",
    },
    validationSchema: CreateBusinessProileSchema,
    onSubmit,
  });

  return (
    <>
      {isUploadingLogo ? <UploadingLoader uploadStatus={uploadStatus} /> : ""}
      <div className="sm:mt-12 xl:w-[90%] 2xl:w-[80%] mx-auto">
        <div className="hidden sm:block onboarding-container-box-two-header">
          <h6 className="text-[#475569] text-base tracking-[0.15px] font-semibold">
            Business profile information
          </h6>
          <p className="font-medium text-xs tracking-[0.5px] text-[#475569]">
            Enter information relating to your business
          </p>
        </div>

        <div className="onboarding-container-box-two-body pb-14">
          <form className="row" onSubmit={handleSubmit}>
            <div className="mb-5 mt-6 flex flex-col sm:flex-row gap-y-4 sm:items-center">
              <div className="sm:w-[60%]">
                <h6 className="text-[#46464A] text-sm tracking-[0.1px] font-medium mb-5">
                  Upload company logo
                </h6>
                <p className="text-[#64748B] text-xs font-medium tracking-[0.5px]">
                  This logo will be displayed on your company’s dashboard
                </p>
              </div>

              <div className="w-[100%] sm:w-[40%] onboarding-file-upload">
                {selectedFile ? (
                  <div className="flex flex-row gap-2">
                    {" "}
                    <div className="flex-grow sm:w-[60%] min-h-[50px] min-w-[50px] border-[0.52px] border-[#CBD5E1] bg-[#fff] rounded-[#CBD5E1] border-solid">
                      <img src={selectedFile} alt="" />
                    </div>
                    <div className="sm:w-[40%] w-max">
                      <button
                        onClick={() => setSelectedFile("")}
                        className="text-[#3B82F6] font-medium text-[11px] leading-[16px] tracking-[0.5px]"
                        style={{ outline: "none", padding: 0, margin: 0 }}
                      >
                        Edit Image
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="file-upload-container">
                    <label
                      htmlFor="file-upload-input"
                      className="custom-upload-label"
                    >
                      <div className="upload-icon mb-3">
                        <img src={Upload} alt="Upload Icon" />
                      </div>
                      <div className="upload-text">
                        <span>Click to upload or drag and drop a file</span>
                        <p>.PNG, .JPEG</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="file-upload-input"
                      className="file-upload-input"
                      accept=".jpeg,.png"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    {selectedFile && (
                      <div className="selected-file">
                        <p>Selected File: {selectedFile.name}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-5 mt-6">
              <div
                className={`input-select-box ${
                  !errors.business_category &&
                  touched.business_category &&
                  values.business_category
                    ? "filled"
                    : errors.business_category && touched.business_category
                    ? "error"
                    : ""
                }`}
              >
                <select
                  name="business_category"
                  id="business_category"
                  value={values.business_category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={user?.business_category}
                >
                  <option value="" disabled>
                    --Select--
                  </option>

                  {businessCategories?.map((_, index) => (
                    <option value={_.value} key={index}>
                      {_.label}
                    </option>
                  ))}
                </select>

                <label htmlFor="business_category" className="label-name">
                  <span className="label-text">Business Category </span>
                </label>
              </div>
              <div>
                {errors.business_category && touched.business_category && (
                  <p className="error">{errors.business_category}</p>
                )}
              </div>
            </div>

            <div className="mb-5 mt-6">
              <div
                className={`input-textarea-box ${
                  !errors.business_description &&
                  touched.business_description &&
                  values.business_description
                    ? "filled"
                    : errors.business_description &&
                      touched.business_description
                    ? "error"
                    : ""
                }`}
              >
                <textarea
                  name="business_description"
                  id="business_description"
                  cols="30"
                  rows="5"
                  value={values.business_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={user?.business_description}
                ></textarea>

                <label htmlFor="business_description" className="label-name">
                  <span className="label-text">Business Description </span>
                </label>
              </div>
              <div>
                {errors.business_description &&
                  touched.business_description && (
                    <p className="error">{errors.business_description}</p>
                  )}
              </div>
            </div>

            <div className="grid xl:grid-cols-2 gap-x-2">
              <div className="mb-5 mt-6 lg:my-3 xl:my-0">
                <div
                  className={`input-select-box ${
                    !errors.country && touched.country && values.country
                      ? "filled"
                      : errors.country && touched.country
                      ? "error"
                      : ""
                  }`}
                >
                  <select
                    name="country"
                    id="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={user?.country}
                  >
                    <option value="" disabled>
                      --Select--
                    </option>
                    <option value="Nigeria">Nigeria</option>
                  </select>

                  <label htmlFor="country" className="label-name">
                    <span className="label-text">Country </span>
                  </label>
                </div>
                <div>
                  {errors.country && touched.country && (
                    <p className="error">{errors.country}</p>
                  )}
                </div>
              </div>
            </div>
            <div className=" mb-5 mt-6">
              <div
                className={`input-box ${
                  !errors.email &&
                  touched.alternate_email &&
                  values.alternate_email
                    ? "filled"
                    : errors.alternate_email && touched.alternate_email
                    ? "error"
                    : ""
                }`}
              >
                <input
                  type="text"
                  name="alternate_email"
                  id="alternate_email"
                  autoComplete="off"
                  value={values.alternate_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={user?.alternate_email}
                />
                {!user?.alternate_email && (
                  <label htmlFor="alternate_email" className="label-name">
                    <span className="label-text">
                      Alternate business email address{" "}
                    </span>
                  </label>
                )}
              </div>
              <div>
                {errors.alternate_email && touched.alternate_email && (
                  <p className="error">{errors.alternate_email}</p>
                )}
              </div>
            </div>

            <div className="onboarding-container-main-box-body-footer w-full min-h-[50px] px-3 lg:px-[105px] py-2 sm:py-[18px]">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="next-btn bg-[#2563EB] text-white p-[11px] min-w-[250px]"
                  disabled={isSubmitting}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BusinessProfileInformation;
