import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../utils/https";

const baseUrl = process.env.REACT_APP_BASE_API;

const initialState = {
  user: null,
  userDetails: {},
  users: [],
  permissions: [],
  features: [],
  allDept: [],
  allBranches: [],
  allPositions: [],
  allLevels: [],
  allLevelNames: [],
  allLeaves: [],
  roles: [],
  companySizes: [],
  noticePeriods: [],
  activeDashboardTab: "Dashboard",
  allUtilities: {},
  status: {
    fetching: "",
    deleting: "",
    creating: "",
    deactivating: "",
    message: "",
  },
  error: { fetching: "", deleting: "" },
};
export const getUtils = createAsyncThunk("companySizes/getUtils", async () => {
  try {
    const res = await axios.get(`${baseUrl}/utils/onboarding`);
    return res;
  } catch (err) {
    return err;
  }
});
export const getAllUtils = createAsyncThunk(
  "allUtilities/getAllUtils",
  async () => {
    try {
      const res = await axiosInstance().get(`${baseUrl}/utils/authenticated`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const AddFeatures = createAsyncThunk(
  "user/AddFeatures",
  async (payload) => {
    const body = {
      selected_features: payload.selected_features,
    };
    try {
      const res = await axios.put(
        `${baseUrl}/tenants/${payload.tenantId}/select-features`,
        body,
        {
          headers: {
            Authorization: `Basic RnVzZUVSUFBhcnRuZXJzUmVhY3RDbGllbnQ6YzlnK0B2ayQ1YmIzYWI4MDRmNDlmNDRiMjg5YmU3MGFlNTFmMTE2NTlh`,
            "Content-Type": "application/json",
          },
        }
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const LoginUser = createAsyncThunk("user/LoginUser", async (payload) => {
  try {
    const res = await axios.post(`${baseUrl}/o/oauth/token`, payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res;
  } catch (err) {
    return err;
  }
});

export const NewPassword_ = createAsyncThunk(
  "user/NewPassword_",
  async (payload) => {
    const body = {
      password: payload.password,
    };
    try {
      const res = await axios.post(
        `${baseUrl}/auth/password-reset?uid=${payload?.user_id}&token=${payload?.token}`,
        body,
        {
          headers: {
            Authorization: `Basic RnVzZUVSUFBhcnRuZXJzUmVhY3RDbGllbnQ6YzlnK0B2ayQ1YmIzYWI4MDRmNDlmNDRiMjg5YmU3MGFlNTFmMTE2NTlh`,
            "Content-Type": "application/json",
          },
        }
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const ForgotPassword_ = createAsyncThunk(
  "user/ForgotPassword_",
  async (payload) => {
    try {
      const res = await axios.post(`${baseUrl}/auth/forgot-password`, payload, {
        headers: {
          Authorization: `Basic RnVzZUVSUFBhcnRuZXJzUmVhY3RDbGllbnQ6YzlnK0B2ayQ1YmIzYWI4MDRmNDlmNDRiMjg5YmU3MGFlNTFmMTE2NTlh`,
          "Content-Type": "application/json",
        },
      });
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const SignupUser = createAsyncThunk(
  "user/SignupUser",
  async (payload) => {
    try {
      const res = await axiosInstance().post(`/tenants/add`, payload);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateUrl = createAsyncThunk("user/CreateUrl", async (payload) => {
  try {
    const res = await axiosInstance().put(
      `/tenants/${payload?.tenantId}/add-tenant-url`,
      { website: payload.website }
    );
    return res;
  } catch (err) {
    return err;
  }
});

export const chooseFeatures = createAsyncThunk(
  "features/chooseFeatures",
  async () => {
    try {
      const res = await axiosInstance().get(`/features/`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const AddUsers = createAsyncThunk("user/AddUsers", async (payload) => {
  try {
    const res = await axiosInstance().post(
      `/users/team-members/${payload.tenant_id}/add`,
      payload.userData
    );
    return res;
  } catch (err) {
    return err;
  }
});

export const getTenantPermissions = createAsyncThunk(
  "permissions/getTenantPermissions",
  async (tenant_id) => {
    try {
      const res = await axiosInstance().get(
        `/module-permissions/${tenant_id}/all`
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const getMerchantRoles = createAsyncThunk(
  "roles/getMerchantRoles",
  async (tenant_id) => {
    try {
      const res = await axiosInstance().get(`/groups/${tenant_id}/onboarding`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const sendVerficationLink = createAsyncThunk(
  "user/sendVerficationLink",
  async (payload) => {
    try {
      const res = await axiosInstance().post(
        `/auth/send-email-confirmation-link`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const checkEmailVerification = createAsyncThunk(
  "user/checkEmailVerification",
  async ({ uid, token }) => {
    try {
      const res = await axiosInstance().get(
        `/auth/verify-email?uid=${uid}&token=${token}`
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const createMerchantRoles = createAsyncThunk(
  "roles/createMerchantRoles",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`groups/onboarding/add`, payload);
      dispatch(getMerchantRoles(payload?.tenant_id));
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const uploadUsers = createAsyncThunk(
  "users/uploadUsers",
  async (data) => {
    const formData = new FormData();
    formData.append("file", data.selectedFile);

    try {
      const res = await axiosInstance().post(
        `/users/team-members/${data.tenant_id}/upload-team-members-lite`,
        formData
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const getTenantDetails = createAsyncThunk(
  "users/getTenantDetails",
  async () => {
    try {
      const res = await axiosInstance().get(`/tenants/profile`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const uploadFile = createAsyncThunk("user/uploadFile", async (file) => {
  try {
    const res = await axiosInstance().post(`files/upload`, file);
    return res;
  } catch (err) {
    return err;
  }
});

export const CreateTenantProfile = createAsyncThunk(
  "user/CreateTenantProfile",
  async (data, { dispatch }) => {
    try {
      const res = await axiosInstance().put(`tenants/add-profile-info`, data);
      dispatch(getTenantDetails());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateTenantContact = createAsyncThunk(
  "user/CreateTenantContact",
  async (data, { dispatch }) => {
    try {
      const res = await axiosInstance().put(`tenants/add-contact`, data);
      dispatch(getTenantDetails());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetDepartments = createAsyncThunk(
  "allDept/GetDepartments",
  async () => {
    try {
      const res = await axiosInstance().get(`departments/`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const AddTenantDepartment = createAsyncThunk(
  "allDept/AddTenantDepartment",
  async (data, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`departments/add`, data);
      dispatch(GetDepartments());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const DeleteTenantDepartment = createAsyncThunk(
  "allDept/DeleteTenantDepartment",
  async (data, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`departments/delete`, data);
      dispatch(getTenantDetails());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetBranches = createAsyncThunk(
  "allBranches/GetBranches",
  async () => {
    try {
      const res = await axiosInstance().get(`branches/`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const AddTenantBranches = createAsyncThunk(
  "allbranches/AddTenantBranches",
  async (data, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`branches/add`, data);
      dispatch(GetBranches());
      dispatch(getTenantDetails());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const DeleteTenantBranch = createAsyncThunk(
  "allbranches/DeleteTenantBranch",
  async (data, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`/branches/delete`, data);
      dispatch(GetBranches());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const getPositions = createAsyncThunk(
  "allPositions/getPositions",
  async () => {
    try {
      const res = await axiosInstance().get(`/positions/`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const createPosition = createAsyncThunk(
  "allPositions/createPositions",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`/positions/add`, payload);
      dispatch(getPositions());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const getLevelNames = createAsyncThunk(
  "allLevelNames/getLevelNames",
  async () => {
    try {
      const res = await axiosInstance().get(`/levels/names`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const createLevelName = createAsyncThunk(
  "allLevelNames/createLevelName",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`/levels/names/add`, payload);
      dispatch(getLevelNames());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const getLeaveNames = createAsyncThunk(
  "allLeaves/getLeaveNames",
  async () => {
    try {
      const res = await axiosInstance().get(`/leave-types/`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const createLeaveName = createAsyncThunk(
  "allLeaves/createLeaveName",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`/leave-types/add`, payload);
      dispatch(getLeaveNames());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const getAllLevel = createAsyncThunk(
  "allLevels/getAllLevel",
  async () => {
    try {
      const res = await axiosInstance().get(`/levels/`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const createLevel = createAsyncThunk(
  "allLevels/createLevel",
  async (payload, { dispatch }) => {
    try {
      const res = await axiosInstance().post(`/levels/add`, payload);
      dispatch(getAllLevel());
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const allNoticePeriods = createAsyncThunk(
  "noticePeriods/allNoticePeriods",
  async () => {
    try {
      const res = await axiosInstance().get(`/levels/notice-periods`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const CreateOrganizationalStructure = createAsyncThunk(
  "user/CreateOrganizationalStructure",
  async (payload) => {
    try {
      const res = await axiosInstance().put(
        `/tenants/add-organizational-structure`,
        payload
      );
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const GetUserDetails = createAsyncThunk(
  "userDetails/GetUserDetails",
  async (id) => {
    try {
      const res = await axiosInstance().get(`users/team-workspaces/details`);
      return res;
    } catch (err) {
      return err;
    }
  }
);

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = {};
      state.userDetails = {};
      state.users = [];
      state.permissions = [];
      state.features = [];
      state.allDept = [];
      state.allBranches = [];
      state.allPositions = [];
      state.allLevels = [];
      state.allLevelNames = [];
      state.allLeaves = [];
      state.roles = [];
      state.companySizes = [];
      state.noticePeriods = [];
      // From here we can take action only at this "counter" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },
    setActiveTab: (state, action) => {
      state.activeDashboardTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(LoginUser.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(SignupUser.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(SignupUser.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(SignupUser.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(CreateUrl.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateUrl.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(CreateUrl.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(chooseFeatures.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(chooseFeatures.fulfilled, (state, action) => {
        state.status.fetching = "succeeded";
        state.features = action.payload.data.data.results;
      })
      .addCase(chooseFeatures.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(createMerchantRoles.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(createMerchantRoles.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(createMerchantRoles.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getTenantPermissions.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getTenantPermissions.fulfilled, (state, action) => {
        state.status.fetching = "succeeded";
        state.permissions = action?.payload?.data?.data;
      })
      .addCase(getTenantPermissions.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(uploadUsers.pending, (state) => {
        state.status.creating = "loading";
      })
      // Pass the generated action creators to `.addCase()`
      .addCase(uploadUsers.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
        state.status.message = action.message;
      })
      .addCase(uploadUsers.rejected, (state, action) => {
        state.status.creating = "failed";
      })
      .addCase(getMerchantRoles.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getMerchantRoles.fulfilled, (state, action) => {
        state.status.fetching = "succeeded";
        state.roles = action?.payload?.data?.data?.results;
      })
      .addCase(getMerchantRoles.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action?.error?.message;
      })
      .addCase(AddUsers.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(AddUsers.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(AddUsers.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(sendVerficationLink.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(sendVerficationLink.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(sendVerficationLink.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(checkEmailVerification.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(checkEmailVerification.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(checkEmailVerification.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getTenantDetails.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getTenantDetails.fulfilled, (state, action) => {
        state.status.creating = "succeeded";
        state.user = action.payload?.data?.data;
      })
      .addCase(getTenantDetails.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(uploadFile.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(CreateTenantProfile.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateTenantProfile.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateTenantProfile.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(CreateTenantContact.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateTenantContact.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateTenantContact.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(GetDepartments.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetDepartments.fulfilled, (state, action) => {
        state.allDept = action?.payload?.data?.data?.results;
        state.status.creating = "succeeded";
      })
      .addCase(GetDepartments.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(AddTenantDepartment.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(AddTenantDepartment.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(AddTenantDepartment.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(DeleteTenantDepartment.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(DeleteTenantDepartment.fulfilled, (state) => {
        state.status.deleting = "succeeded";
      })
      .addCase(DeleteTenantDepartment.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(GetBranches.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetBranches.fulfilled, (state, action) => {
        state.allBranches = action.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(GetBranches.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(AddTenantBranches.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(AddTenantBranches.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(AddTenantBranches.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(DeleteTenantBranch.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(DeleteTenantBranch.fulfilled, (state) => {
        state.status.deleting = "succeeded";
      })
      .addCase(DeleteTenantBranch.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getPositions.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getPositions.fulfilled, (state, action) => {
        state.allPositions = action?.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(getPositions.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(createPosition.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(createPosition.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(createPosition.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getLevelNames.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getLevelNames.fulfilled, (state, action) => {
        state.allLevelNames = action?.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(getLevelNames.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(createLevelName.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(createLevelName.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(createLevelName.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getLeaveNames.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getLeaveNames.fulfilled, (state, action) => {
        state.allLeaves = action?.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(getLeaveNames.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(createLeaveName.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(createLeaveName.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(createLeaveName.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getAllLevel.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getAllLevel.fulfilled, (state, action) => {
        state.allLevels = action?.payload?.data?.data?.results;
        state.status.fetching = "succeeded";
      })
      .addCase(getAllLevel.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(createLevel.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(createLevel.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(createLevel.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(allNoticePeriods.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(allNoticePeriods.fulfilled, (state, action) => {
        state.noticePeriods = action?.payload?.data?.data?.notice_periods;
        state.status.fetching = "succeeded";
      })
      .addCase(allNoticePeriods.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(CreateOrganizationalStructure.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(CreateOrganizationalStructure.fulfilled, (state) => {
        state.status.creating = "succeeded";
      })
      .addCase(CreateOrganizationalStructure.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(GetUserDetails.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(GetUserDetails.fulfilled, (state, action) => {
        state.userDetails = action?.payload?.data?.data;
        state.status.fetching = "succeeded";
      })
      .addCase(GetUserDetails.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getUtils.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getUtils.fulfilled, (state, action) => {
        state.companySizes = action?.payload?.data?.data?.company_sizes;
        state.status.fetching = "succeeded";
      })
      .addCase(getUtils.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(getAllUtils.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(getAllUtils.fulfilled, (state, action) => {
        state.allUtilities = action?.payload?.data?.data;
        state.status.fetching = "succeeded";
      })
      .addCase(getAllUtils.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(AddFeatures.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(AddFeatures.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(AddFeatures.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(NewPassword_.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(NewPassword_.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(NewPassword_.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      })
      .addCase(ForgotPassword_.pending, (state) => {
        state.status.fetching = "loading";
      })
      .addCase(ForgotPassword_.fulfilled, (state) => {
        state.status.fetching = "succeeded";
      })
      .addCase(ForgotPassword_.rejected, (state, action) => {
        state.status.fetching = "failed";
        state.error.fetching = action.error?.message;
      });
  },
});

export const { logout, setActiveTab } = usersSlice.actions;

export default usersSlice.reducer;
