import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Popover, Whisper } from "rsuite";
import styled from "styled-components";
import cancel from "./../../../../../asset/CloseBtn.svg";
import dot from "./../../../../../asset/svgs/dot-vertical.svg";
import { useFormik } from "formik";
import { format } from "date-fns";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import addIcon from "./../../../../../asset/svgs/add.svg";
import AddNameValue from "./AddNameValue";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FaPercent } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  CreateBonus,
  GetBonuses,
  UpdateBonus,
} from "../../../../../store/slices/PayrollSlice";

// styles
const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: transparent;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #1e293b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    z-index: 0;
  }
  .error {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  .field_error {
    color: #f43f5e;
    font-size: 11px;
    margin-top: 4px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
`;
const CustomModal = styled(Modal)`
  .rs-modal-content {
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 592px;
  }
`;
export default function AddBonusModal({
  selectedEmployeeData,
  ToggleOpenAddBon,
  ToggleLoadModal,
  open,
  branch,
  employeeBonus,
  actionType,
  setMainPage,
}) {
  //states
  const [bonusType, setBonusType] = useState(
    actionType === "edit" ? employeeBonus?.type : "fixed_amount"
  );
  const [bonusName_Id, setBonusName_Id] = useState();
  const [openaddNameValue, setOpenAddNameValue] = useState(false);
  const [addNameType, setAddNameType] = useState("");
  const [nameData, setNameData] = useState("");

  //other hooks
  const dispatch = useDispatch();
  const { bonusesNames } = useSelector((state) => state.payroll);
  const {
    deduction_types,
    percentage_of: percentDef,
    cycle,
  } = useSelector((state) => state.users.allUtilities);

  //datas
  const bonusNamesList = bonusesNames?.map((type) => {
    return {
      label: type?.name,
      value: type?.id,
    };
  });
  const percentageOf = percentDef?.map((type) => {
    return {
      label: type?.name,
      value: type?.value,
    };
  });
  const frequency = cycle?.map((type) => {
    return {
      label: type?.name,
      value: type?.value,
    };
  });
  const selectedEmployeesId = selectedEmployeeData?.map((item) => item.user_id);

  //functions
  function ToggleAddNameValue() {
    setOpenAddNameValue(!openaddNameValue);
  }
  console.log(selectedEmployeesId);

  useEffect(() => {
    if (actionType === "edit") {
      const findBonusName = bonusesNames?.find(
        (item) => item.name === employeeBonus?.bonus_name
      );
      const findPercentageOf = percentDef?.find(
        (ending) => ending.value === employeeBonus?.percentage_of
      );
      const findFrequency = cycle?.find(
        (frequency) => frequency.value === employeeBonus?.cycle
      );
      setFieldValue("name", {
        label: findBonusName?.name,
        value: findBonusName?.id,
      });
      setFieldValue("percentage_of", {
        label: findPercentageOf?.name,
        value: findPercentageOf?.value,
      });
      setFieldValue("cycle", {
        label: findFrequency?.name,
        value: findFrequency?.value,
      });
    }
  }, [actionType, bonusesNames, percentDef, employeeBonus]);

  //schemas
  const fixedAmountYup = yup.object().shape({
    name: yup.object().shape({
      label: yup.string().required("Bonus name is required"),
    }),
    type: yup.string().required("This field is required"),
    amount: yup.string().required("Amount is required"),
    start_date: yup
      .date()
      .typeError("Start date is required")
      .required("This field is required"),
    cycle: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
  });
  //
  const percentageYup = yup.object().shape({
    name: yup.object().shape({
      label: yup.string().required("Bonus name is required"),
    }),
    type: yup.string().required("This field is required"),
    percentage_of: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
    percentage_value: yup.string().required("This field is required"),
    start_date: yup
      .date()
      .typeError("Start date is required")
      .required("This field is required"),
    cycle: yup.object().shape({
      label: yup.string().required("This field is required"),
    }),
  });

  //onsubmit function
  const onSubmit = async (values, actions) => {
    const toastId = toast.loading(
      actionType === "edit"
        ? "Updating bonus, please wait"
        : "Creating bonus, please wait..."
    );
    const {
      name,
      type,
      amount,
      percentage_of,
      percentage_value,
      start_date,
      cycle,
    } = values;

    const fixedAmount = {
      name: name?.label,
      type,
      amount,
      value: amount,
      cycle: cycle?.value ? cycle?.value : "",
      start_date,
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      bonus_name_id: actionType === "edit" ? "" : bonusName_Id.value,
    };
    const percentageData = {
      name: name?.label,
      type,
      cycle: cycle?.value ? cycle?.value : "",
      percentage_of: percentage_of?.value ? percentage_of?.value : "",
      percentage_value,
      start_date,
      beneficiary_ids: selectedEmployeesId,
      branch_id: branch?.id,
      bonus_name_id: actionType === "edit" ? "" : bonusName_Id.value,
    };

    const data = () => {
      if (type === "fixed_amount") {
        return fixedAmount;
      } else {
        return percentageData;
      }
    };

    const result = await dispatch(
      actionType === "edit"
        ? UpdateBonus({
            payload: {
              ...data(),
              beneficiary_ids: [employeeBonus.user_id],
              branch_id: employeeBonus.branch_id,
              bonus_name_id: employeeBonus.bonus_name_id,
            },
            bonus_id: employeeBonus?.id,
          })
        : CreateBonus(data())
    );
    await dispatch(GetBonuses());

    if (result.payload.status < 400) {
      toast.update(toastId, {
        render: result?.payload?.message || result?.payload?.data?.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm();
      ToggleOpenAddBon(!open);
    } else {
      toast.update(toastId, {
        render:
          result?.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : result?.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  //formik definition
  const formik = useFormik({
    initialValues: {
      name: "",
      type: actionType === "edit" ? employeeBonus?.type : "",
      amount: actionType === "edit" ? employeeBonus?.amount : "",
      percentage_of: "",
      percentage_value:
        actionType === "edit" ? employeeBonus?.percentage_value : "",
      start_date:
        actionType === "edit"
          ? format(new Date(employeeBonus?.start_date), "yyyy-MM-dd")
          : new Date("YYYY-MM-DD"),
      cycle: "",
    },
    validationSchema:
      bonusType === "fixed_amount"
        ? fixedAmountYup
        : bonusType === "percentage"
        ? percentageYup
        : null,
    onSubmit,
  });

  //components
  const NameMenu = (props) => {
    return (
      <>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.children.lenght <= 0 ? (
              <span className="p-2">No allowance added yet</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              type="button"
              className="text-[#2563EB] my-1 flex justify-start items-center p-2"
              onClick={() => {
                ToggleAddNameValue();
                setAddNameType("add");
              }}
            >
              <img src={addIcon} alt="addIcon" />
              <span className="font-bold text-sm">New Bonus</span>
            </button>
          </div>
        </components.Menu>
      </>
    );
  };
  const Option = (props) => {
    const speaker = (
      <Popover visible>
        <Dropdown.Menu>
          <Dropdown.Item
            eventKey={1}
            onClick={() => {
              ToggleAddNameValue();
              setAddNameType("edit");
              setNameData(props?.data);
            }}
          >
            <div className="flex items-center gap-2">
              <span className="text-txgray2 text-sm font-bold">
                {" "}
                Edit option
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey={2}>
            <div className="flex items-center gap-2">
              <span className="text-[#E11D48] text-sm font-bold">
                {" "}
                Delete option
              </span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
    return (
      <>
        <components.Option {...props}>
          <div className="flex justify-between px-4 items-center">
            <span className="text-[#475569] font-bold text-sm">
              {" "}
              {props.children}{" "}
            </span>
            <Whisper
              placement="autoVerticalEnd"
              trigger="click"
              speaker={speaker}
            >
              <IconButton
                onClick={(e) => e.stopPropagation()}
                className="cursor-pointer px-5"
              >
                <img src={dot} alt="dot" className="cursor-pointer" />
              </IconButton>
            </Whisper>
          </div>
        </components.Option>
      </>
    );
  };
  //
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    // isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <>
      <CustomModal
        onClose={() => {
          ToggleOpenAddBon();
          ToggleLoadModal();
        }}
        open={open}
        style={{
          content: {
            padding: "24px 48px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            minWidth: "592px",
          },
        }}
      >
        <div className="flex items-center justify-between gap-5 border-b-[1px] border-[#F1F5F9] pb-4">
          <p className="text-txgray3 text-[22px] font-semibold">
            {actionType === "edit" ? "Edit Bonus" : "Add New Bonus"}
          </p>
          <IconButton
            onClick={() => {
              ToggleOpenAddBon();
              ToggleLoadModal();
            }}
          >
            <img src={cancel} alt="close" />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit} className="mt-6 flex flex-col gap-6">
          <StyledInputWrapper>
            <Select
              value={values?.name}
              onChange={(name) => {
                setFieldValue("name", name);
                setBonusName_Id(name);
              }}
              options={bonusNamesList}
              placeholder="Bonus Name"
              components={{ Menu: NameMenu, Option }}
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: "48px",
                  fontSize: "14px",
                }),
              }}
              isDisabled={actionType === "edit"}
            />
            {errors.name && touched.name && (
              <p className="field_error">{errors.name.label}</p>
            )}
          </StyledInputWrapper>
          <StyledInputWrapper>
            <FormControl>
              <p className="text-txgray2 text-sm">
                Type of Benefit Calculation
              </p>
              <RadioGroup
                value={values.type}
                onChange={(type) => {
                  setFieldValue("type", type.target.value);
                  setBonusType(type.target.value);
                }}
                row
              >
                {deduction_types?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<Radio />}
                    label={item.name}
                    className="text-txgray2 !text-sm"
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "14px !important",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {errors.type && touched.type && (
              <p className="field_error">{errors.type}</p>
            )}
          </StyledInputWrapper>
          {bonusType === "fixed_amount" ? (
            <StyledInputWrapper>
              <input
                id="amount"
                name="amount"
                type="number"
                placeholder="Amount"
                value={values?.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.amount && touched.amount && "error"}
              />
              {errors.amount && touched.amount && (
                <p className="field_error">{errors.amount}</p>
              )}
            </StyledInputWrapper>
          ) : (
            <>
              <StyledInputWrapper>
                <Select
                  value={values?.percentage_of}
                  onChange={(percentage_of) => {
                    setFieldValue("percentage_of", percentage_of);
                  }}
                  options={percentageOf}
                  placeholder="Percentage of"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "48px",
                      fontSize: "14px",
                    }),
                  }}
                />
                {errors.percentage_of && touched.percentage_of && (
                  <p className="field_error">{errors.percentage_of.label}</p>
                )}
              </StyledInputWrapper>
              <StyledInputWrapper>
                <div>
                  <input
                    id="percentage_value"
                    name="percentage_value"
                    type="number"
                    placeholder="Percentage"
                    value={values.percentage_value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.percentage_value &&
                      touched.percentage_value &&
                      "error"
                    }
                    style={{
                      paddingRight: "34px",
                    }}
                  />
                  <span className="ml-2 absolute top-0 right-0 p-[18px] ">
                    <FaPercent />
                  </span>
                </div>
                {errors.percentage_value && touched.percentage_value && (
                  <p className="field_error">{errors.percentage_value}</p>
                )}
              </StyledInputWrapper>
            </>
          )}
          <StyledInputWrapper>
            <div>
              <input
                id="start_date"
                name="start_date"
                type="date"
                placeholder="Start Date"
                value={values.start_date}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.start_date && touched.start_date && "error"}
              />
              <span className="absolute top-[-10px] left-[14px] bg-white px-1 text-sm text-[#475569]">
                Start Date
              </span>
            </div>
            {errors.start_date && touched.start_date && (
              <p className="field_error">{errors.start_date}</p>
            )}
          </StyledInputWrapper>
          <StyledInputWrapper>
            <Select
              value={values?.cycle}
              onChange={(cycle) => {
                setFieldValue("cycle", cycle);
              }}
              options={frequency}
              placeholder="Select frequency"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: "48px",
                  fontSize: "14px",
                }),
              }}
            />
            {errors.cycle && touched.cycle && (
              <p className="field_error">{errors.cycle.label}</p>
            )}
          </StyledInputWrapper>
          <button
            type="submit"
            className="w-full text-sm bg-[#2563EB] font-bold text-[#FFFFFF] h-12 px-4 rounded-md"
          >
            {actionType === "edit" ? "Save Changes" : "Add New Bonus"}
          </button>
        </form>
      </CustomModal>
      <AddNameValue
        openaddNameValue={openaddNameValue}
        ToggleAddNameValue={ToggleAddNameValue}
        type="Bonus"
        branch={branch}
        addType={addNameType}
        nameData={nameData}
      />
    </>
  );
}
