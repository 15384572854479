import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import accounting from "../../asset/auth/account.svg";
import inventory from "../../asset/auth/note.svg";
import hr from "../../asset/auth/user.svg";
import crm from "../../asset/auth/handshake.svg";
import invoice from "../../asset/auth/sticky-note.svg";
import project_management from "../../asset/auth/fact-check.svg";
import payroll_management from "../../asset/auth/payments.svg";
import BlueBack from "../../asset/auth/blue-back-arrow.svg";
import SignUpLayout from "../layout/SignUpLayout";
import Loader from "../../component/atoms/Loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AddFeatures, chooseFeatures } from "../../store/slices/userSlice";
import { getUserToken } from "../../utils";

const FEATURES_IMAGES = {
  accounting,
  inventory,
  hr,
  crm,
  invoice,
  project_management,
  payroll_management,
};
const ChooseFeatures = () => {
  const dispatch = useDispatch();
  const [actionStatus, setActionStatus] = useState({
    isGettingUtils: true,
  });
  const [tenantId, setTenantId] = useState("");
  const navigate = useNavigate();
  const { features } = useSelector((state) => state?.users);
  const accessToken = getUserToken();

  useEffect(() => {
    const tenantData = JSON.parse(localStorage.getItem("tenant_data")) ?? {};
    const { tenant_id } = tenantData;
    setTenantId(tenant_id);
  }, []);

  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const selectFeatureFn = (value) => {
    if (selectedFeatures.includes(value)) {
      setSelectedFeatures(
        selectedFeatures.filter((feature) => feature !== value)
      );
    } else {
      setSelectedFeatures([...selectedFeatures, value]);
    }
  };

  const { isGettingUtils } = actionStatus;

  const getFeaturesUtils = async () => {
    setActionStatus({ ...actionStatus, isGettingUtils: true });
    const res = await dispatch(chooseFeatures());
    if (res.payload.status < 400) {
      setActionStatus({ ...actionStatus, isGettingUtils: false });
    } else {
      setActionStatus({ ...actionStatus, isGettingUtils: false });
    }
  };

  useEffect(() => {
    getFeaturesUtils();
  }, []);

  async function submitFeaturesFn(e) {
    e.preventDefault();
    const features_toast_id = toast.loading(
      "Submitting features, please wait..."
    );

    const body = {
      selected_features: selectedFeatures,
      tenantId,
    };

    const res = await dispatch(AddFeatures(body));
    if (res.payload.status < 400) {
      toast.update(features_toast_id, {
        render: res.payload?.data.message
          ? res.payload?.data.message
          : "Features successfully added",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setTimeout(() => {
        setSelectedFeatures([]);
        navigate("/auth/add-users");
      }, 1600);
    } else {
      toast.update(features_toast_id, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  useEffect(() => {
    if(accessToken) {
      localStorage.clear();
    }
  }, [accessToken])
  return (
    <SignUpLayout>
      <div className="2xl:w-11/12 mx-auto">
        <div className="flex gap-2 justify-between lg:justify-start items-center mb-6 lg:mb-0">
          <div className="auth-logo-box lg:hidden mb-6">
            <img src={Logo2} alt="" />
          </div>
          <div className="auth-container-box-two-step-box mb-5">
            <h6>Step 2/3</h6>
            <div className="flex items-center gap-1 mt-1">
              <span></span>
              <span className="active"></span>

              <span></span>
            </div>
          </div>
        </div>
        <div className="xl:w-8/12 mx-auto auth-container-box-two-content-header text-center mb-5">
          <h4>What do you intend to use FUSE ERP for?</h4>
          <p>Choose your features</p>
        </div>

        <div className="row auth-container-box-two-content-body">
          <div className="xl:w-10/12 mx-auto">
            {isGettingUtils && (
              <div className="min-h-[200px] flex items-center justify-center">
                <Loader />
              </div>
            )}

            {!isGettingUtils && (!features || features?.length === 0) && (
              <div className="min-h-[200px] text-[20px] text-[#334155] font-bold flex items-center justify-center">
                <h3>No features found</h3>
              </div>
            )}
            {!isGettingUtils && features && features?.length !== 0 && (
              <>
                {/* <div className="mb-4 flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    name="select_all_features"
                    id="select_all_features"
                  />
                  <label
                    className="text-[#2563EB] text-sm font-bold tracking-[0.1px]"
                    htmlFor=""
                  >
                    Select all
                  </label>
                </div> */}
                <div>
                  <form onSubmit={submitFeaturesFn}>
                    <div className="grid sm:grid-cols-2 gap-4">
                      {features.map((feature, i) => {
                        return feature?.name !== "Payroll Management" ? null : (
                          <div
                            className="w-full mb-4 min-h-[100px]"
                            key={feature?.id || i}
                          >
                            <div className="h-full checkbox-input">
                              <div className="checkbox-input-inner flex gap-2 items-start">
                                <img
                                  src={FEATURES_IMAGES[feature?.value] || ""}
                                  alt=""
                                />
                                <div>
                                  <h5>{feature?.name || "No name"}</h5>
                                  <p>
                                    {feature?.description || "No description"}
                                  </p>
                                </div>
                              </div>
                              <span className="check">
                                <span
                                  className=""
                                  style={{
                                    visibility: "hidden",
                                  }}
                                >
                                  <FaCheck />
                                </span>
                                <FaCheck />
                              </span>
                              <input
                                type="checkbox"
                                value={feature?.value}
                                name={feature?.value}
                                checked={selectedFeatures.includes(
                                  feature?.value
                                )}
                                onChange={() => selectFeatureFn(feature?.value)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="submit-btn mb-5 mt-10">
                      <div className="lg:w-5/12 mx-auto">
                        <button disabled={selectedFeatures?.length === 0}>
                          Continue
                        </button>

                        <Link
                          to="/auth/choose-organization"
                          className="text-decoration-none"
                        >
                          <p className="flex justify-center items-center text-center mt-2">
                            <span className="icon me-1">
                              <img src={BlueBack} alt="" />
                            </span>
                            <span className="text">Go to previous</span>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default ChooseFeatures;
