import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "./../../../../../utils/https";
import Loader from "../../../../../component/atoms/Loader";
import breadcrumbarrow from "../../../../../asset/svgs/breadcrumbarrow.svg";
import naira from "../../../../../asset/svgs/NairaBig.svg";
import nairaSmall from "../../../../../asset/svgs/NairaBlack.svg";
import {
  NameToInitials,
  formatDate,
  formatDateWithDay,
} from "../../../../../utils";
import { useSelector } from "react-redux";

const EmployeePayslip = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state?.users);
  const { branch } = useParams();

  //queries
  const { data: payrollDetails, isPending } = useQuery({
    queryKey: ["payrollDetails", id],
    queryFn: async () => {
      const response = await axiosInstance().get(
        `/payslips/history/${id}/details`
      );
      return response?.data?.data;
    },
    refetchOnMountOrArgChange: true,
  });

  useEffect(() =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  );
  return (
    <div>
      {isPending ? (
        <div className="flex justify-center items-center mt-[10rem]">
          <Loader />
        </div>
      ) : (
        <div className="px-4 lg:px-9 pt-6">
          <div className="flex items-center justify-between">
            <ul className="payroll-bread-crumb flex items-center gap-1 m-0">
              <li className="payroll-bread-crumb-active">
                <p className="text-[#2563EB]">Human Resource</p>
              </li>
              <img src={breadcrumbarrow} alt="arrow" className="px-2" />
              <li>
                <p className="text-[#2563EB]">Payroll</p>
              </li>
              <img src={breadcrumbarrow} alt="arrow" className="px-2" />
              <li>
                <p className="text-[#2563EB]">Payslips</p>
              </li>
              <img src={breadcrumbarrow} alt="arrow" className="px-2" />
              <li>
                <p className="text-[#64748B]">{branch}</p>
              </li>
              <img src={breadcrumbarrow} alt="arrow" className="px-2" />
              <li>
                <p className="text-[#64748B]">Employee Payslip</p>
              </li>
            </ul>
          </div>
          <p className="mt-8 text-[#1E293B] font-semibold text-xl lg:text-[28px] leading-9">
            Employee Payslip
          </p>
          <div className="mt-8 bg-[#FFFFFF] rounded-2xl px-4 lg:px-10 py-8">
            <div className="flex justify-between items-center border-[#93C5FD] border-b-2 pb-4">
              <p className="font-semibold text-[16px]">
                {formatDate(payrollDetails?.date_created)} Payslip
              </p>
              {user?.logo ? (
                <div
                  style={{
                    backgroundImage: `url(${user?.logo})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    borderRadius: "50%",
                  }}
                  className="w-[32px] h-[32px]"
                  alt=""
                />
              ) : (
                <div className="items-center justify-center flex bg-gray-500-100 w-8 h-8 rounded-full text-[#FFFFFF] font-semibold ">
                  {NameToInitials(user?.name)}
                </div>
              )}
            </div>
            <div className="mt-8">
              <p className="uppercase font-normal text-[16px]">
                EMPLOYEE SUMMARY
              </p>
              <div className="mt-4">
                <p className="font-semibold text-[24px] text-[#1E293B]">
                  {" "}
                  {payrollDetails?.employee_summary?.full_name}{" "}
                </p>
                <div className="mt-4 flex flex-col gap-6 lg:gap-0 lg:grid grid-cols-3">
                  <div className="flex justify-between col-span-2">
                    <div className="flex flex-col gap-2 w-full">
                      <p className="text-sm font-bold text-txgray2 leading-5 py-2 ">
                        Staff ID
                      </p>
                      <p className="text-sm font-bold text-txgray2 leading-5 py-2 ">
                        Job Title
                      </p>
                      <p className="text-sm font-bold text-txgray2 leading-5 py-2 ">
                        Date of Joining
                      </p>
                      <p className="text-sm font-bold text-txgray2 leading-5 py-2 ">
                        Pay period
                      </p>
                      <p className="text-sm font-bold text-txgray2 leading-5 py-2 ">
                        Pay Date
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-full gap-2">
                      <p className="text-sm font-normal text-txgray2 leading-5 py-2 ">
                        --
                      </p>
                      <p className="text-sm font-normal text-txgray2 leading-5 py-2 ">
                        {payrollDetails?.employee_summary?.designation}{" "}
                      </p>
                      <p className="text-sm font-normal text-txgray2 leading-5 py-2 ">
                        {formatDateWithDay(
                          payrollDetails?.employee_summary?.date_joined
                        )}{" "}
                      </p>
                      <p className="text-sm font-normal text-txgray2 leading-5 py-2 ">
                        {formatDate(payrollDetails?.date_updated)}{" "}
                      </p>
                      <p className="text-sm font-normal text-txgray2 leading-5 py-2 ">
                        {formatDateWithDay(payrollDetails?.date_updated)}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="bg-[#EFF6FF] px-4 py-6 rounded-lg border-[#F1F5F9] border-[1px]">
                      <span className="flex text-txgray2 text-[22px] font-semibold">
                        <img src={naira} alt="naira" />
                        {Number(payrollDetails?.gross_salary).toLocaleString()}
                      </span>
                      <p className="mt-1 text-sm font-bold leading-5">
                        Employee Gross Pay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <p className="uppercase font-normal text-[16px]">
                EMPLOYEE EARNINGS
              </p>
              <div className="mt-4">
                <div className="bg-[#F1F5F9] flex justify-between px-4 py-[14px] items-center">
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Name
                  </p>
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Monthly Amount
                  </p>
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Annual Amount
                  </p>
                </div>
                {payrollDetails?.earnings?.map((item, index) => (
                  <div
                    className="flex justify-between px-4 py-[14px] items-center border-[#F1F5F9] border-b-[1px]"
                    key={index}
                  >
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start">
                      {item.name}{" "}
                    </p>
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.amount).toLocaleString()}{" "}
                    </p>
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full  text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.annual_amount).toLocaleString()}{" "}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="mt-8">
              <p className="uppercase font-normal text-[16px]">OTHER COSTS</p>
              <div className="mt-4">
                <div className="bg-[#F1F5F9] flex justify-between px-4 py-[14px] items-center">
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Name
                  </p>
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Monthly Amount
                  </p>
                  {/* <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Annual Amount
                  </p> */}
                </div>
                {payrollDetails?.statutory_deductions?.map((item, index) => (
                  <div
                    className="flex justify-between px-4 py-[14px] items-center border-[#F1F5F9] border-b-[1px]"
                    key={index}
                  >
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start">
                      {item.name}{" "}
                    </p>
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.amount).toLocaleString()}{" "}
                    </p>
                    {/* <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      --
                    </p> */}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-8">
              <p className="uppercase font-normal text-[16px]">DEDUCTIONS</p>
              <div className="mt-4">
                <div className="bg-[#F1F5F9] flex justify-between px-4 py-[14px] items-center">
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Name
                  </p>
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Monthly Amount
                  </p>
                  {/* <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Annual Amount
                  </p> */}
                </div>
                {payrollDetails?.deductions?.map((item, index) => (
                  <div
                    className="flex justify-between px-4 py-[14px] items-center border-[#F1F5F9] border-b-[1px]"
                    key={index}
                  >
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start">
                      {item.name}{" "}
                    </p>
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.amount).toLocaleString()}{" "}
                    </p>
                    {/* <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.annual_amount).toLocaleString()}{" "}
                    </p> */}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-8">
              <p className="uppercase font-normal text-[16px]">BENEFITS</p>
              <div className="mt-4">
                <div className="bg-[#F1F5F9] flex justify-between px-4 py-[14px] items-center">
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Name
                  </p>
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Monthly Amount
                  </p>
                  {/* <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Annual Amount
                  </p> */}
                </div>
                {payrollDetails?.benefits?.map((item, index) => (
                  <div
                    className="flex justify-between px-4 py-[14px] items-center border-[#F1F5F9] border-b-[1px]"
                    key={index}
                  >
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start">
                      {item.name}{" "}
                    </p>
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.amount).toLocaleString()}{" "}
                    </p>
                    {/* <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.amount).toLocaleString()}{" "}
                    </p> */}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-8">
              <p className="uppercase font-normal text-[16px]">BONUSES</p>
              <div className="mt-4">
                <div className="bg-[#F1F5F9] flex justify-between px-4 py-[14px] items-center">
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Name
                  </p>
                  <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Monthly Amount
                  </p>
                  {/* <p className="font-semibold text-sm text-txgray2 leading-5 w-full text-start">
                    Annual Amount
                  </p> */}
                </div>
                {payrollDetails?.bonuses?.map((item, index) => (
                  <div
                    className="flex justify-between px-4 py-[14px] items-center border-[#F1F5F9] border-b-[1px]"
                    key={index}
                  >
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start">
                      {item.name}{" "}
                    </p>
                    <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.amount).toLocaleString()}{" "}
                    </p>
                    {/* <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                      <img src={nairaSmall} alt="naira" />
                      {Number(item.annual_amount).toLocaleString()}{" "}
                    </p> */}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-8 grid grid-cols-3">
              <div className="border-[#E2E8F0] border-[1px] border-r-0 rounded-tl-lg rounded-bl-lg text-sm font-normal p-6 col-span-2">
                <span className="font-bold">TOTAL NET PAYABLE</span> = Gross
                Salary - Taxes -Deductions + Benefits
              </div>
              <div className="bg-[#EFF6FF] px-4 py-6 col-span-1">
                <p className="font-normal text-sm text-txgray2 leading-5 w-full text-start flex items-center gap-1">
                  <img src={nairaSmall} alt="naira" />
                  {Number(
                    payrollDetails?.employee_summary?.final_net_pay
                  ).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeePayslip;
