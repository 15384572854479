import React from "react";
import { Modal } from "rsuite";
import cancel from "./../asset/CloseBtn.svg";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import LineButton from "./atoms/LineButton";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/userSlice";
import { logout1 } from "../store/slices/PayrollSlice";
import { useNavigate } from "react-router-dom";

const CustomModal = styled(Modal)`
  .rs-modal-content {
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 584px;
  }
`;
export default function ConfirmLogoutModal({
  openConfirmLogout,
  ToggleConfirmLogout,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(logout1());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/auth/login");
  };
  return (
    <CustomModal open={openConfirmLogout} onClose={ToggleConfirmLogout}>
      <div className="flex items-center justify-between gap-5 pb-4 ">
        <p className="text-txgray3 text-[22px] font-semibold">Confirm Logout</p>
        <IconButton
          onClick={() => {
            ToggleConfirmLogout();
          }}
        >
          <img src={cancel} alt="close" />
        </IconButton>
      </div>
      <p>Are you sure you want to logout?</p>
      <div className="flex gap-4 mt-4">
        <LineButton label={"No, Cancel"} onClick={ToggleConfirmLogout} />
        <button
          onClick={handleLogout}
          className="text-sm bg-[#E11D48] font-bold text-[#FFFFFF] py-[10px] px-4 px-4 rounded-md"
        >
          Logout
        </button>
      </div>
    </CustomModal>
  );
}
