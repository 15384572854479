import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../asset/auth/second-logo.svg";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import Loader from "../../component/atoms/Loader";
import axios from "../api/axios";
import LoginLayout from "../layout/LoginLayout";

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get("uid");
  const token = queryParams.get("token");
  const baseUrl = process.env.REACT_APP_BASE_API;

  async function verifyEmailFn() {
    const VERIFY_EMAIL_TOAST_ID = toast.loading("Verifying, please wait...");

    if (user_id === "" || token === "") {
      toast.update(VERIFY_EMAIL_TOAST_ID, {
        render: "Verification information not available, kindly resend email",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      navigate("/auth/forgot-password");
      return;
    }

    try {
      const res = await axios.get(
        `${baseUrl}/auth/verify-password-reset-link?uid=${user_id}&token=${token}`,
        {
          headers: {
            Authorization: `Basic RnVzZUVSUFBhcnRuZXJzUmVhY3RDbGllbnQ6YzlnK0B2ayQ1YmIzYWI4MDRmNDlmNDRiMjg5YmU3MGFlNTFmMTE2NTlh`,
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = res?.data?.data ?? {};
      const { url: RESET_PASSWORD_URL } = responseData;

      toast.update(VERIFY_EMAIL_TOAST_ID, {
        render:
          res?.data?.message ||
          res?.data?.data?.message ||
          "Verified successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });

      setTimeout(() => {
        toast.update(VERIFY_EMAIL_TOAST_ID, {
          render: "Redirecting to new password page, please wait...",
          type: "info",
          isLoading: false,
          autoClose: 1500,
        });
      }, 2100);

      setTimeout(() => {
        // navigate(`/${RESET_PASSWORD_URL}`);
        window.location.replace(RESET_PASSWORD_URL);
      }, 3800);
    } catch (err) {
      console.error(err, "err");
      toast.update(VERIFY_EMAIL_TOAST_ID, {
        render:
          err?.response?.data?.message ||
          err?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      setTimeout(() => {
        navigate("/auth/forgot-password");
      }, 1800);
    }
  }

  useEffect(() => {
    verifyEmailFn();
  }, [user_id, token]);

  return (
    <LoginLayout>
      <div className="row">
        <div className="2xl:w-9/12 xl:w-10/12 mx-auto bg-rd-500">
          <div className="second-auth-container-box-header mb-5">
            <div className="logo-box hidden sm:block">
              <img src={Logo} alt="" />
            </div>
            <div className="logo-box sm:hidden">
              <img src={Logo2} alt="" />
            </div>
            <h5 className="mt-8 sm:mt-0">Verify email</h5>
            <p>Verifying password reset email</p>
          </div>

          <div className="second-auth-container-box-body mt-14 flex justify-center items-center min-h-[200px]">
            {<Loader />}
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default VerifyEmail;
