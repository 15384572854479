export const paydayOptions = [
  { label: "Select a Payday", value: "" },
  { label: "Every two Weeks", value: "two_weeks" },
  { label: "Last day of the month", value: "last_day_of_month" },
];

export const LeaveOptions = [
  { label: "Operate Monthly Leave Policy", value: "monthly" },
  { label: "Operate a One-Off Leave Policy", value: "one_off" },
];

export const PensionOptions = [
  { label: "Paid pension on gross", value: "paid_on_gross" },
  {
    label: "Paid pension on BHT (Basic, housing, transport)",
    value: "paid_on_bht",
  },
];

export const menuOptions = [
  { label: "Ist", value: "1" },
  { label: "2nd", value: "2" },
  { label: "3rd", value: "3" },
  { label: "4th", value: "4" },
  { label: "5th", valut: "5" },
  { label: "6th", value: "6" },
  { label: "7th", value: "7" },
  { label: "8th", value: "8" },
  { label: "9th", value: "9" },
  { label: "10th", value: "10" },
  { label: "11th", value: "11" },
  { label: "12th", value: "12" },
  { label: "13th", value: "13" },
  { label: "14th", value: "14" },
  { label: "15th", value: "15" },
  { label: "16th", value: "16" },
  { label: "17th", value: "17" },
  { label: "18th", value: "18" },
  { label: "19th", value: "19" },
  { label: "20th", value: "20" },
  { label: "21th", value: "21" },
  { label: "22th", value: "22" },
  { label: "23rd", value: "23" },
  { label: "24th", value: "24" },
  { label: "25th", value: "25" },
  { label: "26th", value: "26" },
  { label: "27th", value: "27" },
  { label: "28th", value: "28" },
  { label: "29th", value: "29" },
  { label: "30th", value: "30" },
  { label: "31st", value: "31" },
];
export const bankCodeOptions = [
  { label: "Access Bank Nigeria Plc", value: "044" },
  { label: "Diamond Bank Plc", value: "063" },
  { label: "Ecobank Nigeria", value: "050" },
  { label: "Enterprise Bank Plc", value: "084" },
  { label: "Fidelity Bank Plc", value: "070" },
  { label: "First Bank of Nigeria Plc", value: "011" },
  { label: "First City Monument Bank", value: "214" },
  { label: "Guaranty Trust Bank Plc", value: "058" },
  { label: "Heritaage Banking Company Ltd", value: "030" },
  { label: "Jaiz Bank", value: "301" },
  { label: "Keystone Bank Ltd", value: "082" },
  { label: "Mainstreet Bank Plc", value: "014" },
  { label: "Skye Bank Plc", value: "076" },
  { label: "Stanbic IBTC Plc", value: "039" },
  { label: "Sterling Bank Plc", value: "232" },
  { label: "Union Bank Nigeria Plc", value: "032" },
  { label: "United Bank for Africa Plc", value: "033" },
  { label: "Unity Bank Plc", value: "215" },
  { label: "WEMA Bank Plc", value: "035" },
  { label: "Zenith Bank International", value: "057" },
];

export const colors = [
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
  "#059669",
  "#D97706",
  "#E11D48",
];
export const years = ["2024", "2025", "2026"];
export const months = [
  {
    label: "Jan",
    value: "January",
  },
  {
    label: "Feb",
    value: "February",
  },
  {
    label: "Mar",
    value: "March",
  },
  {
    label: "Apr",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "Jun",
    value: "June",
  },
  {
    label: "Jul",
    value: "July",
  },
  {
    label: "Aug",
    value: "August",
  },
  {
    label: "Sep",
    value: "September",
  },
  {
    label: "Oct",
    value: "October",
  },
  {
    label: "Nov",
    value: "November",
  },
  {
    label: "Dec",
    value: "December",
  },
];
