import React from "react";
import MainModal from "../../../../../component/MainModal.";
import { useFormik } from "formik";
import * as yup from "yup";
import { AddTenantDepartment } from "../../../../../store/slices/userSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const NewDepartmentModal = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    console.log({ ban: [values] });

    const toastId = toast.loading("Creating department, please wait...");
    const res = await dispatch(AddTenantDepartment([values]));
    if (res?.payload?.status < 400) {
      toast.update(toastId, {
        render: res?.payload.data?.message || "Branch added successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm()
      onClose();
    } else {
      toast.update(toastId, {
        render:
          res?.payload?.data?.message ||
          res?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Department Name is required"),
    }),
    onSubmit,
  });
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Add Department"
        
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          <p className="mb-3">Department Info</p>
          <form onSubmit={handleSubmit}>
            <div
              className={`input-box ${
                !errors.username && touched.name && values.name
                  ? "filled"
                  : errors.name && touched.name
                  ? "error"
                  : ""
              }`}
            >
              <input
                type="text"
                name="name"
                placeholder="department Name"
                autoComplete="off"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div>
                {errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}
              </div>
            </div>
            <div className="upload-from-csv-box-three mt-8">
              <button type="submit" disabled={isSubmitting}>
                Add Department
              </button>
            </div>
          </form>
        </div>
      </MainModal>
    </>
  );
};

export default NewDepartmentModal;
