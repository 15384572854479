import Dashboard from "../dashboard/dashboard-pages/DashboardIndex";
import Payroll from "../dashboard/dashboard-pages/Human Resources/Payroll/PayrollIndex";
import BranchPayslip from "../dashboard/dashboard-pages/Human Resources/Payroll/runpayrollpage/BranchPayslip";
import EmployeePayslip from "../dashboard/dashboard-pages/Human Resources/Payroll/runpayrollpage/EmployeePayslip";
import RunEmployeePayrollIndex from "../dashboard/dashboard-pages/Human Resources/Payroll/runpayrollpage/RunEmployeePayrollIndex";
import ViewPayrollDetails from "../dashboard/dashboard-pages/Human Resources/Payroll/runpayrollpage/ViewPayrollDetails";
import Settings from "../dashboard/dashboard-pages/Settings/Settings";

const AppRouter = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/dashboard/payroll",
    element: <Payroll />,
  },
  {
    path: "/dashboard/payroll/runpayroll/:branchName/:branchId",
    element: <RunEmployeePayrollIndex />,
  },
  {
    path: "/dashboard/payroll/view-payroll/:id",
    element: <ViewPayrollDetails />,
  },
  {
    path: "/dashboard/payroll/branch-payroll/:id",
    element: <BranchPayslip />,
  },
  {
    path: "/dashboard/payroll/employee-payslip/:id/:branch",
    element: <EmployeePayslip />,
  },
  {
    path: "/dashboard/settings",
    element: <Settings />,
  },
  { path: "*", element: <Dashboard /> },
];

export default AppRouter;
