import React, { useState } from "react";
import {
  Dropdown,
  IconButton,
  Input,
  InputGroup,
  Pagination,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../utils/https";
import edit from "./../../../../asset/svgs/edit.svg";
import deleteIcon from "./../../../../asset/svgs/delete.svg";
import DeleteAction from "../settings-modal/DeleteAction";
import AddMember from "../settings-modal/AddMember";
import useUserPermissions from "../../../../utils/permissions";
const { Column, HeaderCell, Cell } = Table;

export default function Teams({ setOpenMember, openMember }) {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [rowData, setRowData] = useState(null);
  const [loadModal, setLoadModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const { addTeamPermission } = useUserPermissions();

  //queries
  const { data: teamData } = useQuery({
    queryKey: ["getAllMembers"],
    queryFn: async () => {
      const response = await axiosInstance().get("/users/team-members/");
      return response?.data?.data?.results;
    },
  });

  //function
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const ToggleLoadModal = () => {
    setLoadModal(!loadModal);
  };

  //data
  const filteredData = teamData?.filter((role) =>
    role?.first_name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const data = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  //components
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover visible>
        {addTeamPermission && (
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey={1}
              onClick={() => {
                setOpenMember(true);
                setRowData(rowData);
                ToggleLoadModal();
              }}
            >
              <div className="flex items-center gap-2">
                <img src={edit} alt="edit" />
                <span className="text-txgray2 text-sm font-bold">
                  {" "}
                  Edit member
                </span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={2}
              onClick={() => {
                setDeleteModal(true);
                setRowData(rowData);
              }}
            >
              <div className="flex items-center gap-2">
                <img src={deleteIcon} alt="edit" />
                <span className="text-[#E11D48] text-sm font-bold">
                  {" "}
                  Delete member
                </span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="autoVerticalEnd" trigger="click" speaker={speaker}>
          <IconButton
            appearance="subtle"
            style={{ padding: 0 }}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                  fill="#475569"
                />
              </svg>
            }
          />
        </Whisper>
      </Cell>
    );
  };

  return (
    <div>
      <div className="">
        <div className="flex gap-2">
          <InputGroup className="!w-full md:!w-[330px] h-[42px]">
            <InputGroup.Addon>
              {" "}
              <SearchIcon />
            </InputGroup.Addon>
            <Input
              placeholder="Search for employee name or job title"
              value={search}
              onChange={setSearch}
            />
          </InputGroup>
        </div>
        <Table
          height={400}
          data={data}
          className="border-[1px] border-[#F1F5F9] rounded-lg mt-4"
        >
          <Column width={200} align="left">
            <HeaderCell>First name</HeaderCell>
            <Cell dataKey="first_name" />
          </Column>
          <Column width={200} align="left">
            <HeaderCell>Last name</HeaderCell>
            <Cell dataKey="last_name" />
          </Column>
          <Column width={250} align="left">
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email" />
          </Column>
          <Column width={190}>
            <HeaderCell>Role</HeaderCell>
            <Cell dataKey="group" />
          </Column>

          <Column width={60}>
            <HeaderCell>
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0V14H18V0H0ZM5.33 12H2V2H5.33V12ZM10.67 12H7.34V2H10.67V12ZM16 12H12.67V2H16V12Z"
                  fill="#475569"
                />
              </svg>
            </HeaderCell>
            <ActionCell dataKey="id" />
          </Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={teamData?.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      {loadModal ? (
        <AddMember
          onClose={() => {
            setOpenMember(false);
            ToggleLoadModal();
          }}
          open={openMember}
          actionType="edit"
          memberData={rowData}
        />
      ) : null}
      <DeleteAction
        open={openDeleteModal}
        onClose={() => setDeleteModal(false)}
        roleData={rowData}
        type="member"
      />
    </div>
  );
}
