import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import axiosInstance from "./../../../../../utils/https";
import { Input, InputGroup, Pagination, SelectPicker, Table } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useSelector } from "react-redux";
import Loader from "../../../../../component/atoms/Loader";

const StatutoryDeductions = () => {
  const { Column, HeaderCell, Cell } = Table;
  const [search, setSearch] = useState("");
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { allBranches, allDept } = useSelector((state) => state?.users);
  const [department, setDepartment] = useState("");
  const [branch, setBranch] = useState("");

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const { data: deductionData, isLoading } = useQuery({
    queryKey: ["statutory-deductiojn"],
    queryFn: async () => {
      const response = await axiosInstance().get("/deductions/statutory");
      return response?.data?.data?.results;
    },
  });
  //datas
  const filteredData = deductionData?.filter(
    (deduction) =>
      deduction?.user_fullname
        ?.toLowerCase()
        ?.includes(search?.toLowerCase()) &&
      deduction?.department === (department || deduction?.department) &&
      deduction?.branch === (branch || deduction?.branch)
  );

  const branchOptions = allBranches?.map((branch) => {
    return {
      label: branch?.name,
      value: branch?.name,
    };
  });

  const deptOptions = allDept?.map((dept) => {
    return {
      label: dept?.name,
      value: dept?.name,
    };
  });
  const mainData = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  //components
  const NairaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.333333 4.94444H1.81481V0.5H3.2963L5.82963 4.94444H9.22222V0.5H10.7037V4.94444H12.1852V6.42593H10.7037V7.90741H12.1852V9.38889H10.7037V13.8333H9.22222L6.68148 9.38889H3.2963V13.8333H1.81481V9.38889H0.333333V7.90741H1.81481V6.42593H0.333333V4.94444ZM3.2963 4.94444H4.13333L3.2963 3.48519V4.94444ZM3.2963 6.42593V7.90741H5.82963L4.98518 6.42593H3.2963ZM9.22222 10.8704V9.38889H8.37037L9.22222 10.8704ZM6.67407 6.42593L7.52593 7.90741H9.22222V6.42593H6.67407Z"
              fill="#475569"
            />
          </svg>

          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };

  return isLoading ? (
    <div className="flex justify-center items-center mt-[10rem]">
      <Loader />
    </div>
  ) : (
    <div className="mt-10 bg-[#FFFFFF]  px-[2px] lg:px-10 py-6">
      <div className="flex justify-between items-center mb-5">
        <div className="flex flex-col lg:flex-row gap-2 w-full">
          <InputGroup className="!w-full md:!w-[330px] h-[42px]">
            <InputGroup.Addon>
              {" "}
              <SearchIcon />
            </InputGroup.Addon>
            <Input
              placeholder="Search for employee name"
              value={search}
              onChange={setSearch}
            />
          </InputGroup>
          <div className="flex gap-2">
            <SelectPicker
              placeholder="Branch"
              data={branchOptions}
              value={branch}
              onChange={setBranch}
              size="lg"
              className="w-full md:w-[200px]"
            />
            <SelectPicker
              placeholder="Department"
              data={deptOptions}
              value={department}
              onChange={setDepartment}
              size="lg"
              className="w-full md:w-[200px]"
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          height={400}
          data={mainData}
          className="border-[1px] border-[#F1F5F9] rounded-lg"
        >
          <Column width={150} align="left">
            <HeaderCell> Employee Name</HeaderCell>
            <Cell dataKey="user_fullname" />
          </Column>
          <Column width={130} align="left">
            <HeaderCell>Department</HeaderCell>
            <Cell dataKey="department" />
          </Column>

          <Column width={130} align="left">
            <HeaderCell>Branch</HeaderCell>
            <Cell dataKey="branch" />
          </Column>
          <Column width={130} align="left">
            <HeaderCell>Tax Amount</HeaderCell>
            <NairaCell dataKey="tax_amount" />
          </Column>
          <Column width={130} align="left">
            <HeaderCell>Pension Employee</HeaderCell>
            <NairaCell dataKey="pension_employee_amount" />
          </Column>
          <Column width={130} align="left">
            <HeaderCell>NHF</HeaderCell>
            <NairaCell dataKey="nhf_amount" />
          </Column>
          <Column width={130} align="left">
            <HeaderCell>NSITF</HeaderCell>
            <NairaCell dataKey="nsitf_amount" />
          </Column>
          <Column width={130} align="left">
            <HeaderCell>ITF</HeaderCell>
            <NairaCell dataKey="itf_amount" />
          </Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={deductionData?.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  );
};

export default StatutoryDeductions;
