import { useSelector } from "react-redux";

const useUserPermissions = () => {
  const { userDetails } = useSelector((state) => state?.users);

  const isAdmin = userDetails?.user_permissions === null;
  const userPermissions = userDetails?.user_permissions;

  //Role
  const viewRolePermission =
    userPermissions?.[0]?.group?.[3]?.access || isAdmin;
  const addRolePermission = userPermissions?.[0]?.group?.[0]?.access || isAdmin;
  const editRolePermission =
    userPermissions?.[0]?.group?.[1]?.access || isAdmin;
  const deleteRolePermission =
    userPermissions?.[0]?.group?.[2]?.access || isAdmin;

  //Team member
  const viewTeamPermission =
    userPermissions?.[3]?.group?.[1]?.access || isAdmin;
  const addTeamPermission = userPermissions?.[3]?.group?.[0]?.access || isAdmin;

  //Payroll
  const viewSetupPayrollPermission =
    userPermissions?.[1]?.group?.[4]?.access || isAdmin;
  const processPayrollPermission =
    userPermissions?.[1]?.group?.[4]?.access || isAdmin;

  //Employee
  const addEmployeePermission =
    userPermissions?.[2]?.group?.[0]?.access || isAdmin;
  const editEmployeePermission =
    userPermissions?.[2]?.group?.[1]?.access || isAdmin;
  const deleteEmployeePermission =
    userPermissions?.[2]?.group?.[2]?.access || isAdmin;
  const viewEmployeePermission =
    userPermissions?.[2]?.group?.[3]?.access || isAdmin;

  return {
    viewRolePermission,
    addRolePermission,
    editRolePermission,
    deleteRolePermission,
    viewTeamPermission,
    addTeamPermission,
    viewSetupPayrollPermission,
    processPayrollPermission,
    addEmployeePermission,
    editEmployeePermission,
    deleteEmployeePermission,
    viewEmployeePermission,
  };
};

export default useUserPermissions;
