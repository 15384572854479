/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import close from "./../../../../asset/svgs/close-circle.svg";
import lamp from "./../../../../asset/svgs/lamp-on.svg";
import { useFormik } from "formik";
import { StyledInputWrapper } from "../../../../component/atoms/StyledComponents";
import Select, { components } from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../utils/https";
import * as yup from "yup";
import toast from "react-hot-toast";
import BlueBotton from "../../../../component/atoms/BlueBotton";

const Withdraw = ({ open, onClose }) => {
  const [accountName, setAccountName] = useState("");
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      bank: "",
      account_number: "",
      account_name: "",
      amount: "",
    },
    // validationSchema
    onSubmit: (values, { resetForm }) => {
      withdrawMutation.mutate({
        account_number: values?.account_number,
        bank_code: values?.bank_code?.value,
        amount: values?.amount,
        description: ".",
      });
    },
    validationSchema: yup.object().shape({
      bank_code: yup.object().shape({
        label: yup.string().required("Bank is a required field"),
      }),
      account_number: yup.string().required("Account number is required"),
      amount: yup.string().required("Amount is required"),
    }),
  });

  //queries
  const { data: bankData } = useQuery({
    queryKey: ["getAllBanks"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/banks/`);
      return response?.data;
    },
  });

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/users/employees/confirm-account-detail", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setAccountName(data?.data?.data?.account_name);
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
  const withdrawMutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/virtual-accounts/withdraw", payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setValues("");
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  useEffect(() => {
    if (
      values.bank_code !== undefined &&
      values?.account_number?.length === 10 &&
      open
    ) {
      mutation.mutate({
        account_number: values?.account_number,
        bank_code: values?.bank_code?.value,
      });
    }
  }, [values?.bank_code, values?.account_number, open]);
  return (
    <Modal
      sx={{ padding: "16px 24px" }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="text-txgray2 text-[22px] font-semibold">Withdrawal</p>
        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="close" />
        </IconButton>
      </div>
      {/* <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded mb-4">
        <img src={lamp} alt="close" />
        <p>
          Please make sure the name of the beneficiary account must be the same
          as the name of the FUSE ERP wallet account
        </p>
      </div> */}
      <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
        <StyledInputWrapper>
          <Select
            value={values?.bank_code}
            onChange={(bank_code) => setFieldValue("bank_code", bank_code)}
            options={bankData?.data?.map((item) => {
              return { label: item.name, value: item.short_code };
            })}
            placeholder="Select Bank"
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "48px",
                fontSize: "16px",
              }),
            }}
          />

          {errors.bank_code && touched.bank_code && (
            <p className="field_error">{errors.bank_code}</p>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <input
            type="text"
            name="account_number"
            id="account_number"
            autoComplete="off"
            placeholder="Account number"
            value={values?.account_number}
            onChange={handleChange}
            onBlur={handleBlur}
            maxlength="10"
          />
          {errors.bank_code && touched.bank_code && (
            <p className="field_error">{errors.bank_code}</p>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <input
            type="text"
            name="accountName"
            id="accountName"
            autoComplete="off"
            placeholder="Account name"
            value={accountName}
            onBlur={handleBlur}
            disabled
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <input
            type="number"
            name="amount"
            id="amount"
            autoComplete="off"
            placeholder="Amount"
            value={values?.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            maxlength="10"
          />
          {errors.amount && touched.amount && (
            <p className="field_error">{errors.amount}</p>
          )}
        </StyledInputWrapper>
        <div className="flex gap-6 justify-end mt-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Cancel
          </button>
          <BlueBotton
            label="Proceed"
            type="submit"
            loading={mutation?.isPending || withdrawMutation?.isPending}
          />
        </div>
      </form>
    </Modal>
  );
};

export default Withdraw;
