import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import dropdown from "./../../../asset/svgs/dropdown.svg";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { DateRangeSinceCreated, convertDateToMonth } from "../../../utils";

const StyledCharts = styled(LineChart)`
  width: 100% !important;
  height: 100% !important;
  .recharts-layer {
    text {
      tspan {
        font-size: 10px !important;
        font-weight: 500 !important;
        color: #64748b !important;
        font-family: "Mulish", sans-serif !important;
      }
    }
  }
  .recharts-cartesian-grid {
    display: none;
  }
  .recharts-layer {
    .recharts-cartesian-axis-line {
      display: none;
    }
    .recharts-cartesian-axis-tick-line {
      display: none;
    }
  }
  .recharts-line {
    .recharts-line-curve {
      stroke: #2563eb;
      stroke-width: 3px;
    }
    .recharts-line-dots {
      .recharts-dot {
        stroke: #2563eb;
        stroke-width: 3px;
        r: 4;
      }
    }
  }
  .recharts-active-dot {
    .recharts-dot {
      fill: #2563eb;
    }
  }
`;
export default function PaydayStatistics({
  componentData,
  createdDate,
  value,
  setValue,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = componentData?.map((item) => {
    return {
      name: convertDateToMonth(item.month),
      amt: item.total_amount,
    };
  });
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col items-center text-txgray2 bg-white rounded px-2 py-1 border-[1px] border-[#F1F5F9]">
          <p className="font-bold text-[16px]">{`${Number(
            payload[0].value
          ).toLocaleString()} Naira spent`}</p>
          <p className="font-normal text-[12px]">{`${label}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div
      className="rounded bg-white border-[#F1F5F9] border-[1px] px-4 py-2 col-span-2"
      style={{ boxShadow: "2px 2px 8px 0px #9494940D" }}
    >
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] pb-2">
        <p className="tetx-[#334155] text-[18px] font-bold">Payday statistic</p>
        <div
          onClick={handleClick}
          className="flex gap-1 items-center border-[#E2E8F0] border-[1px] rounded px-2 py-1 cursor-pointer"
        >
          <p>{value}</p>
          <img src={dropdown} alt="calender" />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {DateRangeSinceCreated(createdDate)?.map((item, index) => (
            <MenuItem
              onClick={(event) => {
                handleClose();
                setValue(item);
              }}
              key={index}
            >
              <p className="text-semibold text-sm text-txgray2">{item}</p>
            </MenuItem>
          ))}
        </Menu>
      </div>
      <StyledCharts
        height={250}
        width={500}
        data={data}
        margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line type="monotone" dataKey="amt" stroke="#82ca9d" />
      </StyledCharts>
    </div>
  );
}
