import React, { useEffect, useState } from "react";
import {
  GetDeductionNames,
  GetDeductions,
} from "../../../../store/slices/PayrollSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../component/atoms/Loader";
import NilImg from "../../../../asset/dashboard/nil-hand.svg";
import BlueBotton from "../../../../component/atoms/BlueBotton";
import AddDeduction from "./deductionpages/AddDeduction";
import { toast } from "react-toastify";
import EmployeesWithDeduction from "./deductionpages/EmployeesWithDeduction";
import Tabs from "../../../dashboard-component/controls/Tabs";
import StatutoryDeductions from "./deductionpages/StatutoryDeductions";

export default function Deductions() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("noDeduction");
  const [mainPage, setMainPage] = useState("");
  const [activeTab, setActiveTab] = useState("statutory");
  const { deductions } = useSelector((state) => state?.payroll);
  const dispatch = useDispatch();

  //
  const getDeductions = async () => {
    setLoading(true);
    const res = await dispatch(GetDeductions());

    setLoading(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    if (deductions?.length <= 0) {
      getDeductions();
    } else if (!deductions) {
      getDeductions();
    }
    dispatch(GetDeductionNames());
  }, []);

  return loading ? (
    <div className="flex justify-center items-center mt-[10rem]">
      <Loader />
    </div>
  ) : (
    <>
      <div className="mt-6 inline-flex">
        <Tabs
          activeTab={activeTab}
          data={[
            {
              name: "Statutory Deductions",
              id: "statutory",
              action: () => setActiveTab("statutory"),
            },
            {
              name: "Other Deductions",
              id: "other",
              action: () => setActiveTab("other"),
            },
          ]}
        />
      </div>

      {activeTab === "statutory" ? (
        <StatutoryDeductions />
      ) : activeTab === "other" ? (
        deductions?.length <= 0 || mainPage === "addDeduction" ? (
          page === "noDeduction" ? (
            <div className="payroll-content">
              <div className="payroll-content-section min-h-[600px] flex items-center justify-center">
                <div className="xl:w-[70%] 2xl:w-[45%] mx-auto text-center">
                  <div className="payroll-content-section-img-box mb-6">
                    <img src={NilImg} className="w-auto mx-auto" alt="" />
                  </div>

                  <div className="payroll-content-section-text-section flex flex-col gap-4">
                    <h5 className="text-[#1e293b] text-[22px] font-semibold leading-[127.273%] mb-4">
                      No Employee Deductions found
                    </h5>
                    <p className="text-[#475569] text-[16px] mb-6">
                      You have not setup any employee deduction for your
                      organization yet. When you do setup one, the details will
                      appear here.
                    </p>
                    <BlueBotton
                      label={"Add New Deduction"}
                      onClick={() => setPage("addDeduction")}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AddDeduction setMainPage={setMainPage} />
          )
        ) : (
          <>
            <div className="flex justify-between mt-4">
              <BlueBotton
                label="Add New Deduction"
                onClick={() => {
                  setMainPage("addDeduction");
                  setPage("addDeduction");
                }}
              />
            </div>
            <EmployeesWithDeduction />
          </>
        )
      ) : null}
    </>
  );
}
