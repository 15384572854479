import React from "react";
import { Drawer } from "rsuite";

const EmployeeDetails = ({ open, onClose, data }) => {
  return (
    <Drawer placement={"right"} open={open} onClose={onClose}>
      <Drawer.Header>
        <Drawer.Title className="font-bold">Employee Details</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div className="border-[1px] border-[#F1F5F9] rounded">
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              First Name:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              {data && data?.first_name}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              Last Name:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              {data && data?.last_name}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              Email:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              {data && data?.email}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              Branch:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              {data && data?.branch}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              Department:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              {data && data?.department}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              Designation:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              {data && data?.designation}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="bg-[#F1F5F9] w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9]">
              Monthly Salary:
            </p>
            <p className="m-0 w-2/4 px-3 py-3 border-b-[1px] boder-[#F1F5F9] flex items-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66797 5.55556H3.85315V2H5.03834L7.06501 5.55556H9.77908V2H10.9643V5.55556H12.1495V6.74074H10.9643V7.92593H12.1495V9.11111H10.9643V12.6667H9.77908L7.74649 9.11111H5.03834V12.6667H3.85315V9.11111H2.66797V7.92593H3.85315V6.74074H2.66797V5.55556ZM5.03834 5.55556H5.70797L5.03834 4.38815V5.55556ZM5.03834 6.74074V7.92593H7.06501L6.38945 6.74074H5.03834ZM9.77908 10.2963V9.11111H9.0976L9.77908 10.2963ZM7.74056 6.74074L8.42204 7.92593H9.77908V6.74074H7.74056Z"
                  fill="#64748B"
                />
              </svg>

              {Number(data && data?.monthly_gross_salary).toLocaleString()}
            </p>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export default EmployeeDetails;
