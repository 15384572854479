import React, { useEffect } from "react";
import Ring1 from "../../asset/auth/ring1.svg";
import Ring2 from "../../asset/auth/ring2.svg";

const AuthLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="min-h-screen bg-[#F1F5F9] relative">
      <img
        src={Ring1}
        className="absolute top-0 left-0 w-[50px] z-[1]"
        alt=""
      />
      <img
        src={Ring2}
        className="absolute top-0 left-0 w-[90px] z-[1]"
        alt=""
      />
      {children}
    </div>
  );
};

export default AuthLayout;
