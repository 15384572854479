import React, { useState } from "react";
import { useFormik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../asset/auth/second-logo.svg";

import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import { LoginFormSchema } from "../schema";
import { LoginUser } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../utils";
import SignUpLayout from "../layout/SignUpLayout";
import ButtonFilled from "../../component/atoms/ButtonFilled";
import toast from "react-hot-toast";

const AuthLogin = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // previous path is going to be the former location or empty string, this helps in redirection after authentication

  const {
    REACT_APP_ADMIN_CLIENT_ID: client_id,
    REACT_APP_SCOPE: scopes,
    REACT_APP_GRANT_TYPE: grant_type,
  } = process.env;

  const onSubmit = async (values, actions) => {
    const { username, password } = values;

    const data = {
      username,
      password,
      client_id,
      client_secret: "c9g+@vk$5bb3ab804f49f44b289be70ae51f11659a",
      grant_type,
      scope: scopes,
    };
    const res = await dispatch(LoginUser(data));
    if (res.payload.status < 400) {
      const { access_token, expires_in, refresh_token, scope } =
        res?.payload?.data.data;
      setToken(access_token);
      setUser({ refresh_token, scope, expires_in });
      navigate("/auth/workspaces");
    } else {
      toast.error(
        res.payload.message === "Request failed with status code 500"
          ? "Something Went Wrong"
          : "Invalid user details"
      );
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginFormSchema,
    onSubmit,
  });

  return (
    <SignUpLayout>
      <div className="row">
        <div className="lg:w-10/12 2xl:w-10/12 mx-auto">
          <div className="second-auth-container-box-header mb-12">
            <div className="logo-box hidden sm:block">
              <img src={Logo} alt="" />
            </div>
            <div className="logo-box sm:hidden">
              <img src={Logo2} alt="" />
            </div>
            <h5 className="mt-8 sm:mt-0">Sign In </h5>
          </div>

          <div className="second-auth-container-box-body">
            <form className="row" onSubmit={handleSubmit}>
              <div className=" mb-6">
                <div
                  className={`input-box ${
                    !errors.username && touched.username && values.username
                      ? "filled"
                      : errors.username && touched.username
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    id="username"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="username" className="label-name">
                    <span className="label-text">Username </span>
                  </label>
                </div>
                <div>
                  {errors.username && touched.username && (
                    <p className="error">{errors.username}</p>
                  )}
                </div>
              </div>

              <div className=" mb-5">
                <div className="text-end forgot-password">
                  <Link to="/auth/forgot-password">
                    <p>Forgot Password?</p>
                  </Link>
                </div>
                <div
                  className={`input-password-box ${
                    !errors.password && touched.password && values.password
                      ? "filled"
                      : errors.password && touched.password
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type={viewPassword ? "text" : "password"}
                    name="password"
                    autoComplete="off"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span
                    className="view"
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewPassword(!viewPassword)}
                  >
                    {viewPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <label htmlFor="email" className="label-name">
                    <span className="label-text">Password </span>
                  </label>
                </div>

                <div>
                  {errors.password && touched.password && (
                    <p className="error">{errors.password}</p>
                  )}
                </div>
              </div>

              <ButtonFilled
                type="submit"
                loading={isSubmitting}
                style={{ width: "100%", justifyContent: "center" }}
              >
                Sign In
              </ButtonFilled>
            </form>

            <div>
              <div className="text-center external-login">
                <p>
                  Don't have an account?{" "}
                  <Link
                    to="/auth/sign-up"
                    className="text-[#3B82F6] text-[12px] tracking-[0.5px]"
                  >
                    Create a free account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default AuthLogin;
