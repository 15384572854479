import React, { useState } from "react";
import { useSelector } from "react-redux";
import noSlip from "../../../../asset/dashboard/noSlip.svg";
import naira from "../../../../asset/svgs/NairaBlack.svg";
import { Table, Pagination, InputGroup, Input } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import Loader from "../../../../component/atoms/Loader";
import { SelectPicker } from "rsuite";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../utils/https";
import { formatDate, formatDateWithDay } from "../../../../utils";
import { useNavigate } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

const Payslip = () => {
  const navigate = useNavigate();
  const { allBranches } = useSelector((state) => state?.users);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState("");

  const { data: allPayslips, isLoading } = useQuery({
    queryKey: ["allPayslips"],
    queryFn: async () => {
      const response = await axiosInstance().get("/payroll/records");
      return response?.data?.data?.results;
    },
  });
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const paidPayslip = allPayslips?.filter(
    (payslip) =>
      payslip?.status === "paid" ||
      payslip?.status === "approved" ||
      payslip?.status === "pending" // please change this to paid
  );
  const filteredData = paidPayslip?.filter(
    (payslip) =>
      (payslip?.branch?.toLowerCase()?.includes(search?.toLowerCase()) ||
        payslip?.payment_method
          ?.toLowerCase()
          ?.includes(search?.toLowerCase())) &&
      payslip?.branch === (branch || payslip?.branch)
  );

  const data = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const branchOptions = allBranches?.map((branch) => {
    return {
      label: branch?.name,
      value: branch?.id,
    };
  });
  //components
  const DateCell = ({ rowData, dataKey, ...props }) => {
    return <Cell {...props}>{formatDateWithDay(rowData[dataKey])}</Cell>;
  };

  const PeriodCell = ({ rowData, dataKey, ...props }) => {
    return <Cell {...props}>{formatDate(rowData[dataKey])}</Cell>;
  };
  const MoneyCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <img src={naira} alt="money" />
          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center mt-[10rem]">
          <Loader />
        </div>
      ) : (
        <>
          {allPayslips?.length > 0 ? (
            <div className="mt-10 bg-[#FFFFFF] p-2">
              <div className="flex justify-between items-center my-5">
                <div className="flex flex-col lg:flex-row gap-2 w-full">
                  <InputGroup className="!w-full md:!w-[330px] h-[42px]">
                    <InputGroup.Addon>
                      {" "}
                      <SearchIcon />
                    </InputGroup.Addon>
                    <Input
                      placeholder="Search for a branch or payment method"
                      value={search}
                      onChange={setSearch}
                    />
                  </InputGroup>
                  <div className="flex ml-0 lg:ml-1">
                    <SelectPicker
                      placeholder="Branch"
                      data={branchOptions}
                      style={{ width: "200px" }}
                      value={branch}
                      onChange={setBranch}
                      size="lg"
                    />
                  </div>
                </div>
              </div>
              <Table
                height={420}
                data={data}
                onRowClick={(rowData) =>
                  navigate(`/dashboard/payroll/branch-payroll/${rowData?.id}`)
                }
              >
                <Column width={100} align="left">
                  <HeaderCell>Pay period</HeaderCell>
                  <PeriodCell dataKey="payment_date" />
                </Column>
                <Column width={120} align="left">
                  <HeaderCell>Payment Date</HeaderCell>
                  <DateCell dataKey="payment_date" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Branch</HeaderCell>
                  <Cell dataKey="branch" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Gross Salary</HeaderCell>
                  <MoneyCell dataKey="gross_total_amount" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Deductions</HeaderCell>
                  <MoneyCell dataKey="total_deducted_amount" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Taxes</HeaderCell>
                  <MoneyCell dataKey="total_tax_paid" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Benefits</HeaderCell>
                  <MoneyCell dataKey="total_benefit_amount" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Bonus</HeaderCell>
                  <MoneyCell dataKey="total_bonus_amount" />
                </Column>
                <Column width={300} flexGrow={1}>
                  <HeaderCell>Payment Method</HeaderCell>
                  <Cell dataKey="payment_method" />
                </Column>
              </Table>
              <div style={{ padding: 20 }}>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="xs"
                  layout={["total", "-", "limit", "|", "pager", "skip"]}
                  total={allPayslips.length}
                  limitOptions={[10, 30, 50]}
                  limit={limit}
                  activePage={page}
                  onChangePage={setPage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </div>
          ) : (
            <div className="payroll-content">
              <div className="payroll-content-section min-h-[600px] flex items-center justify-center">
                <div className="xl:w-[70%] 2xl:w-[45%] mx-auto text-center">
                  <div className="payroll-content-section-img-box mb-6">
                    <img src={noSlip} className="w-auto mx-auto" alt="" />
                  </div>

                  <div className="payroll-content-section-text-section">
                    <h5 className="text-[#1e293b] text-[22px] font-semibold leading-[127.273%] mb-4">
                      No Payslips generated yet
                    </h5>
                    <p className="text-[#475569] text-[16px] mb-6">
                      You do not have any payroll that you haven’t generated
                      payslip for. Once you run , approve and pay any new
                      payroll it will appear here
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Payslip;
