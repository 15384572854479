import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MainModal from "../../../../../authPages/auth-component/MainModal";
import { UpdateComponent } from "../../../../../store/slices/PayrollSlice";

const CreateRoleModal = ({
  modalOpen,
  onClose,
  grossComponents,
  leave_policy,
  type,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDefaultComponents = async (event) => {
    event.preventDefault();
    const TOAST_ID = toast.loading("Creating component.., please wait...");
    if (leave_policy === "monthly") {
      setLoading(true);
      const res = await dispatch(
        UpdateComponent({
          name: "basic",
          percentage: "25",
          payroll_id: grossComponents[0]?.id,
          is_active: true,
          component_id: grossComponents[0]?.id,
        })
      );
      if (res.payload.status < 400) {
        const res_2 = await dispatch(
          UpdateComponent({
            name: "housing",
            percentage: "25",
            payroll_id: grossComponents[1]?.id,
            is_active: true,
            component_id: grossComponents[1]?.id,
          })
        );
        if (res_2.payload.status < 400) {
          const res_3 = await dispatch(
            UpdateComponent({
              name: "transport",
              percentage: "25",
              payroll_id: grossComponents[2]?.id,
              is_active: true,
              component_id: grossComponents[2]?.id,
            })
          );
          if (res_3.payload.status < 400) {
            const res_4 = await dispatch(
              UpdateComponent({
                name: "leave",
                percentage: "25",
                payroll_id: grossComponents[3]?.id,
                is_active: true,
                component_id: grossComponents[3]?.id,
              })
            );
            setLoading(false);
            if (res_4.payload.status < 400) {
              toast.update(TOAST_ID, {
                render: res?.payload?.data?.message,
                type: "sucecess",
                isLoading: false,
                autoClose: 1000,
              });
              onClose();
            }
          }
        }
      } else {
        toast.update(TOAST_ID, {
          render: res.payload.data.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    } else {
      setLoading(true);
      const res = await dispatch(
        UpdateComponent({
          name: "basic",
          percentage: "33.3",
          payroll_id: grossComponents[0]?.id,
          is_active: true,
          component_id: grossComponents[0]?.id,
        })
      );
      if (res.payload.status < 400) {
        const res_2 = await dispatch(
          UpdateComponent({
            name: "housing",
            percentage: "33.3",
            payroll_id: grossComponents[1]?.id,
            is_active: true,
            component_id: grossComponents[1]?.id,
          })
        );
        if (res_2.payload.status < 400) {
          const res_3 = await dispatch(
            UpdateComponent({
              name: "transport",
              percentage: "33.3",
              payroll_id: grossComponents[2]?.id,
              is_active: true,
              component_id: grossComponents[2]?.id,
            })
          );
          if (res_3.payload.status < 400) {
            const res_4 = await dispatch(
              UpdateComponent({
                name: "leave",
                percentage: "0",
                payroll_id: grossComponents[3]?.id,
                is_active: false,
                component_id: grossComponents[3]?.id,
              })
            );
            setLoading(false);
            if (res_4.payload.status < 400) {
              toast.update(TOAST_ID, {
                render: res?.payload?.data?.message,
                type: "sucecess",
                isLoading: false,
                autoClose: 1000,
              });
              onClose();
            }
          }
        }
      } else {
        toast.update(TOAST_ID, {
          render: res.payload.data.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };
  // const handleSubmit =

  return (
    <>
      <MainModal
        size={"sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle={`Confirm use default ${
          type === "policy" ? "policies" : "Components"
        }?`}
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          {type === "policy" ? (
            <p>
              We believe this to be best practice, so by selecting yes, we will
              set your payroll policies to a default option.
            </p>
          ) : (
            <p>
              We believe this to be best practice, so by selecting yes, we will
              set your payroll components to a default option.
            </p>
          )}
          <div className="flex justify-between items-center w-[50%] mt-5">
            <button className="create_role_cancel_button" onClick={onClose}>
              No, Cancel
            </button>
            <button
              className="create_role_save_button"
              onClick={handleDefaultComponents}
              disabled={loading}
            >
              Yes, use default
            </button>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default CreateRoleModal;
