/** @format */

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function LineButton({ onClick, disabled, label, loading, type, ...props }) {
  return (
    <div {...props} className="">
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={`${
          loading ? "px-8" : null
        } text-sm bg-[#EFF6FF] font-bold text-[#2563EB] py-[10px] px-4  rounded-md border-[1px] border-[#2563EB]`}
      >
        {loading ? (
          <CircularProgress
            sx={{
              color: "#2563EB",
              width: "20px !important",
              height: "20px !important",
            }}
          />
        ) : (
          label
        )}
      </button>
    </div>
  );
}

export default LineButton;
