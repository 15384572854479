import React from "react";

const Tabs = ({ activeTab, data }) => {
  return (
    <div className="bg-[#F8FAFC] rounded-3xl border-[#F1F5F9] border-[1px] p-1  flex">
      {data?.map((item, index) => (
        <div
          className={` font-bold text-[12px] md:text-[16px] py-2 px-9 rounded-[20px] cursor-pointer duration-200 ${
            activeTab === item.id
              ? "text-[#2563EB] bg-[#FFFFFF] "
              : "text-txgray2"
          } `}
          key={index}
          onClick={() => item.action()}
          style={{
            boxShadow:
              activeTab === item.id ? " 0px 8px 20px 0px #2563EB1A" : "",
          }}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
