import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SignUpLayout from "../layout/SignUpLayout";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import BlueBack from "../../asset/auth/blue-back-arrow.svg";
import { organizationURLFormSchema } from "../schema";
import { CreateUrl } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { getUserToken } from "../../utils";

const ChooseOrganization = () => {
  const [tenantId, setTenantId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = getUserToken();


  useEffect(() => {
    if(accessToken) {
      localStorage.clear();
    }
  }, [accessToken])

  useEffect(() => {
    const tenantData = JSON.parse(localStorage.getItem("tenant_data")) ?? {};
    const { tenant_id } = tenantData;
    setTenantId(tenant_id);
  }, []);
  const onSubmit = async (values, actions) => {
    const toastId = toast.loading(
      "Submitting Organization URL, please wait..."
    );
    const { website } = values;

    const data = {
      website: `https://www.${website}`,
      tenantId,
    };
    const res = await dispatch(CreateUrl(data));
    if (res.payload.status < 400) {
      toast.update(toastId, {
        render: res?.payload?.data?.message || "Account created successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setTimeout(() => {
        actions.resetForm();
        navigate("/auth/choose-features");
      }, 1600);
    } else {
      toast.update(toastId, {
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      website: "",
    },
    validationSchema: organizationURLFormSchema,
    onSubmit,
  });

  return (
    <SignUpLayout>
      <div className="2xl:w-11/12 mx-auto">
        <div className="flex gap-2 justify-between lg:justify-start items-center mb-6 lg:mb-0">
          <div className="auth-logo-box lg:hidden mb-6">
            <img src={Logo2} alt="" />
          </div>
          <div className="auth-container-box-two-step-box mb-5">
            <h6>Step 1/3</h6>
            <div className="flex items-center gap-1 mt-1">
              <span className="active"></span>
              <span></span>

              <span></span>
            </div>
          </div>
        </div>
        <div className="xl:w-8/12 sm:w-9/12 md:w-8/12 mx-auto auth-container-box-two-content-header">
          <h4>Choose your organization URL</h4>
        </div>

        <div className="row auth-container-box-two-content-body">
          <div className="xl:w-8/12 sm:w-9/12 md:w-8/12 mx-auto">
            <div>
              <form className="row" onSubmit={handleSubmit}>
                <div className=" mb-4">
                  <div
                    className={`input-box ${
                      !errors.website && touched.website && values.website
                        ? "filled"
                        : errors.website && touched.website
                        ? "error"
                        : ""
                    }`}
                  >
                    <input
                      type="text"
                      name="website"
                      autoComplete="off"
                      placeholder="e.g paystack.fuseerp.com"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="email" className="label-name">
                      <span className="label-text">Organization URL </span>
                    </label>
                  </div>
                  {/* <div className="more-info">
                    <p>View other suggestions</p>
                  </div> */}
                  <div>
                    {errors.website && touched.website && (
                      <p className="error">{errors.website}</p>
                    )}
                  </div>
                </div>

                <div className="submit-btn">
                  <button disabled={isSubmitting}>Continue</button>

                  <Link to="/auth/sign-up" className="text-decoration-none">
                    <p className="flex justify-center items-center text-center mt-2">
                      <span className="icon me-1">
                        <img src={BlueBack} alt="" />
                      </span>
                      <span className="text">Go to previous</span>
                    </p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default ChooseOrganization;
