import React from "react";
import BlueBotton from "../../../component/atoms/BlueBotton";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  payrollTab,
  selectedBranchesPersist,
} from "../../dashboard-pages/Human Resources/utils/states";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../utils/https";

export const PayrollRequestAlert = () => {
  const navigate = useNavigate();
  const [tabs, setActiveTab] = useRecoilState(payrollTab);
  const [selectedBranches, setSelectedBranches] = useRecoilState(
    selectedBranchesPersist
  );

  const { data: branchPayroll } = useQuery({
    queryKey: ["branchesPayrollRecord"],
    queryFn: async () => {
      const response = await axiosInstance().get(`branches/payroll-records`);
      return response?.data?.data?.results;
    },
  });

  return (
    <div className="flex justify-between items-center bg-[#EFF6FF] border-[#93C5FD] border-[1px] rounded px-6 py-2 mb-6">
      <p className="text-sm text-[#2563EB] font-bold">
        You have a run payroll request
      </p>
      <BlueBotton
        label="Review request"
        onClick={() => {
          navigate("/dashboard/payroll");
          setActiveTab(4);
          setSelectedBranches(
            branchPayroll
              ?.filter(
                (item) => item.current_month_payroll_record_status === "pending"
              )
              ?.map((item) => item.name)
          );
        }}
      />
    </div>
  );
};
