/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import MainModal from "../../../../../authPages/auth-component/MainModal";
import AddBranch from "./AddBranch";
import AddDepartment from "./AddDepartment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CreateCustomLevel from "../../../../../authPages/modals/CreateCustomLevel";
import BackIcon from "../../../../../asset//svgs/close-circle.svg";
import UploadFromCsv from "./UploadFromCsv";
import PayrollBreakdown from "./PayrollBreakDown";
import {
  CreateEmployees,
  EditEmployee,
  GetAllEmployees,
  GetBreakdown,
  VerifyAcountNumber,
} from "../../../../../store/slices/PayrollSlice";
import { bankCodeOptions } from "../../utils/constants";
import Select, { components } from "react-select";
import { format } from "date-fns";
import { IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../../utils/https";
import { isEmpty } from "../../../../../utils";

const AddemployeeModal = ({ modalOpen, onClose, type, data, fetch }) => {
  const dispatch = useDispatch();
  const [branchModal, setBranchModal] = useState(false);
  const [levelModal, setLevelModal] = useState(false);
  const [departmentModal, setDepartmentModal] = useState(false);
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [breakdownModal, setBreakdownModal] = useState(false);
  const { allBranches, allLevels, allDept, userDetails } = useSelector(
    (state) => state?.users
  );
  const { payrollSetup } = useSelector((state) => state?.payroll);
  const [accountName, setAccountName] = useState("");

  // async functions

  //queries
  const { data: bankData, isLoading } = useQuery({
    queryKey: ["getAllBanks"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/banks/`);
      return response?.data;
    },
  });

  const onSubmit = async (values, actions) => {
    const TOAST_ID = toast.loading(
      type === "edit"
        ? "Updating employee.., please wait..."
        : "Creating employee.., please wait..."
    );
    const {
      first_name,
      last_name,
      middle_name,
      date_of_birth,
      designation,
      resumption,
      branch,
      monthly_gross_salary,
      has_nhf,
      account_number,
      level,
      department,
      bank_code,
      email,
    } = values;
    const body = {
      first_name,
      last_name,
      middle_name,
      date_of_birth,
      designation,
      bank_code: bank_code?.value,
      resumption,
      branch: branch?.value,
      monthly_gross_salary,
      has_nhf,
      account_number,
      level: level.value,
      department: department?.value,
      email,
    };

    const res = await dispatch(
      data
        ? EditEmployee({
            ...body,
            user_id: data?.user_id,
          })
        : CreateEmployees(body)
    );
    if (res.payload.status < 400) {
      const getEmployees = await dispatch(GetAllEmployees());
      if (getEmployees.payload.status < 400) {
        toast.update(TOAST_ID, {
          render: res?.payload?.data?.message,
          type: "sucecess",
          isLoading: false,
          autoClose: 1200,
        });
        setAccountName("");
        actions.resetForm();
        onClose();
      }
    } else {
      toast.update(TOAST_ID, {
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  // fORMIK
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      middle_name: "",
      date_of_birth: new Date("YYYY-MM-DD"),
      designation: "",
      resumption: new Date("YYYY-MM-DD"),
      branch: "",
      monthly_gross_salary: "",
      has_nhf: false,
      account_number: "",
      level: "",
      department: "",
      bank_code: "",
      email: "",
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required("First Name is required"),
      middle_name: yup.string().required("Middle Name is required"),
      last_name: yup.string().required("Last Name is required"),
      date_of_birth: yup
        .date()
        .typeError("Date of birth is required")
        .required("This field is required"),
      designation: yup.string().required("Job title is required"),
      resumption: yup
        .date()
        .typeError("Date of resumption is required")
        .required("This field is required"),
      branch: yup.object().shape({
        label: yup.string().required("Branch is a required field"),
      }),
      monthly_gross_salary: yup.string().required("Monthly gross is required"),
      bank_code: yup.object().shape({
        label: yup.string().required("Bank is a required field"),
      }),
      email: yup.string().email("Invalid email").required("Enter your email"),
      account_number: yup
        .string()
        .length(10)
        .required("Account number is required"),
      level: yup.object().shape({
        label: yup.string().required("Level is a required field"),
      }),
      department: yup.object().shape({
        label: yup.string().required("Department is a required field"),
      }),
    }),
    onSubmit,
  });

  const branchOptions = allBranches?.map((branch) => {
    return {
      label: branch?.name,
      value: branch?.id,
    };
  });

  const jobLevelOptions = allLevels?.map((level) => {
    return {
      label: level?.level_name,
      value: level?.id,
    };
  });

  useEffect(() => {
    if (
      values.bank_code !== undefined &&
      values?.account_number?.length === 10 &&
      modalOpen
    ) {
      const verifyAcoountDetails = async () => {
        const TOAST_ID = toast.loading(
          "Verifying Account details.., please wait..."
        );
        setLoading(true);
        const body = {
          account_number: values?.account_number,
          bank_code: values?.bank_code?.value,
        };
        const res = await dispatch(VerifyAcountNumber(body));
        setLoading(false);
        if (res.payload.status < 400) {
          toast.update(TOAST_ID, {
            render: res?.payload?.data?.message,
            type: "sucecess",
            isLoading: false,
            autoClose: 1000,
          });
          setAccountName(res.payload?.data?.data?.account_name);
        } else {
          toast.update(TOAST_ID, {
            render: res.payload.data.message,
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }
      };
      verifyAcoountDetails();
    }
  }, [values?.bank_code, values?.account_number, modalOpen]);

  const deptOptions = allDept?.map((dept) => {
    return {
      label: dept?.name,
      value: dept?.id,
    };
  });
  const DeptMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props?.selectProps?.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => setDepartmentModal(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add Dept</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };
  const BranchMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props?.selectProps?.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => setBranchModal(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add Branch</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };
  const LevelMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => setLevelModal(true)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#2563EB"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <span>Add Level</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  useEffect(() => {
    if (data) {
      const findBranch = branchOptions?.find(
        (branch) => branch?.label === data.branch
      );
      const findDept = deptOptions?.find(
        (dept) => dept?.label === data.department
      );
      const findLevel = jobLevelOptions?.find(
        (level) => level?.label === data.level
      );
      const findBank = bankData?.data?.find(
        (bank) => bank?.short_code === data.bank_code
      );
      setFieldValue("first_name", data?.first_name);
      setFieldValue("last_name", data?.last_name);
      setFieldValue("middle_name", data?.middle_name);
      setFieldValue("email", data?.email);
      setFieldValue("designation", data?.designation);
      setFieldValue("branch", findBranch);
      setFieldValue("department", findDept);
      setFieldValue("level", findLevel);
      setFieldValue("bank_code", {
        label: findBank?.name,
        value: findBank?.short_code,
      });
      setFieldValue("account_number", data?.account_number);
      setFieldValue("monthly_gross_salary", data?.monthly_gross_salary);
      setFieldValue("has_nhf", data?.has_nhf);
      setFieldValue(
        "date_of_birth",
        format(new Date(data?.date_of_birth), "yyyy-MM-dd")
      );
      setFieldValue(
        "resumption",
        format(new Date(data?.resumption), "yyyy-MM-dd")
      );
    }
  }, [data, isLoading]);

  const getBreakdown = async () => {
    setLoading(true);
    const body = {
      amount: values?.monthly_gross_salary,
      payroll_id: payrollSetup?.id,
      nhf: values?.has_nhf,
    };
    const res = await dispatch(GetBreakdown(body));
    setLoading(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  return (
    <>
      <MainModal
        size={"sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        backdrop="static"
        noClose
        defaultTitle={
          <div className="flex items-center justify-between">
            <h6>{data ? "Update Employee" : "Add Employee"}</h6>
            <IconButton className="mr-3 cursor-pointer" onClick={onClose}>
              <img src={BackIcon} alt="BackIcon" />
            </IconButton>
          </div>
        }
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          <div>
            <form onSubmit={handleSubmit}>
              <div className="border-2 rounded px-4 py-7 mb-5">
                <h6>Personal information</h6>
                <p>Enter employee information below</p>
                <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3 mt-5">
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.first_name &&
                        touched?.first_name &&
                        values?.first_name
                          ? "filled"
                          : errors?.first_name && touched?.first_name
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="off"
                        placeholder="First Name"
                        value={values?.first_name || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      {errors.first_name && touched.first_name && (
                        <p className="error">{errors.first_name}</p>
                      )}
                    </div>
                  </div>
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.middle_name &&
                        touched?.middle_name &&
                        values?.middle_name
                          ? "filled"
                          : errors?.middle_name && touched?.middle_name
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="middle_name"
                        id="middle_name"
                        autoComplete="off"
                        placeholder="Middle Name"
                        value={values?.middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div>
                        {errors?.middle_name && touched?.middle_name && (
                          <p className="error">{errors?.middle_name}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3 mt-5">
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.last_name && touched?.name && values?.last_name
                          ? "filled"
                          : errors?.last_name && touched?.last_name
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        autoComplete="off"
                        placeholder="Last Name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      {errors?.last_name && touched?.last_name && (
                        <p className="error">{errors?.last_name}</p>
                      )}
                    </div>
                  </div>
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.date_of_birth &&
                        touched?.date_of_birth &&
                        values?.date_of_birth
                          ? "filled"
                          : errors?.date_of_birth && touched?.date_of_birth
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="date"
                        id="date_of_birth"
                        name="date_of_birth"
                        max="2007-12-31"
                        value={values?.date_of_birth}
                        onChange={handleChange}
                      />
                      <label htmlFor="start" className="!bottom-[10px]">
                        <span className="bg-white px-1">Date of birth</span>
                      </label>
                    </div>
                    <div>
                      {errors?.date_of_birth && touched?.date_of_birth && (
                        <p className="error">{errors?.date_of_birth}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3 mt-5">
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.email && touched?.email && values?.email
                          ? "filled"
                          : errors?.email && touched?.email
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        placeholder="Email"
                        value={values?.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      {errors?.email && touched?.email && (
                        <p className="error">{errors?.email}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-2 rounded px-4 py-7 mb-5">
                <h6>Job information</h6>
                <p>Enter employee information below</p>
                <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3 mt-5">
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.name &&
                        touched?.designation &&
                        values?.designation
                          ? "filled"
                          : errors?.designation && touched?.designation
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="designation"
                        id="designation"
                        autoComplete="off"
                        placeholder="Job Title"
                        value={values?.designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div>
                      {errors?.designation && touched?.designation && (
                        <p className="error">{errors?.designation}</p>
                      )}
                    </div>
                  </div>
                  <div className="md:w-6/12 w-full">
                    <div className="input-box">
                      <Select
                        value={values?.department}
                        name="Add Department"
                        onChange={(department) =>
                          setFieldValue("department", department)
                        }
                        fetchingData={fetch}
                        options={deptOptions}
                        placeholder="Select Department"
                        components={{ Menu: DeptMenu, Option }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: "48px",
                            fontSize: "16px",
                          }),
                        }}
                      />
                      <div>
                        {errors?.department && touched?.department && (
                          <p className="error">{errors?.department?.label}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div
                    className={`input-box ${
                      !errors?.resumption &&
                      touched?.resumption &&
                      values?.resumption
                        ? "filled"
                        : errors?.resumption && touched?.resumption
                        ? "error"
                        : ""
                    }`}
                  >
                    <input
                      type="date"
                      id="resumption"
                      name="resumption"
                      value={values?.resumption}
                      onChange={handleChange}
                    />
                    <label htmlFor="start" className="!bottom-[11px]">
                      <span className="bg-white px-1">Date of resumption</span>
                    </label>
                  </div>
                  <div>
                    {errors?.resumption && touched?.resumption && (
                      <p className="error">{errors?.resumption}</p>
                    )}
                  </div>
                </div>
                <div className="mt-7">
                  <Select
                    value={values?.branch}
                    onChange={(branch) => setFieldValue("branch", branch)}
                    fetchingData={fetch}
                    options={branchOptions}
                    name="Add Level"
                    placeholder="Select Branch"
                    components={{ Menu: BranchMenu, Option }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: "48px",
                        fontSize: "16px",
                      }),
                    }}
                  />
                  <div>
                    {errors?.branch && touched?.branch && (
                      <p className="error">{errors?.branch.label}</p>
                    )}
                  </div>
                </div>
                <div className="mt-7">
                  <Select
                    value={values.level}
                    onChange={(level) => setFieldValue("level", level)}
                    fetchingData={fetch}
                    options={jobLevelOptions}
                    name="Add Level"
                    placeholder="Search Level"
                    components={{ Menu: LevelMenu, Option }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: "48px",
                        fontSize: "16px",
                      }),
                    }}
                  />
                  <div>
                    {errors?.level && touched?.level && (
                      <p className="error">{errors?.level.label}</p>
                    )}
                  </div>
                </div>
                {/* <span className="underline text-[#2563EB] text-[11px] flex justify-end mt-1">
                  Add work days and time
                </span> */}
              </div>
              <div className="border-2 rounded px-4 py-7 mb-5">
                <h6>Pay information</h6>
                <p>Enter employee nformation below</p>
                <div className="flex md:flex-row flex-col md:items-center flex-grow gap-3 mt-5">
                  <div className="md:w-6/12 w-full">
                    <div
                      className={`input-box ${
                        !errors?.monthly_gross_salary &&
                        touched?.monthly_gross_salary &&
                        values?.monthly_gross_salary
                          ? "filled"
                          : errors?.monthly_gross_salary &&
                            touched?.monthly_gross_salary
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="number"
                        name="monthly_gross_salary"
                        id="monthly_gross_salary"
                        autoComplete="off"
                        min="1"
                        value={values?.monthly_gross_salary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="start" className="!bottom-[11px]">
                        <span className="bg-white px-1">
                          Starting Monthly gross pay
                        </span>
                      </label>
                    </div>
                    <div>
                      {errors?.monthly_gross_salary &&
                        touched?.monthly_gross_salary && (
                          <p className="error">
                            {errors?.monthly_gross_salary}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <button
                  className="text-[#2563EB] text-[11px] flex justify-end mt-1 cursor-pointer float-right"
                  onClick={() => {
                    getBreakdown();
                    setBreakdownModal(true);
                  }}
                  disabled={values?.monthly_gross_salary === ""}
                >
                  View payroll break down
                </button>

                <div className="gap-1.5 flex items-center mt-5">
                  <input
                    type="checkbox"
                    name="has_nhf"
                    id="has_nhf"
                    value={values?.has_nhf}
                    onChange={handleChange}
                  />
                  <span className="flex items-center gap-[2px]">
                    <span className="font-medium text-[12px] leading-[133.33%]">
                      Add N.H fund for employee
                    </span>
                  </span>
                </div>
              </div>
              <h6 className="mt-10">Salary bank details</h6>
              <div
                className={`input-box mt-5 ${
                  !errors?.bank_code && touched?.bank_code && values?.bank_code
                    ? "filled"
                    : errors?.bank_code && touched?.bank_code
                    ? "error"
                    : ""
                }`}
              >
                <Select
                  value={values?.bank_code}
                  onChange={(bank_code) =>
                    setFieldValue("bank_code", bank_code)
                  }
                  options={bankData?.data?.map((item) => {
                    return { label: item?.name, value: item.short_code };
                  })}
                  placeholder="Select Bank"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "48px",
                      fontSize: "16px",
                    }),
                  }}
                />

                <div>
                  {errors?.bank_code && touched?.bank_code && (
                    <p className="error">{errors?.bank_code.label}</p>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <div
                  className={`input-box ${
                    !errors?.account_number &&
                    touched?.account_number &&
                    values?.account_number
                      ? "filled"
                      : errors?.account_number && touched?.account_number
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="string"
                    name="account_number"
                    id="account_number"
                    autoComplete="off"
                    placeholder="Bank account number"
                    value={values?.account_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxlength="10"
                  />
                </div>
                <div>
                  {errors?.account_number && touched?.account_number && (
                    <p className="error">{errors?.account_number}</p>
                  )}
                </div>
              </div>
              <div className="mt-4 input-box">
                <input
                  type="text"
                  name="accountName"
                  id="accountName"
                  autoComplete="off"
                  placeholder="Bank account name"
                  value={accountName}
                  onBlur={handleBlur}
                  disabled
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting || loading}
                className="flex justify-center items-center bg-[#2563EB] text-[#FFFFFF] mt-7 py-3 rounded font-[700] w-[100%] cursor-pointer"
                onClick={() => {
                  if (!isEmpty(errors)) {
                    toast.error("please fill all fields");
                    // console.log(errors);
                  }
                }}
              >
                Submit
              </button>
            </form>
          </div>
          <AddBranch
            modalOpen={branchModal}
            onClose={() => setBranchModal(false)}
          />
          <CreateCustomLevel
            modalOpen={levelModal}
            onClose={() => setLevelModal(false)}
          />
          <UploadFromCsv
            modalOpen={openCsvModal}
            onClose={() => setOpenCsvModal(false)}
            size="sm"
          />
          <PayrollBreakdown
            modalOpen={breakdownModal}
            onClose={() => setBreakdownModal(false)}
            size="sm"
            loading={loading}
          />
          <AddDepartment
            modalOpen={departmentModal}
            onClose={() => setDepartmentModal(false)}
            size="sm"
          />
        </div>
      </MainModal>
    </>
  );
};

export default AddemployeeModal;
