import React, { Fragment, useEffect, useState } from "react";
import MainModal from "../../../../../component/MainModal.";
import DefaultPolicy from "../modals/DefaultPolicy";
import NewComponentModal from "../modals/NewComponent";
import EditReliefModal from "../modals/EditRelieftModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import LightBulb from "../../../../../asset/dashboard/lightbulb.svg";
import Pen from "../../../../../asset/dashboard/pen.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  ActivatePayrollSetup,
  CreateMandatoryRemittanceComponent,
  CreateReliefComponent,
  PayrollSetup,
  UpdatePayrollSetup,
} from "../../../../../store/slices/PayrollSlice";
import { PoliciesFormSchema } from "../../../../../authPages/schema";
import { Tooltip, Whisper } from "rsuite";
import {
  LeaveOptions,
  menuOptions,
  paydayOptions,
  PensionOptions,
} from "../../utils/constants";
import _ from "lodash";
import Select, { components } from "react-select";
import LineButton from "../../../../../component/atoms/LineButton";

const PayrollSetupMainModal = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();
  const { payrollSetup } = useSelector((state) => state?.payroll);
  const [activeSetupTab, setActiveSetupTab] = useState("Policies");
  const [defaultPolicy, setDefaultPolicy] = useState(false);
  const [type, setType] = useState("");
  const [newComponentModal, setNewComponentupModal] = useState(false);
  const [editRelieftModal, setEditReliefmodal] = useState(false);
  const [activeDay, setActiveDay] = useState("month_on_month");
  const [custom, setCustom] = useState(false);
  const [grossComponents, setGrossComponents] = useState([]);
  const [employees, setEmployees] = useState(">25");
  const [fetch] = useState(false);
  const [data, setData] = useState({});
  const [reliefComponents, setReliefComponents] = useState([]);
  const [remmitalComponents, setRemmitalComponents] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (employees === "<15" && modalOpen) {
      const newReliefComponents = reliefComponents?.map((item) => {
        return { ...item, is_active: false };
      });
      const newRemittalComponents = remmitalComponents?.map((item) => {
        if (item?.name === "nsitf") {
          return { ...item, is_active: true };
        } else if (item?.name === "itf") {
          return { ...item, is_active: false };
        }
      });
      setReliefComponents(newReliefComponents);
      setRemmitalComponents(newRemittalComponents);
    } else if (employees === "<25" && modalOpen) {
      const newReliefComponents = reliefComponents?.map((item) => {
        if (item?.name === "pension_employee") {
          return { ...item, is_active: true };
        } else if (item?.name === "pension_employer") {
          return { ...item, is_active: true };
        } else if (item?.name === "nhf") {
          return { ...item, is_active: false };
        }
      });
      const newRemittalComponents = remmitalComponents?.map((item) => {
        if (item?.name === "nsitf") {
          return { ...item, is_active: true };
        } else if (item?.name === "itf") {
          return { ...item, is_active: false };
        }
      });
      setReliefComponents(newReliefComponents);
      setRemmitalComponents(newRemittalComponents);
    } else if (employees === ">25" && modalOpen) {
      const newReliefComponents = reliefComponents?.map((item) => {
        if (item?.name === "pension_employee") {
          return { ...item, is_active: true };
        } else if (item?.name === "pension_employer") {
          return { ...item, is_active: true };
        } else if (item?.name === "nhf") {
          return { ...item, is_active: false };
        }
      });
      const newRemittalComponents = remmitalComponents?.map((item) => {
        if (item?.name === "nsitf") {
          return { ...item, is_active: true };
        } else if (item?.name === "itf") {
          return { ...item, is_active: true };
        }
      });
      setReliefComponents(newReliefComponents);
      setRemmitalComponents(newRemittalComponents);
    }
  }, [employees, modalOpen]);

  useEffect(() => {
    if (payrollSetup) {
      setActiveDay(payrollSetup?.work_days_for_payment);
      const gross_components = payrollSetup?.components?.filter(
        (component) => component.category === "gross"
      );
      const relief_components = payrollSetup?.components?.filter(
        (component) => component.category === "relief"
      );
      const remittance_components = payrollSetup?.components?.filter(
        (component) => component.category === "mandatory_remittance"
      );
      setReliefComponents(relief_components);
      setRemmitalComponents(remittance_components);
      setGrossComponents(gross_components);
    }
  }, [payrollSetup]);

  useEffect(() => {
    if (payrollSetup && !payrollSetup?.setup_complete) {
      setActiveSetupTab("Components");
    } else {
      setActiveSetupTab("Policies");
    }
  }, [payrollSetup]);

  const handleRemmital = async (TOAST_ID, actions) => {
    const modified_remmitalComponents = remmitalComponents?.map((item) => {
      return {
        name: item?.name,
        is_active: item?.is_active,
        percentage: item?.percentage,
        payroll_id: item?.payroll_id,
        id: item.id,
      };
    });
    const res = await dispatch(
      CreateMandatoryRemittanceComponent(modified_remmitalComponents)
    );
    if (res.payload.status < 400) {
      dispatch(
        ActivatePayrollSetup(modified_remmitalComponents?.[0]?.payroll_id)
      );
      toast.update(TOAST_ID, {
        render: res?.payload?.message || res?.payload?.data?.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setActiveSetupTab("Employees");
      actions.resetForm();
      onClose();
    } else {
      toast.update(TOAST_ID, {
        render: res.payload.data?.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const onSubmit = async (values, actions) => {
    if (activeSetupTab === "Policies") {
      const {
        custom_payday_day,
        payday,
        overtime_policy,
        leave_policy,
        pension_computation,
        total_number_of_work_days,
      } = values;

      const paydayData = {
        payday: custom ? "custom" : payday?.value,
        custom_payday_day: custom ? custom_payday_day?.value : null,
        work_days_for_payment: activeDay,
        total_number_of_work_days:
          activeDay === "absolute" ? total_number_of_work_days : 30,
        overtime_policy,
        leave_policy: leave_policy?.value,
        pension_computation: pension_computation?.value,
      };
      const TOAST_ID = toast.loading(
        payrollSetup
          ? "Updating setup instance, please wait..."
          : "Creating setup instance, please wait..."
      );
      const res = await dispatch(
        payrollSetup
          ? UpdatePayrollSetup({ ...paydayData, id: payrollSetup?.id })
          : PayrollSetup(paydayData)
      );
      if (res.payload.status < 400) {
        if (payrollSetup) {
          dispatch(ActivatePayrollSetup(res?.payload?.data?.data?.id));
          onClose();
        }
        toast.update(TOAST_ID, {
          render: res?.payload?.message || res?.payload?.data?.message,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        setActiveSetupTab("Components");
        actions.resetForm();
      } else {
        toast.update(TOAST_ID, {
          render:
            res.payload.message === "Request failed with status code 500"
              ? "Something Went Wrong"
              : "invalid_user",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    } else {
      const TOAST_ID = toast.loading(
        payrollSetup.setup_complete
          ? "Updating component, please wait..."
          : "Creating component, please wait..."
      );
      const modified_remmitalComponents = reliefComponents?.map((item) => {
        return {
          name: item?.name,
          is_active: item?.is_active,
          percentage: item?.percentage,
          payroll_id: item?.payroll_id,
          id: item.id,
        };
      });
      const res = await dispatch(
        CreateReliefComponent(modified_remmitalComponents)
      );
      if (res.payload.status < 400) {
        handleRemmital(TOAST_ID, actions);
      } else {
        toast.update(TOAST_ID, {
          render:
            res.payload.message === "Request failed with status code 500"
              ? "Something Went Wrong"
              : "invalid_user",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      payday: "",
      custom_payday_day: "",
      total_number_of_work_days: "",
      overtime_policy: false,
      leave_policy: "",
      pension_computation: "",
    },
    validationSchema: PoliciesFormSchema,
    onSubmit,
  });

  useEffect(() => {
    if (payrollSetup !== null && payrollSetup !== undefined) {
      const findPayday = paydayOptions?.find(
        (payday) => payday.value === payrollSetup?.payday
      );
      const findPolicy = LeaveOptions?.find(
        (policy) => policy.value === payrollSetup?.leave_policy
      );
      const findComputation = PensionOptions.find(
        (pension) => pension.value === payrollSetup?.pension_computation
      );
      setActiveDay(payrollSetup?.work_days_for_payment);
      setFieldValue(
        "payday",
        findPayday || { label: "Custom", value: "custom" }
      );
      setFieldValue("custom_payday_day", payrollSetup?.custom_payday_day);
      setFieldValue(
        "total_number_of_work_days",
        payrollSetup?.total_number_of_work_days
      );
      setFieldValue("leave_policy", findPolicy);
      setFieldValue("overtime_policy", payrollSetup?.overtime_policy);
      setFieldValue("pension_computation", findComputation);
    }
  }, [payrollSetup]);

  const previous_setup = {
    payday: payrollSetup?.payday,
    custom_payday_day: payrollSetup?.custom_payday_day?.value,
    work_days_for_payment: payrollSetup?.work_days_for_payment,
    total_number_of_work_days: payrollSetup?.total_number_of_work_days,
    overtime_policy: payrollSetup?.overtime_policy,
    leave_policy: payrollSetup?.leave_policy,
    pension_computation: payrollSetup?.pension_computation,
  };

  const paydayData = {
    payday: custom ? "custom" : values.payday?.value,
    custom_payday_day: custom ? values.custom_payday_day?.value : undefined,
    work_days_for_payment: activeDay,
    total_number_of_work_days:
      activeDay === "absolute" ? values?.total_number_of_work_days : 30,
    overtime_policy: values?.overtime_policy,
    leave_policy: values?.leave_policy?.value,
    pension_computation: values?.pension_computation?.value,
  };
  const check = _.isEqual(previous_setup, paydayData);

  const handleReliefChange = (event, id) => {
    const newReliefComponents = reliefComponents?.map((item) => {
      if (item?.id === id) {
        return { ...item, is_active: event.target.checked };
      } else {
        return item;
      }
    });
    setReliefComponents(newReliefComponents);
  };

  const handleRemittalChange = (event, id) => {
    const newRemittalComponents = remmitalComponents?.map((item) => {
      if (item?.id === id) {
        return { ...item, is_active: event.target.checked };
      } else {
        return item;
      }
    });
    setRemmitalComponents(newRemittalComponents);
  };

  const DeptMenu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {props.selectProps.fetchingData ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
            <button
              type="button"
              className="text-[#2563EB] my-1 flex justify-center items-center"
              onClick={() => {
                setFieldValue("payday", "custom");
                setCustom(true);
                setMenuIsOpen(false);
              }}
            >
              <span>Choose Custom</span>
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  };

  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  const gross_percentages =
    payrollSetup !== undefined &&
    grossComponents?.length > 0 &&
    grossComponents
      ?.map((item) => Number(item.percentage))
      ?.reduce((acc, value) => acc + value + 0);
  return (
    <>
      <MainModal
        size={size || "md"}
        modalOpen={modalOpen}
        onClose={() => {
          onClose();
          if (payrollSetup) {
            dispatch(ActivatePayrollSetup(payrollSetup?.id));
          }
        }}
        defaultTitle="Payroll Setup"
        defaultTitleStyle={{
          fontSize: "24px",
          color: "#334155",
          fontWeight: 700,
        }}
      >
        <hr className="mt-1 mb-0" />
        <form onSubmit={handleSubmit}>
          <div className="flex justify-end px-6 mt-3">
            {activeSetupTab === "Components" ? (
              <LineButton
                label="Save & close"
                type={payrollSetup?.setup_complete ? "button" : "submit"}
                disabled={
                  isSubmitting || !payrollSetup || gross_percentages < 100
                }
                onClick={() => {
                  if (payrollSetup?.setup_complete) {
                    onClose();
                  }
                }}
              />
            ) : (
              <LineButton
                label="Save & continue"
                type={payrollSetup && check ? "button" : "submit"}
                disabled={isSubmitting}
                onClick={() => {
                  if (check) {
                    setActiveSetupTab("Components");
                  }
                }}
              />
            )}
          </div>
          <div className="mt-6 mx-auto payroll-setup-modal overflow-hidden">
            <div className="flex gap-6 min-h-[500px] overflow-hidden">
              <div className="md:min-w-[250px]  payroll-setup-modal-content-tab">
                <div>
                  <button
                    type={payrollSetup && check ? "button" : "submit"}
                    disabled={isSubmitting}
                    className={`mb-1 font-bold py-3 px-6 rounded text-left cursor-pointer text-[14px] w-full ${
                      activeSetupTab === "Policies"
                        ? "bg-[#2563EB] text-[#FFFFFF]"
                        : "text-[#475569] "
                    }`}
                    onClick={() => setActiveSetupTab("Policies")}
                  >
                    Policies
                  </button>
                </div>
                <div>
                  <button
                    type={payrollSetup && check ? "button" : "submit"}
                    disabled={isSubmitting}
                    className={`mb-1 font-bold py-3 px-6 rounded text-left cursor-pointer text-[14px] w-full ${
                      activeSetupTab === "Components"
                        ? "bg-[#2563EB] text-[#FFFFFF]"
                        : "text-[#475569] "
                    }`}
                    onClick={() => {
                      if (check) {
                        setActiveSetupTab("Components");
                      }
                    }}
                  >
                    Components
                  </button>
                </div>
                {/* <div>
                  <button
                    type={payrollSetup?.setup_complete ? "button" : "submit"}
                    disabled={
                      isSubmitting || !payrollSetup || gross_percentages < 100
                    }
                    className={`mb-1 font-bold py-3 px-6 rounded text-left cursor-pointer text-[14px] w-full ${
                      activeSetupTab === "Employees"
                        ? "bg-[#2563EB] text-[#FFFFFF]"
                        : "text-[#475569] "
                    }`}
                    onClick={() => {
                      if (payrollSetup?.setup_complete) {
                        onClose();
                      }
                    }}
                  >
                    Finish
                  </button>
                </div> */}
              </div>
              <div className="flex-grow payroll-setup-modal-content-box overflow-scroll">
                {activeSetupTab === "Policies" ? (
                  <div>
                    <div className="payroll-setup-modal-policies w-full border border-solid border-[#E2E8F0] min-h-[300px] rounded-lg py-3 pb-5 px-4">
                      <div className="payroll-setup-modal-policies-header flex items-center justify-between gap-2  py-1 mb-6 border-b border-solid border-[#F1F5F9]">
                        <div>
                          <h5 className="text-sm font-bold tracking-[0.1px] text-[#1e293b]">
                            Set up payroll policies
                          </h5>
                          <p className="text-[#475569] text-[11px] leading-[145.455%] font-medium tracking-[0.5px]">
                            Manage the policies of your payroll
                          </p>
                        </div>
                        {/* <div>
                          <button
                            type="button"
                            className="outline-none rounded bg-[#f1f5f9] p-[10px] text-[#475569] text-sm font-bold tracking-[0.1px]"
                            style={{
                              boxShadow:
                                "0px 1px 2px 0px rgba(37, 99, 235, 0.10)",
                            }}
                            onClick={() => {
                              setType("policy");
                              setDefaultPolicy(true);
                            }}
                          >
                            Use default policy
                          </button>
                        </div> */}
                      </div>

                      <div className="payroll-setup-modal-policies-body">
                        <div className=" xl:w-8/12 2xl:w-7/12 ">
                          <div className="payroll-setup-modal-form mb-6">
                            <label className="text-sm" htmlFor="">
                              When does your company pays?
                            </label>
                            <Select
                              value={values.payday}
                              name="payday"
                              onChange={(department) =>
                                setFieldValue("payday", department)
                              }
                              options={paydayOptions}
                              fetchingData={fetch}
                              placeholder="Choose Payday"
                              components={{ Menu: DeptMenu, Option }}
                              menuIsOpen={menuIsOpen}
                              onMenuClose={() => setMenuIsOpen(false)}
                              onMenuOpen={() => setMenuIsOpen(true)}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  minHeight: "48px",
                                  fontSize: "16px",
                                }),
                              }}
                            />
                            <div>
                              {errors.payday && touched.payday && (
                                <p className="error">{errors.payday?.label}</p>
                              )}
                            </div>
                            <div className="mt-5">
                              {custom && values.payday === "custom" && (
                                <Select
                                  value={values.custom_payday_day}
                                  name="custom_payday_day"
                                  onChange={(department) =>
                                    setFieldValue(
                                      "custom_payday_day",
                                      department
                                    )
                                  }
                                  options={menuOptions}
                                  placeholder="Select day"
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      minHeight: "48px",
                                      fontSize: "16px",
                                    }),
                                  }}
                                />
                              )}
                            </div>
                            <p className="info text-[11px] leading-[140%]">
                              Custom day that exceeds the number of days in a
                              month will be assumed as the last day of the month
                            </p>
                          </div>

                          <div className="payroll-setup-modal-form mb-6">
                            <label className="text-sm" htmlFor="">
                              Set leave allowance policy
                            </label>

                            <Select
                              value={values.leave_policy}
                              name="custom_payday_day"
                              onChange={(department) =>
                                setFieldValue("leave_policy", department)
                              }
                              options={LeaveOptions}
                              placeholder="Select Option"
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  minHeight: "48px",
                                  fontSize: "16px",
                                }),
                              }}
                            />

                            <div>
                              {errors.leave_policy && touched.leave_policy && (
                                <p className="error">
                                  {errors.leave_policy?.label}
                                </p>
                              )}
                            </div>
                            <p className="info text-[11px] leading-[140%]">
                              <span className="tx-blue cursor-pointer">
                                {" "}
                                Monthly leave policy
                              </span>{" "}
                              includes the leave allowance from a % in your
                              annual gross which is paid monthly. This is not an
                              extra pay outside your annual gross like the
                              one-off policy.
                            </p>
                          </div>

                          <div className="payroll-setup-modal-form mb-6">
                            <label className="text-sm" htmlFor="">
                              Pension computation
                            </label>
                            <p className="info text-[11px] leading-[140%] mt-2">
                              How does your organization compute pension?
                            </p>

                            <Select
                              value={values?.pension_computation}
                              name="pension_computation"
                              onChange={(department) =>
                                setFieldValue("pension_computation", department)
                              }
                              options={PensionOptions}
                              placeholder="Select Option"
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  minHeight: "48px",
                                  fontSize: "16px",
                                }),
                              }}
                            />

                            <div>
                              {errors.pension_computation &&
                                touched.pension_computation && (
                                  <p className="error">
                                    {errors.pension_computation?.label}
                                  </p>
                                )}
                            </div>
                            <p className="info text-[11px] leading-[140%]">
                              <span className="tx-blue">
                                Paid pension on gross{" "}
                              </span>{" "}
                              takes 8% of annual gross as pension fund
                            </p>
                          </div>
                        </div>

                        <div className="payroll-divider my-6"></div>

                        <div>
                          <h5 className="text-[#1E293B] text-sm tracking-[0.25px] font-normal">
                            Working Days
                          </h5>
                          <div className="flex items-center">
                            <p className="text-[#64748B] text-[11px] leading-[145.455$] tracking-[0.5px] font-medium my-2">
                              Choose the total number of working day in a month
                              for your organization
                            </p>
                            <div className="ml-1">
                              <Whisper
                                trigger="click"
                                placement="top"
                                controlId={`control-id-top`}
                                speaker={
                                  <Tooltip>
                                    {activeDay === "month"
                                      ? "This will apply to the total number of working days for each calendar month. E.g 1st to 30th"
                                      : "Choose a custom number of working days for your organization"}
                                  </Tooltip>
                                }
                              >
                                <span appearance="subtle">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M8 7.33333V10.6667M8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14ZM8.03333 5.33333V5.4H7.96667V5.33333H8.03333Z"
                                      stroke="#64748B"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              </Whisper>
                            </div>
                          </div>

                          <div className="flex gap-6 items-center">
                            <button
                              type="button"
                              className={`rounded border border-solid ${
                                activeDay === "absolute"
                                  ? "border-[#2563EB] text-[#2563EB]"
                                  : "border-[#64748b] text-[#475569]"
                              } p-2.5 text-sm tracking-[0.1px] font-bold min-w-[200px]`}
                              onClick={() => setActiveDay("absolute")}
                            >
                              Absolute days
                            </button>
                            <button
                              type="button"
                              className={`rounded border border-solid ${
                                activeDay === "month_on_month"
                                  ? "border-[#2563EB] text-[#2563EB]"
                                  : "border-[#64748b] text-[#475569]"
                              } p-2.5  text-sm tracking-[0.1px] font-bold min-w-[200px]`}
                              onClick={() => setActiveDay("month_on_month")}
                            >
                              Month on month
                            </button>
                          </div>
                          {activeDay === "absolute" && (
                            <div className="mt-6 mb-6">
                              <div className={`input-box`}>
                                <input
                                  type="number"
                                  autoComplete="off"
                                  id="total_number_of_work_days"
                                  name="total_number_of_work_days"
                                  value={values?.total_number_of_work_days}
                                  onChange={handleChange}
                                />
                                <label
                                  htmlFor="username"
                                  className="label-name"
                                >
                                  <span className="label-text">
                                    Number of absolute working days
                                  </span>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="payroll-divider my-6"></div>
                        <div className="payroll-setup-modal-overtime-policy">
                          <div className="p-4 border border-solid border-[#E2E8F0] rounded w-full flex gap-2 justify-between">
                            <div className="flex gap-[2px] items-center">
                              <h6 className="mb-0">Overtime policy</h6>
                              <div>
                                <Whisper
                                  trigger="click"
                                  placement="top"
                                  controlId={`control-id-top`}
                                  speaker={
                                    <Tooltip>
                                      Employees who work overtime will be paid
                                      extra hours worked
                                    </Tooltip>
                                  }
                                >
                                  <span appearance="subtle">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M8 7.33333V10.6667M8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14ZM8.03333 5.33333V5.4H7.96667V5.33333H8.03333Z"
                                        stroke="#64748B"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </Whisper>
                              </div>
                            </div>
                            <div className="payroll-setup-modal-overtime-policy-toggle">
                              <label class="toggle">
                                <input
                                  type="checkbox"
                                  id="overtime_policy"
                                  name="overtime_policy"
                                  checked={values?.overtime_policy}
                                  onChange={handleChange}
                                />
                                <span class="toggle-slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="payroll-setup-modal-components-header flex items-center justify-between gap-2  py-1 mb-6 border-b border-solid border-[#F1F5F9]  mx-3">
                      <div>
                        <h5 className="text-sm font-bold tracking-[0.1px] text-[#1e293b]">
                          Payroll components
                        </h5>
                        <p className="text-[#475569] text-[11px] leading-[145.455%] font-medium tracking-[0.5px]">
                          Manage the component of your payroll
                        </p>
                      </div>
                      {/* <div>
                        <button
                          type="button"
                          className="outline-none rounded bg-[#f1f5f9] p-[10px] text-[#475569] text-sm font-bold tracking-[0.1px]"
                          style={{
                            boxShadow:
                              "0px 1px 2px 0px rgba(37, 99, 235, 0.10)",
                          }}
                          onClick={() => {
                            setDefaultPolicy(true);
                          }}
                          disabled={
                            gross_percentages === 100 ||
                            gross_percentages === 99.9
                          }
                        >
                          Use default components
                        </button>
                      </div> */}
                    </div>
                    <div className="payroll-setup-modal-components w-full border border-solid border-[#E2E8F0] min-h-[200px] rounded-lg py-3 pb-5 px-4 mb-6">
                      <div className="payroll-setup-modal-components-header flex items-center justify-between gap-2  py-1 mb-6 border-b border-solid border-[#F1F5F9]">
                        <div>
                          <h5 className="text-sm font-bold tracking-[0.1px] text-[#1e293b]">
                            Gross components
                          </h5>
                          <p className="text-[#475569] text-[11px] leading-[145.455%] font-medium tracking-[0.5px]">
                            Manage the gross component of your payroll
                          </p>
                        </div>
                      </div>

                      <div className="payroll-setup-modal-components-body">
                        <div className="w-full rounded-lg bg-[#EFF6FF] border border-solid border-[#2563eb] px-6 py-2 min-h-[64px] flex items-center gap-3">
                          <div>
                            <img
                              src={LightBulb}
                              className="w-[25px] h-[25px]"
                              alt=""
                            />
                          </div>
                          <div>
                            <p className="text-[#64748B] text-[12px] font-normal leading-[133.33%] tracking-[0.4px]">
                              <span className="font-bold"> Note:</span> Basic,
                              housing and transport allowance makes up 47% of
                              the annual gross which are default components. The
                              percentage can be increased for each component but
                              can not be decreased. The left % (53% or less)
                              will then spread across other allowances you would
                              wish to add
                            </p>
                          </div>
                        </div>
                        <div className="my-6">
                          {grossComponents?.map((component) => (
                            <div
                              className="p-4 border border-solid border-[#E2E8F0] rounded w-full flex gap-2 justify-between mb-4"
                              key={component?.id}
                            >
                              <div className="flex gap-[2px] items-center">
                                <h6 className="mb-0 text-[#1E293B] text-sm tracking-[0.1px]">
                                  <span className="capitalize">
                                    {component?.name}
                                  </span>{" "}
                                  {component?.name === "basic"
                                    ? "salary at"
                                    : "allowance at"}
                                  <span className="tx-blue ml-1">
                                    ({component?.percentage}%)
                                  </span>
                                </h6>
                                <div>
                                  <Whisper
                                    trigger="click"
                                    placement="top"
                                    controlId={`control-id-top`}
                                    speaker={
                                      <Tooltip>
                                        {component?.name === "basic"
                                          ? "Gross percentage will be distributed across BTH with a 33.3% for each. You cannot add a custom component if this is active"
                                          : component?.name === "housing"
                                          ? "Housing allowance by default has 20% of annual gross. You can choose to increase the percentage but cannot go below 20%"
                                          : component?.name === "transport"
                                          ? "Transport allowance by default has 15% of annual gross. You can choose to increase the percentage but cannot go below 15%"
                                          : "Leave allowance by default has 10% of annual gross. You can choose to increase the percentage but cannot go below 10%"}
                                      </Tooltip>
                                    }
                                  >
                                    <span appearance="subtle">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 7.33333V10.6667M8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14ZM8.03333 5.33333V5.4H7.96667V5.33333H8.03333Z"
                                          stroke="#64748B"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </Whisper>
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <button
                                  type="button"
                                  className="outline-none rounded bg-[#f1f5f9] flex items-center gap-1 py-[4px] px-[6px]"
                                  style={{
                                    boxShadow:
                                      "0px 1px 2px 0px rgba(37, 99, 235, 0.10)",
                                  }}
                                  onClick={() => {
                                    setType("edit");
                                    setData(component);
                                    setNewComponentupModal(true);
                                  }}
                                >
                                  <span className="pen">
                                    <img src={Pen} alt="" />
                                  </span>
                                  <span className="text text-[#475569] text-sm font-bold tracking-[0.1px]">
                                    edit
                                  </span>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>

                        {gross_percentages > 0 && gross_percentages < 100 && (
                          <div className="mt-6">
                            <button
                              type="button"
                              className="add-user-btn flex items-center gap-2 mb-3"
                              onClick={() => {
                                setType("add");
                                setNewComponentupModal(true);
                              }}
                            >
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                                    fill="#2563EB"
                                  />
                                </svg>
                              </span>

                              <span className="text">Add custom component</span>
                            </button>
                            <span className="text-[#B45309] text-[10px] mt-[4rem]">
                              Gross compoonent cannot be less than 100%. You
                              have {100 - gross_percentages}% left
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="payroll-setup-modal-components w-full border border-solid border-[#E2E8F0] min-h-[200px] rounded-lg py-3 pb-5 px-4 mb-6">
                      <div className="payroll-setup-modal-components-header flex items-center justify-between gap-2  py-1 mb-6 border-b border-solid border-[#F1F5F9]">
                        <div>
                          <h5 className="text-sm font-bold tracking-[0.1px] text-[#1e293b]">
                            Relief components
                          </h5>
                          <p className="text-[#475569] text-[11px] leading-[145.455%] font-medium tracking-[0.5px]">
                            Manage the relief component of your payroll
                          </p>
                        </div>
                      </div>

                      <div className="payroll-setup-modal-components-body">
                        <div className="mb-6">
                          <h5 className="text-[#1E293B] text-sm tracking-[0.25px] font-normal">
                            Numbers of employees
                          </h5>
                          <p className="text-[#64748B] text-[11px] leading-[145.455$] tracking-[0.5px] font-medium my-2">
                            How many employees does your organization consist
                            of?
                          </p>

                          <div className="flex gap-6 items-center">
                            <button
                              type="button"
                              className={`rounded border border-solid ${
                                employees === "<15"
                                  ? "border-[#2563EB] text-[#2563EB]"
                                  : "border-[#64748b] text-[#475569]"
                              } p-2.5 text-sm tracking-[0.1px] font-bold min-w-[200px]`}
                              onClick={() => setEmployees("<15")}
                              // className="rounded border border-solid border-[#64748b] p-2.5 text-[#475569] text-sm tracking-[0.1px] font-bold min-w-[200px]"
                            >
                              Less than 15 employees
                            </button>
                            <button
                              type="button"
                              className={`rounded border border-solid ${
                                employees === "<25"
                                  ? "border-[#2563EB] text-[#2563EB]"
                                  : "border-[#64748b] text-[#475569]"
                              } p-2.5 text-sm tracking-[0.1px] font-bold min-w-[200px]`}
                              onClick={() => setEmployees("<25")}
                              // className="rounded border border-solid border-[#64748b] p-2.5 text-[#475569] text-sm tracking-[0.1px] font-bold min-w-[200px]"
                            >
                              15 - 25 employees
                            </button>
                            <button
                              type="button"
                              className={`rounded border border-solid ${
                                employees === ">25"
                                  ? "border-[#2563EB] text-[#2563EB]"
                                  : "border-[#64748b] text-[#475569]"
                              } p-2.5 text-sm tracking-[0.1px] font-bold min-w-[200px]`}
                              onClick={() => setEmployees(">25")}
                              // className="rounded border border-solid border-[#64748b] p-2.5 text-[#475569] text-sm tracking-[0.1px] font-bold min-w-[200px]"
                            >
                              More than 25 employees
                            </button>
                          </div>
                          {employees === "<15" && (
                            <span className="text-[#B45309] text-[10px] mt-[4rem]">
                              Pension funds is not necessary for organization
                              with less than 15 employees
                            </span>
                          )}
                        </div>

                        <div className="payroll-setup-modal-overtime-policy">
                          {reliefComponents?.map((component) => (
                            <div
                              className="p-4 border border-solid border-[#E2E8F0] rounded w-full flex gap-2 justify-between"
                              key={component?.id}
                            >
                              <div className="flex gap-[2px] items-center">
                                <h6 className="mb-0 text-[#1E293B] text-sm tracking-[0.1px]">
                                  {component?.name?.replace(/_/g, " ")} at
                                  <span className="tx-blue">
                                    ({component?.percentage}%)
                                  </span>
                                </h6>
                                <div>
                                  <Whisper
                                    trigger="click"
                                    placement="top"
                                    controlId={`control-id-top`}
                                    speaker={
                                      <Tooltip>
                                        This takes 8% of annual BHT or annual
                                        gross of the employee.
                                      </Tooltip>
                                    }
                                  >
                                    <span appearance="subtle">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 7.33333V10.6667M8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14ZM8.03333 5.33333V5.4H7.96667V5.33333H8.03333Z"
                                          stroke="#64748B"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </Whisper>
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                {employees === "<15" &&
                                component?.name === "nhf" ? null : (
                                  <button
                                    type="button"
                                    className="outline-none rounded bg-[#f1f5f9] flex items-center gap-1 py-[4px] px-[6px]"
                                    style={{
                                      boxShadow:
                                        "0px 1px 2px 0px rgba(37, 99, 235, 0.10)",
                                    }}
                                    onClick={() => {
                                      setData(component);
                                      setEditReliefmodal(true);
                                    }}
                                  >
                                    <span className="pen">
                                      <img src={Pen} alt="" />
                                    </span>
                                    <span className="text text-[#475569] text-sm font-bold tracking-[0.1px]">
                                      edit
                                    </span>
                                  </button>
                                )}
                                <div
                                  className={
                                    employees === ">25" &&
                                    component?.name !== "nhf"
                                      ? "payroll-modal-setup-overtime-policy-toggle-disable"
                                      : employees === "<25" &&
                                        component?.name !== "nhf"
                                      ? "payroll-modal-setup-overtime-policy-toggle-disable"
                                      : "payroll-setup-modal-overtime-policy-toggle"
                                  }
                                >
                                  <label
                                    class={
                                      employees === ">25" &&
                                      component?.name !== "nhf"
                                        ? "toggle-disable"
                                        : employees === "<25" &&
                                          component?.name !== "nhf"
                                        ? "toggle-disable"
                                        : "toggle"
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={component.is_active}
                                      onChange={(event) =>
                                        handleReliefChange(event, component?.id)
                                      }
                                      disabled={
                                        employees === ">25" &&
                                        component?.name !== "nhf"
                                          ? true
                                          : employees === "<25" &&
                                            component?.name !== "nhf"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span class="toggle-slider"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="payroll-setup-modal-components w-full border border-solid border-[#E2E8F0] min-h-[200px] rounded-lg py-3 pb-5 px-4 mb-6">
                      <div className="payroll-setup-modal-components-header flex items-center justify-between gap-2  py-1 mb-6 border-b border-solid border-[#F1F5F9]">
                        <div>
                          <h5 className="text-sm font-bold tracking-[0.1px] text-[#1e293b]">
                            Mandatory remittance components
                          </h5>
                          <p className="text-[#475569] text-[11px] leading-[145.455%] font-medium tracking-[0.5px]">
                            Manage the mandatory remittance component of your
                            payroll
                          </p>
                        </div>
                      </div>

                      <div className="payroll-setup-modal-components-body">
                        <div className="payroll-setup-modal-overtime-policy mt-6">
                          {remmitalComponents?.map((component, index) => (
                            <div
                              className="p-4 border border-solid border-[#E2E8F0] rounded w-full flex gap-2 justify-between mb-4"
                              key={index}
                            >
                              <div className="flex gap-[2px] items-center">
                                <h6 className="mb-0 text-[#1e293b] text-sm tracking-[0.1px]">
                                  {component?.name?.replace(/_/g, " ")} at
                                  <span className="tx-blue">
                                    ({component?.percentage})
                                  </span>
                                </h6>
                                <div>
                                  <Whisper
                                    trigger="click"
                                    placement="top"
                                    controlId={`control-id-top`}
                                    speaker={
                                      <Tooltip>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Dolore deserunt iste
                                        magni delectus magnam mollitia.
                                      </Tooltip>
                                    }
                                  >
                                    <span appearance="subtle">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8 7.33333V10.6667M8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 7.21207 2.15519 6.43185 2.45672 5.7039C2.75825 4.97595 3.20021 4.31451 3.75736 3.75736C4.31451 3.20021 4.97595 2.75825 5.7039 2.45672C6.43185 2.15519 7.21207 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14ZM8.03333 5.33333V5.4H7.96667V5.33333H8.03333Z"
                                          stroke="#64748B"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </Whisper>
                                </div>
                              </div>

                              <div
                                className={
                                  employees === ">25"
                                    ? "payroll-modal-setup-overtime-policy-toggle-disable"
                                    : employees === "<25" &&
                                      component?.name === "nsitf"
                                    ? "payroll-modal-setup-overtime-policy-toggle-disable"
                                    : employees === "<15" &&
                                      component?.name === "nsitf"
                                    ? "payroll-modal-setup-overtime-policy-toggle-disable"
                                    : "payroll-setup-modal-overtime-policy-toggle"
                                }
                              >
                                <label
                                  class={
                                    employees === ">25"
                                      ? "toggle-disable"
                                      : employees === "<25" &&
                                        component?.name === "nsitf"
                                      ? "toggle-disable"
                                      : employees === "<15" &&
                                        component?.name === "nsitf"
                                      ? "toggle-disable"
                                      : "toggle"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={component?.is_active}
                                    onChange={(event) =>
                                      handleRemittalChange(event, component?.id)
                                    }
                                    disabled={
                                      employees === ">25"
                                        ? true
                                        : employees === "<25" &&
                                          component?.name === "nsitf"
                                        ? true
                                        : employees === "<15" &&
                                          component?.name === "nsitf"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span class="toggle-slider"></span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>

        <DefaultPolicy
          modalOpen={defaultPolicy}
          onClose={() => setDefaultPolicy(false)}
          grossComponents={grossComponents}
          leave_policy={payrollSetup?.leave_policy}
        />
        <NewComponentModal
          modalOpen={newComponentModal}
          onClose={() => setNewComponentupModal(false)}
          type={type}
          data={data}
          setData={setData}
          gross_percentages={gross_percentages}
        />
        <EditReliefModal
          modalOpen={editRelieftModal}
          onClose={() => setEditReliefmodal(false)}
          reliefComponents={reliefComponents}
          setReliefComponents={setReliefComponents}
          data={data}
        />
      </MainModal>
    </>
  );
};

export default PayrollSetupMainModal;
