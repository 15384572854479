import React from "react";

const LineTab = ({ activeTab, data }) => {
  return (
    <div className="flex gap-6">
      {data?.map((item, index) => (
        <div
          key={index}
          onClick={() => item.action()}
          className="cursor-pointer"
        >
          <p
            className={`
            text-sm font-semibold leading-5 pt-1 pb-[6px]
            ${activeTab === item.id ? "text-[#2563EB]" : "text-txgray2"}
            `}
          >
            {item.name}
          </p>
          {activeTab === item.id && (
            <div className="bg-[#2563EB] h-[4px] rounded-t-[100px]"></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LineTab;
