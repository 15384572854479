import { useFormik } from "formik";
import React, { useState } from "react";
import MainModal from "../auth-component/MainModal";
import { toast } from "react-toastify";
import { createMerchantRoles } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RoleformSchema } from "../schema";

const CreateRoleModal = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();
  const tenantData = JSON.parse(localStorage.getItem("tenant_data")) ?? {};
  const [selectedItems, setSelectedItems] = useState([]);
  const { permissions } = useSelector((state) => state?.users);
  
  // Functions
  const onSubmit = async (values, actions) => {
    const toastId = toast.loading("Creating role, please wait...");
    const { name } = values;
    const data = {
      name,
      permissions: selectedItems,
      tenant_id: tenantData?.tenant_id,
    };
    const res = await dispatch(createMerchantRoles(data));
    if (res.error) {
      toast.update(toastId, {
        render: res.error.message,
        type: "error",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
    if (res.payload.status < 400) {
      toast.update(toastId, {
        render: res?.payload?.message || "Role created successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm();
      setSelectedItems([])
      onClose();
    } else {
      toast.update(toastId, {
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const checkAllFunction = (source) => {
    const find_types = source?.actions.map((item) => item?.value);
    const filtered_arrays = selectedItems?.filter(
      (item) => !find_types.includes(item)
    );
    const check_idx = find_types.every((v) => selectedItems.includes(v));
    if (!check_idx) {
      setSelectedItems([...selectedItems, ...find_types]);
    } else {
      setSelectedItems(filtered_arrays);
    }
  };
  const toggleRowSelect = (item) => {
    const newSelectedItems = [...selectedItems];
    const findIndex = selectedItems.indexOf(item);
    if (findIndex > -1) {
      newSelectedItems.splice(findIndex, 1);
      setSelectedItems(newSelectedItems);
    } else {
      newSelectedItems.push(item);
      setSelectedItems(newSelectedItems);
    }
  };

  // fORMIK
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: RoleformSchema,
    onSubmit,
  });
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Create a new role"
      >
        <div className="mt-3 xl:w-[90%] mx-auto">
          <div className="upload-from-csv">
            <div className="flex gap-2 items-start">
              <span className="mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="14"
                  viewBox="0 0 10 14"
                  fill="none"
                >
                  <path
                    d="M3.00016 13.0002C3.00016 13.3668 3.30016 13.6668 3.66683 13.6668H6.3335C6.70016 13.6668 7.00016 13.3668 7.00016 13.0002V12.3335H3.00016V13.0002ZM5.00016 0.333496C2.42683 0.333496 0.333496 2.42683 0.333496 5.00016C0.333496 6.58683 1.12683 7.98016 2.3335 8.82683V10.3335C2.3335 10.7002 2.6335 11.0002 3.00016 11.0002H7.00016C7.36683 11.0002 7.66683 10.7002 7.66683 10.3335V8.82683C8.8735 7.98016 9.66683 6.58683 9.66683 5.00016C9.66683 2.42683 7.5735 0.333496 5.00016 0.333496ZM6.90016 7.7335L6.3335 8.1335V9.66683H3.66683V8.1335L3.10016 7.7335C2.20016 7.10683 1.66683 6.08683 1.66683 5.00016C1.66683 3.16016 3.16016 1.66683 5.00016 1.66683C6.84016 1.66683 8.3335 3.16016 8.3335 5.00016C8.3335 6.08683 7.80016 7.10683 6.90016 7.7335Z"
                    fill="#2563EB"
                  />
                </svg>
              </span>
              <p>
                Roles help you manage users you onboard. Determine what modules
                they can see and what they can do in such module.
              </p>
            </div>

            <form className="row" onSubmit={handleSubmit}>
              <div className="mt-10">
                <div
                  className={`input-box ${
                    !errors.name && touched.name && values.name
                      ? "filled"
                      : errors.name && touched.name
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder="e.g admin.."
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="name" className="label-name">
                    <span className="label-text">Role Name </span>
                  </label>
                </div>
                <div>
                  {errors.name && touched.name && (
                    <p className="error">{errors.name}</p>
                  )}
                </div>
              </div>
              <div>
                <div className="flex justify-space-between items-center mt-5 ml-[-1rem]">
                  {permissions?.map((item, index) => {
                    let arrayIds = [];
                    item?.actions?.forEach((type) => {
                      arrayIds.push(type?.value);
                    });
                    const is_checked = arrayIds.every((v) =>
                      selectedItems.includes(v)
                    );
                    return (
                      <div key={index}>
                        <div
                          className={`flex gap-2 items-start sm:px-4 mb-3 ${
                            !errors.terms && touched.terms && values.terms
                              ? "filled"
                              : errors.terms && touched.terms
                              ? "error"
                              : ""
                          }`}
                        >
                          <input
                            checked={is_checked}
                            onChange={() => checkAllFunction(item)}
                            className="mt-1"
                            type="checkbox"
                          />
                          <p className="">{item?.name}</p>
                        </div>
                        {item?.actions?.map((field, idx) => {
                          return (
                            <div
                              key={idx}
                              className={`flex gap-2 items-start sm:px-4 ml-5 ${
                                !errors.terms && touched.terms && values.terms
                                  ? "filled"
                                  : errors.terms && touched.terms
                                  ? "error"
                                  : ""
                              }`}
                            >
                              <input
                                checked={selectedItems?.includes(field?.value)}
                                onChange={() => toggleRowSelect(field?.value)}
                                className="mt-1"
                                type="checkbox"
                              />
                              <p className="">{field?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex justify-between items-center w-[30%] mt-10">
                <button
                  className="create_role_cancel_button"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button disabled={isSubmitting} className="create_role_save_button" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default CreateRoleModal;
