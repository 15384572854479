import React, { useEffect } from "react";
import MainModal from "../../../../../component/MainModal.";
import { FaPercent } from "react-icons/fa";
import { useFormik } from "formik";
import { GrossComponentSchema } from "../../../../../authPages/schema";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateComponent,
  UpdateComponent,
} from "../../../../../store/slices/PayrollSlice";

const NewComponentModal = ({
  modalOpen,
  onClose,
  size,
  type,
  data,
  setData,
  gross_percentages,
}) => {
  const { payrollSetup } = useSelector((state) => state?.payroll);
  const dispatch = useDispatch();

  const closemodal = () => {
    setData({});
    onClose();
  };

  // async functions
  const onSubmit = async (values, actions) => {
    const TOAST_ID = toast.loading("Creating component.., please wait...");

    const res = await dispatch(
      type === "add"
        ? CreateComponent({
            ...values,
            payroll_id: payrollSetup?.id,
            is_active: true,
          })
        : UpdateComponent({
            ...values,
            payroll_id: payrollSetup?.id,
            is_active: true,
            component_id: data?.id
          })
    );
    if (res.payload.status < 400) {
      actions.resetForm();
      toast.update(TOAST_ID, {
        render: res?.payload?.data?.message,
        type: "sucecess",
        isLoading: false,
        autoClose: 1000,
      });
      closemodal();
    } else {
      toast.update(TOAST_ID, {
        render:
          res.payload.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      percentage: "",
    },
    validationSchema: GrossComponentSchema,
    onSubmit,
  });

  useEffect(() => {
    if (data) {
      setFieldValue("name", data?.name);
      setFieldValue("percentage", data.percentage);
    }
  }, [data])
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={closemodal}
        defaultTitle={
          type === "add" ? "Add Custom Component" : "Edit Custom Component"
        }
      >
        <div className="mt-3 xl:w-[100%] mx-auto">
          <form onSubmit={handleSubmit}>
            <div>
              <div
                className={`input-box ${
                  !errors.name && touched.name && values.name
                    ? "filled"
                    : errors.name && touched.name
                    ? "error"
                    : ""
                }`}
              >
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={type === "edit"}
                />
                {type !== "edit" && (
                  <label htmlFor="email" className="label-name">
                    <span className="label-text">Name of gross component </span>
                  </label>
                )}
              </div>
              <div>
                {errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}
              </div>
            </div>
            <div className="mt-5">
              <div
                className={`input-password-box ${
                  !errors.percentage && touched.percentage && values.percentage
                    ? "filled"
                    : errors.username && touched.percentage
                    ? "error"
                    : ""
                }`}
              >
                <input
                  type="number"
                  name="percentage"
                  autoComplete="off"
                  id="percentage"
                  value={values.percentage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={
                    data?.name === "basic"
                      ? 12
                      : data?.name === "housing"
                      ? 20
                      : data?.name === "transport"
                      ? 15
                      : data?.name === "leave"
                      ? 10
                      : 1
                  }
                  max={
                    type === "edit"
                      ? (100 - gross_percentages) +
                        Number(data?.percentage)
                      : (100 - gross_percentages)
                  }
                />
                <span className="view" style={{ cursor: "pointer" }}>
                  <FaPercent />
                </span>
                <label htmlFor="email" className="label-name">
                  <span className="label-text">Perentage</span>
                </label>
              </div>
              <div>
                {errors.percentage && touched.percentage && (
                  <p className="error">{errors.percentage}</p>
                )}
              </div>
            </div>
            <div className="upload-from-csv-box-three mt-8">
              <button type="submit" disabled={isSubmitting}>
                {type === "add" ? "Add Salary Component" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </MainModal>
    </>
  );
};

export default NewComponentModal;
