import Login from "../authPages/pages/AuthLogin";
import SignUp from "../authPages/pages/AuthSignUp";
import ChooseOrganization from "../authPages/pages/ChooseOrganization";
import ChooseFeatures from "../authPages/pages/ChooseFeatures";
import AddUsers from "../authPages/pages/AddUsers";
import ResetPassword from "../authPages/pages/ResetPassword";
import NewPassword from "../authPages/pages/NewPassword";
import VerifyEmail from "../authPages/pages/VerifyEmail";
import EmailConfirmed from "../authPages/pages/emailConfirmation/EmailConfirmed";
import OnBoarding from "../onboarding/OnboardingLayout";
import AccountVerification from "../authPages/pages/emailConfirmation/AccountCreateProcess";
import Verification from "../authPages/pages/emailConfirmation/EmailConfirmed";
import SelectWorkSpace from "../authPages/pages/SelectWorkSpace";

const AuthRouter = [
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/sign-up",
    element: <SignUp />,
  },
  {
    path: "/auth/choose-organization",
    element: <ChooseOrganization />,
  },
  {
    path: "/auth/choose-features",
    element: <ChooseFeatures />,
  },
  {
    path: "/auth/add-users",
    element: <AddUsers />,
  },
  {
    path: "/auth/forgot-password",
    element: <ResetPassword />,
  },
  {
    path: "/auth/reset-password",
    element: <NewPassword />,
  },
  {
    path: "/auth/email-confirmed/",
    element: <EmailConfirmed />,
  },
  {
    path: "/auth/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/auth/onboarding",
    element: <OnBoarding />,
  },
  {
    path: "/auth/account-verification",
    element: <AccountVerification />,
  },
  { path: "*", element: <Login /> },
  {
    path: "/auth/workspaces",
    element: <SelectWorkSpace />,
  },
];

export default AuthRouter;
