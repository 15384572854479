import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import close from "./../../../../../asset/svgs/close-circle.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./../../../../../utils/https";
import PinInput from "react-pin-input";
import styled from "styled-components";
import ButtonFilled from "../../../../../component/atoms/ButtonFilled";

const StyledPin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    border: 1px solid #cbd5e1 !important;
    border-radius: 8px !important;
    gap: 8px !important;
    width: 48px !important;
    height: 60px !important;
  }
`;
export default function VerifyOtp({ openConfirmOtp, onClose, payrollId }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(59);
  const [showResend, setShowResend] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (timeLeft === 0) {
      setShowResend(true);
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const handleResend = () => {
    setShowResend(false);
    setTimeLeft(59);
    sendOtpMutation.mutate();
  };

  //mutations
  const sendOtpMutation = useMutation({
    mutationFn: () => axiosInstance().post("/otps/transaction/send"),
    onSuccess: () => {
      toast.success("OTP sent successfully!");
    },
    onError: (error) => {
      toast.error("Error sending OTP, please try again!");
    },
  });

  const verifyOtpMutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/otps/transaction/verify", payload),
    onSuccess: () => {
      toast.success("OTP verified!");
      checkoutMutation.mutate({
        payroll_record_ids: [payrollId],
      });
    },
    onError: (error) => {
      toast.error("Error verifying OTP, please try again!");
    },
  });

  const checkoutMutation = useMutation({
    mutationFn: (payload) => axiosInstance().post(`payroll/checkout`, payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      navigate("/dashboard/payroll");
      queryClient.invalidateQueries({
        queryKey: ["branchesPayrollRecord", "payrollDetails"],
        refetchType: "active",
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
  return (
    <Modal
      sx={{ padding: "16px 24px" }}
      open={openConfirmOtp}
      onClose={onClose}
    >
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <div>
          <p className="text-txgray2 text-[22px] font-semibold">Enter OTP</p>
          <span className="text-txgray2 text-[12px] font-medium">
            Enter the 6-digit code sent to your email and phonenumber
          </span>
        </div>
        <IconButton onClick={onClose}>
          <img src={close} alt="close" />
        </IconButton>
      </div>
      <div className="flex flex-col items-center px-8">
        <StyledPin>
          <PinInput
            length={6}
            secret
            secretDelay={300}
            onComplete={(value) => {
              setOtp(value);
            }}
            type="numeric"
            inputMode="number"
          />
        </StyledPin>
        <p className="text-sm text-txgray2 font-normal mt-6 flex gap-1">
          Didn’t get code?{" "}
          {showResend ? (
            <button onClick={handleResend} className="text-[#2563EB]">
              Resend
            </button>
          ) : (
            <div>Resend in 00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}</div>
          )}
        </p>
        <ButtonFilled
          style={{ marginTop: "48px", width: "100%", justifyContent: "center" }}
          onClick={() =>
            verifyOtpMutation.mutate({
              code: otp,
            })
          }
          disabled={otp.length < 6}
          loading={verifyOtpMutation.isPending}
        >
          Pay Employees
        </ButtonFilled>
      </div>
    </Modal>
  );
}
