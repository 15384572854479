import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../utils/https";
import Loader from "../../../../../component/atoms/Loader";
import breadcrumbarrow from "../../../../../asset/svgs/breadcrumbarrow.svg";
import payrollIcon from "./../../../../../asset/svgs/payroll.svg";
import peopleIcon from "./../../../../../asset/svgs/people.svg";
import calendarIcon from "./../../../../../asset/svgs/calendar.svg";
import naira from "./../../../../../asset/svgs/NairaBlack.svg";
import cardIcon from "./../../../../../asset/svgs/blue_credit_card.svg";
import tagIcon from "./../../../../../asset/svgs/tag.svg";
import xls from "./../../../../../asset/svgs/xls.svg";
import csv from "./../../../../../asset/svgs/csv.svg";
import pdf from "./../../../../../asset/svgs/pdf.svg";
import { formatDate, formatDateWithDay } from "../../../../../utils";
import {
  Dropdown,
  Input,
  InputGroup,
  Pagination,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import downloadIcon from "./.././../../../../asset/file_download.svg";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";

export default function BranchPayslip() {
  const { id } = useParams();
  const { Column, HeaderCell, Cell } = Table;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  //queries
  const { data: payrollDetails, isPending } = useQuery({
    queryKey: ["payrollDetails", id],
    queryFn: async () => {
      const response = await axiosInstance().get(
        `/payroll/records/${id}/details`
      );
      return response?.data?.data;
    },
    refetchOnMountOrArgChange: true,
  });

  const payslipIds = payrollDetails?.payslips?.results?.map(
    (payslip) => payslip.id
  );

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      axiosInstance().post("/payslips/history/export", payload, {
        responseType: "arraybuffer",
      }),
    onSuccess: (response) => {
      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], {
        type: contentType || "application/pdf",
      });
      saveAs(blob, "payslip.pdf");
      toast.success("Exporting ...");
    },
    onError: (error) => {
      toast.error("Error exporting payslip, please try again");
    },
  });

  //datas
  const filteredData = payrollDetails?.payslips?.results?.filter((employee) =>
    employee?.fullname?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const data = filteredData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  const NairaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="flex items-center gap-1">
          <img src={naira} alt="naira" />
          {Number(rowData[dataKey]).toLocaleString()}
        </div>
      </Cell>
    );
  };
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell
        {...props}
        className="text-sm text-[#2563EB] font-bold cursor-pointer"
        onClick={() =>
          navigate(
            `/dashboard/payroll/employee-payslip/${rowData[dataKey]}/${payrollDetails?.branch}`
          )
        }
      >
        View Payslip
      </Cell>
    );
  };

  return (
    <>
      {isPending ? (
        <div className="flex justify-center items-center mt-[10rem]">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="payroll px-4 lg:px-9 pt-6">
            <div className="flex items-cemmter justify-between">
              <ul className="payroll-bread-crumb flex items-center gap-1 m-0">
                <li className="payroll-bread-crumb-active">
                  <p className="text-[#2563EB]">Human Resource</p>
                </li>
                <img src={breadcrumbarrow} alt="arrow" className="px-2" />
                <li>
                  <p className="text-[#2563EB]">Payroll</p>
                </li>
                <img src={breadcrumbarrow} alt="arrow" className="px-2" />
                <li>
                  <p className="text-[#2563EB]">Run Payroll</p>
                </li>
                <img src={breadcrumbarrow} alt="arrow" className="px-2" />
                <li>
                  <p className="text-[#64748B]">{payrollDetails?.branch}</p>
                </li>
              </ul>
            </div>
            <div className="flex items-center gap-4 lg:mb-0 mt-6">
              <p className="text-txgray2 text-lg lg:text-[22px] font-semibold">
                Payroll Summary
              </p>
              {payrollDetails?.status === "pending" ? (
                <div className="bg-[#FFFBEB] rounded py-[2px] px-2 flex gap-2 items-center">
                  <span className="text-[#D97706] capitalize font-bold">
                    {payrollDetails?.status}
                  </span>
                </div>
              ) : payrollDetails?.status === "approved" ? (
                <div className="bg-[#EFF6FF] rounded py-[2px] px-2 flex gap-2 items-center">
                  <span className="text-[#2563EB] capitalize font-bold">
                    {payrollDetails?.status}
                  </span>
                </div>
              ) : payrollDetails?.status === "declined" ? (
                <div className="bg-[#e11d472d] rounded py-[2px] px-2 flex gap-2 items-center">
                  <span className="text-[#E11D48] capitalize font-bold">
                    {payrollDetails?.status}
                  </span>
                </div>
              ) : payrollDetails?.status === "partially_paid" ? (
                <div className="bg-[#FFFBEB] rounded py-[2px] px-2 flex gap-2 items-center">
                  <span className="text-[#D97706] capitalize font-bold">
                    {payrollDetails?.status}
                  </span>
                </div>
              ) : payrollDetails?.status === "paid" ? (
                <div className="bg-[#ECFDF5] rounded py-[2px] px-2 flex gap-2 items-center">
                  <span className="text-[#059669] capitalize font-bold">
                    {payrollDetails?.status}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4">
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={payrollIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Total Payroll</p>
                  <p className="text-base lg:text-[22px] font-semibold rounded-[4px] mt-1 flex items-center">
                    <sup className="">
                      <img src={naira} alt="naira" className="w-3 h-3" />
                    </sup>
                    {Number(
                      payrollDetails?.net_pay
                    ).toLocaleString()}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={payrollIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Employee Net Salary</p>
                  <p className="text-base lg:text-[22px] font-semibold rounded-[4px] mt-1 flex items-center">
                    <sup className="">
                      <img src={naira} alt="naira" className="w-3 h-3" />
                    </sup>
                    {Number(payrollDetails?.net_pay).toLocaleString()}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={peopleIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Employee paid</p>
                  <p className="text-base lg:text-[22px] font-semibold mt-1">
                    {payrollDetails?.payslips?.number_of_items}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={calendarIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Pay Period</p>
                  <p className="text-base lg:text-[22px] font-semibold mt-1">
                    {formatDate(payrollDetails?.payment_date)}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={calendarIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Payment Date</p>
                  <p className="text-base lg:text-[22px] font-semibold mt-1">
                    {formatDateWithDay(payrollDetails?.payment_date)}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={payrollIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Total Taxes</p>
                  <p className="text-base lg:text-[22px] font-semibold rounded-[4px] mt-1 flex items-center">
                    <sup className="">
                      <img src={naira} alt="naira" className="w-3 h-3" />
                    </sup>
                    {Number(payrollDetails?.total_tax_paid).toLocaleString()}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={tagIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Total Deductions</p>
                  <p className="text-base lg:text-[22px] font-semibold rounded-[4px] mt-1 flex items-center">
                    <sup className="">
                      <img src={naira} alt="naira" className="w-3 h-3" />
                    </sup>
                    {Number(
                      payrollDetails?.total_deducted_amount
                    ).toLocaleString()}
                  </p>
                </div>
              </div>
              <div
                className="bg-[#FFFFFF]  rounded-sm p-4 flex gap-3 items-center min-w-[224px]"
                style={{
                  boxShadow: "2px 2px 8px 0px #9494940D",
                  border: "1px solid #F1F5F9",
                }}
              >
                <img src={cardIcon} alt="icon" />
                <div>
                  <p className="text-sm font-normal">Payment Method</p>
                  <p className="text-base lg:text-[22px] font-semibold mt-1">
                    {payrollDetails?.payment_method === "virtual_account"
                      ? "FUSE ERP wallet"
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 bg-[#FFFFFF] px-4 lg:px-10 py-6">
            <div className="flex justify-between items-center my-5">
              <div className="flex flex-row gap-2 w-full justify-between">
                <InputGroup className="!w-[90%] md:!w-[543px] h-[42px]">
                  <InputGroup.Addon>
                    {" "}
                    <SearchIcon />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Search for employee name"
                    value={search}
                    onChange={setSearch}
                  />
                </InputGroup>
                <Whisper
                  placement="autoVerticalEnd"
                  trigger="click"
                  speaker={
                    <Popover visible>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item eventKey={2}>
                          <div className="flex items-center gap-2">
                            <img src={xls} alt="edit" />
                            <span className="text-txgray2 text-sm font-bold">
                              {" "}
                              Export as XLS (Excel)
                            </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={3}>
                          <div className="flex items-center gap-2">
                            <img src={csv} alt="edit" />
                            <span className="text-txgray2 text-sm font-bold">
                              {" "}
                              Export as CSV
                            </span>
                          </div>
                        </Dropdown.Item> */}
                        <Dropdown.Item
                          eventKey={3}
                          onClick={() =>
                            mutation.mutate({
                              payslip_history_ids: payslipIds,
                            })
                          }
                        >
                          <div className="flex items-center gap-2">
                            <img src={pdf} alt="edit" />
                            <span className="text-txgray2 text-sm font-bold">
                              {" "}
                              Export as PDF
                            </span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Popover>
                  }
                >
                  <button
                    className={`text-sm bg-[#FFFFFF] border-[1px] border-[#E2E8F0] font-bold text-[#475569] py-[10px] px-4 rounded flex gap-2 items-center`}
                  >
                    <img src={downloadIcon} alt="icon" />
                    Export
                  </button>
                </Whisper>
              </div>
            </div>
            <Table
              height={400}
              data={data}
              onRowClick={(rowData) => {}}
              className="border-[1px] border-[#F1F5F9] rounded-lg cursor-pointer"
            >
              <Column align="left" width={130}>
                <HeaderCell>Employee Name</HeaderCell>
                <Cell dataKey="fullname" className="text-txblue" />
              </Column>
              <Column width={130} align="left">
                <HeaderCell>Gross Salary</HeaderCell>
                <NairaCell dataKey="monthly_gross_salary" />
              </Column>
              <Column align="left" width={130}>
                <HeaderCell>Deductions</HeaderCell>
                <NairaCell dataKey="total_deducted_amount" />
              </Column>
              <Column align="left" width={130}>
                <HeaderCell>Taxes</HeaderCell>
                <NairaCell dataKey="tax_paid" />
              </Column>
              <Column align="left" width={130}>
                <HeaderCell>Benefits</HeaderCell>
                <NairaCell dataKey="total_benefit_amount" />
              </Column>
              <Column align="left" width={130}>
                <HeaderCell>Bonus</HeaderCell>
                <NairaCell dataKey="total_bonus_amount" />
              </Column>
              <Column width={130} align="left">
                <HeaderCell>Net Salary</HeaderCell>
                <NairaCell dataKey="net_pay" />
              </Column>
              <Column width={130} align="left">
                <HeaderCell>Action</HeaderCell>
                <ActionCell dataKey="id" />
              </Column>
            </Table>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["total", "-", "limit", "|", "pager", "skip"]}
                total={payrollDetails?.payslips.results.length}
                limitOptions={[10, 30, 50]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
