import { useFormik } from "formik";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../asset/auth/second-logo.svg";
import { NewPassword_ } from "../../store/slices/userSlice";
import LoginLayout from "../layout/LoginLayout";
import { newPasswordFormSchema } from "../schema";

const NewPassword = () => {
  const [viewPasswordOne, setViewPasswordOne] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get("uid");
  const token = queryParams.get("token");

  const onSubmit = async (values, actions) => {
    const NEW_PSW_TOAST_ID = toast.loading(
      "Resetting password, please wait..."
    );
    const { password } = values;

    const data = {
      password,
      user_id,
      token,
    };
    const res = await dispatch(NewPassword_(data));
    if (res.payload.status < 400) {
      toast.update(NEW_PSW_TOAST_ID, {
        render: res.payload?.data.message
          ? res.payload?.data.message
          : "New password is succesful!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      setTimeout(() => {
        navigate("/auth/login");
      }, 3500);
    } else {
      toast.update(NEW_PSW_TOAST_ID, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: newPasswordFormSchema,
    onSubmit,
  });

  return (
    <LoginLayout>
      <div className="row">
        <div className="2xl:w-9/12 xl:w-10/12 mx-auto">
          <div className="second-auth-container-box-header mb-5">
            <div className="logo-box">
              <img src={Logo} alt="" />
            </div>
            <h5>Reset Password</h5>
            <p>Choose a new password</p>
          </div>

          <div className="second-auth-container-box-body mt-14">
            <form className="row" onSubmit={handleSubmit}>
              <div className="mb-6">
                <div
                  className={`input-password-box ${
                    !errors.password && touched.password && values.password
                      ? "filled"
                      : errors.password && touched.password
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type={viewPasswordOne ? "text" : "password"}
                    name="password"
                    id="password"
                    autocomplete="off"
                    required
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span
                    className="view"
                    style={{ cursor: "pointer" }}
                    onClick={() => setViewPasswordOne(!viewPasswordOne)}
                  >
                    {viewPasswordOne ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <label htmlFor="password" className="label-name">
                    <span className="label-text">Enter new password </span>
                  </label>
                </div>
                <div>
                  {errors.password && touched.password && (
                    <p className="error">{errors.password}</p>
                  )}
                </div>
              </div>

              <div className="submit-btn mt-8">
                <button disabled={isSubmitting}>Set new password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default NewPassword;
