import React, { useState } from "react";
import FileUpload from "../auth-component/FileUpload";
import MainModal from "../auth-component/MainModal";
import CSV from "../../asset/auth/csv-icon.svg";
import { toast } from "react-toastify";
import { sendVerficationLink, uploadUsers } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const UploadFromCsv = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tenantData = JSON.parse(localStorage.getItem("tenant_data")) ?? {};
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDownload = () => {
    window.location.href =
      "https://fuseerp-api-liveapp-bucket.s3.eu-west-1.amazonaws.com/templates/addEmployee.xlsx";
  };
  const tenant_id = tenantData?.tenant_id;

  const SendVerificationLink = async (toast_id) => {
    const body = {
      email: tenantData?.email,
    };
    const res = await dispatch(sendVerficationLink(body));
    if (res.payload.status < 400) {
      toast.update(toast_id, {
        render:
          res?.payload?.message ||
          res?.payload?.data?.message ||
          "Login successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      setTimeout(() => {
        toast.update(toast_id, {
          render: "Redirecting to account verification, please wait...",
          type: "info",
          isLoading: false,
          autoClose: 1000,
        });
      }, 1100);

      setTimeout(() => {
        navigate("/auth/account-verification");
      }, 2200);
    } else {
      toast.update(toast_id, {
        render:
          res.payload.message === "Request failed with status code 500"
            ? "Something Went Wrong"
            : res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  //submit formdata
  const handleSubmit = async (event) => {
    const toastId = toast.loading("Submitting File, please wait...");
    event.preventDefault();
    const data = {
      selectedFile,
      tenant_id,
    };
    const res = await dispatch(uploadUsers(data));
    if (res?.payload?.status < 400) {
      SendVerificationLink();
    } else {
      toast.update(toastId, {
        render: res?.payload?.message || "Something Went Wrong",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Upload from CSV"
      >
        <div className="mt-3 xl:w-[90%] mx-auto">
          <div className="upload-from-csv">
            <div className="upload-from-csv-box-one flex flex-col sm:flex-row gap-2 items-center ">
              <p>
                Fill up the essential information in the template after
                downloading, then upload.
              </p>

              <div className="flex flex-col items-center justify-center">
                <img
                  style={{ width: "48px", height: "48px" }}
                  src={CSV}
                  alt=""
                />
                <button onClick={handleDownload}>Download template</button>
              </div>
            </div>

            <form className="row" onSubmit={handleSubmit}>
              <div className="upload-from-csv-box-two">
                <FileUpload
                  fileType="CSV"
                  // acceptedFile=".xlsx"
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </div>

              <div className="upload-from-csv-box-three">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default UploadFromCsv;
