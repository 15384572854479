import React, { useState } from "react";
import SelectPaymentMethod from "../../../../dashboard-component/select/SelectPaymentMethod";
import LineButton from "../../../../../component/atoms/LineButton";
import BlueBotton from "../../../../../component/atoms/BlueBotton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axiosInstance from "../../../../../utils/https";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { isEmpty } from "../../../../../utils";
import { SelectPicker } from "rsuite";

export default function CheckOutPayroll({
  selectedEmployees,
  setConfirm,
  setCheckout,
  setSelect,
  setSuccess,
  setRunPayrollPage,
  setPayrollSummary,
}) {
  // hooks
  const { branchId } = useParams();
  const [value, setValue] = useState("");
  const queryClient = useQueryClient();

  console.log(selectedEmployees);
  //mutations
  const mutation = useMutation({
    mutationFn: (payload) => axiosInstance().post(`payroll/process`, payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setSuccess("processing");
      setRunPayrollPage("success");
      setCheckout("processed");
      setPayrollSummary(data?.data?.data?.results[0]);
      queryClient.invalidateQueries({
        queryKey: ["branchesPayrollRecord"],
        refetchType: "active",
      });
    },
    onError: (error) => {
      toast.error("Error processing payroll, please try again!");
    },
  });

  //queries
  const { data: dashboardData, isPending } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: async () => {
      const response = await axiosInstance().get(`/tenants/dashboard`);
      return response?.data?.data;
    },
  });

  //datas
  const selectedEmployeesId = selectedEmployees?.map((item) => item.user_id);

  return (
    <div
      className="flex flex-col lg:flex-row gap-6 mt-8"
      style={{ height: "calc(100% - 90px)" }}
    >
      <div className="flex ps-4 pe-4 lg:ps-10 lg:pe-6  flex-col justify-between border-r-[1px] border-r-[#BFDBFE] w-full lg:w-3/5 h-full">
        <div>
          <div className="mb-6">
            <p className="text-[#1E293B] text-[24px] font-semibold">
              Payment type
            </p>
            <p className="text-[#475569] text-sm m-0">
              Choose a payment action type
            </p>
          </div>
          <SelectPicker
            data={[
              { label: "Pay Instantly", value: "pay_instantly" },
              {
                label: "Pay on payroll payment date",
                value: "pay_on_payment_date",
              },
            ]}
            onChange={(type) => setValue(type)}
            value={value}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "48px",
                fontSize: "16px",
                width: "100%",
              }),
            }}
            className="w-full"
          />

          {/* condition to check if wwallet balance can make this payment, or if you have created a wallet
           */}
          {isPending && value ? (
            <div className="flex justify-center mt-2">
              <CircularProgress
                sx={{
                  width: "30px !important",
                  height: "30px !important",
                }}
              />
            </div>
          ) : dashboardData?.account_details?.balance <
              selectedEmployees.reduce((acc, curr) => {
                return parseFloat(acc) + parseFloat(curr.monthly_gross_salary);
              }, 0) && value ? (
            <p className="mt-2 text-[#E11D48]">
              Insufficient funds. please fund your FUSE ERP wallet and try
              again!
            </p>
          ) : isEmpty(dashboardData?.account_details) && value ? (
            <p className="mt-2 text-[#E11D48]">
              you've not activated your FUSE ERP wallet, please head to the
              dashboard and generate your FUSE ERP wallet
            </p>
          ) : null}
        </div>
        <div className="hidden lg:flex mt-4 gap-4 fixed bottom-4">
          <LineButton
            label="Cancel"
            onClick={() => {
              setConfirm("unprocessed");
              setCheckout("unprocessed");
              setSelect("processing");
              setRunPayrollPage("selectEmployees");
            }}
          />
          <BlueBotton
            label="Generate payroll"
            loading={mutation.isPending}
            onClick={() => {
              mutation.mutate({
                branch_ids: [branchId],
                user_ids: selectedEmployeesId,
                payment_action: value,
              });
            }}
            disabled={
              dashboardData?.account_details?.balance <
                selectedEmployees.reduce((acc, curr) => {
                  return (
                    parseFloat(acc) + parseFloat(curr.monthly_gross_salary)
                  );
                }, 0) ||
              isEmpty(dashboardData?.account_details) ||
              !value
            }
          />
        </div>
      </div>
      <div className="w-full lg:w-2/5 lg:pr-6 pr-4 ps-4">
        <div className="mb-4">
          <p className="text-[#1E293B] text-[24px] font-semibold">
            Payment Summary
          </p>
          <p className="text-[#475569] text-sm m-0">
            Running payroll for {selectedEmployees.length} employees
          </p>
        </div>
        <div
          className="overflow-y-scroll h-[60vh]"
          style={{
            scrollbarColor: "rgba(0,0,0,0)",
            overflowY: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {selectedEmployees?.map((item, index) => (
            <div className="flex items-center justify-between" key={index}>
              <p>
                {item.last_name} {item.first_name}{" "}
              </p>
              <p>{Number(item.net_pay).toLocaleString()}</p>
            </div>
          ))}
        </div>
        <div className="border-t-[1px] border-b-[1px] border-[#BFDBFE] flex">
          <p className="px-[16px] py-[14px] w-full text-right">Total</p>
          <p className="px-[16px] py-[14px] w-full justify-end m-0 flex items-center gap-1">
            <svg
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.333333 4.94444H1.81481V0.5H3.2963L5.82963 4.94444H9.22222V0.5H10.7037V4.94444H12.1852V6.42593H10.7037V7.90741H12.1852V9.38889H10.7037V13.8333H9.22222L6.68148 9.38889H3.2963V13.8333H1.81481V9.38889H0.333333V7.90741H1.81481V6.42593H0.333333V4.94444ZM3.2963 4.94444H4.13333L3.2963 3.48519V4.94444ZM3.2963 6.42593V7.90741H5.82963L4.98518 6.42593H3.2963ZM9.22222 10.8704V9.38889H8.37037L9.22222 10.8704ZM6.67407 6.42593L7.52593 7.90741H9.22222V6.42593H6.67407Z"
                fill="#475569"
              />
            </svg>
            <span>
              {selectedEmployees
                .reduce((acc, curr) => {
                  return parseFloat(acc) + parseFloat(curr.net_pay);
                }, 0)
                .toLocaleString()}
            </span>
          </p>
        </div>
        <div className="flex lg:hidden my-4 gap-4">
          <LineButton
            label="Cancel"
            onClick={() => {
              setConfirm("unprocessed");
              setCheckout("unprocessed");
              setSelect("processing");
              setRunPayrollPage("selectEmployees");
            }}
          />
          <BlueBotton
            label="Generate payroll"
            loading={mutation.isPending}
            onClick={() => {
              mutation.mutate({
                branch_ids: [branchId],
                user_ids: selectedEmployeesId,
                payment_action: value,
              });
            }}
            disabled={
              dashboardData?.account_details?.balance <
                selectedEmployees.reduce((acc, curr) => {
                  return (
                    parseFloat(acc) + parseFloat(curr.monthly_gross_salary)
                  );
                }, 0) ||
              isEmpty(dashboardData?.account_details) ||
              !value
            }
          />
        </div>
      </div>
    </div>
  );
}
