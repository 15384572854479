import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Layout() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();



  const urlParams = Object.fromEntries([...new URLSearchParams(search)])

  useEffect(() => {
    if (search) {
      localStorage.setItem('email', urlParams);
    }
  }, [search]);

  useEffect(() => {
    switch (pathname) {
      case "/":
        navigate("/auth/login");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  return (
    <>
        <Outlet />
    </>
  );
}
