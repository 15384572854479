import * as yup from "yup";
import { useFormik } from "formik";
import React from "react";
import MainModal from "../auth-component/MainModal";
import { toast } from "react-toastify";
import { createPosition } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";

const CreateCustomPosition = ({ modalOpen, onClose, size }) => {
  const dispatch = useDispatch();

  // Functions
  const onSubmit = async (values, actions) => {
    const toastId = toast.loading("Creating position, please wait...");
    const res = await dispatch(createPosition(values));
    if (res.payload.status < 400) {
      toast.update(toastId, {
        render: res?.payload?.data?.message || "Position created successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      actions.resetForm();
      onClose();
    } else {
      toast.update(toastId, {
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  // fORMIK
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Enter desired position name"),
    }),
    onSubmit,
  });
  return (
    <>
      <MainModal
        size={size || "sm"}
        modalOpen={modalOpen}
        onClose={onClose}
        defaultTitle="Add new position"
      >
        <div className="xl:w-[90%] mx-auto">
          <div className="upload-from-csv">
            <form className="row" onSubmit={handleSubmit}>
              <div className="mt-10">
                <div
                  className={`input-box ${
                    !errors.name && touched.name && values.name
                      ? "filled"
                      : errors.name && touched.name
                      ? "error"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder="Position name."
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  {errors.name && touched.name && (
                    <p className="error">{errors.name}</p>
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center w-[30%] mt-10">
                <button
                  className="create_role_cancel_button"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="create_role_save_button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default CreateCustomPosition;
