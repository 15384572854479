import React from "react";
import { Dialog, IconButton } from "@mui/material";
import cancel from "./../../../../../asset/CloseBtn.svg";
import LineButton from "../../../../../component/atoms/LineButton";
import BlueBotton from "../../../../../component/atoms/BlueBotton";

export default function ConfirmRunPayroll({
  ToggleOpenConfirm,
  open,
  setRunPayrollPage,
  setConfirm,
  setCheckout,
}) {
  return (
    <Dialog
      onClose={ToggleOpenConfirm}
      open={open}
      sx={{
        ".MuiPaper-root": {
          padding: "24px 48px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          maxWidth: "560px",
        },
      }}
    >
      <div className="flex items-center justify-between gap-5">
        <p className="text-txgray3 text-[22px] font-semibold">
          Confirm run all employee payroll?
        </p>
        <IconButton onClick={ToggleOpenConfirm}>
          <img src={cancel} alt="close" />
        </IconButton>
      </div>
      <p className="text-txgray text-sm font-semibold">
        Are you sure you want to run all employee payroll at once?
      </p>
      <div className="flex mt-4 gap-4">
        <LineButton label="No, Cancel" onClick={ToggleOpenConfirm} />
        <BlueBotton
          label="Yes, Run Payroll"
          onClick={() => {
            ToggleOpenConfirm();
            setRunPayrollPage("checkout");
            setConfirm("processed");
            setCheckout("processing");
          }}
        />
      </div>
    </Dialog>
  );
}
