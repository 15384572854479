import React from "react";
import CloseBtn from "../../asset/CloseBtn.svg";

import { Modal } from "rsuite";

// Style can be passed into the component to override the default style, classNames can also be passed into the component to overide the default classes i.e modal-box col-lg-5 col-md-8 col-sm-10 col-11

const MainModal = ({
  children,
  modalOpen,
  size,
  overflow,
  title,
  onClose,
  headerStyle,
  defaultTitle,
  noClose,
}) => {
  return (
    <Modal
      overflow={overflow || true}
      size={size || "lg"}
      open={modalOpen}
      onClose={onClose}
    >
      <Modal.Header
        closeButton={false}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Modal.Title style={{ fontWeight: "bold", fontSize: "22px" }}>{defaultTitle}</Modal.Title>
        {title && (
          <h2
            style={{ letterSpacing: "-2px", ...headerStyle }}
            className="main-modal-title tx-blue"
          >
            {title}
          </h2>
        )}
        {!noClose && (
          <div className="cursor-pointer" onClick={onClose}>
            <img src={CloseBtn} alt="CloseBtn" />
          </div>
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default MainModal;
