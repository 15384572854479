import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import close from "./../../../../asset/svgs/close-circle.svg";
import lamp from "./../../../../asset/svgs/lamp-on.svg";
import { CircularProgress, IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import BlueBotton from "../../../../component/atoms/BlueBotton";
import styled from "styled-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "./../../../../utils/https";
import Button from "../../../../component/atoms/Button";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import RoleCheckBox from "../settings-component/RoleCheckBox";

const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: transparent;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding: 12px 16px;
    outline: none;
    color: #1e293b;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    z-index: 0;
  }
  .error {
    border: 1px solid #f43f5e;
    color: #f43f5e;
  }
  .field_error {
    color: #f43f5e;
    font-size: 11px;
    margin-top: 4px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
`;
export default function CreateRole({ open, onClose, actionType, roleData }) {
  //state
  const queryClient = useQueryClient();

  const defaultPermissions = [
    {
      group: [
        {
          name: "Add Role",
          value: "group.add",
          access: false,
        },
        {
          name: "Edit Role",
          value: "group.edit",
          access: false,
        },
        {
          name: "Delete Role",
          value: "group.delete",
          access: false,
        },
        {
          name: "View Role",
          value: "group.view",
          access: false,
        },
      ],
    },
    {
      payroll: [
        {
          name: "Approve / Decline Payroll",
          value: "payroll.approve_or_decline",
          access: false,
        },
        {
          name: "Disburse Payroll",
          value: "payroll.disburse",
          access: false,
        },
        {
          name: "Process Payroll",
          value: "payroll.process",
          access: false,
        },
        {
          name: "Setup Payroll",
          value: "payroll.setup",
          access: false,
        },
        {
          name: "View Payroll Setup",
          value: "payroll.view",
          access: false,
        },
      ],
    },
    {
      employee: [
        {
          name: "Add / Onboard Employee",
          value: "employee.add",
          access: false,
        },
        {
          name: "Edit Employee",
          value: "employee.edit",
          access: false,
        },
        {
          name: "Delete Employee",
          value: "employee.delete",
          access: false,
        },
        {
          name: "View Employee",
          value: "employee.view",
          access: false,
        },
        {
          name: "Approve / Decline Employee's Application",
          value: "employee.approve_or_decline",
          access: false,
        },
      ],
    },
    {
      team_member: [
        {
          name: "Add / Remove Team Member",
          value: "team_member.add_or_remove",
          access: false,
        },
        {
          name: "View Team Member",
          value: "team_member.view",
          access: false,
        },
      ],
    },
  ];
  const [permissions, setPermissions] = useState([
    {
      group: [
        {
          name: "Add Role",
          value: "group.add",
          access: false,
        },
        {
          name: "Edit Role",
          value: "group.edit",
          access: false,
        },
        {
          name: "Delete Role",
          value: "group.delete",
          access: false,
        },
        {
          name: "View Role",
          value: "group.view",
          access: false,
        },
      ],
    },
    {
      payroll: [
        {
          name: "Approve / Decline Payroll",
          value: "payroll.approve_or_decline",
          access: false,
        },
        {
          name: "Disburse Payroll",
          value: "payroll.disburse",
          access: false,
        },
        {
          name: "Process Payroll",
          value: "payroll.process",
          access: false,
        },
        {
          name: "Setup Payroll",
          value: "payroll.setup",
          access: false,
        },
        {
          name: "View Payroll Setup",
          value: "payroll.view",
          access: false,
        },
      ],
    },
    {
      employee: [
        {
          name: "Add / Onboard Employee",
          value: "employee.add",
          access: false,
        },
        {
          name: "Edit Employee",
          value: "employee.edit",
          access: false,
        },
        {
          name: "Delete Employee",
          value: "employee.delete",
          access: false,
        },
        {
          name: "View Employee",
          value: "employee.view",
          access: false,
        },
        {
          name: "Approve / Decline Employee's Application",
          value: "employee.approve_or_decline",
          access: false,
        },
        {
          name: "Approve / Decline Employee's Application",
          value: "employee.approve_or_decline",
          access: false,
        },
      ],
    },
    {
      team_member: [
        {
          name: "Add / Remove Team Member",
          value: "team_member.add_or_remove",
          access: false,
        },
        {
          name: "View Team Member",
          value: "team_member.view",
          access: false,
        },
      ],
    },
  ]);
  //schema
  const schema = yup.object().shape({
    name: yup.string().required("please enter role name"),
  });

  //mutations
  const mutation = useMutation({
    mutationFn: (payload) =>
      actionType === "edit"
        ? axiosInstance().put(`groups/${roleData?.id}/edit`, payload)
        : axiosInstance().post(`groups/add`, payload),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      onClose();
      setPermissions(defaultPermissions);
      queryClient.invalidateQueries({
        queryKey: ["getAllRoles"],
        refetchType: "active",
      });
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  //formik
  const formik = useFormik({
    initialValues: {
      name: actionType === "edit" ? roleData?.name : "",
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      mutation.mutate({
        name: values.name,
        description: "..",
        permissions: permissions,
      });
    },
  });

  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    formik;

  //query
  const { data, isPending } = useQuery({
    queryKey: ["getRoles"],
    queryFn: async () => {
      const response = await axiosInstance().get(
        "/utils/organization-permissions"
      );
      return response.data.data.permissions;
    },
  });

  // functions
  useEffect(() => {
    if (actionType === "edit") {
      setPermissions(roleData?.permissions);
    } else {
      setPermissions(defaultPermissions);
    }
  }, [roleData, actionType]);

  // useEffect(() => console.log(roleData));

  return (
    <Modal
      sx={{ padding: "16px 24px" }}
      open={open}
      onClose={() => {
        onClose();
        setPermissions(defaultPermissions);
      }}
    >
      <div className="flex justify-between items-center border-b-[1px] border-[#F1F5F9] mb-6 pb-2">
        <p className="text-txgray2 text-[22px] font-semibold">
          {actionType === "edit" ? "Edit role" : "Create a new role"}
        </p>
        <IconButton
          onClick={() => {
            onClose();
            setPermissions(defaultPermissions);
          }}
        >
          <img src={close} alt="close" />
        </IconButton>
      </div>
      <div className="flex items-start gap-2 px-3 py-2 bg-[#EFF6FF] border-[#DBEAFE] border-[1px] rounded mb-4">
        <img src={lamp} alt="close" />
        <p>
          Roles help you manage users you onboard. Determine what modules they
          can see and what they can do in such module.
        </p>
      </div>
      <form>
        <StyledInputWrapper>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Role name"
            value={values?.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.name && touched.name && "error"}
          />
          {errors.name && touched.name && (
            <p className="field_error">{errors.name}</p>
          )}
        </StyledInputWrapper>
      </form>
      <div className="">
        <p className="text-[#334155] text-[16px] font-semibold my-4">
          Add role permissions
        </p>

        {isPending ? (
          <div className="flex justify-center mt-2">
            <CircularProgress
              sx={{
                width: "30px !important",
                height: "30px !important",
              }}
            />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-y-3">
            <RoleCheckBox
              data={data[0]?.group}
              title={"Role"}
              dataKey="group"
              setPermissions={setPermissions}
              permissions={permissions}
            />
            <RoleCheckBox
              data={data[1]?.payroll}
              title={"Payroll"}
              dataKey="payroll"
              setPermissions={setPermissions}
              permissions={permissions}
            />
            <RoleCheckBox
              data={data[2]?.employee}
              title={"Employee"}
              dataKey="employee"
              setPermissions={setPermissions}
              permissions={permissions}
            />
            <RoleCheckBox
              data={data[3]?.team_member}
              title={"Team"}
              dataKey="team_member"
              setPermissions={setPermissions}
              permissions={permissions}
            />
          </div>
        )}
      </div>

      <div className="flex justify-end gap-3  py-6">
        <Button onClick={() => onClose()}>Cancel</Button>
        <BlueBotton
          loading={mutation.isPending}
          label={actionType === "edit" ? "Save" : "Create role"}
          onClick={handleSubmit}
          disabled={
            !permissions
              ?.flatMap((obj) => Object.values(obj))
              .flatMap((arr) => arr)
              .some((permission) => permission.access === true)
          }
        />
      </div>
    </Modal>
  );
}
