/** @format */

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function BlueBotton({ onClick, disabled, label, loading, type, ...props }) {
  return (
    <div {...props} className="">
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={` ${
          loading ? "px-8" : null
        } text-sm bg-[#2563EB] font-bold text-[#FFFFFF] py-[10px] px-4  rounded-md`}
      >
        {loading ? (
          <CircularProgress
            sx={{
              color: "#ffffffd1",
              width: "20px !important",
              height: "20px !important",
            }}
          />
        ) : (
          label
        )}
      </button>
    </div>
  );
}

export default BlueBotton;
