import React from "react";

export default function Button({ children, onClick }) {
  return (
    <button
      className={`text-sm bg-[#F1F5F9] font-bold text-[#475569] py-[10px] px-4 rounded-md flex gap-2 items-center`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
