import { IconButton } from "@mui/material";
import React from "react";
import { Modal } from "rsuite";
import styled from "styled-components";
import close from "./../../asset/svgs/close-circle.svg";
import successfull from "./../../asset/svgs/successfull.svg";
import toast from "react-hot-toast";

const CustomModal = styled(Modal)`
  width: 382px !important;
  .rs-modal-content {
    padding: 0px;
  }
`;
export default function WalletCreated({ open, onClose, walletData }) {
  return (
    <CustomModal open={open} onClose={onClose}>
      <div className="flex flex-col items-center bg-[#EFF6FF] pb-9 ">
        <div className="flex justify-end w-full">
          <IconButton onClick={onClose}>
            <img src={close} alt="close" />
          </IconButton>
        </div>
        <div className="flex flex-col items-center gap-1">
          <img src={successfull} alt="successfull" />
          <p className="text-txgray2 text-[16px] font-normal">
            Wallet successfully created
          </p>
        </div>
      </div>

      <>
        <div className="px-6 py-4 bg-white">
          <p className="text-sm text-txgray2">
            Fund your wallet by sending money to account number.
          </p>

          <div className="mt-4 py-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
            <span className="flex flex-col">
              <span className="font-bold text-txgray2 text-[16px]">
                Account Name
              </span>
              <span className="font-normal text-txgray2 text-sm">
                {walletData?.account_name}
              </span>
            </span>
            <span
              className="font-bold text-txgray2 text-sm border-[#E2E8F0] border-[1px] rounded p-2 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(walletData?.account_name);
                toast.success("Copied!");
              }}
            >
              copy
            </span>
          </div>
          <div className="py-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
            <span className="flex flex-col">
              <span className="font-bold text-txgray2 text-[16px]">
                Account Number
              </span>
              <span className="font-normal text-txgray2 text-sm">
                {walletData?.account_number}
              </span>
            </span>
            <span
              className="font-bold text-txgray2 text-sm border-[#E2E8F0] border-[1px] rounded p-2 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(walletData?.account_number);
                toast.success("Copied!");
              }}
            >
              copy
            </span>
          </div>
          <div className="py-4 flex justify-between items-center border-b-[1px] border-[#F1F5F9]">
            <span className="flex flex-col">
              <span className="font-bold text-txgray2 text-[16px]">
                Bank Name
              </span>
              <span className="font-normal text-txgray2 text-sm">
                {walletData?.bank_name}
              </span>
            </span>
            <span
              className="font-bold text-txgray2 text-sm border-[#E2E8F0] border-[1px] rounded p-2 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(walletData?.bank_name);
                toast.success("Copied!");
              }}
            >
              copy
            </span>
          </div>
        </div>
        <div className="flex gap-6 justify-end py-6 px-6">
          <button
            className="bg-[#F1F5F9] px-4 py-2 rounded"
            onClick={onClose}
            type="button"
          >
            Close
          </button>
        </div>
      </>
    </CustomModal>
  );
}
