import React, { useEffect, useState } from "react";
// import Upload from "../assets/images/upload.svg";
import Upload from "./../../../asset/svgs/folder.svg";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../store/slices/userSlice";
import toast from "react-hot-toast";

const ImageUploader = ({
  id,
  label,
  setImageUrl,
  selectedFile,
  setSelectedFile,
}) => {
  // redux
  const dispatch = useDispatch();

  //  Async functions
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const path = URL.createObjectURL(file);

    if (file.size > 2097152) {
      toast.error("File size should not exceed 2MB");
    } else {
      setSelectedFile(path);
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadFile(formData));
      if (response?.payload?.status < 400) {
        const responseData = response?.payload.data?.data;
        setImageUrl(responseData?.url);
      } else {
        toast.error("An error occured, please try again");
      }
    }
  };

  return (
    <div className="w-full">
      <div>
        <p className="text-txgray text-[13px] font-medium mb-2">{label}</p>
        {selectedFile ? (
          <div className="relative">
            <div
              style={{
                backgroundImage: `url(${selectedFile})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className="w-full h-[144px]"
            ></div>
            <button
              onClick={() => setSelectedFile("")}
              className="text-[#3B82F6] font-normal text-[12px] px-2 py-1 border-[1px] border-[#E2E8F0] rounded-[2px] absolute bottom-2 right-2 bg-[#FFFFFF] "
            >
              Change Image
            </button>
          </div>
        ) : (
          <div className=" border-[#CBD5E1]  h-[144px] border-[1px] border-dashed p-6 flex flex-col items-center rounded-[2px] cursor-pointer">
            <label
              htmlFor={`file-upload-input-${id}`}
              className="flex flex-col items-center cursor-pointer gap-2"
            >
              <div className="upload-icon">
                <img src={Upload} alt="Upload Icon" />
              </div>
              <div className="text-center">
                <p className="text-txgray text-[12px] font-medium leading-4">
                  Drop your image or click to upload
                </p>
                <p className="text-[#94A3B8] text-[12px] font-normal leading-4 m-0">
                  Supported file types: JPG & PNG
                </p>
              </div>
              <span className="text-txgray2 font-normal text-[12px] px-2 py-1 border-[1px] border-[#E2E8F0] rounded-[2px] ">
                Browse
              </span>
            </label>
            <input
              type="file"
              id={`file-upload-input-${id}`}
              className="file-upload-input"
              accept=".jpeg,.png"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {selectedFile && (
              <div className="selected-file">
                <p>Selected File: {selectedFile.name}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
